import React, { useEffect, useState, Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField, SelectField, AutocompleteField } from '../../_presentationals/Form';
import { editValueAction, recordsActions } from '../../_actions';
import { ListItemAdd } from './listItemAdd';

import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

const availableMethods = [
  {key: "01", value: "Efectivo"},
  {key: "02", value: "Cheque nominativo"},
  {key: "03", value: "Transferencia electrónica de fondos"},
  {key: "04", value: "Tarjeta de crédito"},
  {key: "05", value: "Monedero electrónico"},
  {key: "06", value: "Dinero electrónico"},
  {key: "15", value: "Condonación"},
  {key: "17", value: "Compensación"},
  {key: "23", value: "Novación"},
  {key: "28", value: "Tarjeta de débito"},
  {key: "30", value: "Aplicación de anticipos"},
  {key: "31", value: "Intermediario pagos"},
  {key: "99", value: "Por definir"}
];

const aviableUseCdfi = [
  {
    "key": "G01",
    "value": "Adquisición de mercancias",
    
  },
  {
    "key": "G02",
    "value": "Devoluciones, descuentos o bonificaciones"
  },
  {
    "key": "G03",
    "value": "Gastos en general"
  },
  {
    "key": "I01",
    "value": "Construcciones"
  },
  {
    "key": "I02",
    "value": "Mobilario y equipo de oficina por inversiones"
  },
  {
    "key": "I03",
    "value": "Equipo de transporte"
  },
  {
    "key": "I04",
    "value": "Equipo de computo y accesorios",
  },
  {
    "key": "I05",
    "value": "Dados, troqueles, moldes, matrices y herramental"
  },
  {
    "key": "I06",
    "value": "Comunicaciones telefónicas"
  },
  {
    "key": "I07",
    "value": "Comunicaciones satelitales"
  },
  {
    "key": "I08",
    "value": "Otra maquinaria y equipo"
  },
  {
    "key": "D01",
    "value": "Honorarios médicos, dentales y gastos hospitalarios."
  },
  {
    "key": "D02",
    "value": "Gastos médicos por incapacidad o discapacidad"
  },
  {
    "key": "D03",
    "value": "Gastos funerales."
  },
  {
    "key": "D04",
    "value": "Donativos."
  },
  {
    "key": "D05",
    "value": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)."
  },
  {
    "key": "D06",
    "value": "Aportaciones voluntarias al SAR."
  },
  {
    "key": "D07",
    "value": "Primas por seguros de gastos médicos."
  },
  {
    "key": "D08",
    "value": "Gastos de transportación escolar obligatoria."
  },
  {
    "key": "D09",
    "value": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones."
  },
  {
    "key": "D10",
    "value": "Pagos por servicios educativos (colegiaturas)"
  },
  {
    "key": "P01",
    "value": "Por definir"
  }
];

const availableReference = [
  {key:"01", value:"Nota de crédito de los documentos relacionados"},
  {key:"02", value:"Nota de débito de los documentos relacionados"},
  {key:"03", value:"Devolución de mercancía sobre facturas o traslados previos"},
  {key:"04", value:"Sustitución de los CFDI previos"},
  {key:"05", value:"Traslados de mercancias facturados previamente"},
  {key:"06", value:"Factura generada por los traslados previos"},
  {key:"07", value:"CFDI por aplicación de anticipo"},
  {key:"08", value:"Factura generada por pagos en parcialidades"},
  {key:"09", value:"Factura generada por pagos diferidos"},
];

const Dialog = ({ create, editValueAction, ...props }) => {
  // const [ checkInsToPay, setCheckInsToPay ] = useState({});
  const [click, setClick] = useState(true);
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);

  // const handleChange = name => event => {
  //   setCheckInsToPay({ ...checkInsToPay, [name]: event.target.checked });
  // };

  const handleAddProduct = e => {
    const { saleDetailId, saleDetailIdLabel, quantity } = props.selectedProduct;
    if(!saleDetailId || !quantity) { return; }

    const tmp = local;
    const allowSaleDetailId = tmp.find( el => (parseInt(el.saleDetailId) === parseInt(saleDetailId) ) );
    if(allowSaleDetailId) return;

    const product = { saleDetailId, saleDetailIdLabel, quantity: parseFloat(quantity) };

    local && local.push(product);
    editValueAction({ Products: local, name: '', saleDetailId: null, quantity: [] });
    setQty(JSON.stringify(local));
  };

  const handleCreate = async () => {
    const { id } = props.data;
    if(click) {
      setClick(false);
      await editValueAction({ _id: id , creditNotes: true });
      // const checkIns = Object.keys(checkInsToPay).filter(el => checkInsToPay[el] && el).map(el => el);
      // if(checkIns.length !== 0 && props.fields.type === 2){
      //   await editValueAction({ Concepts: checkIns });
      // }
      await create('invoices');
    }
  }
  
  const products = useMemo(() => {
    return ( Object.keys(props.data).length > 0 ? props.data.Sale.SaleDetails.map((el,i) => ({ value: el.id, label: `${el.Product.name}` }) ) : [] )
  },[props.data]);

  const quantityProducts = useMemo(() => {
    if(Object.keys(props.data).length > 0 && props.saleDetailId) {
      const productFilter = props.data.Sale.SaleDetails.find( el => ( parseInt(el.id) === parseInt(props.saleDetailId) ) );
      return Array.from({ length: parseFloat(productFilter.quantity) }, (v,k) => ({ key: (parseInt(k) + 1), value: (parseInt(k) + 1) }) );
    }
    return [];

  },[props.saleDetailId, props.data]);
  // },[props.saleDetailId]);

  useEffect( () => {
    if(props.alert.type === 'success' || !props.isOpen.Finish) {
      editValueAction({ Pieces: [], saleDetailId: null, quantity: '' });
      setLocal([]);
    }
    setClick(true);
  }, [props.alert, props.isOpen, editValueAction]);

  const tableRows = useMemo(() => {    
    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal(tmp);
    };
    
    return local.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">{ el.saleDetailIdLabel }</TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, qty]);


	return (
	  <FormDialog  
      singular="creditNotesDialog" 
      title="¿Desea realizar una Nota de Crédito?"
      handleCustomizedClick={handleCreate}
    >
      {
        !props.data.oneSale && props.data.isGlobal && 
          <SelectField
            fields={[{
              id:"removeFromInvoice",
              label: "Remover NV de Factura Global. (Advertencia: Opción Irreversible)",
              options:[
                {key:false,value:"NO"},
                {key:true,value:"SI"}
              ]
            }]}
            dialog={false}
          />
      }
      <SelectField
        fields={[{
          id:"type",
          label: "Tipo",
          options:[
            {key:2,value:"Devolución"},
            {key:3,value:"Bonificación"}
          ]
        }]}
        dialog={false}
      />
      <SelectField
        fields={[{
          id: "paymentForm",
          label: "Forma de pago",
          options: availableMethods,
        }]}
        dialog={false}
      />

      <SelectField
        fields={[{
          id: "useCdfi",
          label: "Uso de CDFI",
          options: aviableUseCdfi,
        }]}
        dialog={false}
      /> 

      <SelectField
        fields={[{
          id: "reference",
          label: "Tipo de relación",
          options: availableReference,
        }]}
        dialog={false}
      /> 

      { props.type === 3 &&
        <TextField
          fields={[{
            id:"bonus",
            label: "Bonus",
            required: false
          }]}
          dialog={false}
        />
      }
      { props.type === 2 &&
        // <Fragment>
        //   <Grid container>
        //     <Grid item xs={1}>
        //     </Grid>
        //     <Grid item xs={9}>
        //       Productos 
        //       <FormGroup row>
        //         {props.data.Sale.SaleDetails.map((el,i) => {
        //           return (
        //             <FormControlLabel
        //               key={el.id}
        //               control={
        //                 <Checkbox onChange={handleChange(el.id)} />
        //               }
        //               label={`${el.Product.name}(${el.quantity})`}
        //             />
        //           )
        //         })}
        //       </FormGroup>
        //     </Grid>
        //     <Grid item xs={2}>
        //     </Grid>
        //   </Grid>
        // </Fragment>
        <Fragment>
          <ListItemAdd handleClick={handleAddProduct}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                  <AutocompleteField 
                    label="Concepto" 
                    _id="saleDetailId" 
                    dialog={false}
                    options={products}
                    variant="outlined"
                  />
              </Grid>
              <Grid item xs={4}>
                <SelectField
                  fields={[{
                    id: "quantity",
                    label: "Cant.",
                    options: quantityProducts,
                  }]}
                />
              </Grid>
            </Grid>
          </ListItemAdd>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell align="center">Cant.</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows}
            </TableBody>
          </Table>
        </Fragment>
      }
	  </FormDialog>
	)
};

const mapStateToProps = state => ({
  type: state.field.value.type || 0,
  saleDetailId: state.field.value.saleDetailId || 0,
  selectedProduct: {
    saleDetailIdLabel: state.field.value.saleDetailIdLabel, 
    saleDetailId: state.field.value.saleDetailId,
    quantity: state.field.value.quantity,
  },
  isOpen: state.formDialog,
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  create: table => dispatch(recordsActions.create(table))
});

export const CreditNotesDialog = (connect(mapStateToProps, mapDispatchToProps)(Dialog));