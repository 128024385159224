const logisticProduct = ({method, ...props}) => {
	
  switch (method) {
    case 'GET':
    	return `logistics/manufactures`;
    default:
    	return `logistics/${props.id}/replace`
  }
}

export default logisticProduct;