import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';

import Link from '@material-ui/core/Link';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Receipt from '@material-ui/icons/Receipt';

import Module from '../../_presentationals/Module';
import { recordsActions, openFormDialog, editValueAction, alertActions } from '../../_actions';
import { config } from './../../_helpers/config';
import { format } from 'date-fns';
import { parseISO } from 'date-fns';

import { moneyFormat } from '../../_helpers/moneyFormat';

import Ticket from './ticket';
import FilterDialog  from './filter';

const SalesReport = ({getRecords, openFormDialog, editValueAction, ...props }) => {
  const [actions,setActions] = useState([]);

  useEffect(() => {
    getRecords({ table: 'employees', sellers: true });
  }, [getRecords, editValueAction]);
  
  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  useEffect(() => {
    const tmpAction = [{
      icon: <Receipt fontSize="small" />, label: "Generar Ticket", handle: handdleTicket
    },{
      icon: <ZoomInIcon fontSize="small" />, label: "Consultar Venta", handle: details
    }];
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handdleTicket = (e) => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    Ticket(data.id);
  }

  const details = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${id}`, '_blank');
  };

  useEffect(() => {
    const handleError = props.errorMessage;
    let key = "";
    Object.keys(props.validation).forEach(el => { 
      if(props.validation[el].isError === true){
        key = el; 
      }
    });
    const message = errorMessage(key);
    if(key !== "" && message !== ""){
      handleError(message);
    }
  }, [props.validation, props.errorMessage]);

  const filter = () => {
    openFormDialog('Filter');
  };

  const sales = useMemo(() =>
    props.sales.count > 0 ? props.sales.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm"),
        _sort: 'date'
      },
      NV: {
       value: el.folio,
       filter: true,
       _sort: 'text'
      },
      Facturas:{
        align: 'center',
        value: el.Invoices.map(tmp => <Fragment key={tmp.folio}><Link href={tmp.uri} target="_blank">{tmp.folio}</Link><br/></Fragment>),
        arrayValue: el.Invoices.map(tmp => tmp.folio),
        _sort: 'text'
      },
      Cliente: {
       value: el.customer,
       filter: true,
       _sort: 'text'
      },
      "Importe sin IVA":{
        value: moneyFormat(el.totalRaw),
        align: "right",
        alt: parseFloat(parseFloat(el.totalRaw).toFixed(2)),
        _sort: 'alt',
        accumulate: true
      },
      Descuento:{
        value: moneyFormat(el.amountDiscount),
        align: "right",
        alt: parseFloat(parseFloat(el.amountDiscount).toFixed(2)),
        _sort: 'alt'
      },
      Subtotal:{
        value: moneyFormat(el.subTotal),
        align: "right",
        alt: parseFloat(parseFloat(el.subTotal).toFixed(2)),
        _sort: 'alt'
      },
      IVA:{
        value: moneyFormat(el.taxes),
        align: "right",
        alt: parseFloat(parseFloat(el.taxes).toFixed(2)),
        _sort: 'alt'
      },
      Total:{
        value: moneyFormat(el.total),
        align: "right",
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        _sort: 'alt',
        accumulate: true
      },
      "Notas Emitidas": {
        value: `${el.notes}/${el.payments}`
      },
      Usuario:{
        value: el.user,
        _sort: 'text'
      },
      Vendedor:{
        value: el.employee,
        _sort: 'text'
      }
    })) : []
  , [props.sales]);

  return (
    <Fragment>
      <Module
        table="salesActive"
        name="Reporte de Notas de Venta Abiertas"
        records={sales}
        singularName="reporte"
        title="Filtrar Ventas"
        filter={filter}
        edit={false}
        create={false}
        download={true}
        _delete={false}
        actions={actions}
      ></Module>
      <FilterDialog employees={employees} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const errorMessage = key => {
  switch(key){
    case "saleId":
      return "No es posible eliminar esta nota de venta, sugerimos emitir una nota de crédito.";
    case "Error":
      return "Error desconocido, favor de reportar."
    default:
      return "";
  }
}

const mapStateToProps = state => ({
  sales: state.records.salesActive || defaultTableValue,
  validation: state.field.validation,
  employees: state.records.employees || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  errorMessage: value => dispatch(alertActions.error(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);