import 'date-fns';
import React from "react";

import DateFnsUtils from '@date-io/date-fns';
import localeES from 'date-fns/locale/es';

import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

const Component = ({ noFormat, icon, error, onChange, ...props }) => {

  const handleChange = date => { onChange(props.name, date); }
  
  return (
    !noFormat ?
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item xs={1}>
        { icon || "" }
      </Grid>
      <Grid item xs={10}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ localeES }>
          <DateTimePicker
            label={ props.label || '' }
            value={ props.value }
            onChange={ handleChange }
            error={ error ? true : false }
            helperText={error && error}
            format="dd MMMM yyyy HH:mm"
            fullWidth={props.fullWidth || true}
            autoOk={ true }
            autoComplete="off"
            size="small"
            cancelLabel="Cerrar"
            inputVariant={props.variant || 'standard'}
            {...props}
          /> 
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
    :
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ localeES }>
      <DateTimePicker
        label={ props.label || '' }
        value={ props.value }
        onChange={ handleChange }
        error={ error ? true : false }
        helperText={error && error}
        format="dd MMMM yyyy HH:mm"
        fullWidth={props.fullWidth || true}
        autoOk={ true }
        autoComplete="off"
        cancelLabel="Cerrar"
        inputVariant={props.variant || 'standard'}
        {...props}
      /> 
    </MuiPickersUtilsProvider>
  )
}

export default Component;