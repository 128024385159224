import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';

const courtesiesReport = ({method, ...props}) => {

  switch (method) {
    case 'GET':
      if(props.start && props.finish) {
        const start = format(new Date(props.start), 'yyyy-MM-dd', { locale: localeES });
        const finish = format(new Date(props.finish), 'yyyy-MM-dd', { locale: localeES });

        return `courtesies?start=${start}&finish=${finish}`;
      }
      return 'courtesies';
    case 'DELETE':
      return `courtesies/${props._id}`;
    default:
      return 'courtesies';
  }
}

export default courtesiesReport;