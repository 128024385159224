import React, { Fragment, useMemo, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { isDate, format } from 'date-fns';

import Module from '../../_presentationals/Module';
import { openFormDialog } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';

import Filter  from './filter.dialog';

const Component = ({ openFormDialog, ...props }) => {
  const [ subtitle, setSubtitle ] = useState('');

  const rentPlans = useMemo(() =>
    props.rents.count > 0 ? props.rents.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      Concepto:{
        value: el.concept.toUpperCase(),
        _sort: 'text',
        filter: true
      },
      'Cant.': {
        value: el.qty,
        align: 'center',
        _sort: 'text',
      },
      Total: {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
        accumulate: true
      },
      Costo: {
        value: moneyFormat(el.cost),
        alt: parseFloat(parseFloat(el.cost).toFixed(2)),
        align: "right",
        _sort: 'alt',
        accumulate: true
      },
      Utilidad: {
        value: moneyFormat(el.total - el.cost),
        alt: parseFloat(parseFloat(el.total - el.cost).toFixed(2)),
        align: "right",
        _sort: 'alt',
        accumulate: true
      },
      '%Utilidad': {
        // value: moneyFormat(((el.cost > 0 ? el.cost : 1) * 100) / (el.total > 0 ? el.total : 1)),
        // alt: parseFloat(parseFloat(((el.cost > 0 ? el.cost : 1) * 100) / (el.total > 0 ? el.total : 1)).toFixed(2)),
        // value: moneyFormat(!isNaN(((el.cost !== 0 ? el.cost : el.total) / el.total) * 100) ? ((el.cost !== 0 ? el.cost : el.total) / el.total) * 100 : 0),
        // alt: parseFloat(!isNaN(((el.cost !== 0 ? el.cost : el.total) / el.total) * 100) ? ((el.cost !== 0 ? el.cost : el.total) / el.total) * 100 : 0),
        value: moneyFormat(el.total > 0 ? ((el.total - el.cost) / el.total) * 100 : 0),
        alt: parseFloat(parseFloat(el.total > 0 ? ((el.total - el.cost) / el.total) * 100 : 0).toFixed(2)),
        align: "right",
        _sort: 'alt'
      }
    })) : []
  ,[props.rents]);

  useEffect(() => {
    if(isDate(props.start) && isDate(props.finish)) {
      setSubtitle(`${format(new Date(props.start),'dd/MM/yyyy')} al ${format(new Date(props.finish),'dd/MM/yyyy')}`);
    }
  },[props.start,props.finish])

  const filter = () => {
    openFormDialog('Filter');
  };

  return (
    <Fragment>
      <Module
        table="invoicesReview"
        subtitle={subtitle}
        name="Detalle de Ventas"
        records={rentPlans}
        singularName=""
        filter={filter}
        edit={false}
        create={false}
        _delete={false}
        title="Detalle de Ventas"
        download={true}
      />
      <Filter />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  rents: state.records.invoicesReview || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Component);

