 import React from "react";
import { connect } from 'react-redux';

import { endOfWeek, startOfWeek } from 'date-fns';

import { FormDialog } from '../../_presentationals/FormDialog';

import { recordsActions, closeFormDialog } from '../../_actions';

import { DateField } from '../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const Dialog = props => {

  const handdleFilter = e => {
    const { start, finish, employeeId } = props;
    props.getRecords({ table: 'quotations', start, finish, employeeId });
    props.closeFormDialog('QuotationFilter');
  };

	return (
	  <FormDialog  
      singular="QuotationFilter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
      <Autocomplete
        icon="person"
        label="Vendedor" 
        _id="employeeId" 
        options={[{ value: 0, label: 'TODOS' }, ...props.employees]}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  start: state.field.value.start || startOfWeek(new Date()),
  finish: state.field.value.finish || endOfWeek(new Date()),
  employeeId: state.field.value.employeeId || '',
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const FilterDialog = connect(mapStateToProps, mapDispatchToProps)(Dialog);