import React from "react";

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField } from '../../_presentationals/Form';

const OutDialog = props => 
  <FormDialog 
    title="Finalizar Proceso"
    name="OutDialog"
    singular="OutDialog"
    table="warranties"
  >
    <SelectField
      fields={[{
        id: 'type', 
        label: 'Acción',
        options: [{
          key: 1, value: 'Salida por Garantía'
        },{
          key: 3, value: 'Salida por Reparación'
        },{
          key: 2, value: 'Salida por Nota'
        }],
        required: false
      }]}
    />
  </FormDialog>

export default OutDialog;