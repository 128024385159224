import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField, SelectField } from './../../_presentationals/Form';
import { recordsActions } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const ConfirmDialog = ({getRecords, ...props}) => {

  useEffect(() => { getRecords({ table: 'employees', employeeRole: 3 }); }, [ getRecords ]);
  const availableEmployees = useMemo(() => {
    return props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.employees]);


  return (
    <FormDialog
      title={ props.physicalSerie ? "Editar Ensamblaje" : "Finalizar ensamble del producto" }
      name="EditManufacture"
      singular="EditManufacture"
      table="manufactures"
    >
      <TextField
        fields={[{
          id: "physicalSerie",
          label: "Serie Física"
        }]}
      />
      <SelectField
        fields={[{
          id: 'status',
          label: "Renta o Venta",
          options: [
            {key:2, value:"Disponible para Venta"},
            {key:3, value:"Disponible para Renta"},
          ]
        }]}
      />
      <Autocomplete
        icon="user"
        label="Ensamblador" 
        _id="employeeId"
        options={ availableEmployees }
      /> 
    </FormDialog>
  )
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  employees: state.records.employees || defaultTableValue,
  physicalSerie: state.field.value.physicalSerie || ''
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);