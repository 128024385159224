const samples = ({method, ...props}) => {
  switch (method) {
  	case 'PUT':
    case 'DELETE':
      return `samples/${props._id}`;
    default:
      return 'samples';
  }
}

export default samples;