const api = [ 
  'tradename', 'identifier', 'businessName', 'addressLine1', 'addressLine2', 'city', 'zipCode',
  'phone', 'contact', 'email', 'bank', 'code', 'termAmount'
];

const expenses = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      if (values['stateId']) tmp['stateId'] = values['stateId'];
      if (values['countryId']) tmp['countryId'] = values['countryId'];
      if (values['term']) tmp['term'] = values['term'];
      
      return tmp;
    default:
      return tmp;
  }
}

export default expenses;
