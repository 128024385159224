import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
// import Icon from '@material-ui/core/Icon';
import { TextField } from './../../_presentationals/Form';
// import Grid from '@material-ui/core/Grid';
// import { ListItemAdd } from './listItemAdd';
import { editValueAction, recordsActions } from './../../_actions';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

// import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

// const productsWithPrice = [133,134,135];

const Finish = ({ getRecords, editValueAction, create, ...props }) => {
  
  // const [local, setLocal] = useState([]);
  // const [qty, setQty] = useState(0);
  // const [isDisabled, setIsDisabled] = useState(true);

  // const handleAddProduct = e => {
  //   const { productId, productIdLabel, quantity = 1, price, commentaryPiece } = props.selectedProduct;
  //   console.log(price);
  //   if(!productId || isNaN(parseInt(quantity))){ return; }
  //   local && local.push({ productId, productIdLabel, quantity: parseInt(quantity), price, commentary: commentaryPiece });
  //   editValueAction({Pieces: local, productId: null, employeeId: null, quantity: '', price: '', commentaryPiece: ''});    
  //   setQty(JSON.stringify(local));
  //   setLocal(local);
  // };

  // useEffect( () => {
  //   if(props.alert.type === 'success') {
  //     editValueAction({ Pieces: [], productId: null, employeeId: null, quantity: '', price: '', commentaryPiece: '' });
  //     setLocal([]);
  //   }
  // }, [props.alert, editValueAction]);

  // useEffect( () => {
  //   if(!props.isOpen.Warranty) {
  //     editValueAction({ Pieces: [], productId: null, employeeId: null, quantity: '', price: '', commentaryPiece: '' });
  //     setLocal([]);
  //   } else { console.log('<<<>>>>>>>>>'); getRecords({ table: 'products', type: 2 }); }
  // }, [props.isOpen, editValueAction]);

  // const spareProducts = useMemo(() => {
  //   return props.spareProducts.rows ? props.spareProducts.rows.map(({ id, name, salePrice }) => {
  //     console.log(salePrice);
  //     return { value: id, label: `${name}`, price: salePrice }
  //   }
  //   ) : [];
  // }, [props.spareProducts]);

  const handleWarranty = () => {
    create('manufactures');
  }

  // useEffect( () => ( setIsDisabled(productsWithPrice.includes(parseInt(props.selectedProduct.productId)) ? false : true) ),[props.selectedProduct.productId, editValueAction]);

  // const tableRows = useMemo(() => {

  //   const handleDeleteProduct = e => {
  //     const index = e.currentTarget.dataset.index;
  //     delete local[index];
  //     const tmp = local.filter(el => el);
  //     setQty(JSON.stringify(tmp));
  //     setLocal([ ...tmp ]);
  //   };

    
  //   return local.map((el, index) => {
  //     return (<TableRow key={index}>
  //       <TableCell component="th" scope="row">
  //         {el.productIdLabel}
  //       </TableCell>
  //       <TableCell align="center">{el.quantity}</TableCell>
  //       <TableCell align="right">{parseFloat(el.price).toFixed(2)}</TableCell>
  //       <TableCell align="right">{el.commentary}</TableCell>
  //       <TableCell margin="none" align="right">
  //         <Tooltip title="Eliminar" aria-label="Eliminar">
  //           <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
  //             <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
  //           </IconButton>
  //         </Tooltip>
  //       </TableCell>
  //     </TableRow>);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [local, qty]);
  
  return (
    <FormDialog 
      title="Enviar a Garantía"
      singular="Warranty"
      handleCustomizedClick={handleWarranty}
    >
        <TextField
          icon="comment"
          fields={[
            {
              id: "commentary",
              label: "Comentario",
            }
          ]}
        />
       
    </FormDialog>
  );
}

const defaultTableValue = { count: 0, rows: [] };
const mapStateToProps = state => ({
  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity, 
    price: state.field.value.price, 
    commentaryPiece: state.field.value.commentaryPiece
  },
  spareProducts: state.records.products || defaultTableValue,
  state: state,
  isOpen: state.formDialog,
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  create: table => dispatch(recordsActions.create(table)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Finish);