import React, { useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';

import { recordsActions } from '../../_actions';

const Component = props => {

  const borrowers = useMemo(() => 
    props.borrowers.count > 0 ? props.borrowers.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Nombre: {
       value: el.name,
       filter: true,
       _sort: 'text'
      },
      Domicilio:{
        value: `${el.addressLine1}, ${el.addressLine2}, ${el.city}, ${el.zipCode}, ${el.state}`, 
      },
      Teléfono:{
        value: el.phone,
        filter: true
      }
    })) : []
  , [props.borrowers]);
  
  return (
    <Module
      table="borrowers"
      name="prestadores"
      records={borrowers}
      singularName="prestador"
      filter={false}
      title="Crear Prestador"
      download={true}
    >
      <TextField
        icon="account_circle"
        fields={[{
          id: "name",
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        icon="phone"
        fields={[{
          id: "phone",
          label: "Teléfono",
          required: false
        }]}
      />
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1",
            label: "Calle y Número",
            multiline: true
          },
          {
            id: "addressLine2",
            label: "Colonia",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city",
            label: "Ciudad",
            required: false
          },
          {
            id: "zipCode",
            label: "Código Postal",
            required: false
          }
        ]}
      />
      <TextField
        fields={[{
          id: "state",
          label: "Estado",
          required: false
        }]}
      />
    </Module>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  borrowers: state.records.borrowers || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(Component);
