 import React from "react";
import { connect } from 'react-redux';

import { endOfWeek, startOfWeek } from 'date-fns';

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField } from '../../_presentationals/Form';

import { recordsActions, closeFormDialog } from '../../_actions';

import { DateField } from '../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const methodOPtions = [
  { key: 0, value: 'Todos' },
  { key: 'months', value: 'Meses' },
  { key: 'rentPerEvent', value: 'Renta por evento' },
  { key: 'rentPer1Month', value: 'Renta por 1 mes' },
  { key: 'rentPer4Months', value: 'Renta por 4 meses' },
  { key: 'rentPer6Months', value: 'Renta por 6 meses' },
  { key: 'rentPer8Months', value: 'Renta por 8 meses' },
  { key: 'rentPer12Months', value: 'Renta por 12 meses' }
]

const typeOptions = [
  { key: 0, value: 'Todos' },
  { key: 2, value: 'Renta' },
  { key: 1, value: 'Venta' }
]

const Dialog = props => {

  const handdleFilter = e => {
    const { start, finish, method, employeeId, type } = props;
    props.getRecords({ table: 'rents', start, finish, typeRent: method, employeeId, type });
    props.closeFormDialog('RentFilter');
  };

	return (
	  <FormDialog  
      singular="RentFilter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
      <SelectField
        fields={[{
          id: 'method',
          label: 'Modalidad',
          options: methodOPtions,
          required: false
        }]}
      />
      <SelectField
        fields={[{
          id: 'type',
          label: 'Tipo',
          options: typeOptions,
          required: false
        }]}
      />
      <Autocomplete
        icon="person"
        label="Vendedor" 
        _id="employeeId" 
        options={[{ value: 0, label: 'Todos' }, ...props.employees]}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  method: state.field.value.method || 0,
  start: state.field.value.start || startOfWeek(new Date()),
  finish: state.field.value.finish || endOfWeek(new Date()),
  type: state.field.value.type || 0,
  employeeId: state.field.value.employeeId || ''
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const FilterDialog = connect(mapStateToProps, mapDispatchToProps)(Dialog);