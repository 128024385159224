export const userConstants = {
  REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
  
  GETBYID_REQUEST: 'USERS_GETBYID_REQUEST',
	GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
	GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',
  
  RESETE_DATA: 'USER_RESETE_DATA'
};
