import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField, TextField } from '../../_presentationals/Form';
import { closeFormDialog, editValueAction } from '../../_actions';

const paymentMethodOptions = [
  { key: 1, value: 'Contado' },
  { key: 2, value: 'Crédito' },
];

const PaymentMethodDialog = props => {
  
  const [ daysToPayError, setDaysToPayError ] = useState(false);

  useEffect(() => {
    if(parseInt(props.paymentMethod) === 1){
      props.editValueAction({ daysToPay: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paymentMethod]); 

  const handleClick = () => {
    try {
      if(parseInt(props.paymentMethod) === 2 && parseInt(props.daysToPay) <= 0) throw new Error('Proporciona el plazo límite para pago.');
      props.closeFormDialog('PaymentMethodDialog');
    } catch(e){
      setDaysToPayError(e.message);
    }
  } 
  return(
    <FormDialog 
      buttonLabel="Actualizar" 
      singular="PaymentMethodDialog" 
      title="Método de Pago"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
      <SelectField
        fields={[{
          id: 'paymentMethod',
          label: 'Método de Pago',
          options: paymentMethodOptions,
        }]}
      />
      <TextField
        fields={[{
          id:'daysToPay',
          label: 'Plazo de Pago',
          type: 'number',
          isError: daysToPayError,
          disabled: props.paymentMethod === 2 ? false : true
        }]}
      />

    </FormDialog>
  );
}

const mapStateToProps = state => ({ 
  paymentMethod: state.field.value.paymentMethod || 1,
  daysToPay: state.field.value.daysToPay || 0,
});

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodDialog);
