const expenses = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
      return `expenses/${props._id}`;
  	case 'PUT':
      return `expenses/${props._id}/status/${props.status}`;
  	default:
      return 'expenses';
  }
}

export default expenses;