const warrantyNotes = ({method, ...props}) => {
	switch (method) {
    case 'POST':
      if(props.isSale) return `warranties/${props.warrantyId}/sales`;
      if(props.isCourtesy) return `warranties/${props.warrantyId}/courtesies`;
      return 'warranties';
    case 'PUT':
      if(props.isOut) return `warranties/${props._id}/out`
      return `warranties/${props._id}/pieces`
    case 'GET':
      return `warranties?type=2&status=3`;
    default:
      return 'warranties';
  }
}

export default warrantyNotes;