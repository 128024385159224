import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';

import { recordsActions, closeFormDialog } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const Component = ({ products, customers, ...props }) => {

  const handdleFilter = e => {
    const { productId, customerId } = props;
    props.getRecords({ table: 'rentedManufactures', productId, customerId });
    props.closeFormDialog('ManufacturesFilter');
  };

	return (
	  <FormDialog  
      singular="ManufacturesFilter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <Autocomplete
        label="Producto" 
        _id="productId"
        options={ products }
      />
      <Autocomplete
        label="Cliente" 
        _id="customerId"
        options={ [ { label: 'TODOS', value: 0 }, ...customers] }
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({
  productId: state.field.value.productId || null,
  customerId: state.field.value.customerId || null
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
