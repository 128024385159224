import { profileConstants } from '../_constants';
import { profileServices } from '../_services';

const changeLocal = () => (dispatch, getState) => {
  const image = getState().profile.local;
  dispatch({
    type: profileConstants.CHANGE_LOCAL_PROFILE,
    payload: image
  })
}

const get = () => async dispatch => {
  
  const session = JSON.parse(localStorage.getItem('session'));
  const id = session ? session.data.id : false;
  
  if(!id){
    localStorage.removeItem('session');
    window.location.reload(true);
    return;
  }

  profileServices.get(id)
  .then(data => {
    dispatch({
      type: profileConstants.GET_PROFILE,
      payload: data.image
    });
  }).catch(e => console.log(e));
  
}

// const create = profile => dispatch => {
//   dispatch(alertActions.pending("Actualizando..."));
//   profileServices.create({id: 1, profile}).then(response => {
//     dispatch({
//       type: profileConstants.GET_PROFILE_SUCCESS,
//       payload: response.image
//     });
//     dispatch(alertActions.success("Se actualizó la foto de perfil"));
//   });
// }

export const profileActions = {
	changeLocal, get
};
