const statusLabel = status => {
  switch(status) {
    case 1:
      return 'Ensamblando';
    case 2:
      return 'Disponible (Venta)';
    case 3:
      return 'Disponible (Renta)';
    case 4:
      return 'Rentado';
    case 5:
      return 'Vendido';
    case 6:
      return 'Garantía V1';
    case 8:
      return 'Consignado';
    case 10:
      return 'Disponible (Renta)';
    case 15:
      return 'Muestra';
    case 20:
      return 'En servicio';
    case 21:
      return 'Salida de servicio';
    case 22:
      return 'En reparación';
    case 23:
      return 'Equipo dañado';
    default:
      return 'Indefinido';
  }
}

export default statusLabel;