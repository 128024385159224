import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: '#f6f6f6',
    padding: theme.spacing(4),
    flex: 1
	},
	spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardHeader: {
    marginTop: theme.spacing(3), 
    display: 'flex',
    alignItems: 'center',
  },
  outlinedButton: {
  	'&:hover': {
  		backgroundColor: 'transparent'
  	}
  },
  tabs: {
    height: 60
  },
  configTab: {
  	height: 60,
  	width: '10 !important',
  	margin: '10 !important',
  	padding: '10 !important',
  },
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  commentaryContent: {
    padding: theme.spacing(2),
  },
  copyrightContent: {
    marginTop: theme.spacing(2.5),
  },
}));

export default useStyles;