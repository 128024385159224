import React, { useMemo, useEffect } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';

import { history } from './../../_helpers';

const SubClasifications = props => {

  useEffect(() => {
    props.redirect.id === "" && history.push('/Clasification');
  }, [props.redirect.id]);

  const subClasifications = useMemo(() => 
    props.subClasifications.count > 0 ? props.subClasifications.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      Nombre: {
       value: el.name,
       filter: true
      }
    })) : []
  , [props.subClasifications]);

  return (
    <Module
      table="subClasifications"
      name="subclasificaciones"
      singularName="subclasificación"
      filter={false}
      title="Crear Subclasificación"
      records={subClasifications}
    >
      <TextField
        icon="category"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStatesToProps = state => ({
  subClasifications: state.records.subClasifications || defaultTableValue,
  redirect: state.redirect
});

export default connect(mapStatesToProps)(SubClasifications);