const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
	main: {
	 width: 'auto',
	 display: 'block',
	 marginLeft: theme.spacing.unit * 0.5,
	 marginRight: theme.spacing.unit * 0.5,
	 [theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
		 width: 1000,
		 marginLeft: 'auto',
		 marginRight: 'auto',
	 },
 },
 textColor:{
 	color: '#616161'
 },
 buttonLocation: {
 	float: 'right'
 }
});

export { styles }