import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import { styles } from './style';
import DesktopActionsComponent from './_desktopActions';

const Header = props => 
	<DesktopActionsComponent 
		name={props.name} 
		subtitle={props.subtitle} 
		count={props.count}
		filter={props.filter}
		additionalAction={props.additionalAction}
		handleCustomCreate={props.handleCustomCreate}
		create={props.create}
		handleCreate={props.handleCreate}
		singularName={props.singularName}
		print={props.print}
		download={props.download}
    redirect={props.redirect}
    table={props.table}
    handleClickMenu={props.handleClickMenu}
    anchorEl={props.anchorEl}
    handleCloseMenu={props.handleCloseMenu}
    handleCreatePDF={props.handleCreatePDF}
    handleCreateXLS={props.handleCreateXLS}
    handleReturn={props.handleReturn}
    additionalText={props.additionalText}
    totals={props.totals}
	/>

export default withStyles(styles)(Header);