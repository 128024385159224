import React, { useMemo, Fragment } from "react";
import { connect } from 'react-redux';
import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction  } from '../../_actions';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import ListAltIcon from '@material-ui/icons/ListAlt';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

import Log from './log';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { redirectActions, recordsActions } from '../../_actions';
import { history } from './../../_helpers';
import { config } from './../../_helpers/config';
import { moneyFormat } from '../../_helpers/moneyFormat';

const LogisticSale = ({openFormDialog, getRecords, editValueAction, ...props}) => {

  const logisticsSale = useMemo(() => 
    props.logisticFailure.count > 0 ? props.logisticFailure.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha:{
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy hh:mm', {locale: localeES} )
      },
      "Folio":{
        value: el.folio,
        filter: true
      },
      "Cliente":{
        value: el.Customer.firstName,
        filter: true,
       _sort: 'text'
      },
      Total:{
        value: el.isSale ? moneyFormat(el.total) : moneyFormat(0),
        alt: el.isSale ? parseFloat(parseFloat(el.total).toFixed(2)) : 0,
        align: 'right'
      },
      Estatus:{
        value: `${el.generalStatus}`,
      }
    })) : []
  , [props.logisticFailure]);

  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, data, table: 'logisticSale', returnTo: '/Logistic/Failure', returnToLabel: 'Fallas'});
    history.push('/Logistic/Failure/Deliveries');
  };

  const handdleLog = e => {
    const { logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ logs });
    openFormDialog('Log');
  };

  const details = e => {
    const { id, isSale } = JSON.parse(e.currentTarget.dataset.row);
    isSale ? window.open(`${config.apiUrl}/notes/sales/${id}`, '_blank') : window.open(`${config.apiUrl}/notes/courtesies/${id}`, '_blank');
  };

  return (
    <Fragment>
      <Module
        table="logisticFailure"
        name="fallas reportadas"
        records={logisticsSale}
        title="Fallas reportadas"
        singularName="PDF"
        filter={false}
        edit={false}
        create={true}
        _delete={false}
        actions={[
          {
            icon: <ZoomInIcon fontSize="small" />,
            label: "Consultar",
            handle: details
          },
          {
            icon: <ListAltIcon fontSize="small" />,
            label: "Bitácora",
            handle: handdleLog
          },
          {
            icon: <LocalShippingIcon fontSize="small" />,
            label: "Entregas",
            handle: redirect
          }
        ]}
      >
      </Module>
      <Fragment>
        <Log />
      </Fragment>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  logisticFailure: state.records.logisticFailure || defaultTableValue
})

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticSale);