const failures = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
      return `failures/${props._id}`;
    case 'PUT':
    	if(props.isCancel) return `failures/${props._id}/cancel`;
    	return `failures/${props._id}`;
    default:
      return 'failures';
  }
}

export default failures;
