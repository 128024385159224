import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const payments = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
  		return `payments/${props._id}`;
    case 'POST':
    case 'GET':
    default:
      if(props.accountReceivableId || props.redirectId) return `accountReceivables/${props.accountReceivableId || props.redirectId}/payments`;

    	const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy-MM-dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy-MM-dd', {locale: localeES});
      
      return `payments/${start}/${finish}`;
  }
}

export default payments;