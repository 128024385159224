import React, { useState, useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const GeneralDialogModule = ({isOpen, ...props}) => {

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    setIsOpenDialog(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    props.handleCancel();
    // setIsOpenDialog(isOpen);
  }

  const handleAction = () => {
    props.handleAction();
    // setIsOpenDialog(isOpen);
  }

  return (
    <Dialog
      open={isOpenDialog}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="Confirmar"
      aria-describedby="Dialogo de para uso general."
    >
      <DialogTitle disableTypography={true}>{ props.title || ""}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
        { props.buttonMessage &&
          <Button onClick={handleAction} color="primary" autoFocus>
            { props.buttonMessage || "Aceptar" }
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export const GeneralDialog = GeneralDialogModule;
