const api = [ 'name', 'description', 'cost', 'stock' ];

const tools = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      
      return tmp;
    default:
      return tmp;
  }
}

export default tools;
