import React, { Fragment } from "react";
// import { connect } from 'react-redux';

// import { progressActions } from '../../_actions';
import { EmptyTable } from '../../_presentationals/EmptyTable';
import { TableModule } from '../../_presentationals/Table';

import { FormDialog } from '../FormDialog';
import { Snackbar } from '../Snackbar';

const Module = props => {
  
  const { 
    records,
    table,
    filter
  } = props;
  
  // useEffect(() => {
  //   openProgressBar();
  // }, [openProgressBar]);

  // useEffect(() => {
  //   getRecords({ table });
  //   return () => clearRecords('employees'); 
  // }, [getRecords, table, clearRecords]);
  
  // useEffect(() => {
  //   alert === 'success' && getRecords({ table });
  // }, [getRecords, table, alert]);
  
  if(records.length === 0 && !filter){
    
    const { name, singularName, title, children, create } = props;
    return (
      <Fragment>
        <EmptyTable
          plural={name}
          singular={singularName}
          create={create}
          filter={filter}
        />
        <FormDialog
          table={ table }
          title={ title }
          singular={ singularName }
        >
          { children }
        </FormDialog>
        <Snackbar />
      </Fragment>
    );
  }

  if(records.length > 0 || filter){

    const { name, subtitle, singularName, download, tableStructure, updateStructure, edit, _delete, title, children, create, actions, totals } = props;
    const additionalText = props.additionalText || "";

    return (
      <Fragment>
        <TableModule
          table={ table }
          name={ name }
          subtitle={subtitle}
          singularName={ singularName }
          structure={ tableStructure }
          edit={edit}
          _delete={_delete}
          updateStructure={ updateStructure }
          filter={ filter }
          actions={ actions }
          create={ create }
          records={ records }
          download={ download }
          additionalText={additionalText}
          totals={totals}
        />
        <FormDialog
          table={ table }
          title={ title }
          singular={ singularName }
        >
          { children }
        </FormDialog>
        <Snackbar />
      </Fragment>
    );
  }

  return "";

};

// const mapStateToProps = state => ({
//   alert: state.alert.type
// });

// const mapDispatchToProps = dispatch => ({
//   openProgressBar: () => dispatch(progressActions.open())
// });

export default Module;