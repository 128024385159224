import React, { Fragment, useMemo, useState  } from "react";
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import localeES from 'date-fns/locale/es';
import randomize from 'randomatic';

import Module from '../../_presentationals/Module';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';
import * as recordsServices from './../../_services/records.services';
import { openFormDialog, editValueAction, recordsActions } from '../../_actions';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import { config } from './../../_helpers/config';

import Filter from './filter.dialog';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
  const [ confirmId, setConfirmId ] = useState(null);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  
  const courtesyReportV2 = useMemo(() => {
    return props.courtesyReportV2.count > 0 ? props.courtesyReportV2.rows.map(el => { 
      return {
        ID: { 
          show:false,
          value: el.id,
          data: el,
          showIf: el.sale ? true : false,
        },
        Fecha: { 
          value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
          _sort: 'date',
          noBreak: true
        },
        Folio: {
          value: <a href={`${config.apiUrl}/v2/notes/courtesies/${el.id}`} target="_blank" rel="noopener noreferrer">{`${el.folio}-${el.serial}`}</a>,
          alt: el.folio,
          _sort: 'text',
          noBreak: true
        },
        Cliente: {
          value: `${el.Customer.firstName} ${el.Customer.lastName}` || '-',
          _sort: 'text'
        },
        Vendedor: {
          value: `${el.Employee.firstName} ${el.Employee.lastName}` || '-',
          _sort: 'text'
        },
        Usuario: {
          value: `${el.User.firstName} ${el.User.lastName}` || '-',
          _sort: 'text'
        },
        'Autorizado por': {
          value: el.AuthUser ? `${el.AuthUser.firstName} ${el.AuthUser.lastName}` : '-',
          _sort: 'text'
        },
      }
    }) : [];

  }, [props.courtesyReportV2]);

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleCancel = () => setConfirmId(null);

  const handleAction = async () => {
    try{
      const response = await recordsServices._deleteV2(`v2/sales/${confirmId}`);
      if(response.errorCode) throw new Error(response.errorMessage);
      setConfirmId(null);
      const tmpCode = randomize('*', 10);
      setRefreshCode(tmpCode);

      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: 'Los factura fue cancelada exitosamente', 
        severity: 'success' 
      }));
    }catch(e){
      setConfirmId(null);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleFilter = () => {
    props.openFormDialog('Filter');
  }

  return (
    <Fragment>
      <Module
        table="courtesyReportV2"
        name="Reportes de Cortesías"
        records={courtesyReportV2}
        refreshCode={refreshCode}
        singularName="Reporte"
        title="Filtrar Cortesías"
        filter={handleFilter}
        create={false}
        edit={false}
        _delete={false}
        download={true}
      />
      {<Filter />}
      <ConfirmDialog 
        _id={confirmId} 
        handleCancel={handleCancel} 
        handleAction={handleAction} 
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: [] };

const mapStateToProps = state => ({
  courtesyReportV2: state.records.courtesyReportV2 || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  _delete: value => dispatch(recordsActions._delete(value)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);