import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';

import { style } from './style';

const ListItemAdd = withStyles(style)(({ classes, children, handleClick }) => (
	<ListItem>
    <div className={ classes.productItem }>{ children }</div>
    <ListItemSecondaryAction>
       <Tooltip title="Agregar" classes={{ tooltip: classes.tooltip }}>
        <IconButton className={ classes.addButton } onClick={ handleClick }>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  </ListItem>
));

export { ListItemAdd };