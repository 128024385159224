import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
// import HistoryIcon from '@material-ui/icons/History';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import Module from '../../_presentationals/Module';
import { recordsActions, editValueAction, openFormDialog } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { config } from './../../_helpers/config';

// import Log from './../Manufacture/log';
import Warranty from './../Manufacture/warranty';
import { FilterDialog }  from './filter';

// const session = JSON.parse(localStorage.getItem('session'));
// const privileges = session && JSON.parse(session.data.privileges);

const Manufacture = ({ getRecords, updateRecords, editValueAction, clearRecords, openFormDialog, ...props }) => {
  const [actions, setActions] = useState([]);
  useEffect(() => {
    const tmpAction = [
      { icon: <ZoomInIcon fontSize="small" />, label: "Consultar Venta", handle: details }
    ];
    // if(privileges.soldManufactures && privileges.soldManufactures.log){ 
    //   tmpAction.push({ icon: <ListAltIcon fontSize="small" />, label: "Consultar Historial", handle: handdleLog });
    // }
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => { 
    editValueAction({ type: 1 });
    !props.isOpen.Warranty && getRecords({ table: 'products', type: 1 });
  },[ getRecords, props.alert.type, props.isOpen, editValueAction ]);

  useEffect(() => { getRecords({ table: 'products', type: 1 }); return () => clearRecords('products'); }, [getRecords, clearRecords]);
  const products = useMemo(() => {
    return props.products.count > 0 ? props.products.rows.map(({id, name}) => ({ value: id, label: name })) : [];
  }, [props.products]);

  const manufactures = useMemo(() =>
    props.manufactures.count > 0 ? props.manufactures.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      "Fecha de Venta":{
        value: el.createdAt ? format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}) : '-',
        _sort: 'date',
      },
      NV:{
        value: el.folio ? el.folio : '-',
        filter: true,
       _sort: 'text'
      },
      "Serie Física":{
        value: el.physicalSerie,
        filter: true,
       _sort: 'text'
      },
      Producto:{
        value: el.product,
        filter: true,
       _sort: 'text'
      },
      Costo: {
        value: moneyFormat(el.productionCost),
        alt: parseFloat(parseFloat(el.productionCost).toFixed(2)),
        align: 'right',
        accumulate: true, accumulateLabel: 'Costo de Producción',
        _sort: 'alt',
      },
      Sucursal:{
        value: el.branch,
       _sort: 'text'
      },
      Estatus:{
        value: el.status,
       _sort: 'text'
      }
    })) : []
  ,[props.manufactures]);

  // const handleWarranty = e => {
  //   const { id, status } = JSON.parse(e.currentTarget.dataset.row);
  //   if(parseInt(status) === 6) return;
  //   editValueAction({ manufactureId: id, isWarranty: true });
  //   openFormDialog('Warranty');
  // }

  // const handdleLog = e => {
  //   const { logs } = JSON.parse(e.currentTarget.dataset.row);
  //   editValueAction({ logs });
  //   openFormDialog('Log');
  // };

  const filter = () => {
    openFormDialog('ManufacturesFilter');
  };

  const details = e => {
    const { saleId, courtesyId } = JSON.parse(e.currentTarget.dataset.row);
    saleId ? window.open(`${config.apiUrl}/notes/sales/${saleId}`, '_blank') : window.open(`${config.apiUrl}/notes/courtesies/${courtesyId}`, '_blank');
  };

  return (
    <Fragment>
      <Module
        table="soldManufactures"
        name="Equipos Vendidos"
        records={manufactures}
        singularName="Equipo vendido"
        filter={filter}
        edit={false}
        create={false}
        _delete={false}
        title="Equipos Vendidos"
        download={true}
        actions={actions}
      ></Module>
      <Warranty />
      <FilterDialog products={products} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  type: state.field.value.type || 0,
  products: state.records.products || defaultTableValue,
  manufactures: state.records.soldManufactures || defaultTableValue,
  alert: state.alert,
  isOpen: state.formDialog, 
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  updateRecords: value => dispatch(recordsActions.update(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Manufacture);