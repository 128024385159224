import React, { useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';

const Group = props => {

  const groups = useMemo(() => 
    props.groups.count > 0 ? props.groups.rows.map(el => ({
      ID: { 
        value: el.id,
        data: el
      }, 
      Nombre: {
       value: el.name,
       filter: true,
       _sort: 'text'
      }
    })) : []
  , [props.groups]);

  return (
    <Module
      table="groups"
      name="grupos"
      records={groups}
      singularName="grupo"
      filter={false}
      title="Crear Grupo"
    >
      <TextField
        icon="group"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  groups: state.records.groups || defaultTableValue
});

export default connect(mapStateToProps)(Group);