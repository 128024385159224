import React, { useState, useEffect } from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';

import * as recordsServices from './../../_services/records.services';
import FormDialog from './../Sales.v2/helpers/FormDialog';
import Autocomplete from './../Sales.v2/helpers/Autocomplete';

const defaultValues = {
  borrowerId: null
}

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  const [ borrowers, serBorrowers ] = useState([]);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  useEffect(() => {
    recordsServices.getAllV2('borrowers').then(data => {

      const tmp = data.rows.map(el => {
        const address = `${el.addressLine1} ${el.addressLine2}, ${el.city}, ${el.state}, CP ${el.zipCode}`;
        return { 
          label: `${el.phone && el.phone} ${el.name}`.trim(), 
          value: el.id,
          address,
        }
      })
      serBorrowers(tmp);
    });
  }, []);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = () => {
    try{
      if(!values.borrowerId) throw new Error('Selecciona un cliente para la venta.');
      props.handleAction(values);
      setErrors({});
    }catch(e) {
      setErrors({ borrowerId: e.message });
    }
  }

	return (
    <FormDialog
      title='Cliente'
      actionLabel='Seleccionar'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      <Autocomplete 
        icon={<AccountCircleIcon />}
        name='borrowerId'
        label='Nombre del Cliente' 
        onChange={handleAutocompleteChange}
        options={borrowers}
        value={values.borrowerId}
        error={errors.borrowerId}
      />
    </FormDialog>
	)
}

export default Component;