import React from "react";
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { FormDialog } from '../../_presentationals/FormDialog';

const Log = props => {
  return (
    <FormDialog 
      title="Bitácora"
      name="log"
      singular="Log"
      showActionButton={false}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Estatus</TableCell>
            <TableCell>Comentario</TableCell>
            <TableCell align="right">Fecha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.logs && props.logs.map((el, i) => (
            <TableRow key={i}>
              <TableCell>{el.status.label}</TableCell>
              <TableCell>{el.commentary}</TableCell>
              <TableCell align="right">{format(parseISO(el.date), 'dd/MM/yyyy HH:mm', {locale: localeES})}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FormDialog>
  );

}

const mapStateToProps = state => ({
  logs: state.field.value.logs || []
});


export default connect(mapStateToProps, null)(Log);