import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

const Prices = () => 
  <FormDialog
    title="Agregar precios del producto"
    name="Price"
    singular="Prices"
    table="pricesProducts"
  >
    <TextField
      fields={[{
        id: "salePrice",
        label: "Venta",
        required:false
      },{
        id: "productionCost",
        label: "Costo",
        required:false
      }]}
    />
    <br/>
    <strong style={{marginLeft: 45, fontSize: 20}}>Comercio</strong>
    <strong style={{marginLeft: 130, fontSize: 20}}>Industria</strong>
    <TextField
      fields={[{
        id:"rentPerEvent",
        label: "Renta por evento",
        required:false
      },{
        id:"rentPerEventIndustry",
        label: "Renta por evento",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer1Month",
        label: "Renta por 1 mes",
        required:false
      },{
        id:"rentPer1MonthIndustry",
        label: "Renta por 1 mes",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer2Months",
        label: "Renta por 2 meses",
        required:false
      },{
        id:"rentPer2MonthsIndustry",
        label: "Renta por 2 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer3Months",
        label: "Renta por 3 meses",
        required:false
      },{
        id:"rentPer3MonthsIndustry",
        label: "Renta por 3 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer4Months",
        label: "Renta por 4 meses",
        required:false
      },{
        id:"rentPer4MonthsIndustry",
        label: "Renta por 4 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer5Months",
        label: "Renta por 5 meses",
        required:false
      },{
        id:"rentPer5MonthsIndustry",
        label: "Renta por 5 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer6Months",
        label: "Renta por 6 meses",
        required:false
      },{
        id:"rentPer6MonthsIndustry",
        label: "Renta por 6 meses", 
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer7Months",
        label: "Renta por 7 meses",
        required:false
      },{
        id:"rentPer7MonthsIndustry",
        label: "Renta por 7 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer8Months",
        label: "Renta por 8 meses",
        required:false
      },{
        id:"rentPer8MonthsIndustry",
        label: "Renta por 8 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer9Months",
        label: "Renta por 9 meses",
        required:false
      },{
        id:"rentPer9MonthsIndustry",
        label: "Renta por 9 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer10Months",
        label: "Renta por 10 meses",
        required:false
      },{
        id:"rentPer10MonthsIndustry",
        label: "Renta por 10 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer11Months",
        label: "Renta por 11 meses",
        required:false
      },{
        id:"rentPer11MonthsIndustry",
        label: "Renta por 11 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer12Months",
        label: "Renta por 12 meses",
        required:false
      },{
        id:"rentPer12MonthsIndustry",
        label: "Renta por 12 meses",
        required:false
      }]}
    />
  </FormDialog>

export default Prices;
