import React, { Fragment, useState } from "react";
import { connect } from 'react-redux';
import { format } from 'date-fns';

import RoomIcon from '@material-ui/icons/RoomOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';

import { FormDialog } from './../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import DateTimeField from './../Sales.v2/helpers/DateTimeField';
import TextField from './../Sales.v2/helpers/TextField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  collectionDate: null,
  address: '',
  deliveryDate: null,
  collectionCommentary: '',
  deliveryCommentary: ''
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}


const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const authorizeReplace = async () => {
    try{
      setIsLoading(true);
      const response = await recordsServices.updateV2(`v2/replaceAuths/${props.id}/authorize`, values).then(data => {
        setIsLoading(false);
        return data;
      }).catch(err => {
        setIsLoading(false);
        return err;
      });
      if(response.errorCode) throw new Error(response.errorMessage);

      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: 'El reemplazo fue realizado con exito', 
        severity: 'success' 
      }));
      setValues(defaultValues);
      props.closeFormDialog('Authorize');
      props.reload()
    }catch(e){
      setIsLoading(false);
      setSnackbar(snackbar => ({ ...snackbar, time: null, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  return (
    <Fragment>
      <FormDialog 
        name="Authorize"
        singular="Authorize"
        title="Entrega y Recolección"
        primaryButtonLabel='Almacenar'
        isLoading={isLoading}
        handleCustomizedClick={authorizeReplace}
      >
        <TextField
          icon={<RoomIcon />}
          name='address'
          onChange={handleChange}
          value={values.address}
          label='Domicilio'
        />
        <DateTimeField
          icon={<EventIcon />}
          name='deliveryDate'
          label='Fecha y hora de entrega del nuevo equipo'
          onChange={handleDateChange}
          value={values.deliveryDate}
        />
        <DateTimeField
          icon={<EventIcon />}
          name='collectionDate'
          label='Fecha y hora de recolección del actual equipo'
          onChange={handleDateChange}
          value={values.collectionDate}
        />
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || []
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);