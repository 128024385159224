const api = ['name','phone','addressLine1','addressLine2','zipCode','city','reference','stateId','countryId'];

const samples = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return { ...tmp, stateId: tmp.stateId === '' ? null : tmp.stateId, countryId: tmp.countryId === '' ? null : tmp.countryId };
    default:
      return tmp;
  }
}

export default samples;
