import React, { useMemo, useState, Fragment } from "react";
import { connect } from 'react-redux';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format'; 

import { Delete } from "@material-ui/icons";
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';

import Module from '../../_presentationals/Module';

import { openFormDialog } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { config } from './../../_helpers/config';

import Filter  from './filter.dialog';
import Snackbar from './../Sales.v2/helpers/Snackbar';
import CancelCPDialog from './cancelCP.dialog'


const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}

const Component = ({ ...props}) => {

  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [saleId, setSaleId] = useState(null)
  const [isInvoice, setIsInvoice] = useState(false)
  
  const payments  = useMemo(() =>
    props.paymentsV2.count > 0 ? props.paymentsV2.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el,
        showIf: el.InvoiceV2 ? true : false,
      }, 
      "Fecha":{
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        filter: true,
        _sort: 'date',
        noBreak: true
      }, 
      "Folio":{
        value: `${el.folio}-${el.serial}`,
        _sort: 'date',
        noBreak: true
      },
      "CP":{
        value: el.InvoiceV2 ? <a href={el.cpUri} target="_blank" rel="noopener noreferrer">{`${el.InvoiceV2.folio}-${el.InvoiceV2.serial}`}</a> : '-',
        _sort: 'date',
        noBreak: true
      },
      "Factura":{
        value: <a href={el.saleUri} target="_blank" rel="noopener noreferrer">{`${el.SaleV2.folio}-${el.SaleV2.serial}`}</a>,
        filter: true,
        _sort: 'text',
        noBreak: true
      },
      "ImporteFactura":{
        value: moneyFormat(el.SaleV2.total),
        alt: parseFloat(el.SaleV2.total).toFixed(2),
        align: 'right',
        filter: true,
        _sort: 'alt'
      },
      Total: {
        value:  moneyFormat(parseFloat(el.total)),
        alt: parseFloat(parseFloat(el.total)),
        align: 'right',
        filter: true,
        _sort: 'alt'
      }
    })) : []
  , [props.paymentsV2]);

  const filter = () => {
    props.openFormDialog('Filter');
  };

  const handelXml = e => {
    const { InvoiceV2: { uuid } } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/files/invoices/${uuid}/xml`, '_blank');
  };

  const deletePayment = (e) => {
    try {
      const data = JSON.parse(e.currentTarget.dataset.row);
      props.openFormDialog('CancelCPDialog');
      setSaleId(data.InvoiceV2 ? data.InvoiceV2.paymentId : data.id)
      setIsInvoice(!!data?.InvoiceV2)
  
    } catch (error) {
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleCloseCancel = () => {
    props.closeFormDialog('CancelSaleDialog');
    setSaleId(null);
    setIsInvoice(false)
  }

  return (
    <Fragment>
      <Module
        table="paymentsV2"
        name="abonos"
        records={payments}
        singularName="Filtrar"
        title="Abonos"
        filter={filter}
        create={false}
        edit={false}
        download={true}
        _delete={false}
        actions={[ 
          { showIf: true, icon: <DescriptionIcon fontSize="small" />, label: "Descargar XML", handle: handelXml },
          { icon: <Delete fontSize="small" />, label: "Eliminar pago", handle: deletePayment },
        ]}
      />
      <Filter />
      <CancelCPDialog isInvoice={isInvoice} id={saleId} handleClose={handleCloseCancel} />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  paymentsV2: state.records.paymentsV2 || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
