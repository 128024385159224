import React, { useState, useEffect } from 'react';

import FormDialog from './../Sales.v2/helpers/FormDialog';
import SelectField from './../Sales.v2/helpers/SelectField';

const typeOptions = [
  { value: 1, label: "Consignación" },
  { value: 2, label: "Muestra" },
]

const defaultValues = {
  type: 1
}

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = () => {
    try{
      if(!values.type) throw new Error('Selecciona un tipo');
      props.handleAction(values);
      setErrors({});
    }catch(e) {
      setErrors({ type: e.message });
    }
  }

	return (
    <FormDialog
      title='Configuración'
      actionLabel='Seleccionar'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      <SelectField 
        name='type'
        label='Tipo' 
        onChange={handleChange}
        options={typeOptions}
        value={values.type}
        error={errors.type}
      />
    </FormDialog>
	)
}

export default Component;