import React, { useMemo, Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import Module from '../../_presentationals/Module';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format'; 
import { config } from './../../_helpers/config';

import { editValueAction, recordsActions, openFormDialog } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { FilterDialog }  from './filter';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Commission = ({editValueAction, getRecords, ...props}) => {
  useEffect(() => {
    getRecords({ table: 'employees', sellers: true });
  }, [getRecords, editValueAction]);

  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  const commission  = useMemo(() =>
    props.commission.count > 0 ? props.commission.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      "Fecha Abono":{
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        filter: true,
        _sort: 'date'
      },
      "Fecha NV":{
        value: format( parseISO(el.saleCreatedAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        filter: true,
        _sort: 'date'
      },
      "NV":{
        value: el.saleFolio,
        filter: true,
        _sort: 'text'
      },
      "Vendedor":{
        value: `${el.firstName} ${el.lastName}`,
        filter: true,
        _sort: 'text'
      },
      "Venta":{
        value: moneyFormat(el.total),
        align: 'right',
        filter: true,
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        _sort: 'alt'
      },
      "Folio Abono":{
        value: el.folio,
        _sort: 'text'
      },
      "Abono":{
        value: moneyFormat(el.payment),
        align: 'right',
        filter: true,
        alt: parseFloat(parseFloat(el.payment).toFixed(2)),
        _sort: 'alt'
      },
      "Comisión x Venta Total":{
        value: moneyFormat(el.commission),
        align: 'right',
        filter: true,
        alt: parseFloat(parseFloat(el.commission).toFixed(2)),
        _sort: 'alt',
        accumulate: true, accumulateLabel: 'Comisión x Ventas' 
      },
      "Comisión x Abono":{
        value:  moneyFormat(el.commissionPerPayment),
        align: 'right',
        filter: true,
        alt: parseFloat(parseFloat(el.commissionPerPayment).toFixed(2)),
        _sort: 'alt',
        accumulate: true, accumulateLabel: 'Comisión x Abonos'
      },
      Saldo: {
        value:  moneyFormat(el.balance),
        alt: parseFloat((parseFloat(el.balance)).toFixed(2)),
        align: 'right',
        filter: true,
        _sort: 'alt'
      }
    })) : []
  , [props.commission]);

  const details = e => {
    const { saleId } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${saleId}`, '_blank');
  }; 

  const filter = () => {
    props.openFormDialog('CommissionFilter');
  };

  return (
    <Fragment>
      <Module
        table="commission"
        name="comisiones"
        records={commission}
        singularName="Filtrar"
        title="Comisiones"
        filter={filter}
        create={false}
        edit={false}
        download={true}
        _delete={false}
        actions={(privileges.commission && privileges.commission.ticket) &&[{
          icon: <ZoomInIcon fontSize="small" />,
          label: "Generar Ticket",
          handle: details
        }]}
      ></Module>
      <FilterDialog employees={[{ value: 0, label: 'TODOS' }, ...employees]} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  employeeId: state.field.value.employeeId || '',
  start: state.field.value.start || format(new Date(),'yyyy/MM/dd'),
  finish: state.field.value.finish || format(new Date(),'yyyy/MM/dd'),
  commission: state.records.commission || defaultTableValue,
  employees: state.records.employees || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction : value => dispatch(editValueAction(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Commission);