import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormDialog } from './../../_presentationals/FormDialog';

import { recordsActions, editValueAction } from '../../_actions';


const floatFormat = number => !isNaN(parseFloat(number)) ? parseFloat(number).toFixed(2) : 0
const _amount = ({ quantity, amount, discount }) => {
  quantity = floatFormat(quantity);
  discount = floatFormat(discount);
  amount = floatFormat(amount / 1.16);
  const price = amount * quantity;
  return parseFloat(price - (price * (discount / 100))).toFixed(2);
}

const SaleDetails = ({getRecords, clearRecords, ...props }) => {
  const [budget, setBudget] = useState({subtotal: 0, discount: 0, tax: 0, total: 0});
  
  useEffect(() => {
    let tmpSubtotal = 0, tmpDiscount = 0, tmpTax = 0;
    props.SaleDetails && props.SaleDetails.forEach(({quantity, discount, amount, rentPlan = []}) => {
      amount = !amount ? rentPlan[0].amount : amount;
      const tmpAmount = _amount({quantity, amount});
      const tmpDiscountPercentage = floatFormat(discount) / 100;
      const tmpProductTax = ((tmpAmount - (tmpAmount * tmpDiscountPercentage)) * 0.16);
      tmpTax = parseFloat(tmpTax) + parseFloat(tmpProductTax);
      tmpSubtotal = parseFloat(tmpSubtotal) + parseFloat(tmpAmount);
      tmpDiscount = parseFloat(tmpDiscount) + (parseFloat(tmpAmount) * parseFloat(tmpDiscountPercentage));
    });
    
    setBudget({ 
      subtotal: floatFormat(tmpSubtotal), 
      discount: floatFormat(tmpDiscount), 
      tax: floatFormat(tmpTax),
      total: parseFloat(tmpSubtotal - tmpDiscount + tmpTax).toFixed(2)
    });

    editValueAction({total: parseFloat(tmpSubtotal - tmpDiscount + tmpTax).toFixed(2)})

  }, [props.SaleDetails]);
  
  return (
    <FormDialog
      title="Detalles de la venta"
      name="SaleDetails"
      singular="Detail"
      table="Details"
      showActionButton = {false}
    >
      <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Producto</TableCell>
          <TableCell align="center">Cant.</TableCell>
          <TableCell align="right">Dscto.</TableCell>
          <TableCell align="right">Precio Unitario</TableCell>
          <TableCell align="right">Importe</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.SaleDetails && props.SaleDetails.map(({Product, quantity, discount, amount, rentPlan = []}, index) => {
          amount = !amount ? rentPlan[0].amount : amount;
          return(<TableRow key={index}>
            <TableCell component="th" scope="row">
               {Product.name} {rentPlan.length > 0 ? '('+rentPlan[0].method+')' : "(venta)"}
            </TableCell>
            <TableCell align="center">{quantity}</TableCell>
            <TableCell align="right">{discount || 0}%</TableCell>
            <TableCell align="right">{parseFloat(amount  / 1.16).toFixed(2)}MXN</TableCell>
            <TableCell align="right">{_amount({quantity, amount, discount})}MXN</TableCell>
          </TableRow>)
        })}
        <TableRow selected={true}>
          <TableCell colSpan={3} />
          <TableCell align="right">Subtotal:</TableCell>
          <TableCell align="right">{budget.subtotal}MXN</TableCell>
        </TableRow>
        <TableRow selected={true}>
          <TableCell colSpan={3} />
          <TableCell align="right">Dscto:</TableCell>
          <TableCell align="right">{budget.discount}MXN</TableCell>
        </TableRow>
        <TableRow selected={true}>
          <TableCell colSpan={3} />
          <TableCell align="right">IVA:</TableCell>
          <TableCell align="right">{budget.tax}MXN</TableCell>
        </TableRow>
        <TableRow selected={true}>
          <TableCell colSpan={3} />
          <TableCell style={{ fontWeight: 'bold' }} align="right">Total:</TableCell>
          <TableCell style={{ fontWeight: 'bold' }} align="right">{budget.total}MXN</TableCell>
        </TableRow>
      </TableBody>
    </Table>
      
    </FormDialog>
  );
}

const mapStateToProps = state => ({
  SaleDetails: state.field.value.SaleDetails || []
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaleDetails);
