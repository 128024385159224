import React, { useEffect, Fragment, useState, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import Icon from '@material-ui/core/Icon';
import { SelectField } from './../../_presentationals/Form';
import Grid from '@material-ui/core/Grid';
import { ListItemAdd } from './listItemAdd';
import { editValueAction, recordsActions, alertActions } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const Component = ({ confrimationProducts, getRecords, ...props }) => {
  const [local,setLocal] = useState([]);
  const [qty,setQty] = useState([]);
  const [products,setProducts] = useState([]);
  
  useEffect(() => {
    setLocal(confrimationProducts);
    setQty(JSON.stringify(local));

    const tmp = confrimationProducts.reduce((newArray = [],{ productId, name, quantity }) => {
      if(productId) newArray = [ ...newArray, { value: productId, label: name, quantity }];
      return newArray;
    },[]);

    setProducts(tmp);

    props.editValueAction({ receptionProducts: confrimationProducts, productId: null, quantity: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[confrimationProducts]);

  const quantityProducts = useMemo(() => {
    if(props.selectedProduct.productId) {
      const data = products.find(tmp => parseInt(tmp.value) === parseInt(props.selectedProduct.productId));
      if(data) {
        return Array.from({ length: parseFloat(data.quantity) }, (v,k) => ({ key: (parseInt(k) + 1), value: (parseInt(k) + 1) }) );
      }
    }
    return [];
  },[props.selectedProduct.productId, products]);

  const handleAddProduct = e => {
    const { productId, productIdLabel, quantity } = props.selectedProduct;
    if(!productId && !quantity) return;

    const isExists = local.find(tmp => parseInt(tmp.productId) === parseInt(productId));
    if(isExists) {
      props.alertMessage("Producto añadido previamente, Eliminelo y vuelva a ingresar.");
      return;
    }

    setLocal(values => [ { productId, name: productIdLabel, quantity }, ...values ]);
    setQty(JSON.stringify(local));
    props.editValueAction({ receptionProducts: [ { productId, name: productIdLabel, quantity }, ...local ], productId: null, quantity: null } );
  };

  const tableRows = useMemo(() => {
    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal([ ...tmp ]);
      props.editValueAction({ receptionProducts: [ ...tmp ] });
    };

    return local.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">
          {el.name} 
        </TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, qty]);
  
  return (
    <FormDialog 
      title="Confirmación"
      singular="reception"
      table="migrations"
    >
      <Fragment>
        <ListItemAdd handleClick={handleAddProduct}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                label="Refacción" 
                _id="productId" 
                dialog={false}
                options={products}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                fields={[{
                  id: "quantity",
                  label: "Cant.",
                  options: quantityProducts,
                }]}
              />
            </Grid>
          </Grid>
        </ListItemAdd>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell align="center">Cant.</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </Fragment>
    </FormDialog>
  );
}

const mapStateToProps = state => ({
  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel,
    quantity: state.field.value.quantity,
  },
  isOpen: state.formDialog,
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  updateRecords: table => dispatch(recordsActions.update(table)),
  alertMessage: data => dispatch(alertActions.error(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Component);