const logisticIndependent = (method, values) => {
	const tmp = {};
  switch (method) {
    case 'PUT':
      if (values.status) return { status: values.status, commentary: values.commentary }
      if (values.employeeId) return { employeeId: values.employeeId }
    	
      tmp['collectionCommentary'] = values.collectionCommentary;
      tmp['collectionTime'] = values.collectionTime;
      tmp['collectionDate'] = values.collectionDate;
      tmp['receptionName'] = values.receptionName;
      tmp['addressLine1'] = values.addressLine1;
    	tmp['addressLine2'] = values.addressLine2;
    	tmp['city'] = values.city;
    	tmp['zipCode'] = values.zipCode;
    	tmp['reference'] = values.reference;
    	if (values.countryId) tmp['countryId'] = values.countryId;
    	if (values.stateId) tmp['stateId'] = values.stateId;

      return tmp;
    default:
      return tmp;
  }
}

export default logisticIndependent;
