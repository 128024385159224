import React, { useMemo } from "react";
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import { FormDialog } from '../../_presentationals/FormDialog';
import { styles } from './style';

import { SelectField, TextField } from './../../_presentationals/Form';

const ProductDialog = props => {
  
  const rentPrices = useMemo(() => {
    const tmp = [];
    props.rentPrices[1].map(el => tmp.push({ value: methodToText(el.method), key: el.method }));
    return tmp;
  }, [props.rentPrices]);

  const methodOptions = useMemo(() => {
    const tmp = [{ key: 1, value: "Venta" }];
    if(props.rentPrices[0] > 0){
      tmp.push({ key: 2, value: "Renta" });
    }
    return tmp;
  }, [props.rentPrices]);

  return (
    <FormDialog 
      singular="ProductDialog" 
      title="Configuración del Producto" 
      clearDataAfterClose={false}
      handleCustomizedClick={props.handleClick}
    >
      <SelectField 
      	icon="category"
        fields={[{
          id: "methodProduct",
          label: "Modalidad",
          options: methodOptions
        }]}
      />
      {
        (props.methodProduct && props.methodProduct === 2) && <SelectField 
          fields={[{
            id: "rentOptions",
            label: "Plan de Renta",
            options: rentPrices
          }]}
        />
      }
      {
        (props.methodProduct && props.methodProduct === 2) && <TextField
          fields={[{
            id: "planQuantity",
            label: "Cantidad de Plan"
          }]}
        />
      }
    </FormDialog>
  );

}

const methodToText = value => {
  let label = "";
  switch (value){
    case "rentPerEvent":
      label = "Renta por evento (Comercial)";
      break;
    case "rentPer1Month":
      label = "Renta por 1 mes (Comercial)";
      break;
    case "rentPer4Months":
      label = "Renta por 4 meses (Comercial)";
      break;
    case "rentPer6Months":
      label = "renta por 6 meses (Comercial)";
      break;
    case "rentPer12Months":
      label = "Renta por 12 meses (Comercial)";
      break;
    case "rentPer8Months":
      label = "Renta por 8 meses (Comercial)";
      break;

      case "rentPerEventIndustry":
      label = "Renta por evento (Industria)";
      break;
    case "rentPer1MonthIndustry":
      label = "Renta por 1 mes (Industria)";
      break;
    case "rentPer4MonthsIndustry":
      label = "Renta por 4 meses (Industria)";
      break;
    case "rentPer6MonthsIndustry":
      label = "renta por 6 meses (Industria)";
      break;
    case "rentPer12MonthsIndustry":
      label = "Renta por 12 meses (Industria)";
      break;
    case "rentPer8MonthsIndustry":
      label = "Renta por 8 meses (Industria)";
      break;
    default:
      label = "Indefinido";
      break;
  }
  return label;
};

const mapStateToProps = state => ({ 
  rentPrices: state.field.value.rentPrices || [0, []],
  methodProduct: state.field.value.methodProduct || 2,
});

export default connect(mapStateToProps)(withStyles(styles)(ProductDialog));