import React, { useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';

import { moneyFormat } from '../../_helpers/moneyFormat';

const Tool = props => {

  const tools = useMemo(() => 
    props.tools.count > 0 ? props.tools.rows.map(el => ({
      ID: { 
        value: el.id,
        data: el
      }, 
      Nombre: {
       value: el.name,
       filter: true,
       _sort: 'text'
      }, 
      Características: { 
        value: el.description,
        _sort: 'text'
      },
      Costo: { 
        value: moneyFormat(el.cost),
        alt: parseFloat(parseFloat(el.cost).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      Inventario: { 
        value: el.stock,
        alt: parseFloat(parseFloat(el.stock).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      }
    })) : []
  , [props.tools]);

  return (
    <Module
      table="tools"
      name="herramientas"
      singularName="herramienta"
      filter={false}
      download={true}
      title="Crear Herramienta"
      records={tools}
    >
      <TextField
        icon="category"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        fields={[{
          id: "description", 
          label: "Características",
          multiline: true
        }]}
      />
      <TextField
        icon="payment"
        fields={[{
          id: "cost", 
          label: "Costo"
        }]}
      />
      <TextField
        fields={[{
          id: "stock", 
          label: "Inventario"
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  tools: state.records.tools || defaultTableValue
});

export default connect(mapStateToProps, null)(Tool);