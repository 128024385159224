import React, { useMemo, Fragment } from "react";
import { connect } from 'react-redux';

// import ZoomInIcon from '@material-ui/icons/ZoomIn';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format'; 

import Module from '../../_presentationals/Module';

import { openFormDialog } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';
// import { config } from './../../_helpers/config';

import { FilterDialog }  from './filter';

// const session = JSON.parse(localStorage.getItem('session'));
// const privileges = session && JSON.parse(session.data.privileges);

const Commission = ({ ...props}) => {
  
  const commission  = useMemo(() =>
    props.commission.count > 0 ? props.commission.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      "FechaAbono":{
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        filter: true,
        _sort: 'date',
        noBreak: true
      },
      "FechaFacturación":{
        value: format( parseISO(el.SaleV2.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        filter: true,
        _sort: 'date',
        noBreak: true
      },
      "Factura":{
        value: el.SaleV2.folio + '-' + el.SaleV2.serial,
        filter: true,
        _sort: 'text',
        noBreak: true
      },
      "Vendedor":{
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : '-',
        filter: true,
        _sort: 'text',
        noBreak: true
      },
      "ImporteFactura":{
        value: moneyFormat(el.sale),
        alt: parseFloat(el.sale).toFixed(2),
        align: 'right',
        filter: true,
        _sort: 'alt'
      },
      "Abono":{
        value: moneyFormat(el.payment),
        align: 'right',
        filter: true,
        alt: parseFloat(parseFloat(el.payment).toFixed(2)),
        _sort: 'alt'
      },
      "ComisiónMáxima":{
        value: moneyFormat(el.commissionAmount),
        align: 'right',
        filter: true,
        alt: parseFloat(parseFloat(el.commissionAmount).toFixed(2)),
        _sort: 'alt',
        accumulate: true, accumulateLabel: 'Comisión x Ventas' 
      },
      "ComisiónActual":{
        value:  moneyFormat(el.commission),
        align: 'right',
        filter: true,
        alt: parseFloat(parseFloat(el.commission).toFixed(2)),
        _sort: 'alt',
        accumulate: true, accumulateLabel: 'Comisión x Abonos'
      },
      Saldo: {
        value:  moneyFormat(parseFloat(el.commissionAmount) - parseFloat(el.commission)),
        alt: parseFloat(parseFloat(el.commissionAmount) - parseFloat(el.commission)).toFixed(2),
        align: 'right',
        filter: true,
        _sort: 'alt'
      }
    })) : []
  , [props.commission]);

  // const details = e => {
  //   const { saleId } = JSON.parse(e.currentTarget.dataset.row);
  //   window.open(`${config.apiUrl}/notes/sales/${saleId}`, '_blank');
  // }; 

  const filter = () => {
    props.openFormDialog('CommissionFilter');
  };

  return (
    <Fragment>
      <Module
        table="commissionV2"
        name="comisiones"
        records={commission}
        singularName="Filtrar"
        title="Comisiones"
        filter={filter}
        create={false}
        edit={false}
        download={true}
        _delete={false}
      />
      <FilterDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  commission: state.records.commissionV2 || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

/*actions={(privileges.commission && privileges.commission.ticket) &&[{
          icon: <ZoomInIcon fontSize="small" />,
          label: "Generar Ticket",
          handle: details
        }]}*/

export default connect(mapStateToProps, mapDispatchToProps)(Commission);