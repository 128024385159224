const consignmentManufactures = ({method, ...props}) => {
	console.log(props);
  switch (method) {
    case 'DELETE':
      return `consignments/manufactures/${props._id}`;
    case 'POST':
    	return `consignments/${props.redirectId}/manufactures/${props.manufactureId}`
    default:
      return `consignments/${props.redirectId}/manufactures`;
  }
}

export default consignmentManufactures;