const cashClosings = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
      tmp['branchClosing'] = values['branchClosing'] === 1 ? true : false ;
      return tmp;
    default:
      return tmp;
  }
}

export default cashClosings;
