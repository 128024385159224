import React, { Fragment, useMemo, useState } from "react";
import { connect } from 'react-redux';
import randomize from 'randomatic';

import AppsIcon from '@material-ui/icons/AppsOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';

import Module from '../../_presentationals/Module';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';
import * as recordsServices from './../../_services/records.services';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { openFormDialog } from '../../_actions';

import Snackbar from './../Sales.v2/helpers/Snackbar';

import ProductDialog from './product.dialog';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = ({ editValueAction, ...props }) => {
  const [ serviceOrderId, setServiceOrderId ] = useState(null);
  const [ serviceOrderCancelId, setServiceOrderCancelId ] = useState(null);
  const [ isProductOpen, setIsProductOpen ] = useState([false, null]);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  
  const serviceOrderAuths = useMemo(() =>
    props.serviceOrderAuth.count > 0 ? props.serviceOrderAuth.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el,
        showIf: parseInt(el.status) === 7 ? true : false,
        _alternative: parseInt(el.status) === 6 ? true : false,
      }, 
      Fecha: {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        _sort: 'date'
      },
      Cliente: {
       value: el.Customer ? `${el.Customer.firstName} ${el.Customer.lastName}` : '-',
       filter: true,
       _sort: 'text'
      },
      Folio: {
        value: el.folio + '-' + el.serial,
        noBreak: true
      },
      Equipo: {
        value: el.Product ? el.Product.name : '-',
        filter: true,
        _sort: 'text'
      },
      Serie: {
        value: el.physicalSerie,
        _sort: 'text'
      },
      Importe: {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt',
        accumulate: true,
        accumulateLabel: 'Total'
      },
      Usuario: {
        value: el.User ? `${el.User.firstName} ${el.User.lastName}` : '-',
        filter: true,
        _sort: 'text'
      },
      Sucursal: {
        value: el.Branch.name,
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.serviceOrderAuth]);

  const handleActionAuthorize = async serviceOrderId => {
    try{
      setIsLoading(true);
      await recordsServices.updateV2(`v2/serviceOrders/${serviceOrderId}/authorize`).then(data => {
        const tmpCode = randomize('*', 10);
        setRefreshCode(tmpCode);
        setIsLoading(false);
        setServiceOrderId(0);
        setSnackbar(snackbar => ({ 
          ...snackbar, 
          isOpen: true, 
          label: 'La Orden de Servicio fue autorizada', 
          severity: 'success' 
        }));
      });
      
    }catch(e){
      alert(e.message);
      setIsLoading(false);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleActionNoAuthorize = async serviceOrderId => {
    try{
      setIsLoading(true);
      await recordsServices.updateV2(`v2/serviceOrders/${serviceOrderId}/authorize`,{ status: 5 }).then(data => {
        const tmpCode = randomize('*', 10);
        setRefreshCode(tmpCode);
        setIsLoading(false);
        setServiceOrderCancelId(0);
        setSnackbar(snackbar => ({ 
          ...snackbar, 
          isOpen: true, 
          label: 'La Orden de Servicio no fue autorizada', 
          severity: 'success' 
        }));
      });
      
    }catch(e){
      alert(e.message);
      setIsLoading(false);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleAuthorize = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setServiceOrderId(id);
  }

  const handleNoAuthorize = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setServiceOrderCancelId(id);
  }

  const handleCancelAuthorize = () => setServiceOrderId(null);
  const handleCancelNoAuthorize = () => setServiceOrderCancelId(null);

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleProducts = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setIsProductOpen([true, id]);
    props.openFormDialog('Product');
  }

  // const handleAuth = e => {
  //   const { id } = JSON.parse(e.currentTarget.dataset.row);
  //   editValueAction({ _id: id, id });
  //   props.openFormDialog('AuthDialog');
  // }

  return (
    <Fragment>
      <Module
        table="serviceOrderAuth"
        name="Autorización de Ordenes de Servicio"
        records={serviceOrderAuths}
        singularName="Autorización"
        create={false}
        edit={false}
        _delete={true}
        download={true}
        filter={false}
        refreshCode={refreshCode}
        actions={[
          { icon: <AppsIcon fontSize="small" />, label: "Productos", handle: handleProducts },
          { icon: <DoneAllIcon fontSize="small" />, label: "Autorizar", handle: handleAuthorize },
          { icon: <DeleteIcon fontSize="small" />, label: "Cancelar", handle: handleNoAuthorize },
        ]}
      />
      <ProductDialog serviceOrderId={isProductOpen[1]} />
      <ConfirmDialog 
        _id={serviceOrderId} 
        isLoading={isLoading}
        handleCancel={handleCancelAuthorize} 
        handleAction={handleActionAuthorize} 
      />
      <ConfirmDialog 
        _id={serviceOrderCancelId} 
        isLoading={isLoading}
        handleCancel={handleCancelNoAuthorize} 
        handleAction={handleActionNoAuthorize} 
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  serviceOrderAuth: state.records.serviceOrderAuth || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Component);