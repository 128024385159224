import React, { useState, useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

const ConfirmDialogModule = ({_id, ...props}) => {

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    (_id > 0) && setIsOpenDialog(true);
    (_id === 0) && setIsOpenDialog(false);
  }, [_id]);

  const handleClose = () => {
    props.handleCancel();
    setIsOpenDialog(false);
  }

  const handleAction = () => {
    props.handleAction(_id);
    // setIsOpenDialog(false);
  }

  return (
    <Dialog
      open={isOpenDialog}
      fullWidth={true}
      maxWidth="xs"
      disableEscapeKeyDown={true}
      aria-labelledby="Confirmar"
      aria-describedby="Diálogo de confirmación para validar un registro."
    >
      { props.isLoading && <LinearProgress color='secondary' /> }
      <DialogTitle disableTypography={true}>¿Estás seguro que deseas continuar?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button disabled={props.isLoading ? true : false} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={props.isLoading ? true : false} onClick={handleAction} color="primary" autoFocus>
          Si, estoy seguro.
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const ConfirmDialog = ConfirmDialogModule;
