import React, { useEffect } from "react";
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField, SelectField } from '../../_presentationals/Form';
import { styles } from './style';
import { closeFormDialog, editValueAction } from '../../_actions';

const availableMethods = [
  {key: "01", value: "Efectivo"},
  {key: "02", value: "Cheque nominativo"},
  {key: "03", value: "Transferencia electrónica de fondos"},
  {key: "04", value: "Tarjeta de crédito"},
  {key: "05", value: "Monedero electrónico"},
  {key: "06", value: "Dinero electrónico"},
  {key: "08", value: "Vales de despensa"},
  {key: "12", value: "Dación en pago"},
  {key: "13", value: "Pago por subrogación"},
  {key: "14", value: "Pago por consignación"},
  {key: "15", value: "Condonación"},
  {key: "17", value: "Compensación"},
  {key: "23", value: "Novación"},
  {key: "24", value: "Confusión"},
  {key: "25", value: "Remisión de deuda"},
  {key: "26", value: "Prescripción o caducidad"},
  {key: "27", value: "A satisfacción del acreedor"},
  {key: "28", value: "Tarjeta de débito"},
  {key: "29", value: "Tarjeta de servicios"},
  {key: "30", value: "Aplicación de anticipos"},
  {key: "31", value: "Intermediario pagos"},
  {key: "99", value: "Por definir"}
];

const aviableUseCdfi = [
  {
    "key": "G01",
    "value": "Adquisición de mercancias",
    
  },
  {
    "key": "G02",
    "value": "Devoluciones, descuentos o bonificaciones"
  },
  {
    "key": "G03",
    "value": "Gastos en general"
  },
  {
    "key": "I01",
    "value": "Construcciones"
  },
  {
    "key": "I02",
    "value": "Mobilario y equipo de oficina por inversiones"
  },
  {
    "key": "I03",
    "value": "Equipo de transporte"
  },
  {
    "key": "I04",
    "value": "Equipo de computo y accesorios",
  },
  {
    "key": "I05",
    "value": "Dados, troqueles, moldes, matrices y herramental"
  },
  {
    "key": "I06",
    "value": "Comunicaciones telefónicas"
  },
  {
    "key": "I07",
    "value": "Comunicaciones satelitales"
  },
  {
    "key": "I08",
    "value": "Otra maquinaria y equipo"
  },
  {
    "key": "D01",
    "value": "Honorarios médicos, dentales y gastos hospitalarios."
  },
  {
    "key": "D02",
    "value": "Gastos médicos por incapacidad o discapacidad"
  },
  {
    "key": "D03",
    "value": "Gastos funerales."
  },
  {
    "key": "D04",
    "value": "Donativos."
  },
  {
    "key": "D05",
    "value": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)."
  },
  {
    "key": "D06",
    "value": "Aportaciones voluntarias al SAR."
  },
  {
    "key": "D07",
    "value": "Primas por seguros de gastos médicos."
  },
  {
    "key": "D08",
    "value": "Gastos de transportación escolar obligatoria."
  },
  {
    "key": "D09",
    "value": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones."
  },
  {
    "key": "D10",
    "value": "Pagos por servicios educativos (colegiaturas)"
  },
  {
    "key": "P01",
    "value": "Por definir"
  }
];

const Dialog = ({ classes, closeFormDialog, total, billingInformationId, editValueAction, ...props }) => {
  
  useEffect(() => {
    const prepaid = props.paymentType === 1 ? total : "";
    editValueAction({prepaid});
  }, [props.paymentType, editValueAction, total]);

	return (
	  <FormDialog  
      singular="LastStepDialog" 
      title="¿Quieres hacer algo más?"
      handleCustomizedClick={props.handleClick}
      clearDataAfterClose={false}
    >
      <SelectField
        fields={[{
          id: "paymentType",
          label: "Tipo de pago",
          options: [
            { key: 1, value: "Liquidación" },
            { key: 2, value: "Anticipo" },
            { key: 3, value: "No hacer pago" }
          ],
        }]}
      />
      {props.paymentType !== 3 &&
        <div>
    	    <TextField
            fields={[{
              id: "prepaid", 
              label: "Anticipo",
              required: false,
              disabled: props.paymentType === 1 ? true : false
            }]}
          />
          <SelectField
            fields={[{
              id: 'paymentMethod',
              label: 'Método de pago',
              options: availableMethods,
            }]}
          />
        </div>
      }
      {billingInformationId > 0 && 
        <div>
          <SelectField
            fields={[{
              id: "isInvoice",
              label: "Emitir factura electrónica",
              options: [
                { key: 1, value: "Si" },
                { key: 2, value: "No" }
              ],
            }]}
          />
          {props.isInvoice === 1 &&
            <div>
              <TextField
                fields={[{
                  id: "quantityInvoice", 
                  label: "Desglose de facturas"
                }]}
              />

              <SelectField
                fields={[{
                  id: "paymentForm",
                  label: "Forma de pago",
                  options: availableMethods,
                }]}
              />

              <SelectField
                fields={[{
                  id: "useCdfi",
                  label: "Uso de CDFI",
                  options: aviableUseCdfi,
                }]}
              />
            </div>
          }
        </div>
      }

	  </FormDialog>
	)
};

const mapStateToProps = state => ({
  paymentType: state.field.value.paymentType || 0,
  isInvoice: state.field.value.isInvoice || false,
  customers: state.records.customers || {count: 0, rows: {}}
});

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data))
});

export const LastStepDialog = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Dialog));