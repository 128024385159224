// import React from "react";
import * as XLSX from 'xlsx';
import { format } from 'date-fns'
import localeES from 'date-fns/locale/es';

export const JsonToXls = ({ title, data }) => {
	const filename = title + '_' + format(new Date(), "dd-MMM-yyyy_HH-mm", {locale: localeES}) + ".xlsx";
	const wb = XLSX.utils.book_new();

	const header = data.length > 0 ? Object.keys(data[0]).map(el => el) : [0];
	
	let ws = XLSX.utils.json_to_sheet([
	  { [header[0]]: "Reporte de " + title }
	], {
		header,
		skipHeader: true
	});

	// var ws = XLSX.utils.json_to_sheet([
	//   { S:1, h:2, e:3, e_1:4, t:5, J:6, S_1:7 },
	//   { S:2, h:3, e:4, e_1:5, t:6, J:7, S_1:8 }
	// ], {
	// 	header:["S","h","e","e_1","t","J","S_1"],
	// 	skipHeader: false
	// });


	XLSX.utils.sheet_add_json(ws, data, { skipHeader: false, origin: "A2"});

	ws["!merges"] = [{ s: {r: 0, c:0}, e: {r:0, c:(parseInt(header.length) - 1)} }];

	// ws['A1'].s = { 
	// 	alignment: { 
	// 		vertical: 'center', 
	// 		horizontal: 'center' 
	// 	},
	// 	font: {
	// 		bold: true
	// 	}
	// };

  // var cell_ref = XLSX.utils.encode_cell({c:0, r:0});
	// ws["A1"] = { 
	// 	v: "Ejemplo",
	// 	s: {
	// 		alignment: { 
	// 			vertical: 'center', 
	// 			horizontal: 'center' 
	// 		},
	// 		font: {
	// 			bold: true
	// 		}
	// 	}
	// };

  // cell_ref = { s: { alignment: { vertical: 'center', horizontal: 'center' } } };
	// ws.getCell('A1').font = { bold: true, size: 18 };

	const ws_name = title;

	XLSX.utils.book_append_sheet(wb, ws, ws_name);
	XLSX.writeFile(wb, filename);
}