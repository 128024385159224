import validator from 'validator';

export const Validator = ({ value, type, required }) => {
	if(required && validator.isEmpty(value)) return { isError: true, message: "Este campo es obligatorio." };
	
	switch(type){
		case "text":
			return { isError: false, message: "" };
		case "password":
			return passwordValidator(value);
		case "email":
			return emailValidator(value);
		case "phone":
			return phoneValidator(value);
		case "postalCode":
			return postalCodeValidator(value);
		case "creditCard":
			return creditCardValidator(value);
		case "currency":
			return currencyValidator(value);
		case "percent":
			return percentValidator(value);
		case "dateTime":
			return dateTimeValidator(value);
		default:
			return { isError: true, message: "Este campo es obligatorio." };
	}
}

const emailValidator = (value) => {
	if(!validator.isEmail(value) && !validator.isEmpty(value))
		return { isError: true, message: "El correo electrónico no es válido." };
	return { isError: false, message: "" };
}

const phoneValidator = (value) => {
	if(!validator.isInt(value) || !validator.isLength(value, { min:10,max:10 }))
		return { isError: true, message: "El formato de este número de teléfono no es válido." };
	
	return { isError: false, message: "" };
}

const postalCodeValidator = (value) => {
	if(!validator.isPostalCode(value, ['MX']) && !validator.isEmpty(value))
		return { isError: true, message: "El código postal no es válido." };
	return { isError: false, message: "" };
}

const creditCardValidator = (value) => {
	if(!validator.isCreditCard(value))
		return { isError: true, message: "El número de tarjeta de crédito no es válido." };
	return { isError: false, message: "" };
}

const currencyValidator = (value) => {
	if(!validator.isCurrency(value, {allow_negatives: false, digits_after_decimal: [2,3,4,5,6]}) && !validator.isEmpty(value))
		return { isError: true, message: "El monto proporcionado no es válido." };
	return { isError: false, message: "" };
}

const percentValidator = (value) => {
	if(!validator.isFloat(value, {min:0, max:100}) && !validator.isEmpty(value))
		return { isError: true, message: "El porcentaje no es válido." };
	return { isError: false, message: "" };
}

const passwordValidator = (value) => {
	if(validator.isEmpty(value))
		return { isError: true, message: "Este campo es obligatorio." };
	return { isError: false, message: "" };
}

const dateTimeValidator = (value) => {
	if(!validator.toDate(value))
		return { isError: true, message: "Este campo es obligatorio." };
	return { isError: false, message: "" };
}