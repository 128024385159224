import { config, authHeader } from '../_helpers';

export const handleFetchResponseNoAuth = async response => {
  const data = await response.json();
  if(!response.ok){
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
}

export const handleFetchResponse = request => async response => {
  
  const _session = JSON.parse(localStorage.getItem('session'));
  
  if(!response.ok && response.status === 401 && _session){
    const newAccessTokenRequest = await renewAccessToken(_session.refreshToken);
    if(newAccessTokenRequest.ok){
      return await newRequest(request);
    }
  }

  const data = response.json();

  if(!response.ok){
    if(response.status === 401 && _session){
      localStorage.removeItem('session');
      window.location.reload(true);
    }
    if(response.status === 401){
      window.location.reload(true);
    }
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return await data;
};

const renewAccessToken = token => {
  return fetch(
    `${ config.apiUrl }/auth/token`, 
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token })
    }
  )
  .then(response => response.json())
  .then(({ accessToken }) => {
    const _session = JSON.parse(localStorage.getItem('session'));
    const newSession = { ..._session, accessToken };
    localStorage.setItem('session', JSON.stringify(newSession));

    return { ok: true, ...newSession };
  })
  .catch(error => error);
}

const newRequest = ({url, method, headers, body}) => {
  const _session = JSON.parse(localStorage.getItem('session'));
  const myRequest = new Request(
    url, 
    { method, headers: { ...headers, ...authHeader() }, body }
  );
  return fetch(myRequest).then(async response => {
    const data = await response.json();
    if(!response.ok && response.status === 401 && _session){
      localStorage.removeItem('session');
      window.location.reload(true);
    }
    if(!response.ok){

      if(response.status === 401){
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return await data;
  });
}