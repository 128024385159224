const vehicleFailures = ({method, ...props}) => {
  switch (method) {
    case 'POST':
    case 'GET':
      return `vehicles/${props.redirectId}/failures/`;
    case 'PUT':
    	if(props.status) return `vehicles/${props.redirectId}/failures/${props._id}/status`; 
    	if(props.toExpense) return `vehicles/${props.redirectId}/failures/${props._id}/expenses`; 
    	return `vehicles/${props.redirectId}/failures/${props._id}`;
    case 'DELETE':
    	return `vehicles/${props.redirectId}/failures/${props._id}`;
    default:
      return 'vehicles';
  }
}

export default vehicleFailures;
