import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const rents = ({method, ...props}) => {
  switch (method) {
    default:
    	const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy/MM/dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy/MM/dd', {locale: localeES});
      
      return `sales/rents?start=${start}&finish=${finish}&method=${props.typeRent || 0}&employeeId=${props.employeeId || 0}&type=${props.type || 0}`;
  }
}

export default rents;