import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';

import { styles } from './style';

export default withStyles(styles)(({ icon, title, text, handleClick, classes }) => (
	<ListItem className={ classes.listItem } button>
    <ListItemAvatar>
       <Avatar>
         { icon }
       </Avatar>
    </ListItemAvatar>
    <ListItemText primary={ title }  secondary={ text } />
    <ListItemSecondaryAction>
       <Tooltip title="Editar" classes={{ tooltip: classes.tooltip }}>
        <IconButton  onClick={ handleClick }>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  </ListItem>
));