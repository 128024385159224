import React,{ useState, useEffect } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog, editValueAction } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const Component = ({getRecords,...props}) => {
	const [ manufactures, setManufacures ] = useState([]);
	useEffect(() => {
    props.isOpen && getRecords({
      table: 'manufactures',
      productId: props.productId,
      status: props.status,
      methodPlan: props.methodPlan,
      deliveryDate: props.deliveryDate,
      quantity: props.quantity
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.isOpen]);

  useEffect(() => {
    const tmp = props.manufactures.count > 0
    ? props.manufactures.rows.map(el => ({ value: el.id, label: `${el.Product.name} Serie: ${el.physicalSerie} ${el.statusLabel}` }))
    : [];
    setManufacures(manufactures => tmp);
  },[props.manufactures]); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!props.isOpen) props.editValueAction({ productId: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.isOpen]); 

	return (
	  <FormDialog  
      singular="ReplaceDialog" 
      title="Reemplazo"
      table="logisticReplace"
    >
      <Autocomplete
        label="Serie Física" 
        _id="replaceManufactureId" 
        dialog={false}
        options={manufactures}
        variant="outlined"
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({
  isOpen: state.formDialog,
  productId: state.field.value.productId || null,
  status: state.field.value.status,
  methodPlan: state.field.value.methodPlan,
  deliveryDate: state.field.value.deliveryDate,
  quantity: state.field.value.quantity,
  manufactures: state.records.manufactures || { count: 0, rows: [] },
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);