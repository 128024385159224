import React from "react";

import TextField  from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

const SelectField = ({ noFormat, error, icon, options, conditionalChange, ...props }) => 
  <Grid container spacing={0} alignItems="flex-end">
    <Grid item xs={ noFormat ? false : 1 }>
      { icon || "" }
    </Grid>
    <Grid item xs={noFormat ? 12 : 10}> 
      <TextField 
        select
        name={props.name}
        label={props.label}
        variant={ props.variant || "standard"}
        fullWidth={props.fullWidth || true}
        autoFocus={props.autoFocus || false}
        onChange={props.onChange}
        value={props.value}
        error={typeof error === 'string' ? true : false}
        helperText={error && error}
        autoComplete='off'
        data-conditionalchange={conditionalChange}
        { ...props }
      >
        { 
          options.map(el => 
            <MenuItem key={el.value} value={el.value}>
              {el.label}
            </MenuItem>
          ) 
        }
      </TextField>
    </Grid>
  </Grid>

export default SelectField;