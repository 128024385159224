import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const sales = ({method, ...props}) => {
  switch (method) {
    case 'PUT':
      return `sales/${props.id}/cancel`;
    case 'GET':
    	if (props.withoutInvoice) return `sales/withoutInvoices`;
    	if (props.rents) return `sales/rents`;

      const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy-MM-dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy-MM-dd', {locale: localeES});
      
    	return `sales/${start}/${finish}?employeeId=${props.employeeId || 0}`;

    default:
      return 'sales';
  }
}

export default sales;