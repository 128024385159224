const compounds = ({method, ...props}) => {
  switch (method) {
  	case 'PUT':
  	case 'DELETE':
  		return `compounds/${props._id}`
  	case 'POST':
  		return `products/${props.productId}/compounds`
    default:
      return 'compounds';
  }
}

export default compounds;