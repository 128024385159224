import React, { useState, useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import randomize from 'randomatic';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import TextField from './../Sales.v2/helpers/TextField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  description: false,
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);

  useEffect(() => {
     props.id && 
      recordsServices.getAllV2(`v2/serviceOrders/${props.id}`).then(data => {
        setValues(values => ({ ...values, description: data.description }));
      })
  },[props.id]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const handleAction = async () => {
    try{
      const respose = await recordsServices.updateV2(`v2/serviceOrders/${props.id}`, {
        ...values
      });
      if(respose.errorCode) throw new Error(respose.errorMessage);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: respose.message, 
        severity: 'success' 
      }));
      setValues(defaultValues);
      props.closeFormDialog('Edit');
      const tmpCode = randomize('*', 10);
      props.reload(tmpCode);
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog 
        title="Editar"
        name="edit"
        singular="Edit"
        handleCustomizedClick={handleAction}
      >
        <TextField
          name='description'
          label='Descripción de la Falla'
          onChange={handleChange}
          value={values.description}
        />
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);