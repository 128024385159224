 import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import format from 'date-fns/format';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import TodayIcon from '@material-ui/icons/TodayOutlined';

import { FormDialog } from '../../_presentationals/FormDialog';

import { recordsActions, closeFormDialog } from '../../_actions';
import * as recordsServices from '../../_services';

import DateField from './../Sales.v2/helpers/DateField';
import Autocomplete from './../Sales.v2/helpers/Autocomplete';

const currentDate = format(new Date(), 'yyyy/MM/dd');

const defaultValues = {
  start: currentDate,
  finish: currentDate,
  employeeId: null
};

const Dialog = props => {

  const [ error, setError ] = useState({});
  const [ values, setValues ] = useState(defaultValues);
  const [ employees, setEmployees ] = useState([]);

  useEffect(() => {
    recordsServices.getAllV2('employees/sellers?commercial=true').then(data => {
      const tmp = data.rows.map(el => ({ label: `${el.firstName} ${el.lastName}`.trim(), value: el.id }))
      setEmployees(tmp);
    });
  }, [props.isOpen]);

  const handdleFilter = e => {
    try{

      if(!values.employeeId){
        const e = new Error('Selecciona un vendedor.');
        e.name = 'employeeId';
        throw e;
      }

      props.getRecords({ 
        table: 'commissionV2', 
        start: values.start, 
        finish: values.finish, 
        employeeId: values.employeeId ? values.employeeId.value : null
      });
      setError({});
      props.closeFormDialog('CommissionFilter');  

    }catch(e){
      setError({[e.name]: e.message});
    }
    
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

	return (
	  <FormDialog  
      singular="CommissionFilter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField 
        icon={<TodayIcon />}
        name='start'
        label='Fecha Inicial' 
        onChange={handleDateChange}
        value={values.start}
        error={error.start}
      />
      <DateField 
        name='finish'
        label='Fecha Final' 
        onChange={handleDateChange}
        value={values.finish}
        error={error.finish}
      />
      <Autocomplete 
        icon={<AccountCircleIcon />}
        name='employeeId'
        label='Nombre del Vendedor' 
        onChange={handleAutocompleteChange}
        options={employees}
        value={values.employeeId}
        error={error.employeeId}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const FilterDialog = connect(mapStateToProps, mapDispatchToProps)(Dialog);