import React, { useState, Fragment } from 'react';

import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturnOutlined';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Snackbar from './../../../Sales.v2/helpers/Snackbar';
import IconButton from './../../../Sales.v2/helpers/IconButton';
import * as recordsServices from './../../../../_services/records.services';
import { ConfirmDialog } from './../../../../_presentationals/ConfirmDialog';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Status = ['No Definido','No Entregado','Pendiente','Recolectado']

const TableComponent = props => {

	const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
	const [ logisticProductId, setLogisticProductId ] = useState(0);

	const handleCollectionProduct = (e, params) => {
		setLogisticProductId(params.logisticProductId)
	}

	const handleCancelCollectionProduct = () => setLogisticProductId(0);

	const handleActionCollectionProduct = async logisticProductId => {
    try{
      await recordsServices.updateV2(`v2/logistics/${props.logisticId}/products/${logisticProductId}/collection`).then(data => {
	      if(data.errorCode) throw new Error(data.errorMessage);
	      setSnackbar(snackbar => ({ 
	        ...snackbar, 
	        isOpen: true, 
	        label: 'El producto fue recolectado exitosamente', 
	        severity: 'success' 
	      }));
	      props.reload(parseInt(data.statusLogistic) === 3 ? true : false);
	      setLogisticProductId(0);
      });
    }catch(e){
    	setLogisticProductId(0);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Producto</TableCell>
	            <TableCell align="center">Serie</TableCell>
	            <TableCell align="center">Estatus</TableCell>
	            <TableCell></TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.Products.map((el, i) => 
	      				<TableRow key={i}>
	      					
			            <TableCell>{el.Product.name}</TableCell>
			            <TableCell align="center">{el.Manufacture ? el.Manufacture.physicalSerie : '-'}</TableCell>
			            <TableCell align="center">{Status[parseInt(el.status)]}</TableCell>
			            <TableCell>
	      						<IconButton 
	      							size='small'
	      							label='Recolectar'
	      							icon={<AssignmentReturnIcon />}
	      							action={handleCollectionProduct}
	      							disabled={parseInt(el.status) === 2 ? false : true}
	      							params={{ 
	      								position: i, 
	      								logisticProductId: el.id
	      							}}
	      						/>
	      					</TableCell>
	      				</TableRow>
	        		)
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog 
        _id={logisticProductId} 
        handleCancel={handleCancelCollectionProduct} 
        handleAction={handleActionCollectionProduct} 
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
		</Fragment>
	)
}

export default TableComponent;