import React, { useMemo, useEffect } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';

import { history } from './../../_helpers';

const States = props => {

  useEffect(() => {
    props.redirect.id === "" && history.push('/Country');
  }, [props.redirect.id]);

  const states = useMemo(() => 
    props.states.count > 0 ? props.states.rows.map(el => ({
      ID: { 
        value: el.id,
        data: el
      }, 
      Estado: {
       value: el.name,
       filter: true
      }
    })) : []
  , [props.states]);

  return (
    <Module
      table="states"
      name="estados"
      singularName="estado"
      filter={false}
      title="Crear Estado"
      records={states}
    >
      <TextField
        icon="map"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStatesToProps = state => ({
  redirect: state.redirect,
  states: state.records.states || defaultTableValue
});

export default connect(mapStatesToProps)(States);