import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { AutocompleteField, SelectField } from '../../_presentationals/Form';
 
import { closeFormDialog } from '../../_actions';

const ProductDialog = props => {
  const handleClick = () => props.closeFormDialog('ProductDialog');
  return (
    <FormDialog 
      title="Modelo"
      singular="ProductDialog"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
      <AutocompleteField
        label="Producto" 
        _id="productId" 
        options={ props.products }
      />
      <SelectField
        fields={[{
          id: 'isCollection',
          label: "Recolectar",
          options: [
            {key: false, value:"NO"},
            {key: true, value:"SI"}
          ],
          required: false
        }]}
      />
    </FormDialog>
  );
}

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(null, mapDispatchToProps)(ProductDialog);