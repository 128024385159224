import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField } from '../../_presentationals/Form';
import { closeFormDialog } from '../../_actions';

const PlanDialog = props => {
  const handleClick = () => props.closeFormDialog('PlanDialog');  
  return(
    <FormDialog 
      buttonLabel="Actualizar" 
      singular="PlanDialog" 
      title="Configuración del Plan de Financiamiento"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
      <SelectField
        fields={[{
          id: 'planId',
          label: 'Plan de Financiamiento',
          options: availablePlans,
        }]}
      />
    </FormDialog>
  );
}

const availablePlans = [
  {key: 1, value: "Financiamiento 3 meses"},
  {key: 2, value: "Financiamiento 6 meses"},
  {key: 3, value: "Financiamiento 12 meses"}
];

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(null, mapDispatchToProps)(PlanDialog);
