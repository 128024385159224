import React, { Fragment, useMemo, useEffect, useState } from "react";
import { connect } from 'react-redux';

import EditIcon from '@material-ui/icons/EditOutlined';
import EmployeeIcon from '@material-ui/icons/AccountBox';

import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction, recordsActions  } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

import { format } from 'date-fns'
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import { moneyFormat } from '../../_helpers/moneyFormat';

import StatusDialog from './status.dialog';
import EmployeeDialog from './employee.dialog';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Compound = ({ getRecords, ...props }) => {
  const [ additionalText, setAdditionalText ] = useState("");
  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [];

    if(privileges.compounds && privileges.compounds.status){ 
      tmpAction.push({ icon: <EditIcon fontSize="small" />, label: "Cambiar Estatus", handle: handleStatus });
    }

    if(privileges.compounds && privileges.compounds.employee){ 
      tmpAction.push({ icon: <EmployeeIcon fontSize="small" />, label: "Asignar Responsable", handle: handleEmployee });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => ( getRecords({ table: 'products', type: 4 }) ), [getRecords]);
  const products = useMemo(() => {
    return props.products.count > 0 ? props.products.rows.map(({ id, name }) => ({ value: id, label: name })) : [];
  }, [props.products]);

  const compounds = useMemo(() => {
    let totalCost = 0;
    const tmp = props.compounds.count > 0 ? props.compounds.rows.map(el => {
      totalCost = parseFloat(totalCost) + parseFloat(el.productionCost);
      return {
        ID: { 
          show: false,
          value: el.id,
          data: el
        },
        "Fecha de Ensamble": {
          value: format(parseISO(el.createdAt), "dd/MMM/yyyy HH:mm", {locale: localeES})
        },
        Serie: {
          value: el.serial,
        },
        Producto:{
          value: el.Product.name,
          filter: true,
          _sort: 'text'
        },
        Responsable: {
          value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : 'NO ASIGNADO'
        },
        Estatus: {
          value: el.status.label
        },
        Costo: {
          value: moneyFormat(el.productionCost),
          alt: parseFloat(parseFloat(el.productionCost).toFixed(2)),
          filter: true,
          _sort: 'text',
          align: 'right'
        }
      };
    }) : [];

    setAdditionalText(`Costo de Producción: ${moneyFormat(totalCost)}`);
    return tmp
  },[props.compounds]);

  const handleStatus = e => {
    const { id, status, commentary } = JSON.parse(e.currentTarget.dataset.row);
    props.editValueAction({ id, _id: id, status: status.id, commentary });
    props.openFormDialog('StatusDialog'); 
  };

  const handleEmployee = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    props.editValueAction({ id, _id: id });
    props.openFormDialog('EmployeeDialog'); 
  };

  return (
    <Fragment>
      <Module
        table="compounds"
        name="productos compuestos"
        records={compounds}
        singularName="producto compuesto"
        title="Producto Compuesto"
        filter={false}
        edit={false}
        download={true}
        _delete={true}
        create={true}
        additionalText={additionalText}
        actions={actions}
      >
        <Autocomplete
          icon="poll"
          label="Producto" 
          _id="productId"
          options={ products }
        />
      </Module>
      <StatusDialog />
      <EmployeeDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  compounds: state.records.compounds || defaultTableValue,
  products: state.records.products || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Compound);