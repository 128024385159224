import React, { useEffect, useState, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField, TextField } from './../../_presentationals/Form';
import { editValueAction } from './../../_actions';
import { ListItemAdd } from './listItemAdd';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {AutocompleteField} from './../../_presentationals/Form/AutocompleteField';

const data = [{ key: 3, value: 'Recolectado' }];

const ChangeStatus = ({ Products, editValueAction, ...props }) => {
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);

  const logisticProducts = useMemo(() => (
    Products.map( el => {
      return { value: el.manufactureId, label: `${el.name} Serie: ${el.physicalSerie}`, isCollected: el.isCollected, logisticProductId: el.logisticProductId, logisticId: el.logisticId };
    })
  ), [Products]);
  
  const handleAddProduct = e => {
    const { manufactureId, manufactureIdLabel, logisticId, logisticProductId, isCollected } = props.selectedProduct;
    
    if(!manufactureId) return;

    const exists = local.find( el => (parseInt(el.manufactureId) === parseInt(manufactureId)) );
    
    if(exists) return;
    local && local.push({ manufactureId, manufactureIdLabel, isCollected, logisticId, logisticProductId });
    editValueAction({ Products: local, manufactureId: null });
    setQty(JSON.stringify(local));
    setLocal(local);
  };

  useEffect( () => {
    if(props.alert.type === 'success') {
      editValueAction({ Products: [], manufactureId: null });
      setLocal([]);
      setQty(0);
    }
  }, [props.alert, editValueAction]);

  const tableRows = useMemo(() => {
    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal([ ...tmp ]);
    };

    return local.map((el, index) => {
      return (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            {el.manufactureIdLabel}
          </TableCell>
          <TableCell margin="none" align="right">
            { !el.isCollected &&
              <Tooltip title="Eliminar" aria-label="Eliminar">
                <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
                  <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
                </IconButton>
              </Tooltip>
            }
          </TableCell>
        </TableRow>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local,qty]);

  return (
    <FormDialog 
      title="Actualizar Estatus"
      name="ChangeStatus"
      singular="ChangeStatus"
      table="logisticSale"
    >
      <SelectField
        fields={[{
          id: "status",
          label: "Estatus",
          options: data,
        }]}
      />
      <TextField
        fields={[{
          id: "commentary",
          label: "Comentario",
          required: false
        }]}
      />
      <ListItemAdd handleClick={handleAddProduct}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutocompleteField
              label="Productos" 
              _id="manufactureId" 
              dialog={false}
              options={logisticProducts}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </ListItemAdd>
      <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
    </FormDialog>
  );
}

const mapStateToProps = state => ({
  selectedProduct: {
    logisticProductId: state.field.value.logisticProductId, 
    logisticId: state.field.value.logisticId, 
    manufactureId: state.field.value.manufactureId, 
    manufactureIdLabel: state.field.value.manufactureIdLabel,
    isCollected: state.field.value.isCollected
  },
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus);