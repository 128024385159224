const session = JSON.parse(localStorage.getItem('session'));
const COUNTRY_ID = session && session.data.countryId;
const states = ({method, ...props}) => {
  switch (method) {
    case 'POST':
    case 'GET':
    if(props.redirectTable !== 'states' && typeof props.countryId !== 'undefined') return `locations/countries/${ props.countryId }/states`;
      return `locations/countries/${((props.redirectId && props.redirectTable === 'states') || typeof props.countryId !== 'undefined') ? (props.redirectId || props.countryId) : 0}/states`;
    case 'PUT':
    case 'DELETE':
    	return `locations/countries/${(props.redirectId && props.redirectTable === 'states') ? props.redirectId : COUNTRY_ID}/states/${props._id}`;
    default:
      return 'states';
  }
}

export default states;
