const warranties = (method, values) => {
	const tmp = {};
  switch (method) {
    case 'POST':
      if(values.isSale || values.isCourtesy) return { employeeId: values.employeeId, commentary: values.commentary };
      return tmp;
    case 'PUT':
      if(values.isStatus) return tmp;
      if(values.isSerie) return { physicalSerie: values.physicalSerie, status: values.status };
      tmp['employeeId'] = values['employeeId'];
      tmp['type'] = values['type'];
      if(values['Pieces'].length > 0) {
      	tmp['Pieces'] = values['Pieces'];
      }
      return tmp;
    default:
      return tmp;
  }
}

export default warranties;
