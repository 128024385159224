import React, { useState } from 'react';

import randomize from 'randomatic';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import useStyles from './../Sales.v2/style';

import Customer from './../Sales.v2/Customer';
import Employee from './../Sales.v2/Employee';
import Setting from './../Sales.v2/Setting';
import Cart from './../Sales.v2/Cart';
import Copyright from './../Sales.v2/Copyright';

import { ConfirmDialog } from './../../_presentationals/ConfirmDialog';
import * as recordsServices from './../../_services/records.services';

import TextField from './../Sales.v2/helpers/TextField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  customerId: null, 
  catchmentMethod: 1,
  employeeId: null,
  commentary: '',
  rentPlanPaymentMethod: 1,
  Cart: [],
  address: '',
  deliveryCommentary: '',
  deliveryDate: null,
  mobility: 1,
};

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}

const SalesV2 = props => {

	const classes = useStyles();

  const [ values, setValues ] = useState(defaultValues);
  const [ isSettingOpen, setIsSettingOpen ] = useState(false);
  const [ action, setAction ] = useState(1);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isCustomerOpen, setIsCustomerOpen ] = useState(false);
  const [ isEmployeeOpen, setIsEmployeeOpen ] = useState(false);
  const [ isConfirmSaleOpen, setIsConfirmSaleOpen ] = useState(0);
  const [ codeToClear, setCodeToClear ] = useState(null);

  const handleChooseCustomer = data => {
    setValues(values => ({ 
      ...values,
      address: data.customerId.address,
      customerId: data.customerId.value,
      catchmentMethod: data.catchmentMethod
    }));
    handleCloseCustomer();
  }

  const handleChooseEmployee = employeeId => {
    setValues(values => ({ ...values, employeeId: employeeId.value }));
    handleCloseEmployee();
  }

  const handleChooseSetting = data => {
    setValues(values => ({ ...values, ...data }));
    handleCloseSetting();
  }

  const handleCloseCustomer = () => setIsCustomerOpen(false);
  const handleCloseEmployee = () => setIsEmployeeOpen(false);
  const handleCloseSetting = () => setIsSettingOpen(false);
  
	const handleActionChange = (e, newAction) => {
    switch(parseInt(newAction)) {
      case 1: setIsCustomerOpen(true); break;
      case 2: setIsEmployeeOpen(true); break;
      case 3: setIsSettingOpen(true); break;
      default: break;
    }
    setAction(newAction);
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const getCart = data => {
    setValues(values => ({ ...values, Cart: data }));
  }

  const handleConfirmSale = () => {
    setIsConfirmSaleOpen(1);
  }

  const handleCreate = async () => {
    try{
      setIsLoading(true);

      if(values.Cart.length === 0){
        throw new Error("Selecciona al menos un producto para la cotización.");
      }

      if(!values.customerId){
        throw new Error('Selecciona un cliente para la cotización.');
      }

      if(!values.employeeId){
        throw new Error('Selecciona un vendedor.');
      }

      const Products = values.Cart.map(el => ({
        quantity: el.qty,
        rentPlan: el.rentPlan,
        rentPlanQuantity: el.planQuantity,
        productId: el.product.id,
        manufactureId: el.manufactureId,
      }));

      const body = {
        Products,
        customerId: values.customerId,
        catchment: values.catchmentMethod,
        employeeId: values.employeeId,
        commentary: values.commentary,
        rentPlanPaymentMethod: values.rentPlanPaymentMethod,
        deliveryDate: values.deliveryDate,
        deliveryCommentary: values.deliveryCommentary,
        address: values.address,
      }
      
      const response = await recordsServices.createV2('v2/courtesies', body).then(data => {
        setIsLoading(false);
        setIsConfirmSaleOpen(0);
        return data;
      });

      if(response.errorCode) {
        throw new Error(response.errorMessage);
      }

      clearForm();
      //Falta abrir aquó el pdf
      console.log(response)

    }catch(e){
      setIsLoading(false);
      setIsConfirmSaleOpen(0);
      setSnackbar(snackbar => ({ ...snackbar, time: null, isOpen: true, label: e.message, severity: 'warning' }));
    }
    
  }

  const clearForm = () => {
    const tmpCode = randomize('*', 10);
    setCodeToClear(tmpCode);
    setValues(defaultValues);
  }

  const handleCancel = () => {
    setIsConfirmSaleOpen(0);
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

	return (
		<div className={classes.container}>
			<div className={classes.spaceBetween}>
        <div>
          <Typography variant="h4">
            Cortesía
          </Typography>
        </div>
        <div>
        	<Button 
            variant="outlined" 
            color="secondary" 
            size="large" 
            className={classes.outlinedButton}
            onClick={handleConfirmSale}
          >
		        Emitir Cortesía
		      </Button>
        </div>
      </div>
      <Card className={classes.cardHeader}>
      	<div>
          <Tabs value={action} onChange={handleActionChange} aria-label="Opciones de facturación">
            <Tab label="Cliente" value={1} className={classes.tabs} />
            <Tab label="Vendedor" value={2} className={classes.tabs} />
            <Tab label="Logística" value={3} className={classes.tab} />
          </Tabs>
        </div>
      </Card>
      <Card className={classes.card}>
      	<Cart 
          isCourtesy
          getCart={getCart}
          deliveryDate={values.deliveryDate}
          mobility={values.mobility}
          codeToClear={codeToClear}
          rentPlanPaymentMethod={values.rentPlanPaymentMethod}
        />
      </Card>
      <Card className={classes.card}>
        <div className={classes.commentaryContent}>
          <TextField 
            noFormat 
            name='commentary' 
            label='Comentarios' 
            variant='outlined' 
            onChange={handleChange}
            value={values.commentary}
            multiline={true}
            maxRows={2}
            minRows={2}
          />
        </div>
      </Card>
      <div className={classes.copyrightContent}>
        <Copyright />
      </div>
      <Customer isOpen={isCustomerOpen} handleAction={handleChooseCustomer} handleClose={handleCloseCustomer} codeToClear={codeToClear} />
      <Employee isOpen={isEmployeeOpen} handleAction={handleChooseEmployee} handleClose={handleCloseEmployee} codeToClear={codeToClear} />
      <Setting isOpen={isSettingOpen} handleAction={handleChooseSetting} handleClose={handleCloseSetting} address={values.address} codeToClear={codeToClear} />
      <ConfirmDialog handleAction={handleCreate} handleCancel={handleCancel} _id={isConfirmSaleOpen} isLoading={isLoading} />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
		</div>
	);
};



export default SalesV2;