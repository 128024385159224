import React, { useMemo } from "react";
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import Link from '@material-ui/core/Link';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { config } from './../../_helpers/config';

const Rents = ({ openFormDialog, ...props }) => {

  const rentPlans = useMemo(() =>
    props.rents.count > 0 ? props.rents.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm"),
        _sort: 'date'
      },
      NV: {
       align: 'center',
        value: <Link href={`${config.apiUrl}/notes/sales/${el.saleId}`} target="_blank">{el.folio}</Link>,
        _sort: 'text'
      },
      Modelo: {
        value: el.product,
        _sort: 'text',
        filter: true
      },
      'Cant.': {
        value: el.quantity,
        align: 'center',
        _sort: 'text',
      },
      Cliente: {
       value: el.customer,
       filter: true,
       _sort: 'text'
      },
      'Teléfono': {
        value: el.phone,
        _sort: 'text',
      },
      Pagos: {
        value: el.payments,
        align: 'center',
        _sort: 'text',
      },
      Total:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
        accumulate: true
      },
      Enero:{
        value: moneyFormat(el.january),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Febrero:{
        value: moneyFormat(el.february),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Marzo:{
        value: moneyFormat(el.march),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Abril:{
        value: moneyFormat(el.april),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Mayo:{
        value: moneyFormat(el.may),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Junio:{
        value: moneyFormat(el.june),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Julio:{
        value: moneyFormat(el.july),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Agosto:{
        value: moneyFormat(el.august),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Septiembre:{
        value: moneyFormat(el.september),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Octubre:{
        value: moneyFormat(el.october),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Noviembre:{
        value: moneyFormat(el.november),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      },
      Diciembre:{
        value: moneyFormat(el.december),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
      }
    })) : []
  ,[props.rents]);

  return (
    <Module
      table="rentActives"
      name="Rentas Activas"
      records={rentPlans}
      singularName=""
      edit={false}
      create={false}
      _delete={false}
      title="Rentas"
      download={true}
    ></Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  rents: state.records.rentActives || defaultTableValue,
});

export default connect(mapStateToProps,null)(Rents);

