const failures = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
      return `v2/failures/${props._id}`;
    default:
      return 'v2/failures';
  }
} 

export default failures;
