import { isMobile } from "react-device-detect";
export const styles = theme => ({
  actionButton: {
    padding: theme.spacing(1)
  },
  bodyCell: {
    border: 'none',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: '50px',
    '&:hover': {
      background: "transparent"
    }
  },
  bottomNavigation: {
    width: "100%",
    position: 'fixed',
    bottom: 0,
    borderTop: "1px solid #d8d8d8",
    zIndex: theme.zIndex.drawer + 1
  },
  header: {
    paddingTop: isMobile ? theme.spacing(2) : theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(1.5)
  },
  headerButtons: {
    textAlign: "right"
  },
  headerTitle: {
    fontSize: "15px",
    color: "#141414"
  },
  filterCell: {
    width: isMobile ? '110px' : '200px',
    minWidth: isMobile ? '110px' : '200px',
    paddingBottom: theme.spacing(0.5)
  },
  menuOptions: {
    marginTop: theme.spacing(5)
  },
  mobileInput: {
    height: 40
  },
  mobileInputProps: {
    fontSize: '15px',
    paddingTop: theme.spacing(2)
  },
  spacing: {
    marginRight: theme.spacing(0.5)
  }, 
  table: {
    flexGrow: 1,
    paddingBottom: isMobile ? theme.spacing(8) : 0
  },
  tooltip: {
    marginTop: theme.spacing(0.5)
  }
});