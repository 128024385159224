import jsPDF from 'jspdf';
import { authHeader, config } from '../../_helpers';
import { handleFetchResponse } from './../../_services';
import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

const getDataFromServer = saleId => {
	const method = 'GET';
	const url = `${config.apiUrl}/sales/${saleId}`;
  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );
  return fetch(myRequest)
  	.then(handleFetchResponse({ url, method, headers: {} }))
  	.then(data => data);
}

export const invoices = async saleId => {
	const data = await getDataFromServer(saleId);
	console.log(data);
	const date = format(parseISO(data.createdAt), 'dd/mm/yyyy HH:mm:ss', {locale: localeES});
	const customerName = data.Customer ? `${data.Customer.firstName} ${data.Customer.lastName}` : 'Público en General';
	const currency = (data.currencyKey && data.currencyKey !== "MXN") ? data.currencyKey : "";
	const exchangeRate = currency === 'USD' ? ` | ${currency} (${data.exchangeRate})` : '';


	const doc = new jsPDF({
	unit: 'mm',
	  format: [200, 800],
	 
	});

	doc.setFontSize(6);
	doc.text("FACTURA",35, 5, 'center' );
	doc.line(5, 6, 66, 6)


	doc.text(`Fecha: ${date}`, 5, 10, 'left' );
	doc.text("LUGAR DE EXPEDICION", 5, 13, 'left' );
	doc.text("Carr. a Imala 3132", 5, 16, 'left' );
	doc.text("Fracc. Los Ángeles,Culiacan C.P. 80014,", 5, 19, 'left' );
	doc.text("Sinaloa, Culiacan", 5, 22, 'left' );
	doc.text("México", 5, 25, 'left' );
	doc.line(5, 26, 66, 26)

	doc.text("NO. DE SERIE CERTIFICADO", 5, 31, 'left' );
	doc.text("00000001", 5, 34, 'left' );
	doc.text("NO. DE SERIE CERTIFICADO SAT", 5, 37, 'left' );
	doc.text("00000001", 5, 40, 'left' );
	doc.text("FECHA Y HORA CERTIFICACION", 5, 43, 'left' );
	doc.text(`${date} ${exchangeRate}`, 5, 46, 'left' );
	doc.text("METODO DE PAGO:", 5, 49, 'left' );
	doc.text("Efectivo", 5, 52, 'left' );
	doc.text("NU. DE CTA. DE PAGO:", 5, 55, 'left' );
	doc.text("0001", 5, 58, 'left' );
	doc.text("FORMA DE PAGO:", 5, 61, 'left' );
	doc.text("01", 5, 64, 'left' );
	doc.line(5, 65, 66, 65)

	doc.text("EMISOR", 5, 70, 'left' );
	doc.text("Equipos Kool Tech, SA de CV", 5, 73, 'left' );
	doc.text("EKT-170406-4R3", 5, 76, 'left' );
	doc.text("REGIMEN FISCAL", 5, 79, 'left' );
	doc.text("General de Ley Personas Morales", 5, 82, 'left' );
	doc.text("Carr. a Imala 3132", 5, 85, 'left' );
	doc.text("Fracc. Los Ángeles", 5, 88, 'left' );
	doc.text("Sinaloa, Culiacan", 5, 91, 'left' );
	doc.text("MEXICO", 5, 94, 'left' );
	doc.line(5, 95, 66, 95)

	doc.text("CLIENTE", 5, 100, 'left' );
	doc.text(`${customerName}`, 5, 103, 'left' );
	doc.text("SO8G9307145N1", 5, 106, 'left' );
	doc.text("CARRETERA A SANALONA 1790", 5, 109, 'left' );
	doc.text("MIGUEL HIDALGO 80090", 5, 112, 'left' );
	doc.text("Sinaloa, Culiacan", 5, 115, 'left' );
	doc.text("MEXICO", 5, 118, 'left' );
	doc.line(5, 119, 66, 119)

	doc.setFontStyle("bold");
	doc.text("Producto", 5, 124, 'left' );
	doc.text("Cant", 25, 124, 'left' );
	doc.text("Precio U.", 45, 124, 'center' );
	doc.text("Importe", 66, 124, 'right' );
	doc.line(5, 126, 66, 126)
	
	doc.setFontStyle("normal");
	doc.text("Enfriador Kt 80", 5, 129, 'left' );
	doc.text("1", 28, 129, 'left' );
	doc.text("$1200", 45, 129, 'center' );
	doc.text("$1200", 66, 129, 'right' );

	doc.text("importe:", 50, 134, 'right');
	doc.text("$2300", 60, 134, );
	doc.text("Dscto:", 50, 137, 'right');
	doc.text("$0", 60, 137 );
	doc.text("Total:", 50, 140, 'right');
	doc.text("$2300", 60,140);
	doc.text("Cambio:", 50, 143, 'right');
	doc.text("$0", 60,143);
	doc.line(5, 150, 66, 150)

	doc.text("SELLO DIGITAL CFDI", 65.5, 155, 'right');
	doc.text("SELLO SAT", 65.5, 180, 'right');
	doc.text("CADENA ORIGINAL", 65.5, 215, 'right');
	doc.text("asdL", 65.5, 260, 'right');

	doc.autoPrint();
	//window.close();
	doc.output('dataurlnewwindow');
	//doc.save('Ticket De Venta');

}