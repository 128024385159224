import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutlineOutlined';
import EmployeeIcon from '@material-ui/icons/AccountBox';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

import StoreIcon from '@material-ui/icons/StoreOutlined';
import FingerprintIcon from '@material-ui/icons/FingerprintOutlined';
import ProductIcon from '@material-ui/icons/ToysOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import MoneyOffIcon from '@material-ui/icons/MoneyOffOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';

import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BeenhereIcon from '@material-ui/icons/BeenhereOutlined';

import { styles } from './styles';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

class MenuItems extends PureComponent {
  state = {
    open: false,
    openAuths: false,
    openReports: false,
    openLogistics: false,
    openInventory: false,
    isMaster: false,
    fullName: '',
    openInventoryV2: false,
  };

  componentDidMount = () => {
    const session = JSON.parse(localStorage.getItem('session'));
    const { firstName, lastName, master } = session.data || {};

    this.setState({
      fullName: `${firstName} ${lastName}`,
      isMaster: Boolean(master),
    });
  };

  handleClick = e => {
    e.preventDefault();
    this.setState(state => ({ open: !state.open }));
  };

  handleOpenAuths = e => {
    e.preventDefault();
    this.setState(state => ({ openAuths: !state.openAuths }));
  };

  handleOpenReports = e => {
    e.preventDefault();
    this.setState(state => ({ openReports: !state.openReports }));
  };

  handleOpenLogistics = e => {
    e.preventDefault();
    this.setState(state => ({ openLogistics: !state.openLogistics }));
  };

  handleOpenInventories = e => {
    e.preventDefault();
    this.setState(state => ({ openInventory: !state.openInventory }));
  };
  handleOpenInventoryV2s = e => {
    e.preventDefault();
    this.setState(state => ({ openInventoryV2: !state.openInventoryV2 }));
  };

  render() {
    const { classes, toggleDrawer, profile, handleLogout } = this.props;
    const { open, openAuths, openReports, fullName } = this.state;

    return (
      <Fragment>
        <Hidden xsDown implementation='css'>
          <div className={classes.toolbar} />
        </Hidden>
        <div>
          <List className={classes.listItems} component='nav'>
            <Hidden smUp implementation='css'>
              <Grid container spacing={0} className={classes.accountMenu}>
                <Grid item xs={6}>
                  <Avatar className={classes.avatarIcon} alt='Avatar Temporal' src={profile} />
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 60 }}>
                  <IconButton style={{ color: '#fff' }}>
                    <Badge overlap='rectangular' badgeContent={4} color='secondary'>
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton style={{ color: '#fff' }}>
                    <Badge overlap='rectangular' badgeContent={17} color='secondary'>
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>
              <ListItem button onClick={this.handleClick} className={classes.accountButton}>
                <ListItemText
                  classes={{
                    primary: classes.userOptions,
                  }}
                  primary={fullName}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <List component='nav' disablePadding>
                  <ListItem button component={Link} to='/MyAccount'>
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary='Mi cuenta' />
                  </ListItem>
                  <ListItem button component={Link} to='/Preferences'>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary='Preferencias' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText primary='Cerrar Sesión' />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
            </Hidden>
            {!open && (
              <div tabIndex={0} role='button' onClick={toggleDrawer('left', false)}>
                <List component='nav' disablePadding>
                  <Divider />
                  {privileges.salesModule && privileges.salesModule.create && (
                    <ListItem button component={Link} to='/v2/Sale'>
                      <ListItemIcon>
                        <ShoppingCartIcon />
                      </ListItemIcon>
                      <ListItemText primary='Facturación' />
                    </ListItem>
                  )}
                  {privileges.salesModule && privileges.salesModule.create && (
                    <ListItem button component={Link} to='/Invoices/Customized'>
                      <ListItemIcon>
                        <ShoppingCartIcon />
                      </ListItemIcon>
                      <ListItemText primary='Factura Personalizada' />
                    </ListItem>
                  )}

                  {privileges.quotationModule && privileges.quotationModule.create && (
                    <ListItem button component={Link} to='/v2/Quotation'>
                      <ListItemIcon>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText primary='Cotización' />
                    </ListItem>
                  )}

                  {((privileges.saleAuthsV2 && privileges.saleAuthsV2.read) ||
                    (privileges.courtesyAuths && privileges.courtesyAuths.read) ||
                    (privileges.lendAuths && privileges.lendAuths.read) ||
                    (privileges.serviceOrdersAuth && privileges.serviceOrdersAuth.read)) && (
                    <ListItem button component={Link} to='/v2/Sale/Auth'>
                      <ListItemIcon>
                        <BeenhereIcon />
                      </ListItemIcon>
                      <ListItemText primary='Autorizaciones de Facturas' />
                    </ListItem>
                  )}
                  <Collapse in={openAuths} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {privileges.saleAuthsV2 && privileges.saleAuthsV2.read && (
                        <ListItem button component={Link} to='/v2/Sale/Auth'>
                          <ListItemText primary='Facturas' />
                        </ListItem>
                      )}
                    </List>
                  </Collapse>

                  {privileges.accountsReceivables && privileges.accountsReceivables.read && (
                    <ListItem button component={Link} to='/v2/Receivables'>
                      <ListItemIcon>
                        <CreditCardIcon />
                      </ListItemIcon>
                      <ListItemText primary='Cuentas por Cobrar' />
                    </ListItem>
                  )}

                  {privileges.cashClosingsV2 && privileges.cashClosingsV2.read && (
                    <ListItem button component={Link} to='/v2/CashClosing'>
                      <ListItemIcon>
                        <MoneyOffIcon />
                      </ListItemIcon>
                      <ListItemText primary='Corte de Caja' />
                    </ListItem>
                  )}

                  <Divider />
                  {((privileges.logistic && privileges.logistic.read) ||
                    (privileges.logisticSale && privileges.logisticSale.read) ||
                    (privileges.logisticIndependent && privileges.logisticIndependent.read)) && (
                    <ListItem button component={Link} to='/v2/Logistic'>
                      <ListItemIcon>
                        <LocalShippingIcon />
                      </ListItemIcon>
                      <ListItemText primary='Entregas y Recolecciones' />
                    </ListItem>
                  )}
                  {privileges.manufactures && privileges.manufactures.read && (
                    <ListItem button component={Link} to='/Manufacture'>
                      <ListItemIcon>
                        <ExtensionIcon />
                      </ListItemIcon>
                      <ListItemText primary='Equipos En Bodega' />
                    </ListItem>
                  )}

                  <Divider />

                  <Divider />
                  {((privileges.paymentReport && privileges.paymentReport.read) ||
                    (privileges.details && privileges.details.read) ||
                    (privileges.rents && privileges.rents.read) ||
                    (privileges.quotationsReport && privileges.quotationsReport.read) ||
                    (privileges.creditNotesReport && privileges.creditNotesReport.read) ||
                    (privileges.invoices && privileges.invoices.read) ||
                    (privileges.courtesiesReport && privileges.courtesiesReport.read) ||
                    (privileges.salesReport && privileges.salesReport.read)) && (
                    <ListItem button onClick={this.handleOpenReports}>
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText primary='Reportes' />
                      {openReports ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  )}
                  <Collapse in={openReports} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {privileges.salesReport && privileges.salesReport.read && (
                        <ListItem button component={Link} to='/v2/Report/Invoice'>
                          <ListItemText primary='Facturas' />
                        </ListItem>
                      )}
                      {privileges.details && privileges.details.read && (
                        <ListItem button component={Link} to='/v2/Report/Invoice/Details'>
                          <ListItemText primary='Facturas detalle' />
                        </ListItem>
                      )}

                      {privileges.commission && privileges.commission.read && (
                        <ListItem button component={Link} to='/v2/Commission'>
                          <ListItemText primary='Comisiones' />
                        </ListItem>
                      )}
                      {privileges.rents && privileges.rents.read && (
                        <ListItem button component={Link} to='/v2/Report/Invoice/Review'>
                          <ListItemText primary='Rentas' />
                        </ListItem>
                      )}
                      {privileges.salesReport && privileges.salesReport.read && (
                        <ListItem button component={Link} to='/v2/Report/Payment'>
                          <ListItemText primary='Abonos' />
                        </ListItem>
                      )}
                      {privileges.salesReport && privileges.salesReport.read && (
                        <ListItem button component={Link} to='/SalesReport'>
                          <ListItemText primary='Notas de Venta v1' />
                        </ListItem>
                      )}
                      {privileges.logistic && privileges.logistic.read && (
                        <ListItem button component={Link} to='/v2/Report/Logistic/Completed'>
                          <ListItemText primary='Entregas y Recolecciones Realizadas' />
                        </ListItem>
                      )}
                      {privileges.manufactureSale && privileges.manufactureSale.read && (
                        <ListItem button component={Link} to='/v2/Report/Sale/Manufacture'>
                          <ListItemText primary='Equipos Vendidos' />
                        </ListItem>
                      )}
                    </List>
                  </Collapse>

                  {privileges.products && privileges.products.read && (
                    <ListItem button component={Link} to='/Product'>
                      <ListItemIcon>
                        <ProductIcon />
                      </ListItemIcon>
                      <ListItemText primary='Productos' />
                    </ListItem>
                  )}

                  {privileges.customers && privileges.customers.read && (
                    <ListItem button component={Link} to='/Customer'>
                      <ListItemIcon>
                        <PersonOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary='Clientes' />
                    </ListItem>
                  )}

                  {privileges.clasifications && privileges.clasifications.read && (
                    <ListItem button component={Link} to='/Clasification'>
                      <ListItemIcon>
                        <CategoryIcon />
                      </ListItemIcon>
                      <ListItemText primary='Clasificaciones' />
                    </ListItem>
                  )}

                  {privileges.employees && privileges.employees.read && (
                    <ListItem button component={Link} to='/Employee'>
                      <ListItemIcon>
                        <EmployeeIcon />
                      </ListItemIcon>
                      <ListItemText primary='Empleados' />
                    </ListItem>
                  )}
                  {privileges.users && privileges.users.read && (
                    <ListItem button component={Link} to='/User'>
                      <ListItemIcon>
                        <FingerprintIcon />
                      </ListItemIcon>
                      <ListItemText primary='Usuarios' />
                    </ListItem>
                  )}
                  {privileges.branches && privileges.branches.read && (
                    <ListItem button component={Link} to='/Branch'>
                      <ListItemIcon>
                        <StoreIcon />
                      </ListItemIcon>
                      <ListItemText primary='Sucursales' />
                    </ListItem>
                  )}
                </List>
              </div>
            )}
          </List>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.image,
});

export default withStyles(styles)(connect(mapStateToProps)(MenuItems));
