import jsPDF from 'jspdf';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import { authHeader, config } from '../../_helpers';
import { handleFetchResponse } from './../../_services';

const session = JSON.parse(localStorage.getItem('session'));
const BRANCH_NAME = session && session.data.branchName;

const getDataFromServer = (employeeId = null) => {
	const method = 'GET';
	const url = employeeId ? `${config.apiUrl}/logistics/all/${employeeId}/report` : `${config.apiUrl}/logistics/all/report`;

  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );
  console.log(myRequest);
  return fetch(myRequest)
  	.then(handleFetchResponse({ url, method, headers: {} }))
  	.then(data => data);
}


export const LogisticReport = async (employeeId = null) => {
	const data = await getDataFromServer(employeeId);
	
	var doc = new jsPDF('L');

	header(doc);

	let breakLine = 40;
	let tmpBreakLine;
	//DATOS TABLA
	data.rows.forEach( el => {
		const employee = el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : 'No asignado';
    const customer = el.Customer ? `${el.Customer.firstName} ${el.Customer.lastName}` : 'No asignado';
    const address = el.addressLine1+((el.addressLine2 !== "" && el.addressLine2 !== " ") ? ', '+el.addressLine2 : "")+((el.city !== "" && el.city !== "") ? ', '+el.city : "")+((el.zipCode !== "" && el.zipCode !== " ") ? ', '+el.zipCode : "")+ (el.State !== null ? ', '+el.State.name : "") + ((el.reference && el.reference !== '' && el.reference !== ' ') ? `, REFERENCIA: ${el.reference}` : '');
    const Products = el.Products;
    const deliveryDate = (el.deliveryDate && el.deliveryDate !== '' && el.deliveryDate !== ' ') ? format(parseISO(el.deliveryDate), 'dd/MM/yyyy', {locale: localeES}) : 'Fecha no espeficada |';
    const deliveryTime = el.deliveryTime ? el.deliveryTime : '';

    const collectionDate = (el.collectionDate && el.collectionDate !== '' && el.collectionDate !== ' ') ? format(parseISO(el.collectionDate), 'dd/MM/yyyy', {locale: localeES}) : 'Fecha no espeficada';
    const collectionTime = el.collectionTime ? el.collectionTime : '';

		tmpBreakLine = breakLine;
		
		const employeeJustify = doc.splitTextToSize(`${employee}`, 50);
		const customerJustify = doc.splitTextToSize(`${customer}`, 60);
		const addressJustify = doc.splitTextToSize(`${address}`, 60);
		const dateJustify = doc.splitTextToSize(`${deliveryDate} ${deliveryTime}`, 25);
		const dateCollectionJustify = doc.splitTextToSize(`${collectionDate} ${collectionTime}`, 25);
		
		let isNewPage = false;

		doc.text(5, breakLine, employeeJustify, 'left');
		doc.text(55, breakLine, customerJustify, 'left');
		doc.text(115, breakLine, addressJustify, 'left');
		doc.text(235, breakLine, dateJustify, 'left');
		doc.text(260, breakLine, dateCollectionJustify, 'left');

		Products.forEach(({ name, physicalSerie }) => {

			const productJustify= doc.splitTextToSize(`${name} ${physicalSerie}`, 55);

			doc.text(180, tmpBreakLine, productJustify, 'left');
			tmpBreakLine += 5;

			if(tmpBreakLine >= 200){
				breakLine = 40;
				tmpBreakLine = 40;
				doc.addPage('L');
				header(doc);
				isNewPage = true;
			}

		});

		doc.line(5, tmpBreakLine + 5, 290, tmpBreakLine + 5);
		
		breakLine = tmpBreakLine + 10;
		if(!isNewPage && breakLine >= 200){
			breakLine = 40;
			doc.addPage('L');
			header(doc);
		}
	});

	doc.output('dataurlnewwindow');
}

const header = (doc) => {
	//CABECERA
	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text("Reporte de Entregas", 5, 15, 'left');
	doc.text(`${BRANCH_NAME}`, 5, 20, 'left');
	doc.text("Fecha y Hora", 290, 15, 'right');
	doc.text(`${format(new Date(), 'dd/MM/yyyy HH:mm:ss', {locale: localeES})}`, 290, 20, 'right');

	//TABLA
	doc.line(5, 25, 290, 25);
	doc.text("Chofer", 5, 30, 'left');
	doc.text("Cliente", 55, 30, 'left');
	doc.text("Domicilio", 115, 30, 'left');
	doc.text("Productos", 180, 30, 'left');
	doc.text("Entrega", 235, 30, 'left');
	doc.text("Recolección", 260, 30, 'left');
	doc.line(5, 33, 290, 33);

	doc.setFontSize(8);
	doc.setFontStyle("normal");;
}
