export function rentPlanToLabel(value) {
	switch(value){
    case 'rentPerEvent':
      return ['Renta por evento', 0];
    case 'rentPer1Month':
    case 'rentPer1MonthIndustry':
      return ['Renta por 1 mes', 1];
    case 'rentPer2Months':
    case 'rentPer2MonthsIndustry':
      return ['Renta por 2 meses', 2];
    case 'rentPer3Months':
    case 'rentPer3MonthsIndustry':
      return ['Renta por 3 meses', 3];
    case 'rentPer4Months':
    case 'rentPer4MonthsIndustry':
      return ['Renta por 4 meses', 4];
    case 'rentPer5Months':
    case 'rentPer5MonthsIndustry':
      return ['Renta por 5 meses', 5];
    case 'rentPer6Months':
    case 'rentPer6MonthsIndustry':
      return ['Renta por 6 meses', 6];
    case 'rentPer7Months':
    case 'rentPer7MonthsIndustry':
      return ['Renta por 7 meses', 5];
    case 'rentPer8Months':
    case 'rentPer8MonthsIndustry':
      return ['Renta por 8 meses', 8];
    case 'rentPer9Months':
    case 'rentPer9MonthsIndustry':
      return ['Renta por 9 meses', 9];
    case 'rentPer10Months':
    case 'rentPer10MonthsIndustry':
      return ['Renta por 10 meses', 10];
    case 'rentPer11Months':
    case 'rentPer11MonthsIndustry':
      return ['Renta por 11 meses', 11];
    case 'rentPer12Months':
    case 'rentPer12MonthsIndustry':
      return ['Renta por 12 meses', 12];
    case 'customized':
      return ['Factura Personalizada', 0];
    default:
      return ['Venta', ''];
  }
};