const catchments = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
    case 'PUT':
      return `catchments/${props._id}`;
    default:
      return 'catchments';
  }
}

export default catchments;
