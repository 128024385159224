import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const invoices = ({method, ...props}) => {
  switch (method) {
    case 'PUT':
      return `invoices/${props._id}/reinvoices`;
    case 'POST':
      if(props.isComplement) return `invoices/accountReceivables/${props.accountsReceivableId}/complements`
      if (props.creditNotes) return `invoices/${props._id}/creditnotes`
    	if (props.withoutInvoice) return `invoices`
    	return `sales/${props.tmpSaleId}/invoices`;
    case 'DELETE':
    	return `invoices/${props._id}`;
    case 'GET':
      if (props.tmpSaleId) return `sales/${props.tmpSaleId}/invoices`;

      const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy-MM-dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy-MM-dd', {locale: localeES});

      if(props.creditNotes) return `invoices/creditnotes?start=${start}&finish=${finish}`;
      return `invoices/${start}/${finish}`;
     default:
      return `sales/${props.tmpSaleId}/invoices`;
  }
}

export default invoices;