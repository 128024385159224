const migrations = (method, values) => {
  switch (method) {
  	case 'PUT':
  		return { Products: values.receptionProducts }
    default:
      return { Products: values.Products, branchId: values.branchId }
    }
}

export default migrations;
