import React, { Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { openFormDialog } from '../../_actions';

import FilterDialog from './filter';

const Manufacture = props => {  
  const manufactures = useMemo(() =>
    props.manufactures.count > 0 ? props.manufactures.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      Producto:{
        value: el.name,
        filter: true,
        _sort: 'text'
      },
      Costo: {
        value: moneyFormat(el.productionCost),
        alt: parseFloat(parseFloat(el.productionCost).toFixed(2)),
        align: 'right',
        _sort: 'alt',
      },
      Cantidad:{
        value: el.qty,
        alt: el.qty,
        filter: false,
       _sort: 'number'
      },
      Total:{
        value: moneyFormat(el.totalProductionCost),
        alt: parseFloat(parseFloat(el.totalProductionCost).toFixed(2)),
        align: 'right',
        accumulate: true, accumulateLabel: 'Costo de Producción',
        _sort: 'alt',
      }
    })) : []
  ,[props.manufactures]);

  const handleFilter = () => {
    props.openFormDialog('FilterDialog');
  }

  return (
    <Fragment>
      <Module
        table="productManufactures"
        name="Modelos"
        records={manufactures}
        singularName="Modelo"
        edit={false}
        create={false}
        _delete={false}
        title="Por Productos"
        download={true}
        filter={handleFilter}
      >
      </Module>
      <FilterDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  manufactures: state.records.productManufactures || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data))
});


export default connect(mapStateToProps,mapDispatchToProps)(Manufacture);