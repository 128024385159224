import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import SelectField from '../../_containers/Sales.v2/helpers/SelectField';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';

import Form from './../Sales.v2/Cart/Form';
import Table from './../Sales.v2/Cart/Table';

import QuotationPdf from './quotation.pdf';

const _session = JSON.parse(localStorage.getItem('session'));
const ivaTax = _session ? parseFloat(_session.data.ivaTax) : 16;
//Iva puede ser configurado por el cliente

const isAuthorizedOptions = [
  { value: 1, label: 'Es espera' },
  { value: 2, label: 'Autorizada' },
  { value: 3, label: 'No Autorizada' },
];

const Component = props => {
  
  // const [ Products, setProducts ] = useState([]);
  const [ isAuthorizedValue, setIsAuthorizedValue ] = useState(1);
  const [ cartItems, setCartItems ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ getFromServer, setGetFromServer ] = useState(true);
  
  useEffect(() => {
    props.id && 
      recordsServices.getAllV2(`v2/serviceOrders/${props.id}`).then(data => {
        setGetFromServer(false);
        const tmp = data.ServiceOrderDetails.map(el => {
          const iva = parseFloat(el.price) / (1 + (parseFloat(ivaTax) / 100)) * (parseFloat(ivaTax) / 100);
          const price = parseFloat(el.price) - iva;
          const amount = parseFloat((parseFloat(el.quantity) * parseFloat(price)).toFixed(2));
          const discount = parseFloat(parseFloat(el.discount) / parseFloat(el.subtotal)) * 100;

          return {
            id: el.id,
            product: { name: el.Product.name },
            qty: parseFloat(el.quantity),
            tmpDiscount: parseFloat(el.discount),
            discount: parseFloat(discount),
            price: parseFloat(price),
            amount: parseFloat(amount),
          }
        });
        setCartItems(tmp);
      })
  }, [props.id, getFromServer]);

  useEffect(() => {
    if(!props.isOpen.Products) {
      setIsAuthorizedValue(1);
      setCartItems([]);
      setIsLoading(false);
      setGetFromServer(true);
    }
  },[props.isOpen])

  const handleAdd = el => {
    setIsLoading(true);
    const body = {
      productId: el.product.id,
      quantity: el.qty,
      price: el.price,
      discount: el.isWarranty ? 100 : el.discount,
      isWarranty: el.isWarranty
    }
    recordsServices.updateV2(`v2/serviceOrders/${props.id}/products`, body).then(data => {
      setIsLoading(false);
      setGetFromServer(true);
    }).catch(e => {
      console.log(e.message);
      setIsLoading(false);
    });

  }

  const handleChange = async e => {
    try{
      const value = e.target.value;
      setIsLoading(true);
      await recordsServices.updateV2(`v2/serviceOrders/${props.id}/status?status=${value}`).then(data => {
        setIsAuthorizedValue(value);
        setIsLoading(false);
      }).catch(e => e);
    }catch(e) {
      console.log(e);
    }
    
  }

  const handleQuotation = e => {
    QuotationPdf(props.id);
  }

  const removeItemFromCart = index => {
    setIsLoading(true);
    const tmp = cartItems[index];
    recordsServices._deleteV2(`v2/serviceOrders/${props.id}/products/${tmp.id}`).then(data => {
      setIsLoading(false);
      setGetFromServer(true);
    }).catch(e => {
      console.log(e.message);
      setIsLoading(false);
    });
    
  }

  return (
    <FormDialog
      progress={isLoading}
      title="Productos"
      name="products"
      singular="Products"
      disableClick
      disableEscape
      widthDialog="md"
      showActionButton={false}
      headerContent={
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs={6} align='right'>
            <Button 
              color='secondary' 
              variant='outlined'
              onClick={handleQuotation}
            >
              Imprimir Cotización
            </Button>
          </Grid>
          <Grid item xs={6}>
            <SelectField 
              noFormat
              label='Estatus de Cotización'
              name='isAuthorized'
              value={isAuthorizedValue}
              onChange={handleChange}
              options={isAuthorizedOptions}
              variant='outlined'
              size='small'
            />
          </Grid>
        </Grid>
      }
    >
      <Form handleAdd={handleAdd} showWarranty loading={isLoading} />
      <Divider />
      <Table 
        noConfig
        iva={ivaTax} 
        currency={'MXN'} 
        cartItems={cartItems} 
        removeItem={removeItemFromCart}
      />
    </FormDialog>
  );

}

const mapStateToProps = state => ({ 
  isOpen: state.formDialog,
});

export default connect(mapStateToProps,null)(Component);

