import React, { useMemo, Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction  } from '../../_actions';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import ListAltIcon from '@material-ui/icons/ListAlt';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Button from '@material-ui/core/Button';

import Log from './log';
import { LogisticReport } from './logisticReport';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { redirectActions, recordsActions } from '../../_actions';
import { history } from './../../_helpers';
import { config } from './../../_helpers/config';
import { AutocompleteField } from './../../_presentationals/Form';
import { moneyFormat } from '../../_helpers/moneyFormat';

const LogisticSale = ({openFormDialog, getRecords, editValueAction, ...props}) => {

  const logisticsSale = useMemo(() => 
    props.logisticReport.count > 0 ? props.logisticReport.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha:{
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy hh:mm', {locale: localeES} )
      },
      "Folio":{
        value: el.folio,
        filter: true
      },
      "Cliente":{
        value: el.Customer.firstName,
        filter: true,
       _sort: 'text'
      },
      Total:{
        value: el.isSale ? moneyFormat(el.total) : moneyFormat(0),
        alt: el.isSale ? parseFloat(parseFloat(el.total).toFixed(2)) : 0,
        align: 'right'
      },
      Estatus:{
        value: `${el.generalStatus}`,
        _sort: 'text'
      }
    })) : []
  , [props.logisticReport]);

  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    if(data.isSale) {
      setRedirect({id: data.id, data, table: 'logisticSale', returnTo: '/Logistic/Report', returnToLabel: 'Bitácora'});
    history.push('/Logistic/Report/Deliveries');
    }else {
      setRedirect({ id: data.id, isCollection: true, table: 'logisticCourtesy', returnTo: '/Logistic', returnToLabel: 'Entregas' });
      history.push('/Logistic/Courtesy/Deliveries');
    }
  };

  const handdleLog = e => {
    const { logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ logs });
    openFormDialog('Log');
  };

  const details = e => {
    const { id, isSale } = JSON.parse(e.currentTarget.dataset.row);
    isSale ? window.open(`${config.apiUrl}/notes/sales/${id}`, '_blank') : window.open(`${config.apiUrl}/notes/courtesies/${id}`, '_blank');
  };

  const handdleLogisticReport = e => {
    LogisticReport(props.employeeId ? props.employeeId : null);
  }

  useEffect(() => { getRecords({ table: 'employees', employeeRole: 6 }); }, [ getRecords ]);
  const availableEmployees = useMemo(() => {
    return props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.employees]);


  return (
    <Fragment>
      <Module
        table="logisticReport"
        name="reporte general"
        records={logisticsSale}
        title="Bitácora General"
        singularName="PDF"
        filter={false}
        edit={false}
        download={true}
        create={true}
        _delete={false}
        actions={[
          {
            icon: <ZoomInIcon fontSize="small" />,
            label: "Consultar",
            handle: details
          },
          {
            icon: <ListAltIcon fontSize="small" />,
            label: "Bitácora",
            handle: handdleLog
          },
          {
            icon: <LocalShippingIcon fontSize="small" />,
            label: "Entregas",
            handle: redirect
          }
        ]}
      >
        <AutocompleteField
          label="Chofer" 
          _id="employeeId"
          options={ availableEmployees }
        />
        <Button
            onClick={handdleLogisticReport}
            color="primary" 
            style={{float: 'left'}}
          >
          Filtrar
        </Button>
      </Module>
      <Fragment>
        <Log />
      </Fragment>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  logisticReport: state.records.logisticReport || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  employeeId: state.field.value.employeeId || ''
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticSale);