import branches from './branches';
import clasifications from './clasifications';
import countries from './countries';
import products from './products';
import pricesProducts from './pricesProducts';
import states from './states';
import users from './users';
import groups from './groups';
import customers from './customers';
import manufactures from './manufacture';
import subClasifications from './subClasifications';
import employees from './employees';
import billings from './billingInformatios';
import spareProducts from './spareProducts';
import sales from './sales';
import payments from './payments';
import invoices from './invoices';
import quotations from './quotations';
import inventories from './inventories';
import cashClosings from './cashClosings';
import commission from './commission';
import companies from './companies';
import logistic from './logistic';
import warranties from './warranties';
import suppliers from './suppliers';
import expenses from './expenses';
import vehicles from './vehicles';
import vehicleExpenses from './vehicleExpenses';
import logisticSale from './logisticSale';
import logisticProduct from './logisticProduct';
import logisticCollection from './logisticCollection';
import logisticFailure from './logisticFailure';
import logisticReport from './logisticReport';
import independentPayments from './independentPayments';
import failures from './failures';
import credentials from './credentials';
import courtesies from './courtesies';
import consignments from './consignment';
import consignmentManufactures from './consignmentManufactures';
import tools from './tools';
import logisticIndependent from './logisticIndependent';
import compounds from './compounds';
import expensePayments from './expensePayments';
import warrantyNotes from './warrantyNotes';
import vehicleFailures from './vehicleFailures';
import debtsPays from './debtsPays';
import catchments from './catchments';
import physicalInventories from './physicalInventories';
import migrations from './migrations';
import manufacturesSale from './manufacturesSale';
import manufacturesRent from './manufacturesRent';
import logisticReplace from './logisticReplace';
import samples from './samples';
import sampleManufactures from './sampleManufactures';
import customizedInvoices from './customizedInvoices';
import saleAuths from './saleAuths';
import pendingSales from './pendingSales';
import failuresv2 from './failuresv2';
import serviceOrders from './serviceOrders';
import internalServiceOrders from './internalServiceOrders';
import cashClosingsV2 from './cashClosingV2';
import borrowers from './borrowers';

export const chooseFields = {
	branches,
	clasifications,
	countries,
	products,
	pricesProducts,
	states,
	users,
	groups,
	customers,
	manufactures,
	subClasifications,
	employees,
	billings,
	spareProducts,
	sales,
	payments,
	invoices,
	inventories,
	cashClosings,
	commission,
	companies,
	warranties,
	logistic,
	quotations,
	suppliers,
	expenses,
	vehicles,
	vehicleExpenses,
	logisticSale,
	logisticProduct,
	logisticCollection,
	logisticFailure,
	logisticReport,
	independentPayments,
	failures,
	credentials,
	courtesies,
	consignments,
	consignmentManufactures,
	tools,
	logisticIndependent,
	compounds,
	expensePayments,
	warrantyNotes,
	vehicleFailures,
	debtsPays,
	catchments,
	physicalInventories,
	migrations,
	manufacturesSale,
	manufacturesRent,
	logisticReplace,
	samples,
	sampleManufactures,
	customizedInvoices,
	saleAuths,
	pendingSales,
	failuresv2,
	serviceOrders,
	cashClosingsV2,
	internalServiceOrders,
	borrowers
}
