import React, { useEffect, useState, useMemo, Fragment } from "react";
import { connect } from 'react-redux';

import { openFormDialog, editValueAction, redirectActions, recordsActions } from '../../_actions';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import Module from '../../_presentationals/Module';
import { history } from './../../_helpers';

import LogLogistic from '../LogisticSale/logLogistic';
import DeliverySale from '../LogisticSale/deliverySale';
import EmployeeDelivery from '../LogisticSale/employeeDelivery';
import ProductsDialog from '../LogisticSale/ProductsDialog';
import CollectionLogistic from '../LogisticCollection/collectionLogistic';
import { DeliveryTicket } from '../LogisticSale/deliveryTicket';
import { CollectionTicket } from '../LogisticCollection/collectionTicket';

import ListAltIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/EditOutlined';
import ProductIcon from '@material-ui/icons/ToysOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Receipt from '@material-ui/icons/Receipt';

const LogisticSale = ({openFormDialog, editValueAction, ...props}) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    props.redirect.id === "" && history.push('/');
  }, [props.redirect.id]);

  useEffect(() => {
    const tmpAction = [
      { icon: <Receipt fontSize="small" />, label: "Ticket de Entrega", handle: deliveryTicket },
      { icon: <AccountCircle fontSize="small" />, label: "Chofer", handle: handdleEmployee },
      { icon: <EditIcon fontSize="small" />, label: "Editar", handle: props.redirect.isDelivery ? handdleDelivery : handdleCollection },
      { icon: <ProductIcon fontSize="small" />, label: "Productos", handle: handleProducts },
      { icon: <ListAltIcon fontSize="small" />, label: "Bitácora", handle: handdleLog }
    ];
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const logisticCourtesy = useMemo(() =>
    props.logisticCourtesy.count > 0 ? props.logisticCourtesy.rows.map(el => ({
      ID: { 
        show: true,
        value: el.id,
        data: el
      },
      Fecha: {
        value: props.redirect.isDelivery ? el.deliveryDate ? `${format( parseISO(el.deliveryDate), 'dd/MM/yyyy', {locale: localeES} )} ${el.deliveryTime}`: 'No especificada' : el.collectionDate ? `${format( parseISO(el.collectionDate), 'dd/MM/yyyy', {locale: localeES} )} ${el.collectionTime}`: 'No especificada',
        filter: true,
        _sort: 'text'
      },
      "Producto":{
        value: `${ el.CourtesyDetail.Product.name} (${el.CourtesyDetail.quantity}) (${el.CourtesyDetail.isRent ? "Renta" : "Venta"})`,
        filter: true,
        _sort: 'text'
      },
      Modalidad: {
        value: el.CourtesyDetail.rentPlan.label,
        filter: true,
        _sort: 'text'
      },
      Dirección: {
        value: el.addressLine1+(el.addressLine2 !== "" ? ', '+el.addressLine2 : "")+(el.city !== "" ? ', '+el.city : "")+(el.zipCode !== "" ? ', '+el.zipCode : "")+ (el.State !== null ? ', '+el.State.name : "")
      },
      Chofer: {
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : 'No especificado',
        filter: true,
        _sort: 'text'
      },
      Estatus: {
        value: el.status.label
      }
    })) : []

    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [props.logisticCourtesy]);

  const handdleLog = e => {
    const { id, logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, logs });
    openFormDialog('LogLogistic');
  };

  const handdleDelivery = e => {
    const { id, addressLine1, addressLine2, city, zipCode, countryId, stateId, reference, deliveryDate, deliveryTime, deliveryCommentary } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, addressLine1, addressLine2, city, zipCode, countryId, stateId, reference, deliveryDate, deliveryTime, deliveryCommentary });
    openFormDialog('DeliverySale');
  };

  const handdleEmployee = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id });
    openFormDialog('EmployeeDelivery');
  };

  const deliveryTicket = e => {
    console.log(JSON.parse(e.currentTarget.dataset.row));
    const { id, status } = JSON.parse(e.currentTarget.dataset.row);
    parseInt(status.id) === 1 ? DeliveryTicket(id) : CollectionTicket(id);
  };

  const handleProducts = async (e) => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    console.log(data)
    editValueAction({ 
      id: data.id, dataProducts: data.Products, productId: data.CourtesyDetail.productId, 
      isRent: data.CourtesyDetail.isRent, qtyProduct: data.Products.length,
      methodPlan: data.CourtesyDetail.methodPlan, planQuantity: data.CourtesyDetail.planQuantity,
      deliveryDate: data.deliveryDate || format(new Date(),'yyyy/MM/dd',{locale: localeES}),
    });
    openFormDialog('ProductsDialog');
  };

  const handdleCollection = e => {
    const { id, receptionName, collectionDate, collectionTime, collectionCommentary } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, receptionName, collectionDate, collectionTime, collectionCommentary });
    openFormDialog('CollectionLogistic');
  };

  return (
    <Fragment>
      <Module
        edit={false}
        create={false}
        _delete={false}
        table="logisticCourtesy"
        name="Logísticas"
        singularName="Logística"
        filter={false}
        records={logisticCourtesy}
        actions={actions}
      >
      </Module>
        <EmployeeDelivery />
        <DeliverySale />
        <LogLogistic />
        <ProductsDialog />
        <CollectionLogistic />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  redirect: state.redirect,
  logisticCourtesy: state.records.logisticCourtesy || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticSale);