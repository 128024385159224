import { format } from "date-fns";
import locale from 'date-fns/locale/es';

const uri = ({method, ...props}) => {
  switch (method) {
    case 'GET':
      if(props.start || props.finish){
        const startFormat = props.start ? new Date(props.start) : new Date();
        const finishFormat = props.finish ? new Date(props.finish) : new Date();

        const start = format(startFormat, 'yyyy/MM/dd', {locale});
        const finish = format(finishFormat, 'yyyy/MM/dd', {locale});

        const query = { start, finish, employeeId: props.employeeId ? props.employeeId.value : null };
        Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null || query[key] === '' || query[key] === 0) && delete query[key]);      
        let querys = '';
        Object.keys(query).forEach(key => {
          querys += `${key}=${query[key]}&`;
        });
        return `v2/sales/details?${querys}`;
      }

      return `v2/sales/details`;
    default:
      return `v2/sales/details`;
  }
}

export default uri;