import React, { useState, Fragment } from "react";

import randomize from 'randomatic';
import { connect } from 'react-redux';
import format from 'date-fns/format';

import RoomIcon from '@material-ui/icons/RoomOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import TextField from './../Sales.v2/helpers/TextField';
import SelectField from './../Sales.v2/helpers/SelectField';
import DateTimeField from './../Sales.v2/helpers/DateTimeField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  isLogistic: false,
  address: '',
  deliveryDate: null,
  deliveryCommentary: '',
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const yesNoOptions = [
  { value: false, label: 'NO' },
  { value: true, label: 'SI' },
];

const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const handleAction = async () => {
    try{
      const respose = await recordsServices.updateV2(`v2/serviceOrders/${props.serviceOrderId}/delivery`, {
        ...values
      });
      if(respose.errorCode) throw new Error(respose.errorMessage);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: respose.message, 
        severity: 'success' 
      }));
      setValues(defaultValues);
      props.closeFormDialog('Delivery');
      const tmpCode = randomize('*', 10);
      props.reload(tmpCode);
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog 
        title="Entregar Equipo"
        name="delivery"
        singular="Delivery"
        handleCustomizedClick={handleAction}
      >
        <SelectField 
          name='isLogistic'
          label='¿Se entregará a domicilio?' 
          onChange={handleChange}
          options={yesNoOptions}
          value={values.isLogistic}
        />
        <TextField
          icon={<RoomIcon />}
          name='address'
          label='Domicilio de Entrega'
          onChange={handleChange}
          value={values.address}
          disabled={values.isLogistic ? false : true}
        />
        <DateTimeField
          icon={<EventIcon />}
          name='deliveryDate'
          label='Fecha y Hora de Entrega'
          onChange={handleDateChange}
          value={values.deliveryDate}
          disabled={values.isLogistic ? false : true}
        />
        <TextField
          name='deliveryCommentary'
          label='Comentario de la Logística'
          value={values.deliveryCommentary}
          onChange={handleChange}
          disabled={values.isLogistic ? false : true}
        />
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);