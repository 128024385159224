import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';

const manufactures = ({ method, ...props }) => {
  switch (method) {
    case 'POST':
      if (props.isWarranty) return `warranties/manufactures/${props.manufactureId}`;
      return `products/${props.productId}/manufactures`;
    case 'DELETE':
    case 'PUT':
      if (props.isMigration) return `manufactures/migrations/${props.branchId}`;
      if (props.isDelete) return `products/manufactures/${props._id}/cancel`;
      return `products/manufactures/${props._id}`;
    case 'GET':
      if (props.filter) {
        const query = {
          productId: props.productId,
          status: props.status ? props.status : 'allow',
        };

        Object.keys(query).forEach(
          key => (query[key] === 'undefined' || !query[key]) && delete query[key],
        );
        let querys = '';
        Object.keys(query).forEach(key => {
          querys += `${key}=${query[key]}&`;
        });
        if (props.productId) return `products/manufactures?${querys}`;
      }

      if (props.productId && props.status)
        return `products/${props.productId}/manufactures/available/status/${
          props.status
        }?deliveryDate=${format(new Date(props.deliveryDate), 'yyyy/MM/dd', {
          locale: localeES,
        })}&method=${props.methodPlan}&quantity=${props.quantity}`;
      if (!props.productId && props.status && props.deliveryDate)
        return `products/manufactures/available/status/${props.status}?deliveryDate=${format(
          new Date(props.deliveryDate),
          'yyyy/MM/dd',
          { locale: localeES },
        )}&method=${props.methodPlan}&quantity=${props.quantity}`;
      if (!props.productId && props.status)
        return `products/manufactures/available/status/${props.status}`;
      if (props.productId) return `products/${props.productId}/manufactures/available`;

      return `products/manufactures?status=allow`;
    default:
      return `products/manufactures`;
  }
};

export default manufactures;
