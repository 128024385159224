import React, { Component, Fragment } from "react";

export default class NotFound extends Component {
  render() {
    return (
      <Fragment>
      	<br />
        <center><h1>Esta página web no esta disponible.</h1></center>
      </Fragment>
    );
  }
}