import React, { Fragment, useEffect, useState, useMemo } from "react";

import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/es';
import randomize from 'randomatic';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../Sales.v2/helpers/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import TextField from './../Sales.v2/helpers/TextField';
import SelectField from './../Sales.v2/helpers/SelectField';
import Snackbar from './../Sales.v2/helpers/Snackbar';
import DateField from './../Sales.v2/helpers/DateField';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { config } from './../../_helpers/config';

import InvoiceData from './../Sales.v2/InvoiceData';

const defaultValues = {
  total: 0,
  paymentMethod: "",
  bank: "",
  date: new Date()
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}

const paymentFormOptions = [ 
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];

const bankOptions = [
  { value: 1, label: "Scotiabank 4377 Culiacán" },
  { value: 2, label: "Scotiabank 2815 Culiacán" },
  { value: 3, label: "Banregio Culiacán" },
  { value: 4, label: "Bancomer 0499" },
  { value: 5, label: "Scotiabank 4285 Mazatlán" },
  { value: 6, label: "Banregio 0013 Mazatlán " },
  { value: 7, label: "Scotiabank 4393 Mexicali" },
  { value: 8, label: "Banregio Mexicali" },
  { value: 9, label: "Bancomer 0097 Hermosillo" },
  { value: 10, label: "Banregio Hermosillo" },
];

const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  const [ payments, setPayments ] = useState([]);
  const [ isDisabled, setIsDisabled ] = useState(false);
  const [ isInvoiceDataOpen, setIsInvoiceDataOpen ] = useState([false, null]);

  useEffect(() => {
    props.saleId && recordsServices.getAllV2(`v2/sales/${props.saleId}/payments`).then(data => {
      setPayments(data);
    });
  }, [props.saleId]);

  const Payments = useMemo(() =>
    payments.map(el => {
      const uri = el.InvoiceV2
          ? <a  href={`${config.apiUrl}/files/invoices/complements/${el.InvoiceV2.uuid}/pdf`} target="_blank" rel="noopener noreferrer">{`${el.InvoiceV2.folio}-${el.InvoiceV2.serial}`}</a>
          : '';
      return (
        <TableRow key={el.id}>
          <TableCell>{format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale})}</TableCell>
          <TableCell>{`${el.folio}-${el.serial}`}</TableCell>
          <TableCell>{uri}</TableCell>
          <TableCell>{moneyFormat(el.total)}</TableCell>
        </TableRow>
      )
    })
  ,[payments]);

  const handleInvoiceDataClose = () => {
    setIsInvoiceDataOpen([false, null]);
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }
  
  const createPayment = async () => {
    try{
      setIsDisabled(true);
      const response = await recordsServices.createV2(`v2/sales/${props.saleId}/payments`, {
        ...values, 
        bank: (values.paymentMethod === '01' || values.bank === "") ? null : values.bank,
      }).then(data => {
        setIsDisabled(false);
        return data;
      }).catch(err => {
        setIsDisabled(false);
        return err;
      });
      if(response.errorCode) throw new Error(response.errorMessage);

      setValues(defaultValues);
      props.handleClose();
      response.uuid && setIsInvoiceDataOpen([true, response.uuid]);
      const tmpCode = randomize('*', 10);
      props.reload(tmpCode);
    }catch(e){
      setIsDisabled(false);
      setSnackbar(snackbar => ({ ...snackbar, time: null, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog 
        title="Realizar Abono a Venta"
        name="payment"
        singular="Payment"
        actionLabel='Almacenar'
        isOpen={props.isOpen}
        disabled={isDisabled}
        handleClose={props.handleClose}
        handleAction={createPayment}
      >
        <TextField
          name='total'
          onChange={handleChange}
          value={values.total}
          label='Abono'
        />
        <SelectField 
          name='paymentMethod'
          label='Forma de Pago' 
          onChange={handleChange}
          options={paymentFormOptions}
          value={values.paymentMethod}
        />
        { values.paymentMethod !== '01' &&
          <SelectField 
            name='bank'
            label='Cuenta' 
            onChange={handleChange}
            options={bankOptions}
            value={values.bank}
          />
        }
        <DateField
        name='date'
        label='Fecha de pago'
        onChange={handleDateChange}
        value={values.date}
      />
        <br />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Folio</TableCell>
              <TableCell>CP</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { Payments }
          </TableBody>
        </Table>
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
      <InvoiceData complement={true} isOpen={isInvoiceDataOpen[0]} uuid={isInvoiceDataOpen[1]} handleClose={handleInvoiceDataClose} />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || []
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);