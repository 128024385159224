import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';


const Commissions = () => 
  
  <FormDialog
    title="Agregar Comisiones"
    name="Commission"
    singular="Commissions"
    table="commission"
  >
    <TextField
      fields={[{
        id: "salePrice",
        label: "Comisión por venta",
        required:false
      },{
        id:"rentPerEvent",
        label: "Comisión por evento",
        required:false
      }]}
    />
    <br/>
    <strong style={{marginLeft: 45, fontSize: 20}}>Comercio</strong>
    <strong style={{marginLeft: 130, fontSize: 20}}>Industria</strong>
    <TextField
      fields={[{
        id:"rentPer1Month",
        label: "Comisión por 1 mes",
        required:false
      },{
        id:"rentPer1MonthIndustry",
        label: "Comisión por 1 mes",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer2Months",
        label: "Comisión por 2 mes",
        required:false
      },{
        id:"rentPer2MonthsIndustry",
        label: "Comisión por 2 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer3Months",
        label: "Comisión por 3 meses",
        required:false
      },{
        id:"rentPer3MonthsIndustry",
        label: "Comisión por 3 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer4Months",
        label: "Comisión por 4 meses",
        required:false
      },{
        id:"rentPer4MonthsIndustry",
        label: "Comisión por 4 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer5Months",
        label: "Comisión por 5 meses",
        required:false
      },{
        id:"rentPer5MonthsIndustry",
        label: "Comisión por 5 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer6Months",
        label: "Comisión por 6 meses",
        required:false
      },{
        id:"rentPer6MonthsIndustry",
        label: "Comisión por 6 meses", 
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer7Months",
        label: "Comisión por 7 meses",
        required:false
      },{
        id:"rentPer7MonthsIndustry",
        label: "Comisión por 7 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer8Months",
        label: "Comisión por 8 meses",
        required:false
      },{
        id:"rentPer8MonthsIndustry",
        label: "Comisión por 8 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer9Months",
        label: "Comisión por 9 meses",
        required:false
      },{
        id:"rentPer8MonthsIndustry",
        label: "Comisión por 9 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer10Months",
        label: "Comisión por 10 meses",
        required:false
      },{
        id:"rentPer10MonthsIndustry",
        label: "Comisión por 10 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer11Months",
        label: "Comisión por 11 meses",
        required:false
      },{
        id:"rentPer11MonthsIndustry",
        label: "Comisión por 11 meses",
        required:false
      }]}
    />
    <TextField
      fields={[{
        id:"rentPer12Months",
        label: "Comisión por 12 meses",
        required:false
      },{
        id:"rentPer12MonthsIndustry",
        label: "Comisión por 12 meses",
        required:false
      }]}
    />
  </FormDialog>

export default Commissions;
