import React from "react";
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormDialog } from '../../_presentationals/FormDialog';

const ProductsLogistic = props => {
  return (
    <FormDialog 
      title="Productos"
      name="productsLogistic"
      singular="ProductsLogistic"
      showActionButton={false}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Serie</TableCell>
            <TableCell>Producto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.Products && props.Products.map((el, i) => (
            <TableRow key={i}>
              <TableCell>{el.physicalSerie}</TableCell>
              <TableCell>{el.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FormDialog>
  );

}

const mapStateToProps = state => ({
  Products: state.field.value.Products || []
});


export default connect(mapStateToProps, null)(ProductsLogistic);