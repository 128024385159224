import SATKeys from './SATKeys';

const availableSATKeys = ['currency','productServ','unit'];

const billingInformations = ({method, ...props}) => {
  switch (method) {
    case 'POST':
      return `groups/customers/${props.customerId}/billingInformations`;
    case 'GET':
      if(availableSATKeys.includes(props.SATKey)) { return SATKeys(props);}
      return `groups/customers`;
    default:
      return `groups/customers/${props.customerId}/billinginformations/${props._id}`;
  }
}

export default billingInformations;