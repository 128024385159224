const logisticProduct = (method, values) => {
	const tmp = {};
  switch (method) {
  	case 'PUT':
  		if(values.status) tmp['status'] = values.status;
  		if(values.commentary) tmp['commentary'] = values.commentary;

  		tmp['manufactureId'] = values.manufactureId;
  		
  		return tmp;
    default:
      return tmp;
  }
}

export default logisticProduct;
