import { progressConstants } from '../_constants';

const initialState = {
	isLoading: false,
	isLoadingDialog: false
};

export const progress = (state = initialState, action) => {
  switch (action.type) {
    case progressConstants.OPEN_BAR:
      return { ...state, isLoading: true };
    case progressConstants.CLOSE_BAR:
      return { ...state, isLoading: false };
    case progressConstants.OPEN_DIALOG_BAR:
      return { ...state, isLoadingDialog: true };
    case progressConstants.CLOSE_DIALOG_BAR:
      return { ...state, isLoadingDialog: false };
    default:
      return state
  }
};