import React, { useState, useEffect, Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import randomize from 'randomatic';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

// import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AppsIcon from '@material-ui/icons/AppsOutlined';
// import UpdateIcon from '@material-ui/icons/UpdateOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';
import { ConfirmDialog } from './../../_presentationals/ConfirmDialog';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

import * as recordsServices from './../../_services/records.services';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';

import StatusDialog from './status.dialog';
import ProductDialog from './product.dialog';
import CloseDialog from './close.dialog';

const statusLabel = ['No Definido','Abierta','No Definido','Cotizado','Autorizado','No Autorizado','Cerrado','Facturado','Pendiente de Entrega','Entregado'];

const ServiceOrder = ({ getRecords, openFormDialog, editValueAction, ...props }) => {
  
  const [ manufactureOptions, setManufactureOptions ] = useState([]);
  const [ internalServiceOrderId, setInternalServiceOrderId ] = useState(null);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ reopenId, setReopenId ] = useState(0);

  useEffect(() => {
    recordsServices.getAllV2('v2/manufactures?status=5').then(data => {
      const tmp = data.rows.map(el => ({
        label: `${el.Product.name} Serie: ${el.physicalSerie}`, 
        value: el.id
      }))
      setManufactureOptions(tmp);
    });
  }, []);

  const internalServiceOrders = useMemo(() => 
    props.internalServiceOrders.count > 0 ? props.internalServiceOrders.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el,
        _alternative: parseInt(el.status) === 6 ? true : false,
      }, 
      "Creado el": {
        value: format(parseISO(el.createdAt), 'dd/MM/yyyy HH:ss', {locale: localeES}),
        _sort: 'date',
        noBreak: true
      },
      Folio: {
        value: el.folio + '-' + el.serial,
        noBreak: true
      },
      Equipo: {
        value: el.Product ? el.Product.name : '-',
        filter: true,
        _sort: 'text'
      },
      Serie: {
        value: el.physicalSerie,
        _sort: 'text'
      },
      Descripción: {
        value: el.description
      },
      "Registrado por": {
        value: el.User ? el.User.firstName + ' ' + el.User.lastName : '-',
        noBreak: true
      },
      Estatus: {
        value: statusLabel[parseInt(el.status)],
        noBreak: true
      },
      
    })) : []
  , [props.internalServiceOrders]);

  const handleClose = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setInternalServiceOrderId(id);
    openFormDialog('Close');
  }

  const handleOpen = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setReopenId(id);
  }

  const handleReopen = async id => {
    setReopenId(0);
    const respose = await recordsServices.updateV2(`v2/internalServiceOrders/${id}?status=1`);
    if(respose.errorCode) throw new Error(respose.errorMessage);
    const tmpCode = randomize('*', 10);
    setRefreshCode(tmpCode);
  }

  const handleCloseReopen = id => {
    setReopenId(0);
  }

  const handleProducts = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setInternalServiceOrderId(id);
    openFormDialog('Products');
  }

  const reload = (code) => {
    setRefreshCode(code);
  };

  return (
    <Fragment>
      <Module
        table="internalServiceOrders"
        name="Orden de Servicio Interna"
        records={internalServiceOrders}
        singularName="orden de servicio interna"
        refreshCode={refreshCode}
        filter={false}
        create={true}
        title="Orden de servicio interna"
        download={true}
        actions={[
          // { icon: <UpdateIcon fontSize="small" />, label: "Cambiar Estatus", handle: handleStatus },
          { 
            icon: <LockIcon fontSize="small" />,
            icon1: <LockOpenIcon fontSize="small" />,  
            label: "Cerrar Orden de Servicio", 
            label1: "Abrir Orden de Servicio", 
            handle: handleClose,
            handle1: handleOpen
          },
          { icon: <AppsIcon fontSize="small" />, label: "Productos", handle: handleProducts },
        ]}
      >
        <Autocomplete
          label="Equipo" 
          _id="manufactureId" 
          options={manufactureOptions}
        />
        <TextField
          fields={[{
            id: "description",
            label: "Descripción de la Falla"
          }]}
        />
      </Module>
      <CloseDialog reload={reload} id={internalServiceOrderId} />
      <StatusDialog reload={reload} id={internalServiceOrderId} />
      <ProductDialog id={internalServiceOrderId} />
      <ConfirmDialog _id={reopenId} handleAction={handleReopen} handleCancel={handleCloseReopen} />
    </Fragment>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  internalServiceOrders: state.records.internalServiceOrders || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(ServiceOrder);
