import React, { useMemo, Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction  } from '../../_actions';
import localeES from 'date-fns/locale/es';
import format from 'date-fns/format';

import ListAltIcon from '@material-ui/icons/ListAlt';
import UpdateIcon from '@material-ui/icons/Update';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Receipt from '@material-ui/icons/Receipt';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import PictureAsPdf from '@material-ui/icons/PictureAsPdfOutlined';

import Log from './log';
import ChangeStatus from './changeStatus';
import { DeliveryTicket } from './deliveryTicket';
import ContractTicket from './contract.ticket';
import ContractPdf from './contract.pdf';
import { FilterDialog }  from './filter';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { redirectActions, recordsActions } from '../../_actions';
import { history } from './../../_helpers';
import { config } from './../../_helpers/config';
import { moneyFormat } from '../../_helpers/moneyFormat';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Logistic = ({openFormDialog, getRecords, editValueAction, ...props}) => {
  const [employees, setEmployees] = useState([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [
      { icon: <FileCopyIcon fontSize="small" />, label: "Contrato - Ticket", handle: contractTicket},
      { icon: <PictureAsPdf fontSize="small" />, label: "Contrato - PDF", handle: contractPdf},
      { icon: <Receipt fontSize="small" />, label: "Ticket de Entrega", handle: deliveryTicket },
      { icon: <ZoomInIcon fontSize="small" />, label: "Consultar Venta", handle: details }
    ];

    if(privileges.logistic && privileges.logistic.collection){ 
      tmpAction.push({ icon: <UpdateIcon fontSize="small" />, label: "Cambiar Estatus", handle: handdleChangeStatus });
    }

    if(privileges.logistic && privileges.logistic.products){ 
      tmpAction.push({ icon: <LocalShippingIcon fontSize="small" />, label: "Entregas", handle: redirect });
    }

    if(privileges.logistic && privileges.logistic.log){ 
      tmpAction.push({ icon: <ListAltIcon fontSize="small" />, label: "Revisar Historial", handle: handdleLog });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logistics = useMemo(() => 
    props.logistic.count > 0 ? props.logistic.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha:{
        value: el.deliveryDate ? format(new Date(el.deliveryDate), 'dd/MM/yyyy HH:mm', {locale: localeES}) : '-',
        _sort: 'date'
      },
      Folio:{
        value: el.folio,
        filter: true,
        _sort: 'text'
      },
      Cliente:{
        value: el.Customer.firstName,
        filter: true,
       _sort: 'text'
      },
      Dirección:{
        value: el.address
      },
      Total:{
        value: el.isSale ? moneyFormat(el.total) : moneyFormat(0),
        alt: el.isSale ? parseFloat(parseFloat(el.total).toFixed(2)) : 0,
        align: 'right',
        _sort: 'alt'
      },
      Abonado:{
        value: moneyFormat(el.totalPayment),
        alt: parseFloat(parseFloat(el.totalPayment).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      Saldo:{
        value: moneyFormat(el.balance),
        alt: parseFloat(parseFloat(el.balance).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },      
      Estatus:{
        value: `${el.generalStatus}`,
        _sort: 'text'
      }
    })) : []
  , [props.logistic]);

  const redirect = e => {
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    if(data.isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    setRedirect({id: data.id, isDelivery: true, table: `${ data.isSale ? 'logisticSale' : 'logisticCourtesy' }`, returnTo: '/Logistic', returnToLabel: 'Entregas'});
    history.push(`${ data.isSale ? '/Logistic/Deliveries' : '/Logistic/Courtesy/Deliveries' }`);
  };

  const handdleLog = e => {
    const { logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ logs });
    openFormDialog('Log');
  };
  
  const handdleChangeStatus = e => {
    const { id, isSale, isSample } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, isSale, isSample });
    openFormDialog('ChangeStatus');
  };

  const deliveryTicket = e => {
    const { id, isSale, isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    DeliveryTicket(id,isSale);
  };

  const contractTicket = e => {
    const { id, isSale, isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    ContractTicket(id,isSale);
  };

  const contractPdf = e => {
    const { id, isSale, isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    ContractPdf(id,isSale);
  };

  const details = e => {
    const { id, isSale, isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    isSale ? window.open(`${config.apiUrl}/notes/sales/${id}`, '_blank') : window.open(`${config.apiUrl}/notes/courtesies/${id}`, '_blank');
  };

  const filter = () => {
    openFormDialog('LogisiticReportDialog');
  };

  useEffect(() => { getRecords({ table: 'employees', employeeRole: 6 }); }, [ getRecords ]);
  useEffect(() => {
    const tmp = props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
    setEmployees(tmp);
  }, [props.employees]);

  return (
    <Fragment>
      <Module
        table="logistic"
        name="entregas pendientes"
        records={logistics}
        title="Entregar Pendientes"
        singularName="PDF"
        filter={filter}
        edit={false}
        download={true}
        create={true}
        _delete={false}
        actions={actions}
      >
      </Module>
      <Fragment>
        <Log />
        <ChangeStatus />
        <FilterDialog employees={employees} />
      </Fragment>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  logistic: state.records.logistic || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  employeeId: state.field.value.employeeId || ''
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logistic);