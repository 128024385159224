const subClasifications = ({method, ...props}) => {
  switch (method) {
  	case 'POST':
  	case 'GET':
      if(props.redirectId && props.redirectTable === 'subClasifications'){
        return `clasifications/${props.redirectId}/subclasification`;
      }
      return `clasifications/${props.clasificationId}/subclasification`;
    case 'DELETE':
    case 'PUT':
      return `clasifications/${((props.redirectId && props.redirectTable === 'subClasifications')) ? props.redirectId : 0}/subclasification/${props._id}`;
    default:
      return 'clasifications';
  }
}

export default subClasifications;