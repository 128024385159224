import 'date-fns';
import React, { Fragment } from "react";

import { connect } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import localeES from 'date-fns/locale/es';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import { Validator } from './';
import { editValueAction, validateFieldAction } from '../../_actions';

/*
*
* PARA MÁS INFORMACION VISITAR
* https://material-ui-pickers.firebaseapp.com
*
* Ejemplo de uso:
* <DateField
*   id="birthdate"
*   label="Fecha de Nacimiento"
*   icon={<CakeIcon />}
*   openTo="year"
*   maxDate={ this.state.maxDate }
*   initialFocusedDate={ this.state.maxDate }
*   format="d MMMM yyyy"
*   autoOk={ true }
* />
*
*/

const DateFieldUI = ({id, label, required, fullWidth, fieldValue, fieldValidation, editValueAction, validateFieldAction, dialog, icon, ...props}) => {

  const handleChange = date => {
    // const formatedDate = format(new Date(date), 'yyyy-MM-dd', { locale: localeES });
    const formatedDate = date;
    editValueAction({ [id]: formatedDate });
    const validation = Validator({type: "dateTime", value: formatedDate + '', required});
    validateFieldAction({ [id]: { ...validation } })
  }
  return(
    <Fragment>
      <Grid container alignItems={ dialog ? "flex-end" : "stretch"}>
      { dialog && 
        <Grid item xs={1}><Icon className="material-icons-outlined">{icon}</Icon></Grid> 
      }
        <Grid item xs={dialog ? 9 : 12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ localeES }>
            <DatePicker
              id={ id }
              name={ id }
              label={ label }
              value={ fieldValue[id] || "" }
              onChange={ handleChange }
              error={fieldValidation[id] && fieldValidation[id].isError}
              helperText={fieldValidation[id] ? fieldValidation[id].message : ""}
              format="dd MMMM yyyy"
              fullWidth={fullWidth || true}
              autoOk={ true }
              cancelLabel="Cerrar"
              {...props}
            /> 
          </MuiPickersUtilsProvider>
        </Grid>

      </Grid>
    </Fragment>
  );

}

DateFieldUI.defaultProps = {
  dialog: true,
  required: true,
  variant: 'standard'
};

const mapStateToProps = state => ({ 
  fieldValue: state.field.value,
  fieldValidation: state.field.validation
});

const mapDispatchToProps = dispatch => ({ 
  editValueAction: data => dispatch(editValueAction(data)),
  validateFieldAction: data => dispatch(validateFieldAction(data))
});

export const DateField = connect(mapStateToProps, mapDispatchToProps)(DateFieldUI);