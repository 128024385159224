import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const soldManufactures = ({method, ...props}) => {
  switch (method) {
    case 'POST':
      if (props.isWarranty) return `warranties/manufactures/${props.manufactureId}`;
      break;
    case 'GET':
      const start = props.start ? format(new Date(props.start), 'yyyy-MM-dd', {locale: localeES}) : null;
      const finish = props.finish ? format(new Date(props.finish), 'yyyy-MM-dd', {locale: localeES}) : null;
      
      const query = {
        start,
        finish,
        productId: props.productId,
        status: 'sold'
      };
      
      Object.keys(query).forEach(key => (!query[key]) && delete query[key]);      
      let querys = '';
      Object.keys(query).forEach(key => {
        querys += `${key}=${query[key]}&`;
      });

      return `products/manufactures/sold?${querys}`;
    default:
      return `products/manufactures/sold`;
  }
}

export default soldManufactures