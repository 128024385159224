import React, { useMemo, useEffect } from "react";
import { connect } from 'react-redux';


import { FormDialog } from '../../_presentationals/FormDialog';

import { AutocompleteField } from './../../_presentationals/Form';

import { recordsActions } from './../../_actions';

const EmployeeDelivery = ({ getRecords, ...props}) => {
  
  useEffect(() => { getRecords({ table: 'employees', employeeRole: 6 }); }, [ getRecords ]);
  const availableEmployees = useMemo(() => {
    return props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.employees]);

  return (
    <FormDialog 
      title="Chofer"
      name="EmployeeDelivery"
      singular="EmployeeDelivery"
      table="logisticIndependent"
    >
      <AutocompleteField
        label="Chofer" 
        _id="employeeId"
        options={ availableEmployees }
      />
    </FormDialog>
  );

}


const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  employees: state.records.employees || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDelivery);