import React, { Fragment, useState, useEffect } from "react";
import randomize from 'randomatic';
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import Autocomplete from './../Sales.v2/helpers/Autocomplete';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  employeeId: null,
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}


const Component = props => {

  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ employees, setEmployees ] = useState([]);
  const [ values, setValues ] = useState(defaultValues);

  useEffect(() => {
    recordsServices.getAllV2('employees/technicals').then(data => {
      const tmp = data.rows.map(el => ({ label: `${el.firstName} ${el.lastName}`.trim(), value: el.id }))
      setEmployees(tmp);
    });
  }, []);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const handleAction = async () => {
    try{
      const respose = await recordsServices.updateV2(`v2/serviceOrders/${props.serviceOrderId}/close`,{
        employeeId: values.employeeId ? values.employeeId.value : null,
      });
      if(respose.errorCode) throw new Error(respose.errorMessage);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: respose.message, 
        severity: 'success' 
      }));
      setValues(defaultValues);
      props.closeFormDialog('Close');
      const tmpCode = randomize('*', 10);
      props.reload(tmpCode);
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog
        title="Cerrar de Orden de Servicio"
        name="close"
        singular="Close"
        handleCustomizedClick={handleAction}
      >
        <Autocomplete 
          name='employeeId'
          label='Selecciona el técnico que trabajó el equipo' 
          onChange={handleAutocompleteChange}
          options={employees}
          value={values.employeeId}
        />
      </FormDialog>
      <Snackbar 
          isOpen={snackbar.isOpen} 
          label={snackbar.label} 
          severity={snackbar.severity} 
          time={3000} 
          handleClose={handleCloseSnackbar}
        />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);