import React, { useEffect, useState } from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { FormDialog } from '../../_presentationals/FormDialog';

import { moneyFormat } from './../../_helpers/moneyFormat';

import * as recordsServices from './../../_services/records.services';

const Products = props => {

  const [ products, setProducts ] = useState([]);

  useEffect(() => {
    if(props.saleAuthId){
      recordsServices.getAllV2(`v2/saleAuths/${props.saleAuthId}`).then(data => {
        const tmp = data.SaleAuthDetailV2s.map(el => {
          const discountAltPrice = 100 - ((parseFloat(el.price) * 100) / parseFloat(el.listPrice));
          const percentDiscount = parseFloat(discountAltPrice) > 0 ? parseFloat(discountAltPrice) : el.discount;
          const cost = el.prices.productionCost ? parseFloat(el.prices.productionCost) : 0;
          const utility = parseFloat(el.total) > 0 ? ((parseFloat(el.total) - parseFloat(cost)) / parseFloat(el.total)) * 100 : 0;
          return {
            id: el.id,
            name: el.Product ? el.Product.name : '-',
            qty: parseFloat(el.quantity),
            listPrice: parseFloat(el.listPrice),
            price: parseFloat(el.price),
            utility,
            percentDiscount,
            amount: parseFloat(el.quantity) * parseFloat(el.price)
          }
        });
        setProducts(tmp);
      });
    }
  }, [props.saleAuthId]);
  
  return (
    <FormDialog 
      title="Productos Añadidos"
      name="product"
      singular="Product"
      showActionButton={false}
      widthDialog="md"
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell align="center">Cant.</TableCell>
            <TableCell align="right">P. Lista</TableCell>
            <TableCell align="right">P. Solicitado</TableCell>
            <TableCell align="right">Importe</TableCell>
            <TableCell align="center">Descuento(%)</TableCell>
            <TableCell align="center">Utilidad(%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            products.map(el => 
              <TableRow key={el.id}>
                <TableCell>{el.name}</TableCell>
                <TableCell align="center">{el.qty}</TableCell>
                <TableCell align="right">${moneyFormat(el.listPrice)}</TableCell>
                <TableCell align="right">${moneyFormat(el.price)}</TableCell>
                <TableCell align="right">${moneyFormat(el.amount)}</TableCell>
                <TableCell align="center">{moneyFormat(el.percentDiscount)}%</TableCell>
                <TableCell align="center">{moneyFormat(el.utility)}%</TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </FormDialog>
  );

}

export default Products;