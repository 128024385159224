import { progressConstants } from '../_constants';

const open = () => (dispatch, getState) => dispatch({ type: progressConstants.OPEN_BAR });
const close = () => dispatch => dispatch({ type: progressConstants.CLOSE_BAR });

const openDialog = () => (dispatch, getState) => dispatch({ type: progressConstants.OPEN_DIALOG_BAR });
const closeDialog = () => dispatch => dispatch({ type: progressConstants.CLOSE_DIALOG_BAR });

export const progressActions = {
	open, close, openDialog, closeDialog
};
