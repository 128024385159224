import React from 'react';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import useStyles from './style';

const LinearForm = props => {

	const classes = useStyles();

  const handleClick = e => {
    props.handleAdd(e, props.params);
  }

	return (
		<Grid container spacing={1} alignItems='flex-start'>
      <Grid item xs={11}>
      	{ props.children }
      </Grid>
      <Grid item xs={1} align={ props.align || 'right' }>
        <Fab 
          color={props.color || 'secondary'}
          size='small' 
          disabled={props.loading ? true : false}
          onClick={ handleClick }
          className={classes.addButton}
        >
          <AddIcon fontSize={ props.size || "medium" } />
        </Fab>
      </Grid>
    </Grid>
	);
}

export default LinearForm;