import React, { Fragment, useEffect, useMemo, useState } from "react";

import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import PersonIcon from '@material-ui/icons/Person';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { openFormDialog, recordsActions, editValueAction, closeFormDialog, progressActions, alertActions } from './../../_actions';

import { TextField, AutocompleteField } from './../../_presentationals/Form';
import { styles } from './style';
import { Header } from './_Header';
import { ListItemEdit } from './_ListItemEdit';
import { ListItemAdd } from './_ListItemAdd';
import { CustomerDialog } from './_CustomerDialog';
import  SellerDialog  from './_SellerDialog';
import CurrencyDialog  from './_CurrencyDialog';
import PlanDialog  from './_PlanDialog';
import { LastStepDialog }  from './_LastStepDialog';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog/index';
import { GeneralDialog } from '../../_presentationals/GeneralDialog/index';
import { Snackbar } from '../../_presentationals/Snackbar';
// import { Ticket } from './ticket';
import { invoices } from './invoice';
import { PaymentTicket } from './../Payment/ticket';
import {config} from '../../_helpers/config';

const Sales = ({ classes, openFormDialog, getRecords, editValueAction, ...props }) => {
  const [ isInvoiceDialogOpen, setIsInvoiceDialogOpen ] = useState(false);
  const [ generatedInvoices, setGeneratedInvoices ] = useState([]);
  const [ confirmId, setConfirmId ] = useState(0);
  const [ accountReceivableId, setAccountReceivableId ] = useState(0);
  const [ billingInformationId, setAccountbillingInformationId ] = useState(0);
  
  const [budget, setBudget] = useState({subtotal: 0, discount: 0, tax: 0, total: 0});
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [payment, setPayment] = useState(0);
  const [saleId, setSaleId] = useState(0);

  useEffect(() => {
    if(props.alert === "success" && props.invoices.length > 0){
      setGeneratedInvoices(props.invoices);
      setIsInvoiceDialogOpen(true);
      editValueAction({ invoices: [] });
    }
  }, [props.alert, props.invoices, editValueAction]);

  useEffect(() => {
    const handleError = props.errorMessage;
    let key = "";
    Object.keys(props.validation).forEach(el => { 
      if(props.validation[el].isError === true){
        key = el; 
      }
    });
    const message = errorMessage(key);
    if(key !== "" && message !== ""){
      handleError(message);
    }
  }, [props.validation, props.errorMessage]);

  useEffect(() => {
    getRecords({ table: 'customers' }); //1
    getRecords({ table: 'employees', sellers: true }); //2
    getRecords({ table: 'billings', SATKey: 'currency', alias: 'currencies' }); //3
    getRecords({ table: 'companies'}); //4
    getRecords({ table: 'products'}); //5
    editValueAction({ quantity: 1, currencyId: 'MXN', paymentType: 2, exchangeRate: 1, planId: 2 });
  }, [getRecords, editValueAction]);
    
  useEffect(() => {
    if(props.alert === "success" && parseInt(props.sellData.paymentId) > 0 ){
      PaymentTicket(props.sellData.paymentId);
    }
  }, [props.alert, props.sellData.paymentId]);

  useEffect(() => {
    if(props.alert === "success" && parseInt(props.sellData.saleId) > 0 ){
      invoices(props.sellData.saleId);
    }
  }, [props.alert, props.sellData.saleId]);
  
  useEffect(() => {
    if(props.alert === "success" && parseInt(props.sellData.saleId) > 0){
      setAccountReceivableId(props.sellData.accountReceivableId);
      setAccountbillingInformationId(props.billingInformationId);
      invoices(props.sellData.saleId);
      setSaleId(props.sellData.saleId);
      setLocal([]);
      setQty(0);
      setPayment(budget.total);
      setBudget({subtotal: 0, discount: 0, tax: 0, total: 0});
      editValueAction({ saleId: 0 });
      setTimeout(function(){
        editValueAction({ quantity: 1, currencyId: 'MXN', exchangeRate: 1 });
        openFormDialog('LastStepDialog');
      }, 1000);
    }
  }, [props.alert, budget.total, props.sellData.saleId, props.billingInformationId, openFormDialog, editValueAction, props.sellData.accountReceivableId]);

  useEffect(() => {
    props.currencyId !== "MXN" && editValueAction({ exchangeRate: props.exchangeRate })
  }, [props.currencyId, props.exchangeRate, editValueAction]);

  const currentPlan = useMemo(() => {
    return (planToLabel(props.planId));
  }, [props.planId]);

  const currentCurrency = useMemo(() => {
    console.log(props.fields.exchangeRate);
    setBudget({subtotal: 0, discount: 0, tax: 0, total: 0});
    setLocal([]);
    return (currencyToLabel(props.currencyId));
  }, [props.currencyId, props.fields.exchangeRate]);

  const customers = useMemo(() => (
    props.customers.count > 0 ? props.customers.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`, customerDiscount: el.discountRange, billingInformationId: (el.BillingInformation && el.BillingInformation.id) ? el.BillingInformation.id : 0 })) : []
  ), [props.customers]);

  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  const currencies = useMemo(() => (
    props.currencies.count > 0 ? props.currencies.rows.map(el => ({ key: el.id, value: `${el.id} ${el.descripcion}`})) : []
  ), [props.currencies]);
  
  const products = useMemo(() => {
    return (props.products.count > 0 ? props.products.rows.map(el => ({ 
      value: el.id, 
      label: el.name,
      price: el.prices && parseFloat(el.prices.salePrice),
      rentPrice: el.prices && el.prices.rentPrice,
      availableDiscount: el.Clasification && el.Clasification.discount
    })) : [])
  }, [props.products]);

  const tableRows = useMemo(() => {
    console.log(qty);

    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setBudget({subtotal: 0, discount: 0, tax: 0, total: 0});
      setQty(JSON.stringify(local));
      setLocal([ ...tmp ]);
    };

    let tmpSubtotal = 0, tmpDiscount = 0, tmpTax = 0;
    
    return local.map((el, index) => {
      const tmpAmount = amount({quantity: el.quantity, price: el.price});
      const tmpDiscountPercentage = floatFormat(el.discount) / 100;
      const tmpProductTax = ((tmpAmount - (tmpAmount * tmpDiscountPercentage)) * 0.16);
      tmpTax = parseFloat(tmpTax) + parseFloat(tmpProductTax);
      tmpSubtotal = parseFloat(tmpSubtotal) + parseFloat(tmpAmount);
      tmpDiscount = parseFloat(tmpDiscount) + (parseFloat(tmpAmount) * parseFloat(tmpDiscountPercentage));

      setBudget({ 
        subtotal: floatFormat(tmpSubtotal), 
        discount: floatFormat(tmpDiscount), 
        tax: floatFormat(tmpTax),
        total: parseFloat(tmpSubtotal - tmpDiscount + tmpTax).toFixed(2)
      });

      return (<TableRow key={index}>
        <TableCell margin="none" className={ classes.tableCell }>
          <Tooltip title="Eliminar" aria-label="Eliminar" classes={{ tooltip: classes.tooltip }}>
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          {el.productIdLabel} {el.isRented ? el.methodLabel : "(Venta)"}
        </TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell align="right">{el.discount || 0}%</TableCell>
        <TableCell align="right">{parseFloat(el.price / 1.16).toFixed(2) + props.currencyId}</TableCell>
        <TableCell align="right">{amount({quantity: el.quantity, price: el.price, discount: el.discount}) + props.currencyId}</TableCell>
      </TableRow>);
    });

  }, [local, qty, classes, props.currencyId]);
    
  const handleCancelInvoices = () => {
    setIsInvoiceDialogOpen(false);
  };

  const OpenCustomerDialog = () => openFormDialog('CustomerDialog');
  const OpenSellerDialog = () => openFormDialog('SellerDialog');
  const OpenCurrencyDialog = () => openFormDialog('CurrencyDialog');
  const OpenPlanDialog = () => openFormDialog('PlanDialog');
  
  const handleConfirmSale = e => {
    const { id } = e.currentTarget.dataset;
    setConfirmId(id);
  }

  const handleCancelSale = e => {
    setConfirmId(0);
  };
  
  const handleAddProduct = () => {
    const { productId, productIdLabel, quantity = 1, discount = 0, price, rentPrice } = props.selectedProduct;
    if(!productId){ return; }
    if(parseFloat(price) <= 0 || isNaN(parseFloat(price))){ return; }
    local && local.push({ productId, productIdLabel, quantity, discount, price: (price / props.fields.exchangeRate ), salePrice: (price / props.fields.exchangeRate), rentPrice, isRented: false });
    setLocal(local);
    setQty(JSON.stringify(local));
    editValueAction({ productId: null, productIdLabel: '', quantity: 1, price: '0.00', rentPrice: [] });
  };

  const handleSell = () => {
    setConfirmId(0);
    editValueAction({ Products: local });
    props.createRecords('sales');
  };

  const handleLastStep = () => {
    console.log("Identificador de CxC: " + accountReceivableId);
    editValueAction({ tmpSaleId:saleId, accountReceivableId });
    (props.fields.paymentType && props.fields.paymentType < 3) && props.createRecords('payments');
    if(props.fields.isInvoice && props.fields.isInvoice === 1){ 
      setTimeout(function(){
        props.pendingMessage("Espere un momento, estamos emitiendo las facturas electrónicas.");
      }, 1000);
      props.createRecords('invoices');
    }
    setAccountReceivableId(0);
    setAccountbillingInformationId(0);

    setTimeout(function(){
      editValueAction({ Products: [], quantity: 1, currencyId: 'MXN', paymentType: 2, exchangeRate: 1 });
    },1000);
    props.closeFormDialog('LastStepDialog');

  };

  return (
    <Fragment>
      <Header handleClick={handleConfirmSale} />
      <List dense={true} className={ classes.noPadding }>
        <ListItemEdit
          icon={<PersonIcon />}
          title="Cliente"
          text={ props.customerIdLabel }
          handleClick={OpenCustomerDialog}
        />
        <CustomerDialog customers={customers} />
        <Divider />
        <ListItemEdit
          icon={<StoreMallDirectoryIcon />}
          title="Vendedor"
          text={ props.employeeIdLabel }
          handleClick={OpenSellerDialog}
        />
        <SellerDialog employees={employees} />
        <Divider />
        <ListItemEdit
          icon={<AttachMoneyIcon />}
          title="Moneda"
          text={currentCurrency}
          handleClick={OpenCurrencyDialog}
        />
        <CurrencyDialog currencies={currencies} />
        <Divider />
        <ListItemEdit
          icon={<CreditCardIcon />}
          title="Plan de Financiamiento"
          text={currentPlan}
          handleClick={OpenPlanDialog}
        />
        <CurrencyDialog currencies={currencies} />
        <Divider />
        <ListItemAdd handleClick={handleAddProduct}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AutocompleteField 
                label="Producto" 
                _id="productId" 
                dialog={false}
                options={ products }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                dialog={false}
                fields={[{
                  id: "quantity", label: "Cant.",
                  variant: "outlined", margin: "dense", required: false, grid: 4
                },{
                  id: "discount", label: "Descuento (%)",
                  variant: "outlined", margin: "dense", required: false, grid: 4
                },{
                  id: "price", label: "Precio Unitario",
                  variant: "outlined", margin: "dense", disabled: true, required: false, grid: 4
                }]}
              />
            </Grid>
          </Grid>
        </ListItemAdd>
      </List>
      <Divider />
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Acciones</TableCell>
            <TableCell>Producto</TableCell>
            <TableCell align="center">Cant.</TableCell>
            <TableCell align="right">Dscto.</TableCell>
            <TableCell align="right">Precio Unitario</TableCell>
            <TableCell align="right">Importe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { tableRows }
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell align="right">Subtotal:</TableCell>
            <TableCell align="right">{budget.subtotal + props.currencyId}</TableCell>
          </TableRow>
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell align="right">Dscto:</TableCell>
            <TableCell align="right">{budget.discount + props.currencyId}</TableCell>
          </TableRow>
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell align="right">IVA:</TableCell>
            <TableCell align="right">{budget.tax + props.currencyId}</TableCell>
          </TableRow>
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell style={{ fontWeight: 'bold' }} align="right">Total:</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">{budget.total + props.currencyId}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
     <Grid container spacing={2} style={{paddingLeft: 15, paddingRight: 15}}>
        <Grid item xs={12}>
          <TextField
            dialog = {false}
            fields={[{
              id: "commentary", 
              label: "Comentario",
              variant: "outlined", 
              margin: "dense", 
              required: false
            }]}
          />   
        </Grid>
      </Grid>
      <ConfirmDialog _id={confirmId} handleAction={handleSell} handleCancel={handleCancelSale} />
      <LastStepDialog handleClick={handleLastStep} total={payment} billingInformationId={billingInformationId}/>
      <GeneralDialog isOpen={isInvoiceDialogOpen} title={`Facturas Electrónicas:`} handleCancel={handleCancelInvoices}>
        {generatedInvoices.map(el => <a key={el.invoiceId} className={ classes.spacing } href={`${config.apiUrl}/files/invoices/${el.invoiceId}/pdf`} rel="noopener noreferrer" target="_blank">{el.folio}</a>)}
      </GeneralDialog>
      <PlanDialog />
      <Snackbar />
    </Fragment>
  );
}

const currencyToLabel = value => {
  switch(value){
    case 'MXN': return 'MXN Peso Mexicano';
    default: return 'USD Dolar Americano';
  }
}

const planToLabel = value => {
  switch(value){
    case 1: return 'Financiamiento 3 meses';
    case 2: return 'Financiamiento 6 meses';
    case 3: return 'Financiamiento 12 meses';
    default: return 'No identificado';
  }
}

const errorMessage = key => {
  switch(key){
    case "Products":
      return "Selecciona al menos un producto para su venta.";
    case "userId":
      return "Selecciona un vendedor.";
    case "currencyKey":
      return "Selecciona una moneda.";
    case "quantityInvoice":
      return "Error al generar CFDi (Desglose de facturas debe ser mayor a 0).";
    case "paymentForm":
      return "Error al generar CFDi (Forma de Pago no seleccionado).";
    case "useCdfi":
      return "Error al generar CFDi (Uso de CFDi no seleccionado).";
    case "method":
      return "Error al generar el abono (Método de Pago no seleccionado)."
    case "Error":
      return "Error desconocido, favor de reportar."
    default:
      return "";
  }
}

const defaultTableValue = {count: 0, rows: []};
const floatFormat = number => !isNaN(parseFloat(number)) ? parseFloat(number).toFixed(2) : 0
const amount = ({ quantity, price, discount }) => {
  quantity = floatFormat(quantity);
  discount = floatFormat(discount);
  price = floatFormat(price / 1.16);
  const amount = price * quantity;
  return parseFloat(amount - (amount * (discount / 100))).toFixed(2);
}
 
const mapStateToProps = state => ({ 

  planId: state.field.value.planId || 2,

  invoices: state.field.value.invoices || [],
  validation: state.field.validation,

  rentPrices: state.field.value.rentPrices || [0, []],
  salePrice: state.field.value.salePrice,
  method: state.field.value.method,
  index: state.field.value.index,
  rentOptions: state.field.value.rentOptions,

  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity, 
    discount: state.field.value.discount, 
    price: state.field.value.price, 
    rentPrice: state.field.value.rentPrice
  },
  
  sellData: {
    saleId: state.field.value.saleId, 
    folio: state.field.value.folio, 
    accountReceivableId: state.field.value.accountReceivableId, 
    Products: state.field.value.Products, 
    customerIdLabel: state.field.value.customerIdLabel,
    employeeIdLabel: state.field.value.employeeIdLabel,
    paymentId: state.field.value.paymentId,
  },

  products: state.records.products || defaultTableValue,
  customers: state.records.customers || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  currencies: state.records.currencies || defaultTableValue,
  
  alert: state.alert.type,

  exchangeRate: (state.records.companies && state.records.companies.exchangeRate) ? state.records.companies.exchangeRate : 1,

  currencyId: state.field.value.currencyId ? state.field.value.currencyId : 'MXN',
  currencyType: state.field.value.currencyType ? state.field.value.currencyType : 1,

  billingInformationId: state.field.value.billingInformationId ? state.field.value.billingInformationId : 0,
  customerIdLabel: state.field.value.customerIdLabel ? state.field.value.customerIdLabel : 'Público en General',
  employeeIdLabel: state.field.value.employeeIdLabel ? state.field.value.employeeIdLabel : 'No Asignado',

  fields: state.field.value,

  progress: state.progress.isLoadingDialog

});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  createRecords: value => dispatch(recordsActions.create(value)),
  openProgressBar: () => dispatch(progressActions.open()),
  closeProgressBar: () => dispatch(progressActions.close()),
  errorMessage: value => dispatch(alertActions.error(value)),
  pendingMessage: value => dispatch(alertActions.pending(value)),
  clear: () => dispatch(alertActions.clear()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Sales));