import React, { useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';

import { countriesSelectField, statesSelectField, isMaster } from '../../_helpers';

const Branch = props => {

  const states = useMemo(() => statesSelectField(props.countryId), [props.countryId]);

  const branches = useMemo(() => 
    props.branches.count > 0 ? props.branches.rows.map(el => ({
      ID: { 
        value: el.id,
        data: {
          phone1Id: el.BranchPhones && el.BranchPhones[0].id,
          phone1: el.BranchPhones && el.BranchPhones[0].phone,
          addressLine1: el.BranchAddress.addressLine1,
          addressLine2: el.BranchAddress.addressLine2,
          city: el.BranchAddress.city,
          zipCode: el.BranchAddress.zipCode,
          reference: el.BranchAddress.zipCode,
          countryId: el.BranchAddress.countryId,
          stateId: el.BranchAddress.stateId,
          phone2Id: el.BranchPhones && el.BranchPhones[1] && el.BranchPhones[1].id,
          phone2: el.BranchPhones && el.BranchPhones[1] && el.BranchPhones[1].phone,
          ...el
        }
      }, 
      Nombre: {
       value: el.name,
       filter: true,
       _sort: 'text'
      },
      IVA: { 
        value: el.ivaTax+'%',
        align: "center"
      },
      Domicilio: { 
        value: el.BranchAddress.addressLine1+(el.BranchAddress.addressLine2 !== "" ? ', '+el.BranchAddress.addressLine2 : "")+', '+el.BranchAddress.city+', '+el.BranchAddress.zipCode+', '+el.BranchAddress.State.name
      },
      Teléfono:{
        value: el.BranchPhones[0].phone
      }
    })) : []
  , [props.branches]);

  return (
    <Module
      table="branches"
      name="sucursales"
      records={ branches }
      singularName="sucursal"
      filter={false}
      title="Crear Sucursal"
      download={true}
      _delete={isMaster}
    >
      <TextField
        icon="domain"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        fields={[{
          id: "code", 
          label: "Prefijo"
        }]}
      /> 
      <TextField
        icon="phone"
        fields={[{
          id: "phone1", 
          label: "Teléfono Fijo"
        }]}
      />
      <TextField
        fields={[{
          id: "phone2", 
          label: "Teléfono Móvil",
          required: false
        }]}
      />
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1", 
            label: "Calle y Número",
            multiline: true 
          },
          {
            id: "addressLine2", 
            label: "Colonia",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city", 
            label: "Ciudad"
          },
          {
            id: "zipCode", 
            label: "Código Postal"
          }
        ]}
      />
      <SelectField
        fields={[{
          id: 'countryId', 
          label: 'País',
          options: countriesSelectField
        }]}
      />
      <SelectField
        fields={[{
          id: 'stateId', 
          label: 'Estado',
          options: states,
        }]}
      />
      <TextField
        fields={[{
          id: "reference", 
          label: "Referencia",
          multiline: true,
          required: false
        }]}
      /> 
      <SelectField
        icon="poll"
        fields={[{
          id: "ivaTax", 
          label: "Tasa IVA",
          options: [
            {key: 8, value: '8%'},
            {key: 16, value: '16%'}
          ],
        }]}
      /> 
      <TextField
        icon="message"
        fields={[{
          id: "description", 
          label: "Comentarios",
          multiline: true,
          required: false
        }]}
      /> 
    </Module>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  branches: state.records.branches || defaultTableValue,
  countryId: state.field.value.countryId || 0,
});

export default connect(mapStateToProps, null)(Branch);