const expensePayments = ({method, ...props}) => {
  switch (method) {
  	case 'POST':
      return `expenses/${props.expenseId}/payments`;
  	case 'PUT':
      return `expenses/${props.expenseId}/payments/${props.id}`;
  	default:
      return 'expenses';
  }
}

export default expensePayments;