const logisticProduct = (method, values) => {
	const tmp = {};
  switch (method) {
  	case 'PUT':
      console.log(values);
     return { manufactureId: values.manufactureId, replaceManufactureId: values.replaceManufactureId };
    default:
      return tmp;
  }
}

export default logisticProduct;
