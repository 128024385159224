import React from "react";

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField, SelectField } from '../../_presentationals/Form';

const Dialog = props => 
    <FormDialog 
      title="Serie Física"
      singular="PhysicalSerieDialog"
      table="warranties"
    >
      <TextField
        fields={[{
          id: "physicalSerie", 
          label: "Serie Física",
          required: true,
          autoFocus: true
        }]}
      />
      <SelectField
        fields={[{
          id: 'status',
          label: "Modalidad",
          options: [
            {key: 5, value:"Vendido"},
            {key: 4, value:"Rentado"}
          ],
          required: true
        }]}
      />
    </FormDialog>

export default Dialog;