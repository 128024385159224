import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { config } from './../../../_helpers/config';
import { invoices } from './../../Sales/invoice';

import FormDialog from './../helpers/FormDialog';

const InvoiceData = props => {

	const handleCreateTicket = e => {
		const { id } = e.currentTarget.dataset;
    invoices(id);
	}
	
	return (
		<FormDialog
      title='Descarga del CFDi'
      hideAction
      isOpen={props.isOpen}
      handleClose={props.handleClose}
    >
    	<Typography variant="h6" gutterBottom>
        UUID: {props.uuid}
      </Typography>
      <Link href={`${config.apiUrl}${props.complement ? '/files/invoices/complements/' : '/files/invoices/'}${props.uuid}/pdf`} target="_blank">Formato tamaño carta</Link>
      <br/>
      <Link data-id={props.uuid} onClick={handleCreateTicket}>Formato ticket 80mm</Link>
      <br/>
      <Link href={`${config.apiUrl}/files/invoices/${props.uuid}/xml`}>Formato XML</Link>

    </FormDialog>
	)
}

export default InvoiceData;