import { alertConstants } from '../_constants';

export const alert = (state = {}, action) => {
  switch (action.type) {
    case alertConstants.PENDING:
      return {
        type: 'default',
        message:action.message
      };
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message
      };
   case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.message
      }
    case alertConstants.CLEAR:
      return {};  
    default:
      return state

  }
}
