import React, { Fragment } from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitleMUI from '@material-ui/core/DialogTitle';

import { DialogTitle, DialogContent, DialogActions } from './style';

const Component = props => 
  <Fragment>
    <Dialog
     open={ props.isOpen }
     onClose={ props.handleClose }
     fullWidth={ true }
     maxWidth = { props.width || 'sm' }
     fullScreen= { props.fullScreen }
     disableEscapeKeyDown={props.disableEscapeKeyDown}
    >
      { props.title && <DialogTitle isLoading={props.isLoading || props.disabled} children={props.title} /> }
      { props.altTitle && <DialogTitleMUI>{props.altTitle}</DialogTitleMUI> }
      <DialogContent noPadding={props.noPadding}>{ props.children }</DialogContent>
      <DialogActions>
        { !props.hideClose && <Button onClick={ props.handleClose }>Cerrar</Button> }
        { !props.hideAction && (
          <Button onClick={ props.handleAction } disabled={props.isLoading || props.disabled || false} color="secondary">
            { props.actionLabel || "Guardar" }
          </Button>
        )}
      </DialogActions>
    </Dialog>
  </Fragment>
  

export default Component;