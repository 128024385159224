import React, { useCallback, useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';
import Payment from '@material-ui/icons/Payment';

import { recordsActions } from '../../_actions';
import { countriesSelectField, statesSelectField } from '../../_helpers';

const termOptions = [
  { key: 1, value: "Días" },
  { key: 2, value: "Meses" },
  { key: 3, value: "Ninguno" }
];

const bankOptions = [
  { key: "BANAMEX", value: "BANAMEX" },
  { key: "BANORTE", value: "BANORTE" },
  { key: "BBVA", value: "BBVA" },
  { key: "SANTANDER", value: "SANTANDER" },
  { key: "SCOTIABANK", value: "SCOTIABANK" },
  { key: "BANBAJIO", value: "BANBAJIO" },
  { key: "HSBC", value: "HSBC" },
  { key: "BANCOPPEL", value: "BANCOPPEL" }
];

const Suppliers = ({ getRecords, countryId, ...props }) => {
  // eslint-disable-next-line
  const states = useCallback(statesSelectField(countryId), [countryId]);
  
  const suppliers = useMemo(() => 
    props.suppliers.count > 0 ? props.suppliers.rows.map(el => ({
      ID: { 
        value: el.id,
        data: el
      },
      Nombre: {
        value: el.tradename,
        _sort: 'text'
      },
      Dirección: {
        value: el.addressLine1+(el.addressLine2 !== "" ? ', '+el.addressLine2 : "")+(el.city !== "" ? ', '+el.city : "")+(el.zipCode !== "" ? ', '+el.zipCode : "")+ (el.State !== null ? ', '+el.State.name : "")
      },
      Contacto: {
        value: el.contact
      }
    })) : []
  , [props.suppliers]);

  return (
    <Module
      table="suppliers"
      name="proveedores"
      singularName="proveedor"
      filter={false}
      title="Crear Proveedor"
      records={suppliers}
    >
      <TextField
        fields={[{
          id: "tradename",
          label: "Nombre Comercial",
          autoFocus: true
        }]}
      />
      <TextField
        icon="domain"
        fields={[{
          id: "businessName",
          label: "Razón Social"
        }]}
      />
      <TextField
        fields={[{
          id: "identifier",
          label: "RFC"
        }]}
      />
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1",
            label: "Calle y Número",
            multiline: true,
            required: false
          },
          {
            id: "addressLine2",
            label: "Colonia",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city",
            label: "Ciudad",
            required: false
          },
          {
            id: "zipCode",
            label: "Código Postal",
            required: false
          }
        ]}
      />
      <SelectField
        fields={[{
          id: 'countryId', 
          label: 'País',
          options: countriesSelectField,
          required: false
        }]}
      />
      <SelectField
        fields={[{
          id: 'stateId',
          label: 'Estado',
          options: states,
          required: false
        }]}
      />
      <TextField
        icon="phone"
        fields={[{
          id: "phone",
          label: "Teléfono",
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "contact",
          label: "Contacto",
          required: false
        }]}
      />
      <TextField
        icon="email"
        fields={[{
          id: "email",
          label: "Correo",
          required: false
        }]}
      />
      <SelectField
        fields={[{
          id: 'term', 
          label: 'Plazo',
          options: termOptions,
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "termAmount",
          label: "Cantidad",
          required: false
        }]}
      />
      <SelectField
        fields={[{
          id: 'bank', 
          label: 'Banco',
          options: bankOptions,
          required: false
        }]}
      />
      <TextField
        icon={<Payment />}
        fields={[{
          id: "code",
          label: "CLABE",
          required: false
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  states: state.records.states || defaultTableValue,
  countries: state.records.countries || defaultTableValue,
  suppliers: state.records.suppliers || defaultTableValue,
  countryId: state.field.value.countryId || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);