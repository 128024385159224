import { useState, useEffect } from "react";
import * as recordsServices from '../_services';

// const branches = localStorage.getItem('branches') ? JSON.parse(localStorage.getItem('branches')) : { count: 0, rows: [] };

// export const BranchesSelectField = () => branches.rows.map(({ id, name }) => ({ key: id, value: name }))

export const BranchesSelectField = () => {
	const [ branches, setBranches ] = useState([{ key: '', value: '' }]);

	useEffect(() => {
		recordsServices.getAll({ table: 'branches' }).then(async el => {
			const data = await Promise.all(el.rows.map(({id, name, ivaTax}) => ({ key: id, value: name, ivaTax })))
			data.length > 0 && setBranches(data);
		});
	}, []);
	
	return branches;
}
