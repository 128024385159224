const logisticProduct = ({method, ...props}) => {
	
  switch (method) {
    case 'GET':
    	return `logistics/${props.redirectId}/products/`;
    default:
    	return `logistics/${props.redirectId}/products/${props.id}`
  }
}

export default logisticProduct;