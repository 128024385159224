import React, { Fragment, useState } from "react";
import { connect } from 'react-redux';

import { FormDialog } from './../../_presentationals/FormDialog';
import { closeFormDialog } from './../../_actions';

import TextField from './../Sales.v2/helpers/TextField';
import SelectField from './../Sales.v2/helpers/SelectField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import * as recordsServices from './../../_services/records.services';

const defaultValues = {
  reason: '01',
  relatedFolio: '',
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}

const reasonOptions = [
  { label: '01 Comprobante emitido con errores con relación', value: '01' },
  { label: '02 Comprobante emitido con errores sin relación', value: '02' },
  { label: '03 No se llevó a cabo la operación', value: '03' },
  { label: '04 Operación nominativa relacionada en la factura global', value: '04' },
];

const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async () => {
    try{
      const reason = values.reason;
      const relatedFolio = values.reason === '01' ? values.relatedFolio : '';
      const response = await recordsServices.updateV2(`v2/sales/${props.id}`, { reason, relatedFolio });
      if(response.errorCode) throw new Error(response.errorMessage);

      props.closeFormDialog('CancelSaleDialog');

      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: 'Los factura fue cancelada exitosamente', 
        severity: 'success' 
      }));
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }
  
  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  return (
    <Fragment>
      <FormDialog 
        name="CancelSaleDialog"
        singular="CancelSaleDialog"
        title="Cancelar Factura"
        handleCustomizedClick={handleAction}
        handleClose={props.handleCloseCancel}
      >
        <SelectField 
          name='reason'
          label='Motivo de cancelación' 
          onChange={handleChange}
          options={reasonOptions}
          value={values.reason}
        />
        {values.reason === '01' && (
          <TextField 
            name='relatedFolio'
            label='Folio relacionado' 
            onChange={handleChange}
            value={values.relatedFolio}
          />
        )}
        
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || []
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);