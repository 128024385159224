const session = JSON.parse(localStorage.getItem('session'));
const BRANCH_ID = session && session.data.branchId;

const inventories = ({method, ...props}) => {
	switch (method) {
    case 'DELETE':
    case 'PUT':
      return `inventories/${props._id}`
    case 'GET':
    	return `branches/${BRANCH_ID}/inventories` 
    default:
      return 'inventories';
  }
}

export default inventories;