import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';

import ZoomInIcon from '@material-ui/icons/ZoomIn';

import Module from '../../_presentationals/Module';
import {ConfirmDialog} from '../../_presentationals/ConfirmDialog/index';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';
import QuotationDetails from './quotationDetails';
import { config } from './../../_helpers/config';

import { format, endOfWeek, startOfWeek } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import { moneyFormat } from '../../_helpers/moneyFormat';
import { FilterDialog }  from './filter';

const SalesReport = ({ getRecords, clearRecords, openFormDialog, editValueAction, update, ...props }) => {
  const [ confirmId, setConfirmId ] = useState(0);
  const [ START ] = useState(startOfWeek(new Date()));
  const [ FINISH ] = useState(endOfWeek(new Date()));

  useEffect(() => {
    getRecords({ table: 'employees', sellers: true });
  }, [getRecords, editValueAction]);
  
  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  const handleSell = id => {
    editValueAction({ id: id });
    update('quotations');
    setConfirmId(0);
  };

  const handleCancelSell = e => {
    setConfirmId(0);
  };

  const salePDF = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/quotations/${id}`, '_blank');
  };

  useEffect(() => {
    getRecords({ table: 'quotations', start: START, finish: FINISH });
  }, [getRecords, START, FINISH]);

  const quotation = useMemo(() =>
    props.quotation.count > 0 ? props.quotation.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date'
      },
      Folio: {
       value: el.folio,
       filter: true,
       _sort: 'text'
      },
      Cliente: {
       value: el.Customer.firstName + ' ' + el.Customer.lastName,
       filter: true,
       _sort: 'text'
      },
      "Importe sin IVA":{
        value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.totalRaw),
        align: "right",
        alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.totalRaw).toFixed(2)),
        _sort: 'alt',
        accumulate: true
      },
      Descuento:{
        value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.amountDiscount),
        align: "right",
        alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.amountDiscount).toFixed(2)),
        _sort: 'alt'
      },
      Subtotal:{
        value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.subTotal),
        align: "right",
        alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.subTotal).toFixed(2)),
        _sort: 'alt'
      },
      IVA:{
        value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.taxes),
        align: "right",
        alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.taxes).toFixed(2)),
        _sort: 'alt'
      },
      Total:{
        value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.total),
        align: "right",
        alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.total).toFixed(2)),
        _sort: 'alt',
        accumulate: true
      },
      Usuario:{
        value: `${el.User.firstName} ${el.User.lastName}`,
        _sort: 'text'
      },
      Vendedor:{
        value: parseInt(el.status.id) !== 3 ? `${el.Employee.firstName} ${el.Employee.lastName}` : '',
        _sort: 'text'
      }
    })) : []
  , [props.quotation]);

  const filter = () => {
    openFormDialog('QuotationFilter');
  };

  return (
    <Fragment>
      <Module
        table="quotations"
        name="reporte de cotizaciones"
        records={quotation}
        singularName="reporte"
        title="Filtrar Cotizaciones"
        filter={filter}
        edit={false}
        create={false}
        _delete={false}
        download={true}
        actions={[{
          icon: <ZoomInIcon fontSize="small" />,
          label: "Consultar Cotización",
          handle: salePDF
        }]}
      ></Module>
      <QuotationDetails />
      <ConfirmDialog _id={ confirmId } handleAction={handleSell} handleCancel={handleCancelSell} />
      <FilterDialog employees={employees} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  quotation: state.records.quotations || defaultTableValue,
  employees: state.records.employees || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  update: (data) => dispatch(recordsActions.update(data)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);