import React, { useState, Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import randomize from 'randomatic';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import Module from '../../_presentationals/Module';
import { ConfirmDialog } from './../../_presentationals/ConfirmDialog';
import Snackbar from './../Sales.v2/helpers/Snackbar';
import { moneyFormat } from '../../_helpers/moneyFormat';

import * as recordsServices from './../../_services/records.services';

import reportPdf from './report.pdf';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const ServiceOrder = props => {
  
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ reopenId, setReopenId ] = useState(false);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);

  const cashClosing = useMemo(() => 
    props.cashClosing.count > 0 ? props.cashClosing.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el,
        showIf: parseInt(el.status) === 7 ? true : false,
        _alternative: parseInt(el.status) === 6 ? true : false,
      }, 
      Apertura: {
        value: format(parseISO(el.openDate), 'dd/MM/yyyy HH:ss', {locale: localeES}),
        _sort: 'date',
        noBreak: true
      },
      Cierre: {
        value: format(parseISO(el.createdAt), 'dd/MM/yyyy HH:ss', {locale: localeES}),
        _sort: 'date',
        noBreak: true
      },
      Usuario:{
        value: `${el.User ? (el.User.firstName + ' ' + el.User.lastName) : ''}`,
        filter: true,
       _sort: 'text',
       noBreak: true
      },
      Sucursal:{
        value: `${el.Branch.name}`,
       _sort: 'text',
       noBreak: true
      },
      Venta:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
    })) : []
  , [props.cashClosing]);

  const handleCustomCreate = () => {
    setReopenId(true);
  }

  const handleReopen = async id => {
    try {
      setReopenId(false);
      const respose = await recordsServices.createV2(`v2/cashClosings`);
      console.log(respose)
      if(respose.errorCode) throw new Error(respose.errorMessage);

      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: respose.message, severity: 'success' }));
      const tmpCode = randomize('*', 10);
      setRefreshCode(tmpCode);
    }catch(e) {
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleCloseReopen = id => {
    setReopenId(false);
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleOpen = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    reportPdf(id);
  }
  
  return (
    <Fragment>
      <Module
        table="cashClosingsV2"
        name="Cortes de Caja"
        records={cashClosing}
        singularName="corte de caja"
        refreshCode={refreshCode}
        handleCustomCreate={handleCustomCreate}
        filter={false}
        create={true}
        title="Corte de Caja"
        download={true}
        actions={[
          { icon: <PictureAsPdfIcon fontSize="small" />, label: "Abrir Documento", handle: handleOpen },
        ]}
      />
      <ConfirmDialog _id={reopenId} handleAction={handleReopen} handleCancel={handleCloseReopen} />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  cashClosing: state.records.cashClosingsV2 || defaultTableValue,
});

export default connect(mapStateToProps)(ServiceOrder);
