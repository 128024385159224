import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { AutocompleteField } from '../../_presentationals/Form';
 
import { closeFormDialog } from '../../_actions';

const CustomerDialog = props => {
  const handleClick = () => props.closeFormDialog('CustomerDialog');  
  return (
	  <FormDialog 
      buttonLabel="Actualizar" 
      singular="CustomerDialog" 
      title="Configuración del Cliente"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
	    <AutocompleteField 
	    	label="Nombre" 
	    	_id="customerId" 
	    	options={props.customers}
	    />
	  </FormDialog>
	)
};

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(null, mapDispatchToProps)(CustomerDialog);