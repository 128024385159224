import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import SwitchMUI from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import Divider from '@material-ui/core/Divider';

import { FormDialog } from './../../_presentationals/FormDialog';
// import { TextField } from './../../_presentationals/Form';

import { editValueAction, recordsActions } from '../../_actions';
import * as recordsServices from '../../_services';

const defaultValues = {
  create: false,
  read: false,
  update: false,
  delete: false
};

const defaultCredentials = {
  branches: { ...defaultValues, onlyMainBranch: false },
  users: { ...defaultValues, privileges: false },
  employees: { ...defaultValues, tools: false, commissions: false },
  tools: defaultValues,
  clasifications: defaultValues,
  subClasifications: defaultValues,
  compounds: { ...defaultValues, employee: false, estatus: false },
  products: { ...defaultValues, prices: false },
  spareProducts: defaultValues,
  failures: { ...defaultValues, warranty: false },
  vehicles: defaultValues,
  vehicleExpenses: defaultValues,
  vehicleFailures: { ...defaultValues, changeStatus: false },
  warranties: { ...defaultValues, finishWarranty: false, returnToWarranty: false },
  soldManufactures: { ...defaultValues, warranty: false, log: false },
  rentedManufactures: { ...defaultValues, warranty: false, log: false },
  manufactures: { ...defaultValues, warranty: false, log: false, finishWarranty: false, delete: false },
  statusManufactures: defaultValues,
  productManufactures: defaultValues,
  allManufactures: { ...defaultValues, warranty: false, log: false },
  inventories: { ...defaultValues, updateInventory: false },
  logisticIndependent: { ...defaultValues, employee: false, collection: false, products: false, changeStatus: false },
  logisticSale: { ...defaultValues, employee: false, collection: false, products: false, log: false },
  logistic: { ...defaultValues, collection: false, products: false, log: false },
  suppliers: defaultValues,
  expenses: { ...defaultValues, pay: false },
  debtsPays: { ...defaultValues, pay: false },
  catchments: defaultValues,
  groups: defaultValues,
  customers: { ...defaultValues, billingInfo: false },
  commission: { ...defaultValues, ticket: false },
  cashClosings: { ...defaultValues, ticket: false },
  accountsReceivables: { ...defaultValues, ticket: false },
  payments: { ...defaultValues, ticket: false },
  paymentReport: defaultValues,
  details: defaultValues,
  rents: defaultValues,
  quotationsReport: defaultValues,
  creditNotesReport: defaultValues,
  courtesiesReport: defaultValues,
  invoices: { ...defaultValues, creditNote: false },
  salesReport: { ...defaultValues, bill: false },
  failureModule: { create: false },
  consignmentModule: { create: false },
  quotationModule: { create: false },
  creditSalesModule: { create: false },
  courtesyModule: { create: false },
  independentPayments: defaultValues,
  consignments: defaultValues,
  consignmentManufactures: defaultValues,
  salesModule: { create: false },
  courtesyReport: { ...defaultValues },
  physicalInventories: { ...defaultValues },
  manufacturesSale: { ...defaultValues },
  manufacturesRent: { ...defaultValues },
  migrations: { create: false, confirmation: false },
  salesActive: { read: false },
  logisticRepalce: { read: false, replace: false },
  samples: defaultValues,
  sampleManufactures: defaultValues,
  customizedInvoices: defaultValues,
  saleAuthsV2: { ...defaultValues, authorize: false, multiBranch: false },
  courtesyAuths: { ...defaultValues, authorize: false, multiBranch: false },
  lendAuths: { ...defaultValues, authorize: false, multiBranch: false },
  serviceOrdersAuth: { ...defaultValues, authorize: false, multiBranch: false },
  pendingSales: { ...defaultValues, createSale: false },
  replaceAuths: { ...defaultValues, authorize: false },
  failuresv2: { ...defaultValues },
  serviceOrders: { ...defaultValues, authorize: false },
  internalServiceOrders: { ...defaultValues },
  cashClosingsV2: { ...defaultValues },
  pendingSalesV2: { ...defaultValues },
  borrowers: { ...defaultValues },
  sampleReportV2: { ...defaultValues },
  consignmentReportV2: { ...defaultValues },
  manufactureSale: { ...defaultValues },
};

const Credentials = props => {

  const [ credentials, setCredentials ] = useState(defaultCredentials);

  useEffect(() => {
    parseInt(props.userId) > 0 && recordsServices.getAll({ table: 'credentials', _id: props.userId }).then(data => {
      Object.keys(data).length > 0 ? setCredentials({ ...defaultCredentials, ...data }) : setCredentials(defaultCredentials);
    });
  }, [props.userId]);

  const handleChange = (name, action, isChecked) => {
    // console.log({[name]: { ...credentials[name], [action]: isChecked }});
    setCredentials(credentials => ({ ...credentials, [name]: { ...credentials[name], [action]: isChecked } }));
  }

  const handleUpdate = e => {
    e.preventDefault();
    props.editValueAction({ credentials });
    props.updateRecords('credentials');
  }
 
  return (
    <FormDialog
      title="Configuración de Credenciales"
      name="Credential"
      singular="Credentials"
      table="credentials"
      handleCustomizedClick={handleUpdate}
    >
      <Typography variant="subtitle1">
        Equipos Vendidos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.manufactureSale.read} module="manufactureSale" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Prestadores
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.borrowers.read} module="borrowers" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.borrowers.create} module="borrowers" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.borrowers.update} module="borrowers" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.borrowers.delete} module="borrowers" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Autorización de Ventas V2
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.saleAuthsV2.read} module="saleAuthsV2" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.saleAuthsV2.authorize} module="saleAuthsV2" action="authorize" onChange={handleChange} />}
            label="Autorizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.saleAuthsV2.delete} module="saleAuthsV2" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.saleAuthsV2.multiBranch} module="saleAuthsV2" action="multiBranch" onChange={handleChange} />}
            label="Multi Sucursal"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Autorización de Cortesías V2
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.courtesyAuths.read} module="courtesyAuths" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.courtesyAuths.authorize} module="courtesyAuths" action="authorize" onChange={handleChange} />}
            label="Autorizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.courtesyAuths.delete} module="courtesyAuths" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.courtesyAuths.multiBranch} module="courtesyAuths" action="multiBranch" onChange={handleChange} />}
            label="Multi Sucursal"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>
      <Typography variant="subtitle1">
        Autorización de Consignaciones y muestras V2
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.lendAuths.read} module="lendAuths" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.lendAuths.authorize} module="lendAuths" action="authorize" onChange={handleChange} />}
            label="Autorizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.lendAuths.delete} module="lendAuths" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.lendAuths.multiBranch} module="lendAuths" action="multiBranch" onChange={handleChange} />}
            label="Multi Sucursal"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
      
      <br/><br/>
      <Typography variant="subtitle1">
        Autorización de Ordenes de Servicio
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrdersAuth.read} module="serviceOrdersAuth" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrdersAuth.authorize} module="serviceOrdersAuth" action="authorize" onChange={handleChange} />}
            label="Autorizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrdersAuth.delete} module="serviceOrdersAuth" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrdersAuth.multiBranch} module="serviceOrdersAuth" action="multiBranch" onChange={handleChange} />}
            label="Multi Sucursal"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>
      
      <Typography variant="subtitle1">
        Pagos Pendientes
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.pendingSalesV2.read} module="pendingSalesV2" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.pendingSalesV2.delete} module="pendingSalesV2" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
      
      <Typography variant="subtitle1">
        Corte de Caja
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.cashClosingsV2.read} module="cashClosingsV2" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.cashClosingsV2.create} module="cashClosingsV2" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
      
      <Typography variant="subtitle1">
        Orden de Servicio Interna
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.internalServiceOrders.read} module="internalServiceOrders" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.internalServiceOrders.create} module="internalServiceOrders" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.internalServiceOrders.delete} module="internalServiceOrders" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
      
      <Typography variant="subtitle1">
        Orden de Servicio
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrders.read} module="serviceOrders" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrders.create} module="serviceOrders" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrders.update} module="serviceOrders" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.serviceOrders.delete} module="serviceOrders" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Fallas de Equipos v2
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.failuresv2.read} module="failuresv2" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.failuresv2.create} module="failuresv2" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.failuresv2.delete} module="failuresv2" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Autorización de Reemplazo
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.replaceAuths.read} module="replaceAuths" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.replaceAuths.authorize} module="replaceAuths" action="authorize" onChange={handleChange} />}
            label="Autorizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.replaceAuths.delete} module="replaceAuths" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>
      
      <Typography variant="subtitle1">
        Rentas Pendientes
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.pendingSales.read} module="pendingSales" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.pendingSales.createSale} module="pendingSales" action="createSale" onChange={handleChange} />}
            label="Generar Venta"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.pendingSales.delete} module="pendingSales" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Sucursales
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.branches.read} module="branches" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.branches.create} module="branches" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.branches.update} module="branches" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.branches.delete} module="branches" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.branches.onlyMainBranch} module="branches" action="onlyMainBranch" onChange={handleChange} />}
            label="Multi Sucursal"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Usuarios
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.users.read} module="users" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.users.create} module="users" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.users.update} module="users" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.users.delete} module="users" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.users.privileges || false} module="users" action="privileges" onChange={handleChange} />}
            label="Credenciales"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Empleados
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.employees.read || false} module="employees" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.employees.create || false} module="employees" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.employees.update || false} module="employees" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.employees.delete || false} module="employees" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.employees.tools || false} module="employees" action="tools" onChange={handleChange} />}
            label="Herramientas"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.employees.commissions || false} module="employees" action="commissions" onChange={handleChange} />}
            label="Comisiones"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Herramientas
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.tools.read || false} module="tools" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.tools.create || false} module="tools" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.tools.update || false} module="tools" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.tools.delete || false} module="tools" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Clasificaciones
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.clasifications.read || false} module="clasifications" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.clasifications.create || false} module="clasifications" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.clasifications.update || false} module="clasifications" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.clasifications.delete || false} module="clasifications" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Sub Clasificaciones
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.subClasifications.read || false} module="subClasifications" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.subClasifications.create || false} module="subClasifications" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.subClasifications.update || false} module="subClasifications" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.subClasifications.delete || false} module="subClasifications" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
      
      <br/><br/>

      <Typography variant="subtitle1">
        Productos Compuestos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.compounds.read || false} module="compounds" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.compounds.create || false} module="compounds" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.compounds.update || false} module="compounds" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.compounds.delete || false} module="compounds" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.compounds.employee || false} module="compounds" action="employee" onChange={handleChange} />}
            label="Asignar Responsable"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.compounds.status || false} module="compounds" action="status" onChange={handleChange} />}
            label="Cambiar Estatus"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
      
      <br/><br/>

      <Typography variant="subtitle1">
        Productos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.products.read || false} module="products" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.products.create || false} module="products" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.products.update || false} module="products" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.products.delete || false} module="products" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.products.prices || false} module="products" action="prices" onChange={handleChange} />}
            label="Precios"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Componentes de un Equipo
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.spareProducts.read || false} module="spareProducts" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.spareProducts.create || false} module="spareProducts" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.spareProducts.update || false} module="spareProducts" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.spareProducts.delete || false} module="spareProducts" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Seguimiento de Fallas (Clientes)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.failures.read || false} module="failures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.failures.warranty || false} module="failures" action="warranty" onChange={handleChange} />}
            label="Enviar a Garantía"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.failures.delete || false} module="failures" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Vehículos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.vehicles.read || false} module="vehicles" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicles.create || false} module="vehicles" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicles.update || false} module="vehicles" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicles.delete || false} module="vehicles" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Gastos de Vehículos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.vehicleExpenses.read || false} module="vehicleExpenses" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicleExpenses.create || false} module="vehicleExpenses" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicleExpenses.update || false} module="vehicleExpenses" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicleExpenses.delete || false} module="vehicleExpenses" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Fallas de Vehículos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.vehicleFailures.read || false} module="vehicleFailures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicleFailures.create || false} module="vehicleFailures" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicleFailures.update || false} module="vehicleFailures" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicleFailures.delete || false} module="vehicleFailures" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.vehicleFailures.changeStatus || false} module="vehicleFailures" action="changeStatus" onChange={handleChange} />}
            label="Cambiar Estatus"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos en Reparación (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.warranties.read || false} module="warranties" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.warranties.finishWarranty || false} module="warranties" action="finishWarranty" onChange={handleChange} />}
            label="Finalizar Garantía"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.warranties.delete || false} module="warranties" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.warranties.returnToWarranty || false} module="warranties" action="returnToWarranty" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos Vendidos (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.soldManufactures.read || false} module="soldManufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.soldManufactures.warranty || false} module="soldManufactures" action="warranty" onChange={handleChange} />}
            label="Enviar a Garantía"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.soldManufactures.log || false} module="soldManufactures" action="log" onChange={handleChange} />}
            label="Consultar Bitácora"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos Rentados (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.rentedManufactures.read || false} module="rentedManufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.rentedManufactures.warranty || false} module="rentedManufactures" action="warranty" onChange={handleChange} />}
            label="Enviar a Garantía"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.rentedManufactures.log || false} module="rentedManufactures" action="log" onChange={handleChange} />}
            label="Consultar Bitácora"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos Disponibles (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.manufactures.read || false} module="manufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.manufactures.create || false} module="manufactures" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.manufactures.warranty || false} module="manufactures" action="warranty" onChange={handleChange} />}
            label="Enviar a Garantía"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.manufactures.log || false} module="manufactures" action="log" onChange={handleChange} />}
            label="Consultar Bitácora"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.manufactures.finishWarranty || false} module="manufactures" action="finishWarranty" onChange={handleChange} />}
            label="Finalizar Ensamble"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.manufactures.delete || false} module="manufactures" action="delete" onChange={handleChange} />}
            label="Eliminar Equipo"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos Para Venta
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.manufacturesSale.read || false} module="manufacturesSale" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.manufacturesSale.create || false} module="manufacturesSale" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos Para Renta
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.manufacturesRent.read || false} module="manufacturesRent" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.manufacturesRent.create || false} module="manufacturesRent" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos Disponibles por Estatus (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.statusManufactures.read || false} module="statusManufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Equipos Disponibles por Producto (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.productManufactures.read || false} module="productManufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Todos los equipos (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.allManufactures.read || false} module="allManufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.allManufactures.warranty || false} module="allManufactures" action="warranty" onChange={handleChange} />}
            label="Enviar a Garantía"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.allManufactures.log || false} module="allManufactures" action="log" onChange={handleChange} />}
            label="Consultar Bitácora"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Traspaso de Equipos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.migrations.create || false} module="migrations" action="create" onChange={handleChange} />}
            label="Hacer Traspaso"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.migrations.confirmation || false} module="migrations" action="confirmation" onChange={handleChange} />}
            label="Confirmar Traspaso"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Refacciones (Inventarios)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.inventories.read || false} module="inventories" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.inventories.updateInventory || false} module="inventories" action="updateInventory" onChange={handleChange} />}
            label="Actualizar Existencia"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Entregas de Equipos (Logística)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.logistic.read || false} module="logistic" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logistic.collection || false} module="logistic" action="collection" onChange={handleChange} />}
            label="Cambiar Estatus"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logistic.products || false} module="logistic" action="products" onChange={handleChange} />}
            label="Entregas"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logistic.log || false} module="logistic" action="log" onChange={handleChange} />}
            label="Revisar Historial"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Recolección Independiente (Logística)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.logisticIndependent.read || false} module="logisticIndependent" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticIndependent.employee || false} module="logisticIndependent" action="employee" onChange={handleChange} />}
            label="Seleccionar Chofer"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticIndependent.collection || false} module="logisticIndependent" action="collection" onChange={handleChange} />}
            label="Recolectar Equipo"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticIndependent.products || false} module="logisticIndependent" action="products" onChange={handleChange} />}
            label="Consultar Equipos"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticIndependent.changeStatus || false} module="logisticIndependent" action="changeStatus" onChange={handleChange} />}
            label="Cambiar Estatus"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Recolección de Rentas (Logística)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.logisticSale.read || false} module="logisticSale" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticSale.collection || false} module="logisticSale" action="collection" onChange={handleChange} />}
            label="Cambiar Estatus"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticSale.products || false} module="logisticSale" action="products" onChange={handleChange} />}
            label="Entregas"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticSale.log || false} module="logisticSale" action="log" onChange={handleChange} />}
            label="Revisar Historial"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Reemplazo de Equipos (Logística)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.logisticRepalce.read || false} module="logisticRepalce" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.logisticRepalce.replace || false} module="logisticRepalce" action="replace" onChange={handleChange} />}
            label="Reemplazar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Proveedores
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.suppliers.read} module="suppliers" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.suppliers.create} module="suppliers" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.suppliers.update} module="suppliers" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.suppliers.delete} module="suppliers" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Cuantas Por Pagar
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.debtsPays.read} module="debtsPays" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.debtsPays.create} module="debtsPays" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.debtsPays.delete} module="debtsPays" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.debtsPays.pay} module="debtsPays" action="pay" onChange={handleChange} />}
            label="Emitir Pagos"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Captación
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.catchments.read} module="catchments" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.catchments.create} module="catchments" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.catchments.update} module="catchments" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.catchments.delete} module="catchments" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Inventario Físico
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.physicalInventories.read} module="physicalInventories" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.physicalInventories.create} module="physicalInventories" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>


      <Typography variant="subtitle1">
        Corte de Movimientos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.expenses.read} module="expenses" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.expenses.create} module="expenses" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.expenses.delete} module="expenses" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.expenses.pay} module="expenses" action="pay" onChange={handleChange} />}
            label="Saldar CxP"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Grupos (Clientes)
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.groups.read} module="groups" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.groups.create} module="groups" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.groups.update} module="groups" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.groups.delete} module="groups" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Clientes
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.customers.read} module="customers" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.customers.create} module="customers" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.customers.update} module="customers" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.customers.delete} module="customers" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.customers.billingInfo} module="customers" action="billingInfo" onChange={handleChange} />}
            label="Datos de Facturación"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Comisiones
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.commission.read} module="commission" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.commission.ticket} module="commission" action="ticket" onChange={handleChange} />}
            label="Generar Ticket"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Corte de Movimientos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.cashClosings.read} module="cashClosings" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.cashClosings.create} module="cashClosings" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.cashClosings.ticket} module="cashClosings" action="ticket" onChange={handleChange} />}
            label="Visualizar PDF"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Cuentas por Cobrar
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.accountsReceivables.read} module="accountsReceivables" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.accountsReceivables.ticket} module="accountsReceivables" action="ticket" onChange={handleChange} />}
            label="Visualizar PDF"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Abonos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.payments.read} module="payments" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.payments.create} module="payments" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.payments.delete} module="payments" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.payments.ticket} module="payments" action="ticket" onChange={handleChange} />}
            label="Generar Ticket"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Abonos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.paymentReport.read} module="paymentReport" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Ventas por Producto
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.details.read} module="details" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Rentas
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.rents.read} module="rents" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Cotizaciones
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.quotationsReport.read} module="quotationsReport" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Consignaciones 
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.consignmentReportV2.read} module="consignmentReportV2" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.consignmentReportV2.create} module="consignmentReportV2" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.consignmentReportV2.delete} module="consignmentReportV2" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Muestras 
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.sampleReportV2.read} module="sampleReportV2" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.sampleReportV2.create} module="sampleReportV2" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.sampleReportV2.delete} module="sampleReportV2" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Notas de Crédito
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.creditNotesReport.read} module="creditNotesReport" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Cortesías
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.courtesiesReport.read} module="courtesiesReport" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.courtesiesReport.delete} module="courtesiesReport" action="delete" onChange={handleChange} />}
            label="Cancelar Cortesía"
            labelPlacement="end"
          />          
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Facturas Electrónicas
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.invoices.read} module="invoices" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.invoices.delete} module="invoices" action="delete" onChange={handleChange} />}
            label="Cancelar Factura Electrónica"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.invoices.creditNote} module="invoices" action="creditNote" onChange={handleChange} />}
            label="Emitir Nota de Crédito"
            labelPlacement="end"
          />

        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Facturas Personalizas
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.customizedInvoices.create} module="customizedInvoices" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.customizedInvoices.read} module="customizedInvoices" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>


      <Typography variant="subtitle1">
        Reporte de Ventas Abiertas
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.salesActive.read} module="salesActive" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />

        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Reporte de Ventas
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.salesReport.read} module="salesReport" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.salesReport.delete} module="salesReport" action="delete" onChange={handleChange} />}
            label="Cancelar Venta"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.salesReport.bill} module="salesReport" action="bill" onChange={handleChange} />}
            label="Emitir Factura"
            labelPlacement="end"
          />

        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Abonos Independientes
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.independentPayments.read || false} module="independentPayments" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.independentPayments.create || false} module="independentPayments" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.independentPayments.update || false} module="independentPayments" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.independentPayments.delete || false} module="independentPayments" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Consignadores
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.consignments.read || false} module="consignments" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.consignments.create || false} module="consignments" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.consignments.update || false} module="consignments" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.consignments.delete || false} module="consignments" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Consignar Equipos
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.consignmentManufactures.read || false} module="consignmentManufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.consignmentManufactures.create || false} module="consignmentManufactures" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.consignmentManufactures.delete || false} module="consignmentManufactures" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Muestrarios
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.samples.read || false} module="samples" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.samples.create || false} module="samples" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.samples.update || false} module="samples" action="update" onChange={handleChange} />}
            label="Actualizar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.samples.delete || false} module="samples" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <br/><br/>

      <Typography variant="subtitle1">
        Asignar Muestras
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch checked={credentials.sampleManufactures.read || false} module="sampleManufactures" action="read" onChange={handleChange} />}
            label="Consultar"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.sampleManufactures.create || false} module="sampleManufactures" action="create" onChange={handleChange} />}
            label="Crear"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.sampleManufactures.delete || false} module="sampleManufactures" action="delete" onChange={handleChange} />}
            label="Eliminar"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography variant="subtitle1">
        Módulos de Alimentación
      </Typography> 

      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          
          <FormControlLabel
            control={<Switch checked={credentials.failureModule.create} module="failureModule" action="create" onChange={handleChange} />}
            label="Reportar Falla (Clientes)"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.quotationModule.create} module="quotationModule" action="create" onChange={handleChange} />}
            label="Crear Cotizaciones"
            labelPlacement="end"
          />
          {
          // <FormControlLabel
          //   control={<Switch checked={credentials.creditSalesModule.create} module="creditSalesModule" action="create" onChange={handleChange} />}
          //   label="Crear Ventas a Crédito"
          //   labelPlacement="end"
          // />
          }
          <FormControlLabel
            control={<Switch checked={credentials.courtesyModule.create} module="courtesyModule" action="create" onChange={handleChange} />}
            label="Crear Cortesías"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Switch checked={credentials.salesModule.create} module="salesModule" action="create" onChange={handleChange} />}
            label="Crear Ventas a Contado"
            labelPlacement="end"
          />
          
        </FormGroup>
      </FormControl>

    </FormDialog>
  );

}

const Switch = props => {
  const handleChange = e => { props.onChange(props.module, props.action, e.target.checked); }
  return <SwitchMUI size="small" checked={props.checked} onChange={handleChange} />;
}

const mapStateToProps = state => ({
  userId: state.field.value.id || 0
});

const mapDispatchToProps = dispatch => ({
  editValueAction: value => dispatch(editValueAction(value)),
  updateRecords: value => dispatch(recordsActions.update(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Credentials);