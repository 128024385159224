import { format } from "date-fns";
import locale from 'date-fns/locale/es';

const uri = ({method, ...props}) => {
  switch (method) {
  	default:
  		if(props.start || props.finish){
  			const startFormat = props.start ? new Date(props.start) : new Date();
      	const finishFormat = props.finish ? new Date(props.finish) : new Date();

	      const start = format(startFormat, 'yyyy/MM/dd', {locale});
	      const finish = format(finishFormat, 'yyyy/MM/dd', {locale});

	      const query = { start, finish };
	      Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null || query[key] === '' || query[key] === 0) && delete query[key]);      
	      let querys = '';
	      Object.keys(query).forEach(key => {
	        querys += `${key}=${query[key]}&`;
	      });
	      return `v2/sales/manufactures?${querys}`;
  		}

    	return `v2/sales/manufactures`;
  }
}

export default uri