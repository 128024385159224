const vehicles = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
  	case 'PUT':
  		return `vehicles/${props._id}`;
    default:
      return 'vehicles';
  }
}

export default vehicles;