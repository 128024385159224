const logistic = ({method, ...props}) => {

	switch (method) {
		case 'PUT':
			return `logistics/${props._id}`
    default:
      return 'logistics';
  }
}

export default logistic;