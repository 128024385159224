import React, { useEffect, Fragment, useState, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import Icon from '@material-ui/core/Icon';
import { TextField } from './../../_presentationals/Form';
import Grid from '@material-ui/core/Grid';
import { ListItemAdd } from './listItemAdd';
import { editValueAction, recordsActions } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const DeleteManucfacture = ({ spareProducts, getRecords, updateRecords, editValueAction, ...props }) => {
  
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [isAdd, setAdd] = useState(false);

  const handleAddProduct = e => {
    setAdd(true);
    const { productId, productIdLabel, quantity = 1 } = props.selectedProduct;

    if(!productId){ return; }
    local && local.push({ productId, productIdLabel, qty: parseInt(quantity) });
    editValueAction({spareProducts: local, productId: null, employeeId: null, quantity: ''});    
    setQty(JSON.stringify(local));
    setLocal(local);
  };

  useEffect( () => {
    if(props.alert.type === 'success') {
      editValueAction({ spareProducts: [], productId: null, employeeId: null, quantity: '', price: '', commentary: '' });
      setLocal([]);
      setAdd(false);
    }
  }, [props.alert, editValueAction]);

  useEffect( () => {
    if(!props.isOpen.Finish) {
      editValueAction({ spareProducts: [], productId: null, employeeId: null, quantity: '', price: '', commentary: '' });
      setLocal([]);
    }
  }, [props.isOpen, editValueAction]);

  const products = useMemo(() => {
    return (spareProducts.length > 0 ? spareProducts.map((el,i) => ({ value: el.productId, label: `${el.name}` }) ) : [] );
  },[spareProducts]);

  useEffect(() => {
    if(!isAdd) {
      const tmp = spareProducts;
      setQty(JSON.stringify(tmp));
      setLocal(tmp);
      editValueAction({ spareProducts: tmp });
    }
  },[spareProducts, editValueAction, isAdd]);
  // },[spareProducts, editValueAction]);
  //Si falla, sustituir la siguiente línea comentada

  const handleDelete = () => {
    editValueAction({ spareProducts: local, id: props._id, isDelete: true });
    updateRecords('manufactures');
  }

  const tableRows = useMemo(() => {

    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal([ ...tmp ]);
      console.log(tmp);
    };

    
    return local.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">
          {el.productIdLabel || el.name} 
        </TableCell>
        <TableCell align="center">{el.qty}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, qty]);
  
  return (
    <FormDialog 
      title="Eliminar Manufactura"
      name="_delete"
      singular="_delete"
      handleCustomizedClick={handleDelete}
    >
      <Fragment>
        <ListItemAdd handleClick={handleAddProduct}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                label="Producto" 
                _id="productId" 
                dialog={false}
                options={products}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                dialog={false}
                fields={[{
                  id: "quantity", label: "Cant.",
                  variant: "outlined", margin: "dense"
                }]}
              />
            </Grid>
          </Grid>
        </ListItemAdd>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell align="center">Cant.</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </Fragment>
    </FormDialog>
  );
}

const defaultTableValue = { count: 0, rows: [] };
const mapStateToProps = state => ({
  _id: state.field.value._id || 0,
  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity
  },
  products: state.records.products || defaultTableValue,
  state: state,
  isOpen: state.formDialog,
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  updateRecords: table => dispatch(recordsActions.update(table))
});


export default connect(mapStateToProps, mapDispatchToProps)(DeleteManucfacture);