const session = JSON.parse(localStorage.getItem('session'));
const BRANCH_ID = session && session.data.branchId;

const employees = ({method, ...props}) => {
  switch (method) {
    case 'GET':
      if (typeof props.employeeRole !== 'undefined') return `employees/type/${props.employeeRole}`;
      if (typeof props.sellers !== 'undefined') return `employees/sellers`;
      if (typeof props.isTechnical !== 'undefined') return `employees/technicals`;
      return `employees`;
    case 'PUT':
      if(props.isTools) return `employees/${props._id}/tools`;
      return `employees/${props._id}/branches/${BRANCH_ID}`;
    case 'DELETE':
      return `employees/${props._id}/branches/${BRANCH_ID}`;
    default:
      return 'employees';
  }
}

export default employees;