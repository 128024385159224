import React, { useEffect, useState, useMemo } from "react";
import { connect } from 'react-redux';
import { recordsActions } from '../../_actions';
import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from '../../_services';

import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';

import Icon from '@material-ui/core/Icon';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { ListItemAdd } from './listItemAdd';
import { editValueAction } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const ProductsDialog = ({ dataProducts, productId, isRent, getRecords, editValueAction, ...props }) => {
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [ physicalSerie, setPhysicalSerie ] = useState([]);
  const [isAdd, setAdd] = useState(false);
  
  const handleAddProduct = e => {
    setAdd(true);
    const { manufactureId, manufactureIdLabel } = props.selectedProduct;
    
    if(!manufactureId || parseInt(local.length) + 1 > parseFloat(props.qtyProduct)) return;

    const index = physicalSerie.findIndex( el => ( parseInt(el.value) === parseInt(manufactureId) ));
    delete physicalSerie[index];
    const tmp = physicalSerie.filter( el => el);
    setPhysicalSerie(tmp);

    local && local.push({ manufactureId, manufactureIdLabel });
    editValueAction({ Products: local, manufactureId: null });
    setQty(JSON.stringify(local));
    setLocal(local);
  };

  useEffect( () => {
    if(props.alert.type === 'success') {
      editValueAction({ Products: [], manufactureId: null });
      setLocal([]);
      setQty(0);
      setAdd(false);
    }
  }, [props.alert, editValueAction]);

  useEffect(() => {
    if(!isAdd && dataProducts) {
      const tmp = [];
      dataProducts.forEach( el => ( el.manufactureId && tmp.push({ manufactureId: el.manufactureId, manufactureIdLabel: `${el.name} Serie: ${el.physicalSerie}` })) );
      setQty(JSON.stringify(tmp));
      setLocal(tmp);
      editValueAction({ Products: tmp });
    }
  },[dataProducts, editValueAction, isAdd]);

  useEffect(() => {
    parseInt(productId) > 0 && recordsServices.getAll({
      table: 'manufactures', productId, status: isRent ? 3 : 2,
      methodPlan: props.methodPlan, deliveryDate: props.deliveryDate, quantity: props.planQuantity
    }).then(async data => {
      const tmp = await parseInt(data.count) > 0 ? data.rows.map(el => ({ value: el.id, label: `${el.Product.name} Serie: ${el.physicalSerie} ${el.statusLabel}` })) : [];
      setPhysicalSerie(tmp);
    });
  }, [productId, isRent, props.methodPlan, props.deliveryDate, props.planQuantity]);

  const tableRows = useMemo(() => {    
    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal([ ...tmp ]);
    };

    return local.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">
          {el.manufactureIdLabel}
        </TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local,qty]);

  return (
    <FormDialog 
      title={`Asignar Series | Cantidad: ${props.qtyProduct} - Agregados: ${local.length}`}
      name="ProductsDialog"
      singular="ProductsDialog"
      table="logisticSale"
    >
      <ListItemAdd handleClick={handleAddProduct}>
        <Autocomplete
          label="Serie Física" 
          _id="manufactureId" 
          dialog={false}
          options={physicalSerie}
          variant="outlined"
        />
      </ListItemAdd>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </FormDialog>
  );
}

const mapStateToProps = state => ({
  isRent: state.field.value.isRent || 0,
  methodPlan: state.field.value.methodPlan || 'rentPerEvent',
  deliveryDate: state.field.value.deliveryDate || format(new Date(),'yyyy/MM/dd',{locale: localeES}),
  planQuantity: state.field.value.planQuantity || 1,
  productId: state.field.value.productId || 0,
  qtyProduct: state.field.value.qtyProduct || 0,
  selectedProduct: {
    manufactureId: state.field.value.manufactureId, 
    manufactureIdLabel: state.field.value.manufactureIdLabel
  },
  alert: state.alert,
  dataProducts: state.field.value.dataProducts
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductsDialog);