import React, { Fragment, useMemo, useEffect  } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { recordsActions, openFormDialog } from '../../_actions';

import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import FilterDialog from './filter';

const CatchmentsReport = ({getRecords, ...props}) => {

  useEffect(() => {
    getRecords({ table: 'employees', sellers: true });
    getRecords({ table: 'products' });
  }, [getRecords]);
  
  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);
  
  const products = useMemo(() => (
    props.products.count > 0 ? props.products.rows.map(el => ({ value: el.id, label: el.name })) : []
  ), [props.products]);

  const catchments = useMemo(() => (
    props.catchments.count > 0 ? props.catchments.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date'
      },
      Cliente: {
        value: el.customer,
        filter: true,
      },
      "Modo de Captación": {
        value: el.catchment
      },
      Teléfono: {
        value: el.phone
      },
      Correo: {
        value: el.email
      },
      Tipo: {
        value: el.type
      },
      Servicio: {
        value: el.service
      },
      "Efectuado/No Efectuado": {
        value: el.status
      },
      Folio: {
        value: el.folio
      },
      "Nro de Equipos": {
        value: el.quantity
      },
      "Modelo de Equipo": {
        value: el.product,
        filter: true
      },
      Vendedor: {
        value: el.employee
      },
      Empresa: {
        value: el.company,
        filter: true
      },
      Sucursal: {
        value: el.branch
      }
    })) : []
  ), [props.catchments]);

  const filter = () => {
    props.openFormDialog('FilterDialog');
  }

  return (
    <Fragment>
      <Module
        table="catchmentsReport"
        name="Reporte de Captación de Clientes"
        singularName="reporte"
        records={catchments}
        filter={filter}
        edit={false}
        create={false}
        download={true}
        _delete={false}
      ></Module>
      <FilterDialog employees={employees} products={products} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  catchments: state.records.catchmentsReport || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  products: state.records.products || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: data => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(CatchmentsReport);