import React from "react";
import { FormDialog } from '../../_presentationals/FormDialog';

const Component = props =>
  <FormDialog 
    table="saleAuths"
    singular="AuthDialog" 
    title="¡Un momento!"
    primaryButtonLabel="Si, deseo continuar"
  >
    Esta acción no podrá ser revertida, ¿Estás seguro que deseas continuar?
  </FormDialog>

export default Component;