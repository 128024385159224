import React, { useMemo, Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';

import ToysIcon from '@material-ui/icons/ToysOutlined';

import Module from '../../_presentationals/Module';

import Manufacture from './manufacture.dialog';

import { openFormDialog  } from '../../_actions';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Logistic = ({openFormDialog, ...props}) => {

  const [ actions, setActions ] = useState([]);
  const [ customerId, setCustomerId ] = useState(null);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ refreshCode, setRefreshCode ] = useState(null);

  useEffect(() => {
    const tmpAction = [
      { icon: <ToysIcon fontSize="small" />, label: "Equipos", handle: handleManufactures },
    ];
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const replaces = useMemo(() => 
    props.replaces.count > 0 ? props.replaces.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Cliente: {
        value: `${el.firstName} ${el.lastName}`,
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.replaces]);

  const handleManufactures = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setCustomerId(id);
    openFormDialog('Manufacture');
  }

  const reload = (code) => {
    setRefreshCode(code);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  return (
    <Fragment>
      <Module
        table="replacesV2"
        name="reemplazo"
        records={replaces}
        title="Reemplazo de Equipos"
        singularName="PDF"
        refreshCode={refreshCode}
        filter={false}
        edit={false}
        download={true}
        create={false}
        _delete={false}
        actions={actions}
      >
      </Module>
      <Manufacture reload={reload} customerId={customerId} />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  replaces: state.records.replacesV2 || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logistic);