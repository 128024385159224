import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const quotations = ({method, ...props}) => {
  switch (method) {
  	case 'PUT':
  		return `quotations/${props._id}/tosales`;
    case 'GET':
    	const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy-MM-dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy-MM-dd', {locale: localeES});

      return `quotations/${start}/${finish}?employeeId=${props.employeeId || 0}`;
     default:
      return 'quotations';
  }
}

export default quotations;