import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';

import AttachMoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { openFormDialog, editValueAction, redirectActions  } from '../../_actions';
import { history } from './../../_helpers';
import { moneyFormat } from '../../_helpers/moneyFormat';

import { ClassificationSelectField, SubClassificationSelectField } from '../../_helpers/getClassification';
import { BillingKeysAutocomplete } from '../../_helpers/getBillingKeys';

import Prices from './prices';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Product = ({ openFormDialog, editValueAction, setRedirect, ...props }) => {

  const [actions, setActions] = useState([]);

  const classifications = ClassificationSelectField();
  const subClassifications = SubClassificationSelectField(props.clasificationId);
  const availableProductsKeys = BillingKeysAutocomplete('productServ', 'productsKeys', 'descripcion');
  const availableunitKeys = BillingKeysAutocomplete('unit', 'unitKeys', 'nombre');

  useEffect(() => {

    const tmpAction = [];

    if(privileges.products && privileges.products.prices){ 
      tmpAction.push({ icon: <AttachMoneyIcon fontSize="small" />, label: "Precios", handle: handleOpenPrices });
    }

    if(privileges.spareProducts && privileges.spareProducts.read){ 
      tmpAction.push({ icon: <ExtensionIcon fontSize="small" />, label: "Ensamble", handle: redirect });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const products = useMemo(() => 
    props.products.count > 0 ? props.products.rows.map(el => ({
      ID: { 
        value: el.id,
        data: {
          ...el,
          type: el.type.id,
          clasificationId: el.Clasification && el.Clasification.id,
          subClasificationId: el.SubClasification && el.SubClasification.id,
          min: el.min
        }
      }, 
      Nombre: {
       value: el.name,
       filter: true,
       _sort: 'text'
      },
      Tipo: {
        value: el.type.label ? el.type.label : "Indefinido",
        align: "center",
       _sort: 'text'
      },
      'Clave P/S': {
        value: el.productKey ? el.productKey : "",
        align: "center",
       _sort: 'text'
      },
      'Unidad Medida': {
        value: el.unitKey ? el.unitKey : "",
        align: "center",
       _sort: 'text'
      },
      "Cantidad Mínima":{
        value: el.min,
        align: "center",
        _sort: 'number'
      },
      Clasificación: {
        value: `${el.Clasification && el.Clasification.name} (${el.SubClasification && el.SubClasification.name})`,
       _sort: 'text'
      },
      "Precio Venta": {
        value: el.prices ? !isNaN(el.prices.salePrice) ? moneyFormat(el.prices.salePrice) : '0.00' : '0.00',
        alt: el.prices ? !isNaN(el.prices.salePrice) ? parseFloat(parseFloat(el.prices.salePrice).toFixed(2)) : 0 : 0,
        align: "right",
        _sort: 'alt'
      },
      "Costo": {
        value: el.prices ? (el.prices.productionCost ? !isNaN(el.prices.productionCost) ? moneyFormat(el.prices.productionCost) : '0.00' : '0.00'): '0.00',
        alt: el.prices ? (el.prices.productionCost ? !isNaN(el.prices.productionCost) ? parseFloat(parseFloat(el.prices.productionCost).toFixed(2)) : 0 : 0): 0,
        align: "right",
        _sort: 'alt'
      }

    })) : []
  , [props.products]);

  const handleOpenPrices = e => {
    const { prices, id } = JSON.parse(e.currentTarget.dataset.row);
    const { salePrice, productionCost, rentPrice } = prices;
    const rentPrices = {};
    rentPrice && rentPrice.map(el => (rentPrices[el.method] = el.price));
    editValueAction({ salePrice, productionCost, ...rentPrices, id });
		openFormDialog('Prices');
  }

  const redirect = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, table: 'spareProducts', returnTo: '/Product', returnToLabel: 'Productos'});
    history.push('/Product/SpareProduct');
  };

  return (
    <Fragment>
      <Module
        table="products"
        name="productos"
        singularName="producto"
        filter={false}
        title="Crear Producto"
        records={products}
        download={true}
        actions={actions}
      >
        <TextField
          icon="loyalty"
          fields={[{
            id: "name",
            label: "Nombre",
            autoFocus: true
          }]}
        />
        <TextField
          fields={[{
            id: "barCode",
            label: "Código de Barras",
            required: false
          }]}
        />
        <TextField
          icon={<ImportExportIcon />}
          fields={[{
            id: "min",
            label: "Cantidad Mínima",
          }]}
        />
        <SelectField
          icon="category"
          fields={[{
            id: "type",
            label: "Tipo",
            options: [
              { key: 1, value: "Producto Final" },
              { key: 2, value: "Refacción" },
              { key: 4, value: "Componente" },
              { key: 3, value: "Servicio" },
              { key: 5, value: "Producto" }
            ],
          }]}
        />
        <SelectField
          fields={[{
            id: 'clasificationId',
            label: 'Clasificación',
            options: classifications,
          }
          ]}
        />
        <SelectField
          fields={[{
            id: 'subClasificationId',
            label: 'Subclasificación',
            options: subClassifications,
          }
          ]}
        />
        <Autocomplete 
          label="Clave Productos y Servicios(SAT)" 
          _id="productKey" 
          options={ availableProductsKeys }
        />
        <Autocomplete
          label="Unidad de Medida (SAT)" 
          _id="unitKey" 
          options={ availableunitKeys }
        />
      
      </Module>
      <Prices />
    </Fragment>
	);
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  clasificationId: state.field.value.clasificationId || 0,
  products: state.records.products || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
