import React from 'react';

import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = props => 
	<MuiAlert elevation={6} variant="filled" {...props} />

const Snackbar = props => {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    props.handleClose();
  };

	return (
		<MuiSnackbar 
      open={ props.isOpen } 
      autoHideDuration={ props.time || null } 
      anchorOrigin={{ vertical: props.vertical || 'bottom', horizontal: props.horizontal || 'center' }} 
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={props.severity || 'success'}>
        { props.label }
      </Alert>
    </MuiSnackbar>
	);
}

export default Snackbar;