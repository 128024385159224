import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';


import { FormDialog } from '../../_presentationals/FormDialog';

import { SelectField, TextField, DateField } from './../../_presentationals/Form';

import { recordsActions } from './../../_actions';

const CollectionLogistic = ({getRecords, ...props}) => {
  const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));

  useEffect(() => { 
    getRecords({ table: 'countries' });
  }, [getRecords]);

  const countries = useMemo(() => {
    return props.countries.count > 0 ? props.countries.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.countries]);

  useEffect(() => { 
    getRecords({ table: 'states', countryId: props.countryId });

  }, [getRecords, props.countryId]);

  const states = useMemo(() => {
    return props.states.count > 0 ? props.states.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.states]);

  return (
    <FormDialog 
      title="Recolección"
      name="collectionLogistic"
      singular="CollectionLogistic"
      table="logisticIndependent"
    >
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1",
            label: "Calle y Número",
            multiline: true
          },
          {
            id: "addressLine2",
            label: "Colonia",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city",
            label: "Ciudad",
            required: false
          },
          {
            id: "zipCode",
            label: "Código Postal",
            required: false
          }
        ]}
      />
      <SelectField
        fields={[{
          id: 'countryId', 
          label: 'País',
          options: countries,
          required: false
        }]}
      />
      <SelectField
        fields={[{
          id: 'stateId',
          label: 'Estado',
          options: states,
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "reference",
          label: "Referencia",
          multiline: true,
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "receptionName",
          label: "Nombre de quien recibe",
          required: false
        }]}
      />
       <DateField
        id="collectionDate"
        label="Fecha de recolección"
        icon="today"
        disablePast={true}
      />
      <SelectField
        fields={[{
          id: 'collectionTime', 
          label: 'Hora de recolección',
          options: serviceTime
        }]}
      />
      <TextField
        fields={[{
          id: "collectionCommentary",
          label: "Comentario",
          required: false
        }]}
      />
    </FormDialog>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  countries: state.records.countries || defaultTableValue,
  states: state.records.states || defaultTableValue,
  countryId: state.field.value.countryId || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionLogistic);