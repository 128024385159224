const api = ['manufactureId','deliveryTime','deliveryDate','deliveryCommentary','collectionDate'];

const sampleManufactures = (method, values) => {
  const tmp = {};
  switch (method) {
  	case 'POST':
  		Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return { ...tmp, collectionDate: tmp.collectionDate === '' ? null : tmp.collectionDate };
    default:
      return [];
  }
}

export default sampleManufactures;
