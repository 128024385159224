import React, { Fragment, useState, useEffect } from 'react';

import Divider from '@material-ui/core/Divider';

import Form from './Form';
import Table from './Table';

const Cart = props => {

	const [ cartItems, setCartItems ] = useState([]);

	useEffect(() => {
    setCartItems([]);
  }, [props.codeToClear]);
 
	const addItemToCart = data => {
		const iva = (props.isCourtesy || props.isLend) ? 0 : parseFloat(data.price) / (1 + (parseFloat(props.iva) / 100)) * (parseFloat(props.iva) / 100);
		const price = (props.isCourtesy || props.isLend) ? 0 : parseFloat(data.price) - iva;
		const amount = (props.isCourtesy || props.isLend) ? 0 : parseFloat(data.qty) * price;
		const tmpDiscount = (props.isCourtesy || props.isLend) ? 0 : amount * (parseFloat(data.discount) / 100);
		setCartItems(cartItems => ([ ...cartItems, { ...data, amount, price, tmpDiscount } ]));
		props.getCart([ ...cartItems, { ...data, amount, price, tmpDiscount } ]);
	};

	const removeItemFromCart = position => {
		const tmpCartItems = cartItems
			.map((el, i) => (parseInt(i) !== parseInt(position)) && el)
			.filter(el => el);
		setCartItems(tmpCartItems);
		props.getCart(tmpCartItems);
	}

	const updateItemFromCart = (data) => {
		const months = parseInt(data.days) > 1 ? parseInt(data.days) / 30 : 1;
		
		const tmpCartItems = cartItems.map((el, i) => {
			if(parseInt(i) !== parseInt(data.position)) { return el; }

			const tmpPrice = (props.isCourtesy || props.isLend)
				? 0 
				: parseInt(props.rentPlanPaymentMethod) === 2 && parseInt(data.methodProduct) === 2 
					?	parseFloat(data.pricePlan) * months
					: parseInt(data.methodProduct) === 2 
						? parseFloat(data.pricePlan) 
						: data.price 
							? parseFloat(data.price) 
							: parseFloat(el.price);

			const iva = (props.isCourtesy || props.isLend) ? 0 : parseFloat(tmpPrice) / (1 + (parseFloat(props.iva) / 100)) * (parseFloat(props.iva) / 100);
			const price = (props.isCourtesy || props.isLend) ? 0 : parseFloat(tmpPrice) - iva;
			const amount = (props.isCourtesy || props.isLend) ? 0 : parseFloat(el.qty) * parseFloat(price);
			const tmpDiscount = (props.isCourtesy || props.isLend) ? 0 : amount * (parseFloat(el.discount) / 100);			

			return { 
				...el,
				product: {
					...el.product,
					name: data.manufactureId ? data.manufactureId.label : el.product.name
				},
				rentPlan: parseInt(data.methodProduct) === 2 && data.rentPlan,
				manufactureId: data.manufactureId && data.manufactureId.value,
				planQuantity: parseInt(data.methodProduct) === 2 && data.planQuantity,
				amount, 
				price, 
				tmpDiscount,
				config: data
			};
		});
		setCartItems(tmpCartItems);
		props.getCart(tmpCartItems);
	}

	return (
		<Fragment>
			<Form handleAdd={addItemToCart} codeToClear={props.codeToClear} isCourtesy={props.isCourtesy} isLend={props.isLend} />
			<Divider />
			<Table 
				iva={(props.isCourtesy || props.isLend) ? 0 : props.iva} 
				currency={(props.isCourtesy || props.isLend) ? '' : props.currency} 
				cartItems={cartItems} 
				removeItem={removeItemFromCart}
				updateItem={updateItemFromCart}
				deliveryDate={props.deliveryDate}
				mobility={props.mobility}
				codeToClear={props.codeToClear}
				isCourtesy={props.isCourtesy}
				isLend={props.isLend}
			/>
		</Fragment>
	);
}

export default Cart