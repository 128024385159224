const samplesManufactures = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
      return `samples/manufactures/${props._id}`;
    case 'POST':
      return `samples/${props.redirectId}/manufactures/`
    default:
      return `samples/${props.redirectId}/manufactures`;
  }
}

export default samplesManufactures;