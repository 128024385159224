import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { resetFormAction, alertActions } from './../../_actions';

const mapDispatchToProps = dispatch => ({
  resetFormAction: () => dispatch(resetFormAction()),
  clearAlert: () => dispatch(alertActions.clear())
});

export const Authenticated = connect(null, mapDispatchToProps)(({ component: Component, resetFormAction, clearAlert, ...rest }) => {
	useEffect(() => {
		resetFormAction();
		clearAlert();
	})
	
	return localStorage.getItem('session') 
		? <Route {...rest} render={props => <Component {...props} />} />
		: window.location.replace("/public/signIn")
});