import React, { useEffect, useState, Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField, SelectField, AutocompleteField } from '../../_presentationals/Form';
import { editValueAction, recordsActions } from '../../_actions';
import { ListItemAdd } from './listItemAdd';

import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

const availableMethods = [
  {key: "01", value: "Efectivo"},
  {key: "02", value: "Cheque nominativo"},
  {key: "03", value: "Transferencia electrónica de fondos"},
  {key: "04", value: "Tarjeta de crédito"},
  {key: "05", value: "Monedero electrónico"},
  {key: "06", value: "Dinero electrónico"},
  {key: "15", value: "Condonación"},
  {key: "17", value: "Compensación"},
  {key: "23", value: "Novación"},
  {key: "28", value: "Tarjeta de débito"},
  {key: "30", value: "Aplicación de anticipos"},
  {key: "31", value: "Intermediario pagos"},
  {key: "99", value: "Por definir"}
];

const aviableUseCdfi = [
  {
    "key": "G01",
    "value": "Adquisición de mercancias",  
  },
  {
    "key": "G02",
    "value": "Devoluciones, descuentos o bonificaciones"
  },
  {
    "key": "G03",
    "value": "Gastos en general"
  },
  {
    "key": "I01",
    "value": "Construcciones"
  },
  {
    "key": "I02",
    "value": "Mobilario y equipo de oficina por inversiones"
  },
  {
    "key": "I03",
    "value": "Equipo de transporte"
  },
  {
    "key": "I04",
    "value": "Equipo de computo y accesorios",
  },
  {
    "key": "I05",
    "value": "Dados, troqueles, moldes, matrices y herramental"
  },
  {
    "key": "I06",
    "value": "Comunicaciones telefónicas"
  },
  {
    "key": "I07",
    "value": "Comunicaciones satelitales"
  },
  {
    "key": "I08",
    "value": "Otra maquinaria y equipo"
  },
  {
    "key": "D01",
    "value": "Honorarios médicos, dentales y gastos hospitalarios."
  },
  {
    "key": "D02",
    "value": "Gastos médicos por incapacidad o discapacidad"
  },
  {
    "key": "D03",
    "value": "Gastos funerales."
  },
  {
    "key": "D04",
    "value": "Donativos."
  },
  {
    "key": "D05",
    "value": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)."
  },
  {
    "key": "D06",
    "value": "Aportaciones voluntarias al SAR."
  },
  {
    "key": "D07",
    "value": "Primas por seguros de gastos médicos."
  },
  {
    "key": "D08",
    "value": "Gastos de transportación escolar obligatoria."
  },
  {
    "key": "D09",
    "value": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones."
  },
  {
    "key": "D10",
    "value": "Pagos por servicios educativos (colegiaturas)"
  },
  {
    "key": "P01",
    "value": "Por definir"
  },
  {
    "key": "S01",
    "value": "Sin efectos fiscales (Usar con público en general)"
  },
];

const Dialog = ({ getRecords, editValueAction, ...props }) => {
  const [concepts, setConcepts] = useState([]);
  const [qty, setQty] = useState(0);

  useEffect(() => {
    getRecords({ table: 'customers' }); //1
  }, [getRecords, editValueAction]);

  const customers = useMemo(() => (
    props.customers.count > 0 ? props.customers.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName} ${el.CustomerPhones[0].phone}`, customerDiscount: el.discountRange, address: el.CustomerAddress, billingInformationId: (el.BillingInformation && el.BillingInformation.id) ? el.BillingInformation.id : 0 })) : []
  ), [props.customers]);

  const handleAddProduct = e => {
    const { quantity, discount, price, concept, unitKey, productKey } = props.conceptFields;
    if(concept === '' || unitKey === '' || productKey === '') return;
    if (isNaN(discount) || isNaN(quantity) || isNaN(quantity)) return;
    if (parseInt(discount) < 0 || parseInt(quantity) < 0 || parseInt(price) < 0) return;

    setConcepts(concepts => ([ ...concepts, { quantity, discount, price, concept, unitKey, productKey } ]));
    editValueAction({
      quantity: 1, discount: 0, price: 0, concept: '', unitKey: 'H87', productKey: '40101703',
      Concepts: [ ...concepts, { quantity, discount, price, concept, unitKey, productKey } ]
    });
    setQty(JSON.stringify(concepts));
  };

  useEffect( () => {
    if(props.alert.type === 'success') {
      editValueAction({ 
        iva: '', commentary: '', useCdfi: '', paymentMethod: '', paymentForm: '',
        saleId: null, customerId: null, quantity: 1, discount: 0, price: 0, concept: '',
        unitKey: '', productKey: ''
      });
      setConcepts([]);
    }
    editValueAction({ quantity: 1, discount: 0, price: 0, unitKey: 'H87', productKey: '40101703' });
  }, [props.alert, props.isOpen, editValueAction]);

  const tableRows = useMemo(() => {    
    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete concepts[index];
      const tmp = concepts.filter(el => el);
      setQty(JSON.stringify(tmp));
      setConcepts(tmp);
    };
    
    return concepts.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell>{ el.concept }</TableCell>
        <TableCell>{ `${el.unitKey} | ${el.productKey}` }</TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell align="right">{el.discount}</TableCell>
        <TableCell align="right">{el.price}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concepts, qty]);


	return (
	  <FormDialog 
      table="customizedInvoices"
      singular="conceptsDialog" 
      title="Conceptos"
      widthDialog='md'
    >
      <AutocompleteField 
        label="Cliente" 
        _id="customerId" 
        options={customers}
        dialog={false}
        noFormat
      />
      <SelectField
        fields={[{
          id: "iva",
          label: "IVA",
          options: [{ key: 16, value: '16%' }, { key: 8, value: '8%' }],
        }]}
        dialog={false}
      />
      <SelectField
        fields={[{
          id: "paymentMethodInvoice",
          label: "Método de Pago",
          options: [{ key: 1, value: 'PUE' },{ key: 2, value: 'PPD' }],
        }]}
        dialog={false}
      />
      <SelectField
        fields={[{
          id: "paymentForm",
          label: "Forma de pago",
          options: availableMethods,
        }]}
        dialog={false}
      />
      <SelectField
        fields={[{
          id: "useCdfi",
          label: "Uso de CDFI",
          options: aviableUseCdfi,
        }]}
        dialog={false}
      />
      <TextField
        fields={[{
          id: "commentary",
          label: "Comantario",
        }]}
        dialog={false}
        noFormat
      />
      <Fragment>
        <ListItemAdd handleClick={handleAddProduct}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fields={[{
                  id: "concept",
                  label: "Concepto",
                  required: true,
                  variant: "outlined",
                  multiline: true
                }]}
                dialog={false}
                noFormat
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                dialog={false}
                fields={[{
                  id: "quantity", label: "Cant.", type: 'number',
                  variant: "outlined", margin: "dense", grid: 4
                },{
                  id: "discount", label: "Desc.", type: 'number',
                  variant: "outlined", margin: "dense", grid: 4
                },{
                  id: "price", label: "Precio", type: 'number',
                  variant: "outlined", margin: "dense", grid: 4, 
                }]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                dialog={false}
                fields={[{
                  id: "unitKey", label: "Clave de Unidad",
                  variant: "outlined", margin: "dense", grid: 6
                },{
                  id: "productKey", label: "Clave de Producto o Servicio",
                  variant: "outlined", margin: "dense", grid: 6
                }]}
              />
            </Grid>
          </Grid>
        </ListItemAdd>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Concepto</TableCell>
              <TableCell>Clave</TableCell>
              <TableCell align="center">Cant.</TableCell>
              <TableCell align="right">Dscto.</TableCell>
              <TableCell align="right">Precio Unitario</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </Fragment>
	  </FormDialog>
	)
};

const defaultTableValue = {count: 0, rows: []};
const mapStateToProps = state => ({
  type: state.field.value.type || 0,
  saleId: state.field.value.saleId || null,
  customerId: state.field.value.customerId || null,
  isOpen: state.formDialog,
  alert: state.alert,
  conceptFields: {
    quantity: state.field.value.quantity || 1, 
    discount: state.field.value.discount || 0,
    price: state.field.value.price || 0,
    concept: state.field.value.concept || '',
    unitKey: state.field.value.unitKey || '',
    productKey: state.field.value.productKey || '',
  },
  customers: state.records.customers || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default (connect(mapStateToProps, mapDispatchToProps)(Dialog));