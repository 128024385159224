import { userConstants } from '../_constants';
import { userServices } from '../_services';
import { alertActions, validateFieldAction } from '../_actions';

const createNoAuth = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));

	const { password, confirmPassword } = getState().field.value;
	
	if(password !== confirmPassword){
		dispatch(validateFieldAction({confirmPassword: { isError: true, message: "Estas contraseñas no coinciden."}}));
		return;
	}

	userServices.createNoAuth(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		window.location.reload(true);
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const createAddress = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.createAddress(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Domicilio almacenado correctamente"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const createPhone = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.createPhone(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Teléfono almacenado correctamente"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const creteBillingInformation = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.creteBillingInformation(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Datos de facturación almacenados correctamente"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const verifyCode = () => (dispatch, getState) => {

	dispatch(alertActions.pending("Verificando..."));

	const session = JSON.parse(localStorage.getItem('session'));
  const id = session ? session.account : false;
	const { code } = getState().field.value;

	if(!id){
		localStorage.removeItem('session');
		window.location.reload(true);
	}

	if(code === ""){
		dispatch(validateFieldAction({code: { isError: true, message: "Este campo es obligatorio"}}));
		dispatch(alertActions.error());
		return;
	}

	userServices.verifyCode({ id, code })
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			dispatch(validateFieldAction({code: { isError: true, message: JSON.parse(errorMessage)[0].message}}));
			dispatch(alertActions.error());
			return;
		}
		window.location.reload(true);
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const verifyUsername = () => (dispatch, getState) => {
	const { username } = getState().field.value;
	userServices.verifyUsername({ username })
	.then(data => {
		if(data.errorCode){
			dispatch(validateFieldAction({username: { isError: true, message: data.errorMessage}}));
			return;
		}
		dispatch(validateFieldAction({ readyToSMS: true }));
	}).catch(e => console.log(e));
}

const getById = () => dispatch => {
	userServices.getById()
	.then(data => {
		const user = {
			username: data.username,
			firstName: data.firstName,
			lastName: data.lastName
		};
		dispatch(success(userConstants.GETBYID_SUCCESS, user));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const editName = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.editName(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Datos Actualizados"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const editProfile = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.editProfile(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Datos Actualizados"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const editPhone = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.editPhone(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Datos Actualizados"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const editBillingInformation = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.editBillingInformation(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Datos Actualizados"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const editAddress = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Guardando..."));
	userServices.editAddress(getState().field.value)
	.then(({ errorCode, errorMessage }) => {
		if(errorCode){
			const fields = [];
			JSON.parse(errorMessage).forEach(({ name, message }) => {
				fields[name] = { isError: true, message };
			});
			dispatch(alertActions.error());
			dispatch(validateFieldAction(fields))
			return;
		}
		dispatch(alertActions.success("Datos Actualizados"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")));
};

const forgotPasswordByPhone = () => (dispatch, getState) => {
	dispatch(alertActions.pending("Actualizando..."));

	userServices.forgotPasswordByPhone(getState().field.value)
	.then(data => {
		console.log(data);
		// if(!data.isValid){
		// 	dispatch(validateFieldAction({newPassword: {isError: true, message: "Este campo es obligatorio"}}));
		// 	dispatch(validateFieldAction({confirmationPassword: {isError: true, message: "Este campo es obligatorio"}}));
		// 	dispatch(alertActions.error());
		// 	return;
		// }
		// dispatch(alertActions.success("¡Contraseña actualizada!"));
	})
	.catch(error => dispatch(alertActions.error("Error inesperado. Contacta a soporte técnico")))
}

const success = (type, user) => ({type: type, payload: user});

export const userActions = {
	createNoAuth,
	createPhone,
	createAddress,
	creteBillingInformation,
	verifyCode,
	getById,
	editName,
	editProfile,
	editPhone,
	editAddress,
	editBillingInformation,
	verifyUsername,
	forgotPasswordByPhone
};
