const tools = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
  	case 'PUT':
  		return `tools/${props._id}`;
    default:
      return 'tools';
  }
}

export default tools;