import React from "react";
import { Route, Switch } from "react-router-dom";
import { Authenticated } from "./_presentationals/Routes";
import { Unauthenticated } from "./_presentationals/Routes";

import HomeRoute from "./_containers/Home";
import signInRoute from "./_containers/Login";
import forgotPasswordByEmail from "./_containers/Login/forgotPassword";
import MyAccount from "./_containers/MyAccount";
import Preferences from "./_containers/Preferences";
import NotFound from "./_containers/NotFound";

import Branch from "./_containers/Branch";
import Product from "./_containers/Product";
import Inventory from "./_containers/Inventory"
import Clasification from "./_containers/Clasification";
import User from "./_containers/User";
import Country from "./_containers/Country";
import State from "./_containers/State";
import Customer from "./_containers/Customer";
import CustomerGroup from "./_containers/CustomerGroup";
import SubClasification from "./_containers/SubClasification";
import Employee from "./_containers/Employee";

import Manufacture from './_containers/Manufacture';
import RentedManufacture from './_containers/RentedManufacture';
import SoldManufacture from './_containers/SoldManufacture';

import Sales from './_containers/Sales';
import CreditSales from './_containers/CreditSales';
import AccountsReceivable from './_containers/AccountsReceivable';
import Payment from './_containers/Payment';
import SpareProduct from './_containers/SpareProduct';
import SalesReport from './_containers/SalesReport';
import Quotation from './_containers/Quotation';
import SalesNoteWithoutInvoice from './_containers/SalesNoteWithoutInvoice';
import CashClosing from './_containers/CashClosing';
import QuotationReport from './_containers/QuotationReport';
import Invoices from './_containers/Invoices';
import CreditNotesReport from './_containers/CreditNotesReport';
import Commission from './_containers/Commission';
import Logistic from './_containers/Logistic';
import LogisticSale from './_containers/LogisticSale';
import LogisticProduct from './_containers/LogisticProduct';
import Warranty from './_containers/Warranty';
import Expense from './_containers/Expense';
import Supplier from './_containers/Supplier';
import Vehicle from './_containers/Vehicle';
import VehicleExpense from './_containers/VehicleExpense';
import PaymentReport from './_containers/PaymentReport';

import LogisticCollectionProduct from './_containers/LogisticCollectionProduct';
import LogisticCollection from './_containers/LogisticCollection';
import LogisticCollectionSale from './_containers/LogisticCollectionSale';

import LogisticFailureProduct from './_containers/LogisticFailureProduct';
import LogisticFailure from './_containers/LogisticFailure';
import LogisticFailureSale from './_containers/LogisticFailureSale';

import LogisticReportProduct from './_containers/LogisticReportProduct';
import LogisticReport from './_containers/LogisticReport';
import LogisticReportSale from './_containers/LogisticReportSale';
import RentReport from './_containers/RentReport';
import IndependentPayment from './_containers/IndependentPayment';
import Failure from './_containers/Failure';
import FailureReport from './_containers/FailureReport';
import Courtesy from './_containers/Courtesy';
import CourtesiesReport from './_containers/CourtesiesReport';
import LogisticCourtesy from './_containers/LogisticCourtesy';
import Consignment from './_containers/Consignment';
import AllManufacture from './_containers/AllManufacture';
import ProductManufacture from './_containers/ProductManufacture';
import StatusManufacture from './_containers/StatusManufacture';
import ConsignmentManufacture from './_containers/ConsignmentManufacture';
import Tool from './_containers/Tool';
import LogisticIndependent from './_containers/LogisticIndependent';
import Compound from './_containers/Compound';
import ConsignedManufacture from './_containers/ConsignedManufacture';
import WarrantyNote from './_containers/WarrantyNote';
import VehicleFailure from './_containers/VehicleFailure';
import ExpenseReport from './_containers/ExpenseReport';
import ExpenseProductsReport from './_containers/ExpenseProductsReport';
import CatchmentsReport from './_containers/CatchmentsReport';
import Catchment from './_containers/Catchment';
import PhysicalInventories from './_containers/PhysicalInventories';
import Migration from './_containers/Migration';
import ManufactureSale from './_containers/ManufactureSale';
import ManufactureRent from './_containers/ManufactureRent';
import SalesActive from './_containers/SalesActive';
import LogisticReplace from './_containers/LogisticReplace';
import RentPlans from './_containers/RentPlansReport';
import RentActives from './_containers/RentActives';
import Sample from './_containers/Sample';
import SampleManufacture from './_containers/SampleManufacture';
import CustomizedInvoice from './_containers/CustomizedInvoice';
import SaleAuth from './_containers/SaleAuth';
import SaleAuthReport from './_containers/SaleAuthReport';
import PendingSale from './_containers/PendingSale';
import Expired from './_containers/Expired';

import SalesV2 from './_containers/Sales.v2';
import LogisticV2 from './_containers/Logistic.v2';
import LogisticCollectionV2 from './_containers/LogisticCollection.v2';
import ReplaceV2 from './_containers/Replace.v2';
import SaleAuthV2 from './_containers/SaleAuth.v2';
import ReplaceAuth from './_containers/ReplaceAuth';
import FailureV2 from './_containers/Failure.v2';
import ServiceOrder from './_containers/ServiceOrder';
import InternalServiceOrder from './_containers/InternalServiceOrder';
import ServiceOrderClosed from './_containers/ServiceOrderClosed';
import receivablesV2 from './_containers/Receivable.v2';
import ReportInvoicesV2 from './_containers/Report.Invoices.v2';
import ReportInvoiceDetail from './_containers/Report.InvoiceDetail';
import cashClosingsV2 from './_containers/CashClosing.v2';
import ReportServiceOrder from './_containers/Report.ServiceOrder';
import PendingSalesV2 from './_containers/PendingSalesV2';
import CommissionV2 from './_containers/Commission.v2';
import ReportPaymentsV2 from './_containers/Report.Payments.v2';
import ReportSalesReviewV2 from './_containers/Report.Invoices.Review.v2';
import ReportLogisticCompleted from './_containers/Report.LogisticCompleted.v2';
import CourtesyAuth from './_containers/CourtesyAuth';
import LendAuth from './_containers/LendAuth';
import ManufactureV2 from './_containers/Manufacture.v2';
import ManufactureRentV2 from './_containers/ManufactureRent.v2';
import ManufactureSaleV2 from './_containers/ManufactureSale.v2';
import ServiceOrderAuth from './_containers/ServiceOrderAuth';
import ManufactureRepairV2 from './_containers/ManufactureRepair.v2';
import ManufactureSamplesV2 from './_containers/ManufactureSamples.v2';
import ManufactureConsignmentV2 from './_containers/ManufactureConsignment.v2';
import ManufactureServiceV2 from './_containers/ManufactureService.v2';
import QuotationV2 from './_containers/Quotation.v2';
import CourtesyV2 from './_containers/Courtesy.v2';
import Borrower from './_containers/Borrower';
import Lend from './_containers/Lend';
import QuotationReportV2 from './_containers/Report.Quotation.v2';
import CourtesyReportV2 from './_containers/Report.Courtesy.v2';
import ConsignmentReportV2 from './_containers/Report.Consignment.v2';
import SampleReportV2 from './_containers/Report.Sample.v2';
import ManufactureSaleReport from './_containers/Report.ManufactureSale';

const session = JSON.parse(localStorage.getItem('session'));
const isMaster = session ? Boolean(session.data.master) : false;

const Routes = () =>
  <Switch>
    <Authenticated path="/" exact component={HomeRoute} />
     <Unauthenticated path="/public/signIn" component={signInRoute} />
    <Unauthenticated path="/public/forgotPassword/email" component={forgotPasswordByEmail} />
    <Authenticated path="/Branch" component={Branch} />
    <Authenticated path="/Product" exact component={Product} />
    <Authenticated path="/Inventory" component={Inventory} />
    <Authenticated path="/Product/SpareProduct" component={SpareProduct} />
    <Authenticated path="/Clasification" exact component={Clasification} />
    <Authenticated path="/Clasification/SubClasification" component={SubClasification} />
	<Authenticated path="/User" component={User} />
    <Authenticated path="/MyAccount" component={MyAccount} />
    <Authenticated path="/Preferences" component={Preferences} />

    { (isMaster || !session) && <Authenticated path="/Country" exact component={Country} /> }
    { (isMaster || !session) && <Authenticated path="/Country/State" component={State} /> }

    <Authenticated path="/Customer" exact component={Customer} />
    <Authenticated path="/Customer/Group" component={CustomerGroup} />
    
    <Authenticated path="/Manufacture" exact component={Manufacture} />
    <Authenticated path="/Manufacture/Rent" component={ManufactureRent} />
    <Authenticated path="/Manufacture/Sale" component={ManufactureSale} />
    <Authenticated path="/Manufacture/Consigned" component={ConsignedManufacture} />
    <Authenticated path="/Manufacture/Rented" component={RentedManufacture} />
    <Authenticated path="/Manufacture/Sold" component={SoldManufacture} />
    <Authenticated path="/Manufacture/All" component={AllManufacture} />
    <Authenticated path="/Manufacture/Product" component={ProductManufacture} />
    <Authenticated path="/Manufacture/Status" component={StatusManufacture} />

    <Authenticated path="/Employee" component={Employee} />
    <Authenticated path="/Sales" component={Sales} />
    <Authenticated path="/SalesReport" component={SalesReport} />
    <Authenticated path="/AccountsReceivable" exact component={AccountsReceivable} />
    <Authenticated path="/AccountsReceivable/Payment" component={Payment} />
    <Authenticated path="/Quotation" component={Quotation} />
    <Authenticated path="/SalesNoteWithoutInvoice" component={SalesNoteWithoutInvoice} />
    <Authenticated path="/CashClosing" component={CashClosing} />
    <Authenticated path="/CreditSales" component={CreditSales} />
    <Authenticated path="/QuotationReport" component={QuotationReport} />
    <Authenticated path="/Invoices/Customized" component={CustomizedInvoice} />
    <Authenticated path="/Invoices" component={Invoices} />
    <Authenticated path="/CreditNotesReport" component={CreditNotesReport} />
    <Authenticated path="/Commission" component={Commission} />

    <Authenticated path="/Logistic/Report/Deliveries/Products" component={LogisticReportProduct} />
    <Authenticated path="/Logistic/Report/Deliveries" component={LogisticReport} />
    <Authenticated path="/Logistic/Report" component={LogisticReportSale} />

    <Authenticated path="/Logistic/Failure/Deliveries/Products" component={LogisticFailureProduct} />
    <Authenticated path="/Logistic/Failure/Deliveries" component={LogisticFailure} />
    <Authenticated path="/Logistic/Failure" component={LogisticFailureSale} />

    <Authenticated path="/Logistic/Collection/Deliveries/Products" component={LogisticCollectionProduct} />
    <Authenticated path="/Logistic/Collection/Deliveries" component={LogisticCollection} />
    <Authenticated path="/Logistic/Collection" component={LogisticCollectionSale} />

    <Authenticated path="/Logistic/Courtesy/Deliveries" component={LogisticCourtesy} />
    <Authenticated path="/Logistic/Deliveries/Products" component={LogisticProduct} />
    <Authenticated path="/Logistic/Deliveries" component={LogisticSale} />
    <Authenticated path="/Logistic/Independent" component={LogisticIndependent} />
    <Authenticated path="/Logistic/Replace" component={LogisticReplace} />
    <Authenticated path="/Logistic" component={Logistic} />

    <Authenticated path="/Warranty" component={Warranty} />
    <Authenticated path="/Supplier" component={Supplier} />
    <Authenticated path="/Expense/Report" component={ExpenseReport} />
    <Authenticated path="/Expense/Product" component={ExpenseProductsReport} />
    <Authenticated path="/Expense" component={Expense} />
    <Authenticated path="/Vehicle/Failure" component={VehicleFailure} />
    <Authenticated path="/Vehicle/Expense" component={VehicleExpense} />
    <Authenticated path="/Vehicle" component={Vehicle} />
    <Authenticated path="/PaymentReport" component={PaymentReport} />
    <Authenticated path="/RentReport" component={RentReport} />
    
    <Authenticated path="/IndependentPayment" component={IndependentPayment} />
    <Authenticated path="/Failure/Report" component={FailureReport} />
    <Authenticated path="/Failure" component={Failure} />
    <Authenticated path="/Courtesy" component={Courtesy} />
    <Authenticated path="/CourtesiesReport" component={CourtesiesReport} />
    <Authenticated path="/Consignment/Manufacture" component={ConsignmentManufacture} />
    <Authenticated path="/Consignment" component={Consignment} />
    <Authenticated path="/Tool" component={Tool} />
    <Authenticated path="/Compound" component={Compound} />
    <Authenticated path="/WarrantyNote" component={WarrantyNote} />
    <Authenticated path="/Catchment/Report" component={CatchmentsReport} />
    <Authenticated path="/Catchment" component={Catchment} />
    <Authenticated path="/PhysicalInventory" component={PhysicalInventories} />
    <Authenticated path="/Migration" component={Migration} />
    <Authenticated path="/SalesActive" component={SalesActive} />
    <Authenticated path="/RentPlans/Report" component={RentPlans} />
    <Authenticated path="/RentActives/Report" component={RentActives} />
    <Authenticated path="/Sample/Manufacture" component={SampleManufacture} />
    <Authenticated path="/Sample" component={Sample} />
    <Authenticated path="/Sale/Auth/Report" component={SaleAuthReport} />
    <Authenticated path="/Sale/Expired" component={Expired} />
    <Authenticated path="/Sale/Auth" component={SaleAuth} />
    <Authenticated path="/Sale/Pending" component={PendingSale} />
    
    <Authenticated path="/v2/Sale/Auth" component={SaleAuthV2} />
    <Authenticated path="/v2/Sale/" component={SalesV2} />
    <Authenticated path="/v2/Logistic/Replace/Auth" component={ReplaceAuth} />
    <Authenticated path="/v2/Logistic/Replace" component={ReplaceV2} />
    <Authenticated path="/v2/Logistic/Collection" component={LogisticCollectionV2} />
    <Authenticated path="/v2/Logistic/" component={LogisticV2} />
    <Authenticated path="/v2/Failure/" component={FailureV2} />
    <Authenticated path="/serviceOrder/closed" component={ServiceOrderClosed} />
    <Authenticated path="/serviceOrder/auth" component={ServiceOrderAuth} />
    <Authenticated path="/serviceOrder/" component={ServiceOrder} />
    <Authenticated path="/internalServiceOrder/" component={InternalServiceOrder} />
    <Authenticated path="/v2/Receivables/" component={receivablesV2} />
    <Authenticated path="/v2/CashClosing" component={cashClosingsV2} />
    
    <Authenticated path="/v2/Report/Sale/Manufacture" component={ManufactureSaleReport} />
    <Authenticated path="/v2/Report/Logistic/Completed" component={ReportLogisticCompleted} />
    <Authenticated path="/v2/Report/Invoice/Review" component={ReportSalesReviewV2} />
    <Authenticated path="/v2/Report/Invoice/Details" component={ReportInvoiceDetail} />
    <Authenticated path="/v2/Report/Invoice" component={ReportInvoicesV2} />
    <Authenticated path="/v2/Report/Consignment" component={ConsignmentReportV2} />
    <Authenticated path="/v2/Report/Sample" component={SampleReportV2} />
    <Authenticated path="/v2/Report/ServiceOrder" component={ReportServiceOrder} />
    <Authenticated path="/v2/Report/Payment" component={ReportPaymentsV2} />
    <Authenticated path="/v2/Report/Quotation" component={QuotationReportV2} />
    <Authenticated path="/v2/Report/Courtesy" component={CourtesyReportV2} />

    <Authenticated path="/v2/PendingSalesV2" component={PendingSalesV2} />
    <Authenticated path="/v2/Commission" component={CommissionV2} />
    <Authenticated path="/v2/Courtesy/Auth" component={CourtesyAuth} />
    <Authenticated path="/v2/Courtesy" component={CourtesyV2} />
    <Authenticated path="/v2/Lend/Auth" component={LendAuth} />
    <Authenticated path="/v2/Lend" component={Lend} />
    <Authenticated path="/v2/ManufactureV2/Lends/Samples" component={ManufactureSamplesV2} />
    <Authenticated path="/v2/ManufactureV2/Lends/Consignment" component={ManufactureConsignmentV2} />
    <Authenticated path="/v2/ManufactureV2/Rents" component={ManufactureRentV2} />
    <Authenticated path="/v2/ManufactureV2/Sales" component={ManufactureSaleV2} />
    <Authenticated path="/v2/ManufactureV2/Repair" component={ManufactureRepairV2} />
    <Authenticated path="/v2/ManufactureV2/Service" component={ManufactureServiceV2} />
    <Authenticated path="/v2/ManufactureV2" component={ManufactureV2} />
    <Authenticated path="/v2/Borrower" component={Borrower} />
    <Authenticated path="/v2/Quotation" component={QuotationV2} />

    <Route component={NotFound} />
  </Switch>;

export default Routes;
