import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  userActions, profileActions } from './../../_actions';

import Account from './account';

class Myaccount extends Component {

  componentDidMount(){
		const { getData, getProfile } = this.props;
		getData();
    getProfile();
	};


	componentDidUpdate = prevProps => {
  	const { alertType } = this.props;
  	if(prevProps.alertType !== alertType && alertType === 'success'){
  		this.props.getData();
  	}
	}

  render(){
    const { user, profile } = this.props;
    return(
      <div>
        <Account user={user} profile={profile}/>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  user: state.user,
  alertType: state.alert.type,
  profile: state.profile.image
});

const mapDispatchToProps = dispatch => ({
	getData: () => dispatch(userActions.getById()),
  getProfile: () => dispatch(profileActions.get()),

});
export default (connect(mapStateToProps, mapDispatchToProps)(Myaccount));
