const commission = (method, values) => {
    console.log(values);
    let tmp = {};
  switch (method) {
    case 'PUT':
      tmp = {
        "Commissions":[{
          "method":"salePrice",
          "commission": values['salePrice'] ? values['salePrice'] : 0
        },
        {
        "method":"rentPerEvent",
        "commission": values['rentPerEvent'] ? values['rentPerEvent'] : 0
        },
        {
        "method":"rentPerEventIndustry",
        "commission": values['rentPerEventIndustry'] ? values['rentPerEventIndustry'] : 0
        },
        {
        "method":"rentPer1Month",
        "commission": values['rentPer1Month'] ? values['rentPer1Month'] : 0
        },
        {
        "method":"rentPer1MonthIndustry",
        "commission": values['rentPer1MonthIndustry'] ? values['rentPer1MonthIndustry'] : 0
        },
        {
        "method":"rentPer2Months",
        "commission": values['rentPer2Months'] ? values['rentPer2Months'] : 0
        },
        {
        "method":"rentPer2MonthsIndustry",
        "commission": values['rentPer2MonthsIndustry'] ? values['rentPer2MonthsIndustry'] : 0
        },
        {
        "method":"rentPer3Months",
        "commission": values['rentPer3Months'] ? values['rentPer3Months'] : 0
        },
        {
        "method":"rentPer3MonthsIndustry",
        "commission": values['rentPer3MonthsIndustry'] ? values['rentPer3MonthsIndustry'] : 0
        },
        {
        "method":"rentPer4Months",
        "commission": values['rentPer4Months'] ? values['rentPer4Months'] : 0
        },
        {
        "method":"rentPer4MonthsIndustry",
        "commission": values['rentPer4MonthsIndustry'] ? values['rentPer4MonthsIndustry'] : 0
        },
        {
        "method":"rentPer5Months",
        "commission": values['rentPer5Months'] ? values['rentPer5Months'] : 0
        },
        {
        "method":"rentPer5MonthsIndustry",
        "commission": values['rentPer5MonthsIndustry'] ? values['rentPer5MonthsIndustry'] : 0
        },
        {
        "method":"rentPer6Months",
        "commission": values['rentPer6Months'] ? values['rentPer6Months'] : 0
        }, 
        {
        "method":"rentPer6MonthsIndustry",
        "commission": values['rentPer6MonthsIndustry'] ? values['rentPer6MonthsIndustry'] : 0
        },
        {
        "method":"rentPer7Months",
        "commission": values['rentPer7Months'] ? values['rentPer7Months'] : 0
        },
        {
        "method":"rentPer7MonthsIndustry",
        "commission": values['rentPer7MonthsIndustry'] ? values['rentPer7MonthsIndustry'] : 0
        },
        {
        "method":"rentPer8Months",
        "commission": values['rentPer8Months'] ? values['rentPer8Months'] : 0
        },
        {
        "method":"rentPer8MonthsIndustry",
        "commission": values['rentPer8MonthsIndustry'] ? values['rentPer8MonthsIndustry'] : 0
        },
        {
        "method":"rentPer9Months",
        "commission": values['rentPer9Months'] ? values['rentPer9Months'] : 0
        },
        {
        "method":"rentPer9MonthsIndustry",
        "commission": values['rentPer9MonthsIndustry'] ? values['rentPer9MonthsIndustry'] : 0
        },
        {
        "method":"rentPer10Months",
        "commission": values['rentPer10Months'] ? values['rentPer10Months'] : 0
        },
        {
        "method":"rentPer10MonthsIndustry",
        "commission": values['rentPer10MonthsIndustry'] ? values['rentPer10MonthsIndustry'] : 0
        },
        {
        "method":"rentPer11Months",
        "commission": values['rentPer11Months'] ? values['rentPer11Months'] : 0
        },
        {
        "method":"rentPer11MonthsIndustry",
        "commission": values['rentPer11MonthsIndustry'] ? values['rentPer11MonthsIndustry'] : 0
        },
        {
        "method":"rentPer12Months",
        "commission": values['rentPer12Months'] ? values['rentPer12Months'] : 0
        },
        {
        "method":"rentPer12MonthsIndustry",
        "commission": values['rentPer12MonthsIndustry'] ? values['rentPer12MonthsIndustry'] : 0
        }]
      };
      return tmp;
    default:
      return tmp;
  }
}

export default commission;
