import React, { useMemo, useEffect  } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { recordsActions, openFormDialog } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { TextField, SelectField } from './../../_presentationals/Form';

import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

const CatchmentsReport = ({getRecords, ...props}) => {

  useEffect(() => {
    getRecords({ table: 'customers' });
  }, [getRecords]);
  
  const customers = useMemo(() => (
    props.customers.count > 0 ? props.customers.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.customers]);

  const catchments = useMemo(() => (
    props.catchments.count > 0 ? props.catchments.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date'
      },
      Cliente: {
        value: el.customer,
        filter: true,
      },
      "Modo de Captación": {
        value: el.catchmentLabel
      },
      Descripción: {
        value: el.description
      }
    })) : []
  ), [props.catchments]);

  return (
    <Module
      table="catchments"
      name="Captación de Clientes"
      singularName="captacion de cliente"
      records={catchments}
      filter={false}
      download={true}
      create={true}
      edit={true}
      _delete={true}
    >
      <Autocomplete
        label="Cliente" 
        _id="customerId" 
        options={customers}
      />
      <SelectField
        fields={[{
          id: "catchment",
          label: "Método de Captación",
          options: catchmentOptions,
        }]}
      />
      <TextField
      fields={[{
        id: "description", 
        label: "Descripción"
      }]}
    />
      </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const catchmentOptions = [
  { key: 1, value: "Cliente" },
  { key: 2, value: "Internet" },
  { key: 3, value: "Distribuidor" },
  { key: 4, value: "Página web" },
  { key: 5, value: "Comisionista" },
  { key: 6, value: "Recomendación" },
  { key: 7, value: "Publicidad en carros" },
  { key: 8, value: "Seguimiento de cliente" },
  { key: 9, value: "Redes sociales empresa" },
  { key: 10, value: "Redes sociales personales" },
  { key: 11, value: "Publicidad fachada de la empresa" },
  { key: 12, value: "Publicidad fuera de la sucursal" },
  { key: 13, value: "Mercado Libre" },
  { key: 14, value: "No identificado" }
];

const mapStateToProps = state => ({
  catchments: state.records.catchments || defaultTableValue,
  customers: state.records.customers || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: data => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(CatchmentsReport);