const vehicleExpenses = ({method, ...props}) => {
  switch (method) {
    case 'POST':
    case 'GET':
      return `vehicles/${props.redirectId}/expenses/`;
    case 'DELETE':
    	return `vehicles/${props.redirectId}/expenses/${props._id}`;
    default:
      return 'vehicles';
  }
}

export default vehicleExpenses;
