
const payments = (method, values) => {
	const tmp = {};
  switch (method) {
    case 'POST':
      tmp['payment'] = values['prepaid'] ? values['prepaid'] : values['payment'];
      tmp['method'] = values['paymentMethod'];
      return tmp;
    default:
      return tmp;
    }
}

export default payments;