import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';

import { styles } from './style';

// import MobileActionsComponent from './_mobileActions';
import HeaderComponent from './_header';
import BodyComponent from './_body';

const Body = ({classes, ...props}) => {
	return (
		<TableContainer className={ classes.table}>
			<Table stickyHeader>
	    	<HeaderComponent 
	    		records={props.records}
	    		handleSort={props.handleSort}
	    		direction={props.direction}
	    		textFilter={props.textFilter}
	    		handleChangeFilter={props.handleChangeFilter}
	    	/>
	    	<BodyComponent 
	    		records={props.records}
	    		rows={props.rows}
	    		actions={props.actions}
	    		table={props.table}
	      	edit={props.edit}
	      	_delete={props._delete}
	      	handleEdit={props.handleEdit}
	      	handleDelete={props.handleDelete}
	    	/>
	    </Table>
	  </TableContainer>
	)
}

export default withStyles(styles)(Body);