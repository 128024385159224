const accountsReceivables = ({method, ...props}) => {
  switch (method) {
    case 'POST':
    case 'PUT':
    case 'DELETE':
    case 'GET':
    	return `accountReceivables` 
    default:
      return 'accountReceivables';
  }
}

export default accountsReceivables;