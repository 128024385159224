import React from 'react';

import DrawerUI from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import MenuItems from './menuItems';

import { styles } from './styles';

const Drawer = props => {
	const { classes, theme, toggleDrawer, left, handleLogout, userInformation, profile, user } = props;
	return (
	  <nav className={ classes.drawer }>
	    <Hidden smUp implementation="css">
	      <DrawerUI 
	  	    open={ left }
	  	    variant="temporary"
		   		onClose={toggleDrawer('left', false)} 
		   		anchor={theme.direction === 'rtl' ? 'right' : 'left'}
		   	>
		  	  <MenuItems 
		  	  	profile={ profile } 
		  	  	user={ user }
		  	  	userInformation={ userInformation } 
		  	  	handleLogout={ handleLogout } 
		  	  	toggleDrawer={ toggleDrawer } 
		  	  />
	   	  </DrawerUI>
	    </Hidden>
	    <Hidden xsDown implementation="css">
	      <DrawerUI
  	  		classes={{
      		  paper: classes.drawerPaper,
	    		}}
	    		variant="permanent"
	    		open
  		  >
    	    <MenuItems 
    	    	profile={ profile } 
    	    	userInformation={ userInformation } 
    	    	handleLogout={ handleLogout } 
    	    	toggleDrawer= { toggleDrawer } 
    	    />
  		  </DrawerUI>
	    </Hidden>
	  </nav>
	);
};
export default withStyles(styles, { withTheme: true })(Drawer);