import React, { useMemo } from "react";
import { connect } from 'react-redux';

import CircleIcon from '@material-ui/icons/AddCircleOutline';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';
import { history } from './../../_helpers';
import { redirectActions } from './../../_actions';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Clasification = props => {

  const clasifications = useMemo(() => 
    props.clasifications.count > 0 ? props.clasifications.rows.map(el => ({
      ID: { 
        value: el.id,
        data: el
      }, 
      Nombre: {
       value: el.name,
       filter: true,
       _sort: 'text'
      },
      "¿Aplica Dscto?": { 
        value: discountFormat(el.discount),
        align: "center",
       _sort: 'text'
      },
      "¿Aplica Inventario?": { 
        value: discountFormat(el.inventory),
        align: "center",
       _sort: 'text'
      }
    })) : []
  , [props.clasifications]);

  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, table: 'subClasifications', returnTo: '/Clasification', returnToLabel: 'Clasificaciones'});
    history.push('/Clasification/SubClasification');
  };

  return (
    <Module
      table="clasifications"
      name="clasificaciones"
      singularName="clasificación"
      filter={false}
      title="Crear Clasificación"
      actions={ (privileges.subClasifications && privileges.subClasifications.read) && [{
        icon: <CircleIcon fontSize="small" />,
        label: "Subclasificación",
        handle: redirect
      }]}
      records={clasifications}
    >
      <TextField
        icon="category"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <SelectField
        fields={[{
          id: 'discount',
          label: '¿Acepta descuento?',
          options: [
            { key: true, value: "Si" },
            { key: false, value: "No" },
          ]
        }]}
      />
      <SelectField
        fields={[{
          id: 'inventory',
          label: 'Aplica inventario?',
          options: [
            { key: true, value: "Si" },
            { key: false, value: "No" },
          ]
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  clasifications: state.records.clasifications || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  setRedirect: data => dispatch(redirectActions.setRedirect(data))
});

const discountFormat = value => {
  switch(value) {
    case true:
      return "Si";
    case false: 
      return "No";
    default:
      return "Indefinido";
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clasification);