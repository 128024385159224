import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/es';

import PaymentIcon from '@material-ui/icons/PaymentOutlined';

import { config } from './../../_helpers/config';
import Module from './../../_presentationals/Module';
import { openFormDialog, editValueAction, recordsActions } from '../../_actions';

import { moneyFormat } from '../../_helpers/moneyFormat';
import PaymentDialog from './payment.dialog';

const Component = ({openFormDialog, ...props}) => {
  const [ actions, setActions ] = useState([]);
  const [ saleId, setSaleId ] = useState(null);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    const tmpAction = [
      { icon: <PaymentIcon fontSize="small" />, label: "Abonos", handle: handlePayment }
    ];
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const receibables = useMemo(() => {
    return props.receivablesv2.count > 0 ? props.receivablesv2.rows.map(el => { 
      const totalPayment = el.PaymentV2s.reduce((total,tmp) => (parseFloat(total) + parseFloat(tmp.total)),0);
      const totalInvoice = el.InvoiceV2s.reduce((total,tmp) => (parseFloat(total) + parseFloat(tmp.total)),0);
      const balance = (parseFloat(el.total) - parseFloat(totalInvoice)) - parseFloat(totalPayment);
      
      return {
        ID: { 
          show:false,
          value: el.id,
          data: el
        },
        Fecha: { 
          value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale}),
          _sort: 'date'
        },
        Folio: {
          value: <a  href={`${config.apiUrl}/files/invoices/${el.uuid}/pdf`} target="_blank" rel="noopener noreferrer">{`${el.folio}-${el.serial}`}</a>,
          _sort: 'text'
        },
        Cliente: {
          value: el.Customer ? `${el.Customer.firstName} ${el.Customer.lastName}` : '-',
          _sort: 'text'
        },
        Vendedor: {
          value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : '-',
          _sort: 'text'
        },
        Cargo: {
          value: moneyFormat(el.total),
          alt: parseFloat(parseFloat(el.total).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Abono: {
          value: moneyFormat(totalPayment),
          alt: parseFloat(parseFloat(totalPayment).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        NC: {
          value: moneyFormat(totalInvoice),
          alt: parseFloat(parseFloat(totalInvoice).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Saldo: {
          value: moneyFormat(balance),
          alt: parseFloat(parseFloat(balance).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Sucursal: {
          value: branchesToLabel(el.branchId),
          _sort: 'text'
        }
      }
    }) : [];

  }, [props.receivablesv2]);

  const reload = (code) => {
    setRefreshCode(code);
    setSaleId(null);
  };

  const handlePayment = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setSaleId(id);
    setIsOpen(true)
  }

  const handleClosePayment = () => setIsOpen(false);

  return (
    <Fragment>
      <Module
        table="receivablesv2"
        name="cuentas por cobrar"
        records={receibables}
        actions={actions}
        refreshCode={refreshCode}
        singularName="reporte"
        title="Cuentas por Cobrar"
        filter={false}
        create={false}
        edit={false}
        _delete={false}
        download={true}
      ></Module>
      <PaymentDialog isOpen={isOpen} handleClose={handleClosePayment} reload={reload} saleId={saleId}/>
    </Fragment>
  );
}

function branchesToLabel(value){
  switch(value){
    case 1: return 'Culiacán';
    case 3: return 'Mazatlán';
    case 4: return 'Mexicali';
    case 5: return 'Hermosillo';
    default: return '-';
  }
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  receivablesv2: state.records.receivablesv2 || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  _delete: value => dispatch(recordsActions._delete(value)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);