import green from '@material-ui/core/colors/green';

const styles = theme => ({
  background: {
    background: "linear-gradient(90deg, rgba(20,91,230,1) 20%, rgba(42,240,252,1) 100%)",
    minHeight: "100vh",
    margin: 0,
    padding: 0,
    fontFamily: "'Heebo', sans-serif",
    [theme.breakpoints.down('sm')]: {
      background: "linear-gradient(90deg, rgba(20,91,230,1) 30%, rgba(42,240,252,1) 100%)",
    }
  },
  header1: {
    background: "#fff",
    width: "100%",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #1e90ff"
  },
  header2: {
    background: "#fff",
    width: "100%",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      background: "transparent",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(0),
    }
  },
  headerLink: {
    marginLeft: theme.spacing(2)
  },
  body: {
    width: "100%",
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    }
  },
  bodyLink: {
    color: "#fff"
  },
  logo: {
    marginTop: theme.spacing(1),
    width: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  typography1: {
    color: "#fff",
    marginTop: theme.spacing(13),
  },
  typography2: {
    color: "#fff",
    lineHeight: 1
  },
  typography3: {
    fontWeight: 700,
    marginTop: theme.spacing(3)
  },
  typography4: {
    color: '#454545',
    marginBottom: theme.spacing(4)
  },
  typographyDialog1: {
    lineHeight: 1
  },
  typographyTitle1: {
    color: "#313131", 
    fontWeight: 300
  },
  typographySubtitle1: {
    color: "#595959", 
    lineHeight: 1.5
  },
  registerButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: green[500],
    color: "#fff",
    '&:hover': {
      backgroundColor: green[700],
    },
    textTransform: "capitalize",
    fontWeight: 700,
    letterSpacing: -1
  },
  logInButtom: {
    margin: 0,
    '&:hover': {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down('sm')]: {
      color: "#fff",
      border: "2px solid #fff",
      '&:hover': {
        border: "2px solid #daeaf6",
      },
    }
  },
  dialogAvatar: {
    backgroundColor: "#1e90ff"
  },
  dialogButtonContainer: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  buttonDialog1: {
    borderRadius: 0,
    '&:hover': {
      backgroundColor: "transparent",
    }
  },
  dialogContent1: {
    margin: 0,
    padding: 0
  }
});

export { styles };
