import jsPDF from 'jspdf';
import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns'

import { authHeader, config } from '../../_helpers';
import { handleFetchResponse } from './../../_services';

const session = JSON.parse(localStorage.getItem('session'));
const BRANCH_NAME = session && session.data.branchName;

const getDataFromServer = (start,finish) => {
	const method = 'GET';
	const url = `${config.apiUrl}/inventories/control?start=${start}&finish=${finish}`;

  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );

  return fetch(myRequest)
  	.then(handleFetchResponse({ url, method, headers: {} }))
  	.then(data => data);
}

export const InventoryControl = async (start,finish) => {
	const startFormat = start ? format( new Date(start), 'yyyy-MM-dd', { locale: localeES }) : format( new Date(), 'yyyy-MM-dd', { locale: localeES });
	const finishFormat = finish ? format( new Date(finish), 'yyyy-MM-dd', { locale: localeES }) : format( new Date(), 'yyyy-MM-dd', { locale: localeES });

	const data = await getDataFromServer(startFormat,finishFormat);

	const doc = new jsPDF("l");

	header(doc);

	let breakLine = 40;
	let tmpBreakLine;

	data.rows.forEach( el => {
		const date = format(parseISO(el.createdAt), 'dd/mm/yyyy', {locale: localeES});

    tmpBreakLine = breakLine;

    const userJustify = doc.splitTextToSize(`${el.User.firstName} ${el.User.lastName}`, 50);
		const productJustify = doc.splitTextToSize(`${el.Inventory.Product.name}`, 45);
		const descriptionJustify = doc.splitTextToSize(`${el.description}`, 60);
		
		doc.text(10, breakLine, userJustify, 'left');
		doc.text(70, breakLine, productJustify, 'left');
		doc.text(145, breakLine, descriptionJustify, 'left');
		doc.text(210, breakLine, `${el.lastStock}`, 'left');
		doc.text(235, breakLine, `${el.stock}`, 'left');
		doc.text(280, breakLine, date, 'right');

		doc.line(10, tmpBreakLine + 5, 280, tmpBreakLine + 5);

		breakLine = tmpBreakLine + 10;
		if(breakLine >= 200) {
			breakLine = 40;
			doc.addPage('L');
			header(doc);
		}

	});
	

	doc.output('dataurlnewwindow');
}

const header = (doc) => {
	//CABECERA
	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text("Reporte de Inventario", 10, 15, 'left');
	doc.text(`${BRANCH_NAME}`, 10, 20, 'left');
	doc.text("Fecha y Hora", 280, 15, 'right');
	doc.text(`${format(new Date(), 'dd/MM/yyyy HH:mm:ss', {locale: localeES})}`, 280, 20, 'right');

	//TABLA
	doc.line(10, 35, 280, 35);
	doc.text("Usuario", 10, 30, 'left');
	doc.text("Producto", 70, 30, 'left');
	doc.text("Descripción", 145, 30, 'left');
	doc.text("Virtual", 210, 30, 'left');
	doc.text("Real", 235, 30, 'left');
	doc.text("Fecha y Hora", 280, 30, 'right');
	doc.line(10, 35, 280, 35);

	doc.setFontSize(8);
	doc.setFontStyle("normal");
}