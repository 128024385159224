const api = ['employeeId', 'customerId'];
const productsWithPrice = [13,133,134,135];

const quotations = (method, values) => {
   const tmp = {};
	switch (method) {
		case 'POST':
			Object.keys(values).forEach(key => {
      	  if(api.includes(key)) { tmp[key] = values[key];}
   	 	});
         // console.log(values);
         tmp['catchment'] = values['method'];
   	 	tmp['isInvoice'] = values['isInvoice'] === 1 ? true : false;
         tmp['amountInvoice'] = values['amountInvoice'];
         tmp['currencyKey'] = values['currencyId'];
         tmp['exchangeRate'] = values['exchangeRate'];
   	 	tmp['Products'] = [];
   	 	values['Products'].forEach((ele,index) => {
   	 		const { productId, quantity, isRented } = ele;

   	 		const discount = ele.discount !== '' ? ele.discount : 0;
            tmp['Products'].push({productId, quantity, discount});
            if (isRented) {
               tmp['Products'][index]['rentPlan'] = [{ quantity: ele.planQuantity, method: ele.method }];
            }

            if(productsWithPrice.includes(parseInt(productId))) {
               tmp['Products'][index]['priceProduct'] = ele.price;
            }
            
   	 	});
   	 	return tmp;
   	 default:
   	 	return tmp;
	}
}

export default quotations;