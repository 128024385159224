
export const styles = theme => ({
	root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
	main: {
	 width: 'auto',
	 display: 'block',
	 marginLeft: theme.spacing(1),
	 marginRight: theme.spacing(1),
	 [theme.breakpoints.up(900 + theme.spacing(3) * 2)]: {
		 width: 900,
		 marginLeft: 'auto',
		 marginRight: 'auto',
	 },
   listItems: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
 }
});
