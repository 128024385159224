import React from "react";

import { FormDialog } from '../../_presentationals/FormDialog';

import { SelectField, TextField } from './../../_presentationals/Form';

const data = [{ key: 2, value: 'Falla' }];

const ChangeStatusProduct = props => 
  <FormDialog 
    title="Actualizar Estatus"
    name="ChangeStatusProduct"
    singular="ChangeStatusProduct"
    table="logisticProduct"
  >
    <SelectField
      fields={[{
        id: "status",
        label: "Estatus",
        options: data
      }]}
    />
    <TextField
      fields={[{
        id: "commentary",
        label: "Comentario",
        required: false
      }]}
    />
  </FormDialog>
 
export default ChangeStatusProduct;