import React, { useMemo, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { recordsActions  } from './../../_actions';

import { history } from './../../_helpers';
import Module from '../../_presentationals/Module';
import AutocompleteField from './../../_presentationals/Form/AutocompleteFieldV2';
import { moneyFormat } from '../../_helpers/moneyFormat';

const ConsignmentManufacture = props => {

  const { alert, getRecords } = props;

  const [ loadData, setLoadData ] = useState(0);
  
  useEffect(() => {
    props.redirect.id === "" && history.push('/Consignment/');
  }, [props.redirect.id]);

  useEffect(() => { 
    (alert === "success" || !loadData) && getRecords({ table: 'manufactures', filter: true, status: 'allow' });  
    setLoadData(1);
  }, [ alert, loadData, getRecords ]);

  const manufactures = useMemo(() => {
    return props.manufactures.rows ? props.manufactures.rows.map(({ id, physicalSerie, statusLabel, Product }) => ({ value: id, label: `${Product.name} ${physicalSerie} ${statusLabel}` })) : [];
  }, [props.manufactures]);
  
  const consignmentManufactures = useMemo(() => 
    props.consignmentManufactures.count > 0 ? props.consignmentManufactures.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      Producto: {
        value: el.Manufacture.Product.name,
        filter: true,
        _sort: 'text'
      },
      "Serie Física": {
        value: el.Manufacture.physicalSerie,
        filter: false,
        _sort: 'text'
      },
      Costo: { 
        value: moneyFormat(el.productionCost),
        alt: parseFloat(parseFloat(el.productionCost).toFixed(2)),
        align: 'right',
        accumulate: true, accumulateLabel: 'Costo de Producción',
        _sort: 'alt'
      }
    })) : []
  , [props.consignmentManufactures]);

  return (
    <Module
      table="consignmentManufactures"
      name="equipos en consignación"
      singularName="equipo en consignación"
      title="Equipo en Consignación"
      records={consignmentManufactures}
      download={true}
      filter={false}
      edit={false}
    >
      <AutocompleteField
        label="Serie Física" 
        _id="manufactureId" 
        options={ manufactures }
      />
    </Module>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  redirect: state.redirect,
  manufactureId: state.field.value.manufactureId || 0,
  consignmentManufactures: state.records.consignmentManufactures || defaultTableValue,
  manufactures: state.records.manufactures || defaultTableValue,
  alert: state.alert.type
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsignmentManufacture);