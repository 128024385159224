export const authHeader = () => {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('session'));
    
    if (user && user.accessToken) {
        return { 'Authorization': 'bearer ' + user.accessToken };
    } else {
        return {};
    }
}


