import React from 'react';

import Grid from '@material-ui/core/Grid';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';

import "./style.css";

function renderRow(props) {
  const { data, index, style } = props;

  return React.cloneElement(data[index], {
    style: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      ...style,
    },
  });
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = smUp ? 36 : 48;

  const outerElementType = React.useMemo(() => {
    return React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} />);
  }, []); // eslint-disable-line

  return (
    <div ref={ref}>
      <FixedSizeList
        style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto' }}
        itemData={children}
        height={250}
        width="100%"
        outerElementType={outerElementType}
        innerElementType="ul"
        itemSize={itemSize}
        overscanCount={5}
        itemCount={itemCount}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

// const defaultValue = { value: '', label: '' };

function AutocompleteField({ noFormat, icon, onChange, error, ...props }) {

  const classes = useStyles();

  const handleChange = (e, newValue) => {
    const tmp = newValue 
      ? newValue 
      : props.defaultValue 
        ? props.defaultValue 
        : null;
    onChange(e, props.name, tmp);
  }

  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item xs={ noFormat ? false : 1 }>
        { icon || "" }
      </Grid>
      <Grid item xs={noFormat ? 12 : 10}> 
        <Autocomplete
          id={props.name}
          disableListWrap
          classes={classes}
          value={props.value}
          onChange={handleChange}
          ListboxComponent={ListboxComponent}
          options={props.options}
          getOptionLabel={ option => option.label }
          noOptionsText='No encontramos resultados'
          renderInput={params => (
            <TextField 
              {...params} 
              variant={props.variant} 
              label={props.label} 
              fullWidth
              autoFocus={props.autoFocus}
              error={error ? true : false}
              helperText={error && error}
              size={props.size || "small"}
              disabled={props.disabled || false}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

// error={typeof error === 'string' ? true : false}
export default AutocompleteField;