import React, { Fragment, useMemo, useState } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';

const Manufacture = props => {
  const [ additionalText, setAdditionalText ] = useState("");
  
  const manufactures = useMemo(() => {

    const tmp = props.manufactures.count > 0 ? props.manufactures.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      Producto:{
        value: el.name,
        filter: true,
        _sort: 'text'
      },
      Costo: {
        value: moneyFormat(el.productionCost),
        alt: parseFloat(parseFloat(el.productionCost).toFixed(2)),
        align: 'right',
        _sort: 'alt',
      },
      Estatus: {
        value: el.labelStatus,
        filter: false,
        _sort: 'text',
      },
      Cantidad:{
        value: el.qty,
        alt: el.qty,
        filter: false,
       _sort: 'text'
      },
      Total:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt',
      }
    })) : [];

    setAdditionalText(`
      Es: ${moneyFormat(props.manufactures.totalAssembling)} | 
      PV: ${moneyFormat(props.manufactures.totalForSale)} |
      PR: ${moneyFormat(props.manufactures.totalForRent)} |
      V: ${moneyFormat(props.manufactures.totalSold)} |
      R: ${moneyFormat(props.manufactures.totalRented)} |
      G: ${moneyFormat(props.manufactures.totalWarranty)} |
      C: ${moneyFormat(props.manufactures.totalConsigned)}
    `);

    return tmp;
  },[props.manufactures]);

  return (
    <Fragment>
      <Module
        table="statusManufactures"
        name="Equipos"
        records={manufactures}
        singularName="Equipo"
        filter={false}
        edit={false}
        create={false}
        _delete={false}
        title="Por Status"
        download={true}
        additionalText={additionalText}
      >
      </Module>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  manufactures: state.records.statusManufactures || defaultTableValue,
});


export default connect(mapStateToProps,null)(Manufacture);