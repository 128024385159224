import React, { Fragment, useEffect, useState, useMemo } from "react";
import { connect } from 'react-redux';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import Module from '../../_presentationals/Module';
import { TextField, SelectField, DateField } from './../../_presentationals/Form';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';

import Product from './products';
import PaymentDialog from './paymentDialog';
import ProductsDialog from './productsDialog';
import ExpenseTicket from './expense.ticket';
import ExpensePdf from './expense.pdf';

import ListAltIcon from '@material-ui/icons/ListAlt';
import Payment from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import PictureAsPdf from '@material-ui/icons/PictureAsPdfOutlined';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Expense = ({ getRecords, openFormDialog, editValueAction, update, ...props }) => {
  const [ payments, setPayments ] = useState([]);

  const [actions, setActions] = useState([]);

  useEffect(() => {
    const tmpAction = [
      { icon: <ReceiptIcon fontSize="small" />, label: "Ticket", handle: handleTicket },
      { icon: <PictureAsPdf fontSize="small" />, label: "PDF", handle: handlePdf },
      { icon: <ListAltIcon fontSize="small" />, label: "Productos", handle: handleProductsDialog }
    ];
    if(privileges.debtsPays && privileges.debtsPays.pay){ 
      tmpAction.push({icon: <Payment fontSize="small" />, label: 'Pagos', handle: handdlePay });
    }
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if(props.alert === "success" && parseInt(props.recordId) > 0) ExpenseTicket(props.recordId);
  },[props.alert,props.recordId]);

  const expenses = useMemo(() =>
    props.expenses.count > 0 ? props.expenses.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: {
        value: format(parseISO(el.date), 'dd/MM/yyyy HH:ss', {locale: localeES}),
        _sort: 'date'
      },
      Folio: {
        value: el.serial,
        filter: true,
        _sort: 'text'
      },
      Tipo: {
        value: el.type.label,
        _sort: 'text'
      },
      Método: {
        value: el.action.label,
        _sort: 'text'
      },
      Factura: {
       value: el.folio,
       filter: true,
       _sort: 'text'
      },
      Comentario: {
        value: el.commentary,
        _sort: 'text'
      },
      Proveedor: {
        value: el.Supplier.tradename,
        _sort: 'text',
        filter: true
      },
      Vehículo: {
        value: el.Vehicle ? `${el.Vehicle.name } ${el.Vehicle.brand} ${el.Vehicle.model}` : '',
        filter: true,
        _sort: 'text'
      },
      Total:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
        accumulate: true
      },
      Abonado:{
        value: moneyFormat(el.payments),
        alt: parseFloat(parseFloat(el.payments).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Saldo:{
        value: moneyFormat(el.balance),
        alt: parseFloat(parseFloat(el.balance).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Estado: {
        value: el.status.label,
        _sort: 'text'
      }
    })) : []
  , [props.expenses]);

  useEffect(() => { getRecords({ table: 'suppliers' }); }, [getRecords]);

  const suppliers = useMemo(() => {
    return props.suppliers.count > 0 ? props.suppliers.rows.map(({id, tradename}) => ({ key: id, value: tradename })) : [];
  }, [props.suppliers]);

  const handdlePay = e => {
    const { id, ExpensePayments } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ expenseId: id });
    setPayments(ExpensePayments);
    openFormDialog('PaymentDialog');
  };

  const handleProductsDialog = e => {
    const { Products } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ Products });
    openFormDialog('ProductsDialog');
  };

  const handleTicket = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    ExpenseTicket(id)
  }

  const handlePdf = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    ExpensePdf(id)
  }

  return (
    <Fragment >
      <Module
        table="debtsPays"
        name="cuentas por pagar"
        singularName="cuenta por pagar"
        filter={false}
        edit={false}
        title="Crear Cuenta por Pagar"
        records={expenses}
        actions={actions}
        widthDialog={'md'}
      >
        <SelectField
          fields={[{
            id: "action",
            label: "Método de Pago",
            options: [
              { key: 1, value: "Contado" },
              { key: 2, value: "Crédito" }
            ],
            autoFocus: true
          }]}
        />
        <SelectField
          fields={[{
            id: "type",
            label: "Tipo",
            options: [
              { key: 2, value: "Administrativo" },
              { key: 1, value: "Almacén" },
              { key: 3, value: "Vehículo" },
              { key: 4, value: "Consumible" }
            ]
          }]}
        />
        <DateField
          id="date"
          label="Fecha de Adquisición"
        />
        <TextField
          fields={[{
            id: "folio",
            label: "Número de Factura",
            required: false
          }]}
        />
        <TextField
          fields={[{
            id: "commentary",
            label: "Comentario",
            required: false
          }]}
        />
        <SelectField
          fields={[{
            id: 'supplierId', 
            label: 'Proveedor',
            options: suppliers
          }]}
        />
        <Product />
      </Module>
      <PaymentDialog ExpensePayments={payments} />
      <ProductsDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  suppliers: state.records.suppliers || defaultTableValue,
  expenses: state.records.debtsPays || defaultTableValue,
  alert: state.alert.type,
  recordId: state.field.value.recordId || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: value => dispatch(editValueAction(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Expense);