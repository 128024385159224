const api = ['firstName', 'discountRange', 'rentDiscountRange', 'addressLine1', 'addressLine2', 'city', 'zipCode', 'reference','iva'];

const branches = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
      api.push('phone1', 'phone2');
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      if (values['stateId']) tmp['stateId'] = values['stateId'];
      if (values['countryId']) tmp['countryId'] = values['countryId'];
      tmp['lastName'] = " ";
      return { ...tmp, employeeId: values.employeeId || null, iva: tmp.iva !== '' ? tmp.iva : null };
    case 'PUT':
      api.push('Phone');
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      tmp['Phone'] = [];
      ['phone1', 'phone2'].forEach((key) => {
        typeof values[key] !== 'undefined' && tmp['Phone'].push({ phoneId: values[key+'Id'], phone: values[key] })
      });
      if (values['stateId']) tmp['stateId'] = values['stateId'];
      if (values['countryId']) tmp['countryId'] = values['countryId'];
      tmp['lastName'] = "";
      return { ...tmp, employeeId: values.employeeId || null, iva: tmp.iva !== '' ? tmp.iva : null };
    default:
      return tmp;
  }
}

export default branches;