import { format } from "date-fns";
import locale from 'date-fns/locale/es';

const uri = ({method, ...props}) => {
  switch (method) {
    default:
    	const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy/MM/dd', { locale });
      const finish = format(finishFormat, 'yyyy/MM/dd', { locale });
      
      return `v2/sales/details/review?start=${start}&finish=${finish}`;
  }
}

export default uri;