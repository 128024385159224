import React, { PureComponent } from 'react';
import { Router } from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { history } from './_helpers';
import Navigation from "./_containers/Navigation";

import Routes from "./Routes";
import 'typeface-roboto';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: blue,
  },
});

class App extends PureComponent {
  render() {
    const session = JSON.parse(localStorage.getItem('session'));
  	const isAuth = session ? session.isAuth : false;
    const isVerified = session ? session.isVerified : false;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
	      <Router history={history}>
	  	    { componentToRender({ isAuth, isVerified }) }
        </Router>
      </MuiThemeProvider>
    );
  }
}

const componentToRender = ({isAuth, isVerified}) => {
  if(isAuth){ return <Navigation />; }
  return <Routes />;
}

export default App;
