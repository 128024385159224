const logisticIndependent = ({method, ...props}) => {
  switch (method) {
  	case 'PUT':
  		if(props.status) return `logistics/independents/${props.id}/status`;
  		return `logistics/independents/${props.id}`;
    default:
      return `logistics/independents`;
  }
}

export default logisticIndependent