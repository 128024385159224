import React, { useEffect, Fragment, useState, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import Icon from '@material-ui/core/Icon';
import { TextField, AutocompleteField } from './../../_presentationals/Form';
import Grid from '@material-ui/core/Grid';
import { ListItemAdd } from './listItemAdd';
import { editValueAction, recordsActions } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const productsWithPrice = [133,134,135];

const PiecesDialog = ({ getRecords, spareProducts, editValueAction, update, ...props }) => {
  
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [isAdd, setAdd] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleAddProduct = e => {
    setAdd(true);
    const { productId, productIdLabel, quantity = 1, price, commentary } = props.selectedProduct;
    if(!productId || isNaN(parseInt(quantity)) || isNaN(parseFloat(price))){ return; }

    const total = parseFloat(quantity) * parseFloat(price); 

    local && local.push({ productId, productIdLabel, quantity: parseInt(quantity), price, total, commentary });
    editValueAction({Pieces: local, productId: null, quantity: '', price: '', commentary: ''});    
    setQty(JSON.stringify(local));
    setLocal(local);
  };

  useEffect( () => {
    if(props.alert.type === 'success') {
      setAdd(false);
      editValueAction({ Pieces: [], productId: null, employeeId: null, quantity: '', price: '', commentary: '' });
      setLocal([]);
    }
  }, [props.alert, editValueAction]);

  useEffect( () => {
    if(!props.isOpen.Finish) {
      setAdd(false);
      editValueAction({ Pieces: [], productId: null, employeeId: null, quantity: '', price: '', commentary: '' });
      setLocal([]);
    }
  }, [props.isOpen, editValueAction]);

  useEffect(() => {
    getRecords({ table: 'products', type: 2 });
    getRecords({ table: 'employees', isTechnical: true });
  }, [ getRecords ]);

  const products = useMemo(() => {
    return props.products.rows ? props.products.rows.map(({ id, name, salePrice }) => ({ value: id, label: `${name}`, price: salePrice })) : [];
  }, [props.products]);

  const availableEmployees = useMemo(() => {
    return props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.employees]);

  useEffect(() => {
    if(!isAdd) {
      const tmp = spareProducts;
      setQty(JSON.stringify(tmp));
      setLocal(tmp);
      editValueAction({ Pieces: tmp });
    }
  },[spareProducts, editValueAction, isAdd]);

  useEffect( () => ( setIsDisabled(productsWithPrice.includes(parseInt(props.selectedProduct.productId)) ? false : true) ),[props.selectedProduct.productId, editValueAction]);

  const tableRows = useMemo(() => {

    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal([ ...tmp ]);
    };

    
    return local.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">
          {el.productIdLabel}
        </TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell align="right">{parseFloat(el.price).toFixed(2)}</TableCell>
        <TableCell align="right">{parseFloat(el.total).toFixed(2)}</TableCell>
        <TableCell align="right">{el.commentary}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, qty]);
  
  return (
    <FormDialog 
      title="Realizar Inspección"
      name="PiecesDialog"
      singular="PiecesDialog"
      table="warranties"
      widthDialog="md"
    >
      <Fragment>
        <AutocompleteField
          icon="account_circle"
          label="Técnico" 
          _id="employeeId"
          options={ availableEmployees }
        />
          <Fragment>
          <ListItemAdd handleClick={handleAddProduct}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AutocompleteField 
                  label="Producto" 
                  _id="productId" 
                  dialog={false}
                  options={products}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  dialog={false}
                  fields={[{
                    id: "quantity", label: "Cant.",
                    variant: "outlined", margin: "dense", grid: 6
                  },{
                    id: "price", label: "Precio",
                    variant: "outlined", margin: "dense", disabled: isDisabled, grid: 6
                  },]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  dialog={false}
                  fields={[{
                    id: "commentary", label: "Comentario",
                    variant: "outlined", margin: "dense",
                    required: false
                  }]}
                />
              </Grid>
            </Grid>
          </ListItemAdd>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell align="center">Cant.</TableCell>
                <TableCell align="right">Precio</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Comentario</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows}
            </TableBody>
           </Table>
          </Fragment>
      </Fragment>
    </FormDialog>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity, 
    price: state.field.value.price, 
    commentary: state.field.value.commentary
  },
  products: state.records.products || defaultTableValue,
  state: state,
  employees: state.records.employees || defaultTableValue,
  isOpen: state.formDialog,
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(PiecesDialog);