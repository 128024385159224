import { useState, useEffect } from "react";
import * as recordsServices from '../_services';

export const BillingKeysAutocomplete = (SATKey, alias, name) => {
	const [ billingKeys, setBillingKeys ] = useState([]);

	useEffect(() => {
		recordsServices.getAll({ table: 'billings', SATKey, alias }).then(async el => {
			const data = el.count > 0 && await Promise.all(el.rows.map(el => ({ value: el.id, label: `${el.id} ${el[name]}` })));
			data.length > 0 && setBillingKeys(data);
		});
	}, [SATKey, alias, name]);
	
	return billingKeys;
}