import React, { useMemo } from "react";
import { connect } from 'react-redux';
import Module from '../../_presentationals/Module';
import { SelectField } from './../../_presentationals/Form';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Details } from './details';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO'
import { moneyFormat } from '../../_helpers/moneyFormat';
import localeES from 'date-fns/locale/es';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const CashClosing = props => {
  
  const cashClosings = useMemo(() => 
    props.cashClosings.count > 0 ? props.cashClosings.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      Apertura: {
        value: format(parseISO(el.openDate), "dd/MM/yyyy HH:mm", { locale: localeES }),
        _sort: 'date'
      },
      Cierre: {
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", { locale: localeES }),
        _sort: 'date'
      },
      Usuario:{
        value: `${el.User ? (el.User.firstName + ' ' + el.User.lastName) : ''}`,
        filter: true,
       _sort: 'text'
      },
      Sucursal:{
        value: `${el.Branch.name}`,
       _sort: 'text'
      },
      Tipo:{
        value: `${el.type}`,
       _sort: 'text'
      },
      Venta:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
    })) : []
  , [props.cashClosings]);

  const handdleDetails = e => {
    const { id, type } = JSON.parse(e.currentTarget.dataset.row);
    Details(id, type);
  };

  return (
  	<Module
      table="cashClosings"
      name="corte de movimientos"
      records={cashClosings}
      singularName="corte de movimientos"
      filter={false}
      title="Corte"
      create={true}
      edit={false}
      _delete={false}
      download={true}
      actions={ (privileges.cashClosings && privileges.cashClosings.ticket) && [{
        icon: <PictureAsPdfIcon fontSize="small" />,
        label: "Visualizar PDF",
        handle: handdleDetails
      }]}
    >
      <SelectField
        icon="schedule"
        fields={[{
          id: "branchClosing",
          label: "¿Último corte del día?",
          options: [
            { key: 1, value: "Si" },
            { key: 2, value: "No" }
          ],
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  cashClosings: state.records.cashClosings || defaultTableValue
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CashClosing);