const branches = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
    case 'PUT':
      if(props.branchSession) return `branches/${props._id}/session`;
      return `branches/${props._id}`;
    case 'GET':
    	if(props._id) return `branches/${props._id}` 
    	return `branches` 
    default:
      return 'branches';
  }
}

export default branches;