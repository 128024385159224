import { formDialogConstants } from '../_constants';

export const formDialog = (state = {}, action) => {
  switch (action.type) {
    case formDialogConstants.OPEN_FORM_DIALOG:
      return { ...state, [action.payload]: true };
    case formDialogConstants.CLOSE_FORM_DIALOG:
      return { ...state, [action.payload]: false };
    default:
      return state;
  }
};