import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const Dialog = props => {

  const handdleFilter = e => {
    const { employeeId } = props;
    props.getRecords({ table: 'salesActive', employeeId });
    props.closeFormDialog('Filter');
  }; 

	return (
	  <FormDialog  
      singular="Filter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <Autocomplete
        icon="person"
        label="Vendedor" 
        _id="employeeId" 
        options={[{ value: 0, label: 'TODOS' }, ...props.employees]}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({
  employeeId: state.field.value.employeeId || '',
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);