
const rentPriceToLabel = value => {
  switch (value){
    case "rentPerEvent":
      return "Renta por evento (Comercial)";
    case "rentPer1Month":
      return "Renta por 1 mes (Comercial)";
    case "rentPer2Months":
      return "Renta por 2 meses (Comercial)";
    case "rentPer3Months":
      return "Renta por 3 meses (Comercial)";
    case "rentPer4Months":
      return "Renta por 4 meses (Comercial)";
    case "rentPer5Months":
      return "Renta por 5 meses (Comercial)";
    case "rentPer6Months":
      return "Renta por 6 meses (Comercial)";
    case "rentPer7Months":
      return "Renta por 7 meses (Comercial)";
    case "rentPer8Months":
      return "Renta por 8 meses (Comercial)";
    case "rentPer9Months":
      return "Renta por 9 meses (Comercial)";
    case "rentPer10Months":
      return "Renta por 10 meses (Comercial)";
    case "rentPer11Months":
      return "Renta por 11 meses (Comercial)";
    case "rentPer12Months":
      return "Renta por 12 meses (Comercial)";
    case "rentPerEventIndustry":
      return "Renta por evento (Industria)";
    case "rentPer1MonthIndustry":
      return "Renta por 1 mes (Industria)";
    case "rentPer2MonthsIndustry":
      return "Renta por 2 meses (Industria)";
    case "rentPer3MonthsIndustry":
      return "Renta por 3 meses (Industria)";
    case "rentPer4MonthsIndustry":
      return "Renta por 4 meses (Industria)";
    case "rentPer5MonthsIndustry":
      return "Renta por 5 meses (Industria)";
    case "rentPer6MonthsIndustry":
      return "Renta por 6 meses (Industria)";
    case "rentPer7MonthsIndustry":
      return "Renta por 7 meses (Industria)";
    case "rentPer8MonthsIndustry":
      return "Renta por 8 meses (Industria)";
    case "rentPer9MonthsIndustry":
      return "Renta por 9 meses (Industria)";
    case "rentPer10MonthsIndustry":
      return "Renta por 10 meses (Industria)";
    case "rentPer11MonthsIndustry":
      return "Renta por 11 meses (Industria)";
    case "rentPer12MonthsIndustry":
      return "Renta por 12 meses (Industria)";
    default: return "-";
  }
};

export default rentPriceToLabel;