import React, { useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';
import { recordsActions, editValueAction  } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';

import { history } from './../../_helpers';

const SpareProducts = ({ getRecords, editValueAction, clearRecords, ...props}) => {
  const [ additionalText, setAdditionalText ] = useState("");

  useEffect(() => {
    props.redirect.id === "" && history.push('/Product');
  }, [props.redirect.id]);

  useEffect(() => { getRecords({ table: 'products', type: 2 }); return () => clearRecords('products'); }, [getRecords, clearRecords]);

  const products = useMemo(() => props.products.count > 0 ? props.products.rows.map(({id, name}) => ({ key: id, value: name })) : [], [props.products]);

  const spareProducts = useMemo(() => {
    let totalCost = 0;

    const tmpSpare = props.spareProducts.count > 0 ? props.spareProducts.rows.map(el => {
      totalCost += parseFloat(el.productionCost) * parseFloat(el.qty);
      return { 
        ID: { 
          show: false,
          value: el.id,
          data: el
        }, 
        Cantidad: {
          value: el.qty,
          _sort: 'number',
        }, 
        Nombre: {
         value: el.name,
         _sort: 'text',
         filter: true
        },
        Clasificación: {
          value: `${el.Clasification.name} (${el.SubClasification.name})`,
          _sort: 'text'
        },
        Costo: {
          value: moneyFormat(parseFloat(el.productionCost) * parseFloat(el.qty)),
          alt: parseFloat((parseFloat(el.productionCost) * parseFloat(el.qty)).toFixed(2)),
          align: 'right'
        }
      }
    }) : [];

    setAdditionalText(`Costo de Producción: ${moneyFormat(totalCost)}`);
    return tmpSpare;
  }, [props.spareProducts]);
  
  return (
    <Module
      table="spareProducts"
      name="Refacciones"
      singularName="Refacción"
      filter={false}
      title="Crear Refacción"
      records={spareProducts}
      download={true}
      additionalText={additionalText}
    >
      <SelectField
        fields={[{
          id: 'productId',
          label: 'Componente y/o refacción',
          options: products,
        }]}
      />
      <TextField
        fields={[{
          id: "qty",
          label: "Cantidad"
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStatesToProps = state => ({
  redirect: state.redirect,
  products: state.records.products || defaultTableValue,
  spareProducts: state.records.spareProducts || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction : value => dispatch(editValueAction(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value))
});

export default connect(mapStatesToProps, mapDispatchToProps)(SpareProducts);