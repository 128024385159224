import format from 'date-fns/format';

const api = [ "customerId", "productId", "address", "description", "collectionCommentary", "collectionDate" ]; 

const failures = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });

      const collectionTime = values.collectionTime ? values.collectionTime : '12:00:00';

      return { 
        ...tmp, 
        collectionDate:  values.collectionDate 
          ? format(new Date(values.collectionDate), 'yyyy/MM/dd') + ' ' + collectionTime 
          : null
      };

    default:
      return tmp;
  }
}

export default failures;