import React from "react";


import { FormDialog } from '../../_presentationals/FormDialog';

import { SelectField, TextField } from './../../_presentationals/Form';

const data = [{ key: 3, value: 'Recolectado' }];

const ChangeStatus = () =>
  <FormDialog 
    title="Actualizar Estatus"
    name="ChangeStatus"
    singular="ChangeStatus"
    table="logisticIndependent"
  >
    <SelectField
      fields={[{
        id: "status",
        label: "Estatus",
        options: data,
      }]}
    />
    <TextField
      fields={[{
        id: "commentary",
        label: "Comentario",
        required: false
      }]}
    />
  </FormDialog>
  
export default ChangeStatus;