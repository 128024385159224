import React, { useMemo, Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction  } from '../../_actions';

import ToysIcon from '@material-ui/icons/ToysOutlined';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import AcUnitIcon from '@material-ui/icons/AcUnitOutlined';

import { redirectActions, recordsActions } from '../../_actions';
import { config } from './../../_helpers/config';
import ReplaceDialog from './replace.dialog';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Component = props => {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    let tmpAction = [
      { icon: <ZoomInIcon fontSize="small" />, label: "Consultar Venta", handle: details }
    ];
    if(privileges.logistic && privileges.logistic.collection){ 
      tmpAction = [ ...tmpAction, { icon: <ToysIcon fontSize="small" />, label: "Remplazar Serie", handle: handleReplace }];
      tmpAction = [ ...tmpAction, { icon: <AcUnitIcon fontSize="small" />, label: "Remplazar Equipo", handle: handleReplaceProduct }];
    }
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logisticReplaces = useMemo(() => 
    props.logisticReplace.count > 0 ? props.logisticReplace.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha:{
        value: el.date,
        _sort: 'date'
      },
      Folio:{
        value: el.folio,
        filter: true,
        _sort: 'text'
      },
      Cliente:{
        value: el.customer,
        filter: true,
       _sort: 'text'
      },     
      Producto:{
        value: el.product,
        _sort: 'text'
      },    
      "Serie Física":{
        value: el.physicalSerie,
        _sort: 'text'
      }
    })) : []
  , [props.logisticReplace]);

  const handleReplace = e => {
    const {
      id, manufactureId, productId, status, methodPlan, deliveryDate, quantity
    } = JSON.parse(e.currentTarget.dataset.row);

    if(parseInt(status) === 6) return;
    props.editValueAction({ id, manufactureId, productId, status, methodPlan, deliveryDate, quantity });
    props.openFormDialog('ReplaceDialog');
  }
  const handleReplaceProduct = e => {
    const {
      id, manufactureId, status, methodPlan, deliveryDate, quantity
    } = JSON.parse(e.currentTarget.dataset.row);

    if(parseInt(status) === 6) return;
    props.editValueAction({ id, manufactureId, status, methodPlan, deliveryDate, quantity });
    props.openFormDialog('ReplaceDialog');
  }

  const details = e => {
    const { saleId, courtesyId } = JSON.parse(e.currentTarget.dataset.row);
    saleId ? window.open(`${config.apiUrl}/notes/sales/${saleId}`, '_blank') : window.open(`${config.apiUrl}/notes/courtesies/${courtesyId}`, '_blank');
  };

  return (
    <Fragment>
      <Module
        table="logisticReplace"
        name="Remplazo de Equipo"
        records={logisticReplaces}
        title="Remplazo de Equipo"
        actions={actions}
        edit={false}
        download={true}
        create={false}
        _delete={false}
        filter={false}
      >
      </Module>
      <ReplaceDialog/>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  logisticReplace: state.records.logisticReplace || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);