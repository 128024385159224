import React, { PureComponent, Fragment } from 'react';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import {
 Typography, ListItem, ListItemText, List, Grid, Avatar, Paper, ListItemAvatar
}from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';

class Account extends PureComponent {
  render(){
    const { classes, user, profile } = this.props;
    return(
      <Fragment>
        <main className={classes.main}>
          <Typography variant="h5" color="inherit" noWrap gutterBottom align="center">
            Información personal
          </Typography>
          <Typography  component="p" align="center">
            Información básica que usas en los servicios de Kool Tech
          </Typography>
          <div className={classes.root}>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={1}>
                  <List className={classes.listItems}>
                   <ListItem>
                     <Typography variant="h5" component="p" align="left">
                       Perfil
                     </Typography>
                   </ListItem>
                   <ListItem>
                     <ListItemAvatar>
                       <Avatar
                        alt=""
                        src={profile}
                        >
                       </Avatar>
                     </ListItemAvatar>
                     <ListItemText primary="Foto" secondary="Agrega una nueva para personalizar mas tu perfil" />
                   </ListItem>
                   <ListItem>
                     <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                     </ListItemAvatar>
                     <ListItemText primary="Nombre" secondary={user.firstName + " " +user.lastName} />
                   </ListItem>
                   <ListItem >
                     <ListItemAvatar>
                        <Avatar>
                          <EmailIcon />
                        </Avatar>
                     </ListItemAvatar>
                     <ListItemText primary="Nombre de usuario"  secondary={user.username} />
                   </ListItem>
                   <ListItem  >
                   <ListItemAvatar>
                      <Avatar>
                        <LockIcon />
                      </Avatar>
                   </ListItemAvatar>
                   <ListItemText primary="Contraseña" secondary="Última fecha de modificación" />
                   </ListItem>
                 </List>
                </Paper>
              </Grid>
            </Grid>
        </div>
        </main>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Account);
