import React, { useState, Fragment } from "react";

import randomize from 'randomatic';
import { connect } from 'react-redux';

import { FormDialog } from './../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import TextField from './../Sales.v2/helpers/TextField';
import SelectField from './../Sales.v2/helpers/SelectField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import InvoiceData from './../Sales.v2/InvoiceData';

const defaultValues = {
  paymentType: 1,
  daysToPay: 0,
  employeeId: null,
  paymentForm: "03",
  useCfdi: "G03",
  commentary: "",
  bank: "",
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const paymentMethodOptions = [
  { value: 1, label: "Contado" },
  { value: 2, label: "Contado por pagar" },
  { value: 3, label: "Crédito" },
];

const paymentFormOptions = [ 
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];

const useCfdiOptions = [
  { value: "G01", label: "Adquisición de mercancias" },
  { value: "G03", label: "Gastos en general" },
  { value: "I08", label: "Otra maquinaria y equipo" },
  { value: "P01", label: "Por definir" }
];

const bankOptions = [
  { value: 1, label: "Scotiabank 4377 Culiacán" },
  { value: 2, label: "Scotiabank 2815 Culiacán" },
  { value: 3, label: "Banregio Culiacán" },
  { value: 4, label: "Bancomer 0499" },
  { value: 5, label: "Scotiabank 4285 Mazatlán" },
  { value: 6, label: "Banregio 0013 Mazatlán " },
  { value: 7, label: "Scotiabank 4393 Mexicali" },
  { value: 8, label: "Banregio Mexicali" },
  { value: 9, label: "Bancomer 0097 Hermosillo" },
  { value: 10, label: "Banregio Hermosillo" },
];

const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDisabled, setIsDisabled ] = useState(false);
  const [ isInvoiceDataOpen, setIsInvoiceDataOpen ] = useState([false, null]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));

    if(name === 'paymentType') {
      if(parseInt(value) === 1){
        setValues(values => ({ ...values, daysToPay: 0 }));
        setIsDisabled(true);
      }else{
        setIsDisabled(false);
      }
    }
  }

  const handleInvoiceDataClose = () => {
    setIsInvoiceDataOpen([false, null]);
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const handleAction = async () => {
    try{
      setIsLoading(true);
      const response = await recordsServices.createV2(`v2/serviceOrders/${props.serviceOrderId}/sale`, {
        ...values,
        employeeId: values.employeeId ? values.employeeId.value : null,
        method: (parseInt(values.paymentType) === 3) ? 'PPD' : 'PUE',
        bank: (values.paymentForm === '01' || values.bank === "") ? null : values.bank,
      }).then(data => {
        setIsLoading(false);
        return data;
      }).catch(err => {
        setIsLoading(false);
        return err;
      });
      if(response.errorCode) throw new Error(response.errorMessage);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: response.message, 
        severity: 'success' 
      }));
      setValues(defaultValues);
      props.closeFormDialog('Sale');
      setIsInvoiceDataOpen([true, response.uuid]);
      const tmpCode = randomize('*', 10);
      props.reload(tmpCode);
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog 
        title="Datos de facturación"
        name="sale"
        isLoading={isLoading}
        singular="Sale"
        handleCustomizedClick={handleAction}
      >
        <SelectField 
          name='paymentForm'
          label='Forma de Pago' 
          onChange={handleChange}
          options={paymentFormOptions}
          value={values.paymentForm}
        />
        <SelectField 
          name='useCfdi'
          label='Uso del CFDi' 
          onChange={handleChange}
          options={useCfdiOptions}
          value={values.useCfdi}
        />
        <SelectField 
          name='paymentType'
          label='Método de Pago' 
          onChange={handleChange}
          options={paymentMethodOptions}
          value={values.paymentType}
        />
        <TextField 
          name='daysToPay'
          label='Plazo de Pago'
          type='number' 
          disabled={isDisabled}
          onChange={handleChange}
          value={values.daysToPay}
        />
        { values.paymentForm !== '01' &&
          <SelectField 
            name='bank'
            label='Cuenta' 
            onChange={handleChange}
            options={bankOptions}
            value={values.bank}
          />
        }
        <TextField
          name='commentary'
          label='Comentario'
          onChange={handleChange}
          value={values.commentary}
        />
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
      <InvoiceData isOpen={isInvoiceDataOpen[0]} uuid={isInvoiceDataOpen[1]} handleClose={handleInvoiceDataClose} />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);