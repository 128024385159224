import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import { resetFormAction } from './../../_actions';

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const mapDispatchToProps = dispatch => ({
  resetFormAction: () => dispatch(resetFormAction())
});

export const Unauthenticated = connect(null, mapDispatchToProps)(
  ({ component: C, resetFormAction, ...rest }) => {
    const redirect = querystring("redirect");
    resetFormAction();
    return (
      <Route
        {...rest}
        render={props =>
          !localStorage.getItem('session')
          ? <C {...props} />
          : <Redirect
              to={redirect === "" || redirect === null ? "/" : redirect}
            />
        }
      />
    );
  }
);