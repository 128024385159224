import React, { Fragment, useState } from 'react';

import Typography from '@material-ui/core/Typography';

import Snackbar from './../../Sales.v2/helpers/Snackbar';
import * as recordsServices from './../../../_services/records.services';
import { ConfirmDialog } from './../../../_presentationals/ConfirmDialog';
import Button from './../../../_presentationals/Button';
import Table from './Table';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
	const [ logisticId, setLogisticId ] = useState(0);
	const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
	
	const handleCollection = () => setLogisticId(props.logisticId);

	const handleCancelCollection = () => setLogisticId(0);

	const handleActionCollection = async () => {
		try{
      await recordsServices.updateV2(`v2/logistics/${props.logisticId}/collection`).then(data => {
        if(data.errorCode) throw new Error(data.errorMessage);
	      setSnackbar(snackbar => ({ 
	        ...snackbar, 
	        isOpen: true, 
	        label: 'Los productos fueron recolectado exitosamente', 
	        severity: 'success' 
	      }));
	      props.reload(true);
	      setLogisticId(0);
      });
    }catch(e){
      setLogisticId(0);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
	}

	const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

	return (
		<Fragment>
			<Typography variant="body2" gutterBottom>
        Al recolectar todos los productos de la logística el estatus de la misma pasara a "Recolectado".
        <b> Al aplicar esta acción no se podrá revertir los cambios.</b>
      </Typography>
      <Button
        variant='outlined'
        onClick={handleCollection}
        label='Recolectar todos los productos' 
      />
			<Table 
				logisticId={props.logisticId}
				Products={props.Products}
				reload={props.reload}
			/>
			<ConfirmDialog 
        _id={logisticId} 
        handleCancel={handleCancelCollection} 
        handleAction={handleActionCollection} 
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
		</Fragment>
	);
}

export default Component