const api = ['employeeId', 'customerId'];

const courtesies = (method, values) => {
   const tmp = {};
	switch (method) {
		case 'POST':
			Object.keys(values).forEach(key => {
      	  if(api.includes(key)) { tmp[key] = values[key];}
   	 	});
         tmp['catchment'] = values['catchment'];
         tmp['commentary'] = values['commentary'];
   	 	tmp['Products'] = [];
   	 	values['Products'].forEach((ele,index) => {
   	 		const { PhysicalSeries, productId, quantity, isRented, addressLine1, addressLine2, city, zipCode, countryId, stateId, reference, deliveryDate, deliveryTime, deliveryComment, mobility } = ele;

            tmp['Products'].push({productId, quantity});
   	 		if (isRented) {
   	 			tmp['Products'][index]['rentPlan'] = [{ quantity: ele.planQuantity, method: ele.method }];
   	 		}

            tmp['Products'][index]['mobility'] = mobility;
            tmp['Products'][index]['addressLine1'] = addressLine1 ? addressLine1 : "";
            tmp['Products'][index]['addressLine2'] = addressLine2 ? addressLine2 : "";
            tmp['Products'][index]['city'] = city ? city : "";
            tmp['Products'][index]['zipCode'] = zipCode ? zipCode : "";       
            tmp['Products'][index]['reference'] = reference ? reference : "";
            tmp['Products'][index]['deliveryDate'] = deliveryDate ? deliveryDate : "";
            tmp['Products'][index]['deliveryTime'] = deliveryTime ? deliveryTime : "";
            tmp['Products'][index]['deliveryComment'] = deliveryComment ? deliveryComment : "";
            tmp['Products'][index]['PhysicalSeries'] = PhysicalSeries || [];

            if (countryId) {
               tmp['Products']['countryId'] = countryId;
            }

            if (stateId) {
               tmp['Products']['stateId'] = stateId;
            }  

   	 	});
         
   	 	return tmp;
   	 default:
   	 	return tmp;
	}
}

export default courtesies;