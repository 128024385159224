import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

// import CircleIcon from '@material-ui/icons/AddCircleOutline';
import ProductIcon from '@material-ui/icons/ToysOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

import { redirectActions } from '../../_actions';
import { history } from './../../_helpers';
import Module from '../../_presentationals/Module/index.v2';
import { TextField } from './../../_presentationals/Form/TextField';

import * as recordsServices from '../../_services';
import ConsignmentPdf from './consignment.pdf';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Consignment = props => {

  const [ consignments, setConsignments ] = useState([]);
  const [ loadData, setLoadData ] = useState(0);

  const redirect = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    props.setRedirect({id: data.id, table: 'consignmentManufactures', returnTo: '/Consignment', returnToLabel: 'Consignaciones'});
    history.push('/Consignment/Manufacture'); 
  };

  const handelPdf = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    ConsignmentPdf(id);
  }

  useEffect(() => {
    (props.alert === "success" || !loadData) && 
    recordsServices.getAll({ table: 'consignments' }).then(data => {
      data.count > 0 && setConsignments(data.rows.map(el => ({
        ID: { 
          value: el.id,
          data: el,
          show: false,
        }, 
        Nombre: {
         value: el.name,
         filter: true,
         _sort: 'text'
        },
        Teléfono: { 
          value: el.phone, 
          _sort: 'text'
        },
        Domicilio:{
          value: el.addressLine1 + (el.addressLine2 !== "" ? ', ' + el.addressLine2 : "") + (el.city !== "" ? ', ' + el.city : "") + (el.zipCode !== "" ? ', ' + el.zipCode : "") + (el.state !== "" ? ', ' + el.state : "")
        }
      })));
      setLoadData(1);
    });
  }, [loadData, props.alert]);

  return (
    <Module
      table="consignments"
      name="consignatarios"
      singularName="consignatario"
      title="Crear Consignatario"
      records={consignments}
      download={true}
      filter={false}
      actions={ (privileges.consignmentManufactures && privileges.consignmentManufactures.read) && [
        { icon: <PictureAsPdfIcon fontSize="small" />, label: "PDF", handle: handelPdf },
        { icon: <ProductIcon fontSize="small" />, label: "Consignar Equipos", handle: redirect },
      ]}
    >
      <TextField
        icon="account_circle"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        icon="phone"
        fields={[{
          id: "phone",
          label: "Teléfono"
        }]}
      />
      <TextField
          icon="room"
          fields={[
            {
              id: "addressLine1",
              label: "Calle y Número",
              multiline: true,
              required: false
            },
            {
              id: "addressLine2",
              label: "Colonia",
              multiline: true,
              required: false
            }
          ]}
        />
        <TextField
          fields={[
            {
              id: "city",
              label: "Ciudad",
              required: false
            },
            {
              id: "zipCode",
              label: "Código Postal",
              required: false
            }
          ]}
        />
        <TextField
          fields={[{
            id: "state",
            label: "Estado",
            required: false
          }]}
        />
    </Module>
  );
}

const mapStateToProps = state => ({
  alert: state.alert.type
});

const mapDispatchToProps = dispatch => ({
  setRedirect: data => dispatch(redirectActions.setRedirect(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Consignment);