import React from "react";

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

const WarrantyDialog = props =>
  <FormDialog 
    title="Enviar a Garantía y Fallas"
    singular="WarrantyDialog"
    table="failures"
  >
    <TextField
      icon="comment"
      fields={[
        {
          id: "commentary",
          label: "Comentario",
        }
      ]}
    />  
  </FormDialog>


export default WarrantyDialog;