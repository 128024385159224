import React, { Fragment, useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';

import PersonIcon from '@material-ui/icons/Person';
import ProductIcon from '@material-ui/icons/ToysOutlined';
// import Build from '@material-ui/icons/Build';

import { TextField } from './../../_presentationals/Form';
import { Snackbar } from '../../_presentationals/Snackbar';
import ListItemEdit from './_ListItemEdit';
import CustomerDialog from './_CustomerDialog';
import ProductDialog from './_ProductDialog';
// import ManufactureDialog from './_ManufactureDialog';
import Header from './_Header';

import { styles } from './style';
import { openFormDialog, recordsActions, alertActions } from './../../_actions';

const Failure = ({ classes, getRecords, createRecords, ...props }) => {
  
  useEffect(() => {
    getRecords({ table: 'customers' });
    getRecords({ table: 'products', type: 1 });
  },[getRecords]);

  const products = useMemo(() => (
    props.products.count > 0 ? props.products.rows.map(({id, name}) => ({ value: id, label: name })) : []
  ), [props.products]);

  const customers = useMemo(() => (
    props.customers.count > 0 ? props.customers.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName} ${el.CustomerPhones[0].phone}`, customerDiscount: el.discountRange, address: el.CustomerAddress, billingInformationId: (el.BillingInformation && el.BillingInformation.id) ? el.BillingInformation.id : 0 })) : []
  ),[props.customers]);

  //MESSAGES
  useEffect(() => {
    const handleError = props.errorMessage;
    let key = "";
    Object.keys(props.validation).forEach(el => { 
      if(props.validation[el].isError === true){
        key = el; 
      }
    });
    const message = errorMessage(key);
    if(key !== "" && message !== ""){
      handleError(message);
    }
  }, [props.validation, props.errorMessage]);


  const OpenCustomerDialog = () => props.openFormDialog('CustomerDialog');
  const OpenProductDialog = () => props.openFormDialog('ProductDialog');
  // const OpenManufactureDialog = () => props.openFormDialog('ManufactureDialog');

  const handleFinish = () => {
    createRecords('failures');
  }

  return (
    <Fragment>
      <Header handleClick={handleFinish} />
      <List dense={true} className={ classes.noPadding }>
        <ListItemEdit
          icon={<PersonIcon />}
          title="Cliente"
          text={props.customerIdLabel}
          handleClick={OpenCustomerDialog}
        />
        <Divider />
        <ListItemEdit
          icon={<ProductIcon />}
          title="Modelo"
          text={props.productIdLabel}
          handleClick={OpenProductDialog}
        />
      </List>
      <Divider />
      <Fragment>
        <Grid container spacing={1} className={classes.padding}>
          <Grid item xs={12}>
            <TextField
              dialog={false}
              fields={[{
                id: "description", 
                label: "Descripción",
                multiline: true,
                rows: 10,
                variant: "outlined",
                required: true
              }]}
            />
          </Grid>
        </Grid>
      </Fragment>
      <CustomerDialog customers={customers} />
      <ProductDialog products={products} />
      <Snackbar />
    </Fragment>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const errorMessage = key => {
  switch(key){
    case "customerId":
      return "Seleccione un cliente.";
    case "productId":
      return "Seleccione un modelo.";
    case "physicalSerie":
      return "Escriba una serie física.";
    case "description":
      return "Escriba una descripción de la falla.";
    case "Error":
      return "Error desconocido, favor de reportar."
    default:
      return "";
  }
}

const mapStateToProps = state => ({
  productId: state.field.value.productId || null,

  customers: state.records.customers || defaultTableValue,
  products: state.records.products || defaultTableValue,

  customerIdLabel: state.field.value.customerIdLabel ? state.field.value.customerIdLabel : 'No Asignado',
  productIdLabel: state.field.value.productIdLabel ? state.field.value.productIdLabel : 'No Asignado',
  physicalSerie: state.field.value.physicalSerie ? state.field.value.physicalSerie : 'No Asignada',

  validation: state.field.validation,
  alert: state.alert.type,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  createRecords: table => dispatch(recordsActions.create(table)),
  errorMessage: value => dispatch(alertActions.error(value)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Failure));