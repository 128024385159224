const cashClosings = ({method, ...props}) => {
  switch (method) {
    case 'GET':
      return `cashClosings/`;
    default:
      return 'cashClosings';
  }
}

export default cashClosings;
