import { format } from "date-fns";
import locale from 'date-fns/locale/es';

const logistic = ({method, ...props}) => {

	switch (method) {
		case 'PUT':
			return `v2/logistics/${props._id}`
    default:
    	const startFormat = props.start ? new Date(props.start) : null;
      const finishFormat = props.finish ? new Date(props.finish) : null;

      const start = startFormat && format(startFormat, 'yyyy/MM/dd', {locale});
      const finish = finishFormat && format(finishFormat, 'yyyy/MM/dd', {locale});

      const query = { start, finish, employeeId: props.employeeId };
      Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null || query[key] === '' || query[key] === 0) && delete query[key]);      
      let querys = '';
      Object.keys(query).forEach(key => {
        querys += `${key}=${query[key]}&`;
      });
      return `v2/logistics?${querys}`;
  }
}

export default logistic;