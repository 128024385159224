import { format } from "date-fns";
import locale from 'date-fns/locale/es';

const courtesyAuths = ({method, ...props}) => {
  switch (method) {
    case 'DELETE': 
      return `v2/courtesies/${props._id}`;
    default:
      // if(props.filter) {
        const startFormat = props.start ? new Date(props.start) : new Date();
        const finishFormat = props.finish ? new Date(props.finish) : new Date();

        const start = format(startFormat, 'yyyy/MM/dd', {locale});
        const finish = format(finishFormat, 'yyyy/MM/dd', {locale});

        return `v2/courtesies?start=${start}&finish=${finish}&status=1`;
      // }
      // return `v2/courtesies/actives`;
  }
}

export default courtesyAuths;