import React, { Fragment, useMemo  } from "react";
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/es';

import Module from '../../_presentationals/Module';
import { openFormDialog, recordsActions } from '../../_actions';

import Filter from './filter.dialog';

const Component = props => {

  const manufactureSale = useMemo(() =>
    props.manufactureSale.count > 0 ? props.manufactureSale.rows.map(el => { 
      return {
        ID: { 
          show:false,
          value: el.id,
          data: el,
        },
        Fecha: { 
          value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale}),
          _sort: 'date',
          noBreak: true
        },
        Folio: {
          value: <a href={el.uri} target="_blank" rel="noopener noreferrer">{el.folio}</a>,
          alt: el.folio,
          _sort: 'text',
          noBreak: true
        },
        Equipo: {
          value: el.product || '-',
          _sort: 'text'
        },
        Cliente: {
        	value: el.customer || '-',
          _sort: 'text'
        },
        Teléfono: {
          value: el.phone || '-',
          _sort: 'text'
        },
      }
    }) : []
  , [props.manufactureSale]);

  const handleFilter = () => {
    props.openFormDialog('Filter');
  }

  return (
    <Fragment>
      <Module
        table="manufactureSale"
        name="Reporte de Equipos Facturados"
        records={manufactureSale}
        singularName="Reporte"
        title="Filtrar Equipos"
        filter={handleFilter}
        create={false}
        _delete={false}
        edit={false}
        download={true}
      />
      <Filter />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: [] };

const mapStateToProps = state => ({
  manufactureSale: state.records.manufactureSale || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);