import React, { useMemo, useState, Fragment  } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';

import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import PiecesDialog from './PiecesDialog';
import NoteDialog from './NoteDialog';
import FilterDialog  from './filter';
import OutDialog  from './outDialog';
import PhysicalSerieDialog  from './physical.serie.dialog';

// const session = JSON.parse(localStorage.getItem('session'));
// const privileges = session && JSON.parse(session.data.privileges);

const Warranty = ({openFormDialog, editValueAction, ...props}) => {
  const [ spareProducts, setSpareProducts ] = useState([]);
  const [ availableProducts, setAvailableProducts ] = useState([{value: null, label: 'Todos'}]);

  const warranties = useMemo(() => {
    const tmp = props.warranties.count > 0 ? props.warranties.rows.map(el => {
      
      setAvailableProducts(oldArray => !oldArray.some(children => parseInt(children.value) === parseInt(el.productId)) 
        ? [ ...oldArray, { value: el.productId, label: el.name } ]
        : oldArray
      );
      
      return {
        ID: { 
          value: el.id,
          data: el,
          _sort: 'number',
          show: false
        }, 
        Fecha:{
          value: format(parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES}),
          _sort: 'date'
        },
        'Serie Física': {
          value: el.physicalSerie,
          alt: parseInt(el.physicalSerie),
          _sort: 'alt'
        },
        Producto: {
          value: el.name,
          _sort: 'text',
          filter: true
        },
        Técnico: {
          value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : '-',
          _sort: 'text',
        },
        Estatus: {
          value: el.status.label,
          _sort: 'text',
        },
        Comentario:{
          value: el.commentary
        },
        Cliente: {
          value: el.Customer ? `${el.Customer.firstName} ${el.Customer.lastName}` : '-',
          _sort: 'text',
          filter: true
        },
        Precio: {
          value: moneyFormat(el.total),
          alt: parseFloat(parseFloat(el.total).toFixed(2)),
          align: 'right',
          accumulate: true, accumulateLabel: 'Costo de Reparación',
          _sort: 'alt'
        }
      }
    }) : [];
    return tmp;
  }, [props.warranties]);

  const handlePieces = e =>{
    const { id, Pieces } = JSON.parse(e.currentTarget.dataset.row);
    setSpareProducts(Pieces);
    editValueAction({ id, _id: id, });
    openFormDialog('PiecesDialog');
  }

  const handleSerie = e => {
    const { id, manufactureId } = JSON.parse(e.currentTarget.dataset.row);
    if(!manufactureId) {
      editValueAction({ id, _id: id, isSerie: true });
      openFormDialog('PhysicalSerieDialog');
    }
  };

  const handleOut = e => {
    const { id, manufactureId } = JSON.parse(e.currentTarget.dataset.row);
    if(manufactureId) {
      editValueAction({ id, _id: id, isOut: true });
      openFormDialog('OutDialog');
    }
  };

  const filter = () => {
    openFormDialog('WarrantyFilter');
  };

  return (
    <Fragment>
      <Module
        table="warranties"
        name="Garantías y Fallas"
        records={warranties}
        singularName="Garantía y Falla"
        title="equipos en reparación"
        filter={filter}
        create={false}
        edit={false}
        _delete={true}
        download={true}
        actions={[{
          icon: <ExtensionIcon fontSize="small" />,
          label: "Serie Física",
          handle: handleSerie
        },{
          icon: <AssignmentTurnedInIcon fontSize="small" />,
          label: "Realizar Inspección",
          handle: handlePieces
        },
        // {
        //   icon: <ShoppingCartIcon fontSize="small" />,
        //   label: "Nota de Venta",
        //   handle: handleSale
        // },{
        //   icon: <CardGiftcard fontSize="small" />,
        //   label: "Cortesía",
        //   handle: handleCourtesy
        // },
        {
          icon: <DoneAllIcon fontSize="small" />,
          label: "Finalizar Proceso",
          handle: handleOut
        }]}
      >
      </Module>
      <PiecesDialog spareProducts={spareProducts} />
      <FilterDialog products={availableProducts} />
      <NoteDialog />
      <OutDialog />
      <PhysicalSerieDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  warranties: state.records.warranties || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  update: (data) => dispatch(recordsActions.update(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Warranty);