const countries = localStorage.getItem('countries') ? JSON.parse(localStorage.getItem('countries')) : { count: 0, rows: [] };
const session = JSON.parse(localStorage.getItem('session'));

export const isMaster = session ? Boolean(session.data.master) : false;
export const countriesSelectField = countries.rows.map(({id, name}) => ({ key: id, value: name }))
export const statesSelectField = countryId => {
	const states = countries.rows.map(({ id, states }) => 
		(parseInt(id) === parseInt(countryId)) && states.rows.map(el => ({ key: el.id, value: el.name }))
	).filter(el => el);
	return states[0] ? states[0] : [{ key: '', value: 'No se encontraron valores para mostrar.' }];
}