import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField, AutocompleteField } from './../../_presentationals/Form';
import { recordsActions } from './../../_actions';

const NoteDialog = ({ getRecords, ...props }) => {

  useEffect(() => { 
    getRecords({ table: 'sellers', sellers: true }); 
    getRecords({ table: 'customers' }); 
  }, [ getRecords ]);
  
  const sellers = useMemo(() => (
    props.sellers.rows ? props.sellers.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : []
  ), [props.sellers]);
  
  const customers = useMemo(() => (
    props.customers.count > 0 ? props.customers.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName} ${el.CustomerPhones[0].phone}`, customerDiscount: el.discountRange, address: el.CustomerAddress, billingInformationId: (el.BillingInformation && el.BillingInformation.id) ? el.BillingInformation.id : 0 })) : []
  ), [props.customers]);
  
  return (
    <FormDialog 
      title={`Emitir Nota de ${ props.isSale ? 'Venta' : 'Cortesía' }`}
      name="NoteDialog"
      singular="NoteDialog"
      table="warrantyNotes"
    >
      <AutocompleteField
        label="Cliente" 
        _id="customerId"
        options={ customers }
      />
      <AutocompleteField
        label="Vendedor" 
        _id="employeeId"
        options={ sellers }
      />
      <TextField
        fields={[{
          id: "commentary",
          label: "Comentario",
          required: false
        }]}
      />  
    </FormDialog>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  sellers: state.records.sellers || defaultTableValue,
  customers: state.records.customers || defaultTableValue,
  isSale: state.field.value.isSale || false,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteDialog);