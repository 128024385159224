import React, { Fragment, useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import { recordsActions, editValueAction, openFormDialog } from '../../_actions';
import Module from '../../_presentationals/Module';
import { format } from 'date-fns'
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Receipt from '@material-ui/icons/Receipt';

import WarrantyDialog from './_WarrantyDialog';
import FailureTicket from './failureTicket';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const FailureReport = props => {

  useEffect(() => {
    props.getRecords({ table: 'products', type: 2 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.getRecords]);

  const products = useMemo(() => (
    props.products.rows ? props.products.rows.map(({id, name, productionCost}) => ({ value: id, label: name, price: productionCost })) : []
  ),[props.products]);

  const handleWarranty = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    props.editValueAction({ id });
    props.openFormDialog('WarrantyDialog');
  }

  const handleTicket = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    FailureTicket(id)
  }

  const failures = useMemo(() => {
    return props.failures.count > 0 ? props.failures.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      "Fecha de Registro":{
        value: format(parseISO(el.createdAt), "dd/MMM/yyyy HH:mm", {locale: localeES})
      },
      Cliente: {
        value: el.Customer.firstName,
        filter: true,
        _sort: 'text'
      },
      Producto: {
        value: el.Product.name,
        filter: true,
        _sort: 'text'
      },
      Descripción: {
        value: el.description,
       _sort: 'text'
      },
      Recolectar: {
        value: el.isCollection ? 'SI' : 'NO'
      },
      Usuario: {
        value: el.User.firstName+' '+el.User.lastName,
        _sort: 'text'
      }
    })) : []
  }, [props.failures]);

  return (
    <Fragment>
      <Module
        table="failures"
        name="Seguimiento de Garantías y Fallas"
        records={failures}
        singularName="seguimiento"
        filter={false}
        edit={false}
        create={false}
        _delete={true}
        download={true}
        actions={ (privileges.failures && privileges.failures.warranty) && [{
          icon: <Receipt fontSize="small" />, label: "Ticket",handle: handleTicket
        },{
          icon: <LocalShippingIcon fontSize="small" />, label: "Recolección", handle: handleWarranty
        }]}
      >
      </Module>
      <WarrantyDialog products={products} _id={props._id} />
    </Fragment>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  _id: state.field.value._id || null,
  failures: state.records.failures || defaultTableValue,
  products: state.records.products || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(FailureReport);