import React, { useEffect, useState  } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

// import { editValueAction, recordsActions } from './../../_actions';
import * as recordsServices from '../../_services';

const Manufacture = ({ productId, isRent, ...props }) => {

  const [ physicalSerie, setPhysicalSerie ] = useState([]);
  
  useEffect(() => {
    parseInt(productId) > 0 && recordsServices.getAll({ table: 'manufactures', productId, status: isRent ? 3 : 2 }).then(async data => {
      const tmp = await parseInt(data.count) > 0 ? data.rows.map(el => ({ value: el.id, label: `${el.Product.name} Serie: ${el.physicalSerie} ${el.statusLabel}` })) : [];
      setPhysicalSerie(tmp);
    });
  }, [productId, isRent]);

  // useEffect(() => { getRecords({ table: 'manufactures', productId, status: isRent ? 3 : 2 }); return () => clearRecords('manufactures'); }, [getRecords, productId, clearRecords, isRent]);

  // const availablePhysicalSerie = useMemo(() => {
  //   return props.manufactures.rows ? props.manufactures.rows.map(({id, Product, physicalSerie, statusLabel}) => ({ value: id, label: `${Product.name} Serie: ${physicalSerie} ${statusLabel}` })) : [];
  // }, [props.manufactures]);
  
  return (
    <FormDialog 
      title="Asignar manufactura"
      name="PhysicalSerie"
      singular="PhysicalSeries"
      table="logisticProduct"
    >
      <Autocomplete
        label="Serie Física" 
        _id="manufactureId" 
        options={ physicalSerie }
      />
    </FormDialog>
  );

}

// const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  isRent: state.field.value.isRent || 0,
  productId: state.field.value.productId || 0
});

// const mapDispatchToProps = dispatch => ({
//   editValueAction: data => dispatch(editValueAction(data))
// });

export default connect(mapStateToProps)(Manufacture);