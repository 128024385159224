import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const catchmentsReport = ({method, ...props}) => {
  switch (method) {
    case 'GET':
  	 	const start = format(props.start ? new Date(props.start) : new Date(), 'yyyy/MM/dd', {locale: localeES});
      const finish = format(props.finish ? new Date(props.finish) : new Date(), 'yyyy/MM/dd', {locale: localeES});

    	return `catchments/reports?start=${start}&finish=${finish}&employeeId=${props.employeeId}&productId=${props.productId}`;
    default:
      return 'catchments';
  }
}

export default catchmentsReport;
