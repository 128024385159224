import { formConstants } from '../_constants';

const { EDIT_FIELD_VALUE, RESET_FIELD_VALUE, VALIDATE_FIELD, EDIT_TYPE_FIELD, RESET_FIELDS, EDIT_ID_FIELD } = formConstants;
const editValueAction = data => ({type: EDIT_FIELD_VALUE,payload: data,});
const editTypeAction = data => ({type: EDIT_TYPE_FIELD, payload: data,});
const editIdentifierAction = data => ({type: EDIT_ID_FIELD, payload: data,});
const validateFieldAction = data => ({type: VALIDATE_FIELD, payload: data,});
const resetValueAction = data =>  ({type: RESET_FIELD_VALUE,payload: data});
const resetFormAction = () => ({type: RESET_FIELDS});

export { editValueAction, editIdentifierAction, validateFieldAction, editTypeAction, resetValueAction, resetFormAction }
