import React, { useMemo, Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { format } from 'date-fns';

import Module from '../../_presentationals/Module';
import { editValueAction, recordsActions, openFormDialog } from './../../_actions';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { TextField } from './../../_presentationals/Form';

import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckOutlined';

import { ListItemAdd } from './listItemAdd';
import MigrationPdf from './migration.pdf';
import ReceptionForm from './reception.form';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Migration = ({editValueAction, getRecords, ...props}) => {
  const [manufactures, setManufactures] = useState([]);
  const [models, setModels] = useState([]);
  const [spareParts, setSpareParts] = useState([]);
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [actions, setActions] = useState([]);
  const [confirmationProducts,setConfirmationProducts] = useState([]);

  useEffect(() => {
    let tmpAction = [
      { icon: <PictureAsPdfIcon fontSize="small"/>, label: 'Consultar', handle: handlePdf }
    ];
    if(privileges.migrations && privileges.migrations.confirmation){ 
      tmpAction.push({ icon: <PlaylistAddCheckIcon fontSize="small" />, label: "Confirmación", handle: handleReception });
    }
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    if(props.alert === "success") setLocal([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.alert]);

  useEffect(() => {
    if(!props.isOpen.Traspaso) {
      const tmpManufactures = props.manufactures.rows ? props.manufactures.rows.map(({ id, Product, physicalSerie }) => ({ value: id, label: `${Product.name} - ${physicalSerie}`, physicalSerie, productId: Product.id })) : [];
      setManufactures(tmpManufactures);
      setLocal([]);

      const tmpModels = props.products.rows ? props.products.rows.reduce((tmp=[],el) => {
        if(parseInt(el.type.id) === 1) return [ ...tmp, { value: el.id, label: el.name }];
        return tmp;
      },[]) : [];

      const tmpSapres = props.products.rows ? props.products.rows.reduce((tmp=[],el) => {
        if(parseInt(el.type.id) === 2) return [ ...tmp, { value: el.id, label: el.name }];
        return tmp;
      },[]) : [];

      setModels(tmpModels);
      setSpareParts(tmpSapres);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.isOpen]);

  useEffect(() => { 
    getRecords({ table: 'branches' }); 
    getRecords({ table: 'manufactures' }); 
    getRecords({ table: 'products' });
  }, [ getRecords ]);

  const branches = useMemo(() => {
    return props.branches.rows ? props.branches.rows.map(({ id, name }) => ({ value: id, label: name })) : [];
  }, [props.branches]);

  useEffect(() => {
    const tmp = props.manufactures.rows ? props.manufactures.rows.map(({ id, Product, physicalSerie }) => ({ value: id, label: `${Product.name} - ${physicalSerie}`, physicalSerie, productId: Product.id })) : [];
    setManufactures(tmp);
    editValueAction({ Products: [], modelId: '', manufactureId: '', productId: '', quantity: 0, initial: 0, fin: 0 });
  }, [props.manufactures,editValueAction]);

  useEffect(() => {
    const tmpModels = props.products.rows ? props.products.rows.reduce((tmp=[],el) => {
      if(parseInt(el.type.id) === 1) return [ ...tmp, { value: el.id, label: el.name }];
      return tmp;
    },[]) : [];

    const tmpSapres = props.products.rows ? props.products.rows.reduce((tmp=[],el) => {
      if(parseInt(el.type.id) === 2) return [ ...tmp, { value: el.id, label: el.name }];
      return tmp;
    },[]) : [];

    setModels(tmpModels);
    setSpareParts(tmpSapres);
    editValueAction({ Products: [], modelId: '', manufactureId: '', productId: '', quantity: 0, initial: 0, fin: 0 });
  }, [props.products,editValueAction]);

  const handleAddManufacture = e => {
    const { modelId } = props.selectedModel;
    const { manufactureId, manufactureIdLabel } = props.selectedManufacture;
    const { productId, productIdLabel } = props.selectedProduct;
    if(!modelId && !manufactureId && !productId) return;
    if(modelId && manufactureId && productId) return;

    if(modelId) {
      if(!props.initial || props.initial === '') return;
      if(!props.fin || props.fin === '') return;

      const tmpResult = manufactures.reduce((newObject={series: [], models: []},el) => {
        if(isNaN(parseInt(el.physicalSerie))) return newObject;
        if(parseInt(el.productId) !== parseInt(modelId)) return newObject;
        if(parseInt(el.physicalSerie) < parseInt(props.initial) || parseInt(el.physicalSerie) > parseInt(props.fin)) return newObject;
        return { series: [ ...newObject.series, el.value ], models: [ ...newObject.models, { manufactureId: el.value, name: el.label, quantity: 1 }] };
      },{series: [], models: []});
      
      const tmpManu = manufactures.reduce((newArray=[],el) => {
        if(tmpResult.series.includes(el.value)) return newArray;
        return [ ...newArray, el ];
      },[]);
      
      setManufactures(tmpManu);
      setLocal(values => ([ ...tmpResult.models, ...values ]));
      setQty(JSON.stringify(local));
      editValueAction({ Products: [ ...tmpResult.models, ...local ], modelId: '', manufactureId: '', productId: '', quantity: 0, initial: 0, fin: 0 });
    }

    if(manufactureId) {
      const index = manufactures.findIndex( el => ( parseInt(el.value) === parseInt(manufactureId) ));
      delete manufactures[index];
      const tmp = manufactures.filter( el => el);
      setManufactures(tmp);

      setLocal(values => [ { manufactureId, name: manufactureIdLabel, quantity: 1 }, ...values ]);
      setQty(JSON.stringify(local));
      editValueAction({ Products: [ { manufactureId, name: manufactureIdLabel, quantity: 1 }, ...local ], modelId: '', manufactureId: '', productId: '', quantity: 0, initial: 0, fin: 0 });
    }

    if(productId) {
      const index = spareParts.findIndex( el => ( parseInt(el.value) === parseInt(productId) ));
      delete spareParts[index];
      const tmp = spareParts.filter( el => el);
      setSpareParts(tmp);

      setLocal(values => [ { productId, name: productIdLabel, quantity: props.quantity }, ...values ]);
      setQty(JSON.stringify(local));
      editValueAction({ Products: [ { productId, name: productIdLabel, quantity: props.quantity }, ...local ], modelId: '', manufactureId: '', productId: '', quantity: 0, initial: 0, fin: 0 });    
    }
  };

  const handlePdf = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    MigrationPdf(id);
  };

  const handleReception = e => {
    const { id, Products, updatedAt } = JSON.parse(e.currentTarget.dataset.row);
    if(updatedAt) return;
    editValueAction({ id, _id: id });
    setConfirmationProducts(Products);
    props.openFormDialog('reception');
  }

  const migrations = useMemo(() =>
    props.migrations.count > 0 ? props.migrations.rows.map(el => ({
      background: {
        show: false,
        value: el.background
      },
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: {
        value: format(new Date(el.createdAt), 'dd/MM/yyyy HH:mm'),
        _sort: 'date'
      },
      Folio: {
        value: el.folio,
        _sort: 'text',
        filter: true
      },
      Estatus: {
        value: el.status,
        _sort: 'text'
      },
      'Fecha de Recepción': {
        value: el.updatedAt ? format(new Date(el.updatedAt), 'dd/MM/yyyy HH:mm') : '-',
        _sort: 'date'
      },
      Origen: {
        value: el.branch,
       _sort: 'text'
      },
      Destino: {
        value: el.migrationBranch
      },
      Usuario:{
        value: el.user,
       _sort: 'text',
      },
      'Confimación':{
        value: el.confirmationUser,
       _sort: 'text',
      }
    })) : []
  ,[props.migrations]);

  const tableRows = useMemo(() => {

    const handleDeleteManufacture = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal([ ...tmp ]);
    };

    
    return local.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">
          { el.name } 
        </TableCell>
        <TableCell component="th" scope="row">{ el.quantity }</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteManufacture} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, qty]);

  return (
    <Fragment>
      <Module
        table="migrations"
        name="Traspasos"
        records={migrations}
        singularName="Traspaso"
        title="Traspasos"
        edit={false}
        filter={false}
        download={true}
        _delete={false}
        actions={actions}
      >
        <Autocomplete
          label="Sucursal" 
          _id="branchId"
          options={branches}
          dialog={false}
        />
        <ListItemAdd handleClick={handleAddManufacture}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                label="Modelo" 
                _id="modelId" 
                dialog={false}
                options={models}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                dialog={false}
                fields={[{
                  id: "initial", label: "Ini.",
                  variant: "outlined", margin: "dense", required: false,
                }]}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                dialog={false}
                fields={[{
                  id: "fin", label: "Fin",
                  variant: "outlined", margin: "dense", required: false,
                }]}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                label="Serie" 
                _id="manufactureId" 
                dialog={false}
                options={manufactures}
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                label="Refacción" 
                _id="productId" 
                dialog={false}
                options={spareParts}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                dialog={false}
                fields={[{
                  id: "quantity", label: "Cant.",
                  variant: "outlined", margin: "dense", required: false,
                }]}
              />
            </Grid>
          </Grid>
        </ListItemAdd>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </Module>
      <ReceptionForm confrimationProducts={confirmationProducts} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  migrations: state.records.migrations || defaultTableValue,
  branches: state.records.branches || defaultTableValue,
  manufactures: state.records.manufactures || defaultTableValue,
  products: state.records.products || defaultTableValue,
  selectedManufacture: {
    manufactureId: state.field.value.manufactureId, 
    manufactureIdLabel: state.field.value.manufactureIdLabel,
  },
  selectedModel: {
    modelId: state.field.value.modelId, 
    modelIdLabel: state.field.value.modelIdLabel,
  },
  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel,
  },
  initial: state.field.value.initial || 0,
  fin: state.field.value.fin || 0,
  quantity: state.field.value.quantity || 0,
  alert: state.alert.type,
  isOpen: state.formDialog, 
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction : value => dispatch(editValueAction(value)),
  updateRecords: value => dispatch(recordsActions.update(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Migration);