import { redirectConstants } from '../_constants';

const initialState = {
  id: '',
  table: '',
  returnTo: '',
  returnToLabel: ''
};

export const redirect = (state = initialState, action) => {
  switch (action.type) {
    case redirectConstants.SET_REDIRECT_FIELDS:
    	return { ...state, ...action.payload };
    case redirectConstants.CLEAR_REDIRECT_FIELDS:
    	return initialState;
    default:
      return state
  }
};