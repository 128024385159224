import React, { useMemo, Fragment, useState, useEffect } from "react";

import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/EditOutlined';
import Module from '../../_presentationals/Module';
import InventoryDialog from './inventoryDialog';
import { openFormDialog, editValueAction  } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';

import { FilterDialog }  from './filter';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Inventory = ({openFormDialog, editValueAction, getRecords, ...props}) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [];

    if(privileges.inventories && privileges.inventories.updateInventory){ 
      tmpAction.push({ icon: <EditIcon fontSize="small" />, label: "Actualizar Existencia", handle: handdleInventory });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const inventories = useMemo(() =>
    props.inventories.count > 0 ? props.inventories.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      Producto:{
        value: el.Product.name,
        filter: true,
       _sort: 'text'
      },
      Inventario:{
        value: el.quantity,
       _sort: 'number',
       align: 'center'
      },
      Costo: {
        value: moneyFormat(parseFloat(el.productionCost) * parseFloat(el.quantity)),
        alt: parseFloat((parseFloat(el.productionCost) * parseFloat(el.quantity)).toFixed(2)),
        accumulate: true, accumulateLabel: 'Costo de Producción',
        filter: true,
        _sort: 'alt',
        align: 'right'
      }
    })) : []
  ,[props.inventories]);

  const handdleInventory = e => {
    const { id, quantity } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, quantity });
    openFormDialog('InventoryDialog'); 
  };

  const filter = () => {
    openFormDialog('InventoryFilter');
  };

  return (
    <Fragment>
      <Module
        table="inventories"
        name="Refacciones"
        records={inventories}
        singularName="Refacción"
        title="Refacciones"
        filter={filter}
        edit={false}
        download={true}
        _delete={false}
        create={false}
        actions={actions}
      >

      </Module>
      <InventoryDialog />
      <FilterDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  inventories: state.records.inventories || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);