import React, { useMemo, useState, useEffect, Fragment  } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog/index';

import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturnOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import CardGiftcard from '@material-ui/icons/CardGiftcardOutlined';
// import PiecesDialog from './PiecesDialog';
import NoteDialog from './noteDialog';
import ProductsDialog  from './productsDialog';
import ListAltIcon from '@material-ui/icons/ListAlt';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Warranty = ({openFormDialog, editValueAction, ...props}) => {
  const [confirmId,setConfirmId] = useState(0);
  const [actions,setActions] = useState([]);

  useEffect(() => {
    let tmp = [];
    
    if(privileges.warranties && privileges.warranties.returnToWarranty) { 
      tmp = [{ icon: <AssignmentReturnIcon fontSize="small" />, label: "Regresar a Garantía", handle: handleConfirmation }];
    }
    
    setActions([ ...tmp, {
      icon: <ListAltIcon fontSize="small" />, label: "Productos", handle: handleProductsDialog
    },{
      icon: <ShoppingCartIcon fontSize="small" />, label: "Nota de Venta", handle: handleSale
    },{
      icon: <CardGiftcard fontSize="small" />, label: "Cortesía", handle: handleCourtesy
    }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const warranties = useMemo(() => {
    // let totalCost = 0;

    const tmp = props.warranties.count > 0 ? props.warranties.rows.map(el => {
      // totalCost += parseFloat(el.productionCost);
      
      return {
        ID: { 
          show: true,
          value: el.id,
          data: el
        }, 
        Fecha:{
          value: format(parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES})
        },
        Estatus: {
          value: el.status.label
        },
        Producto: {
          value: el.name,
          _sort: 'text',
          filter: true
        },
        Serie: {
          value: el.physicalSerie
        },
        Comentario:{
          value: el.commentary
        }
      }
    }) : [];

    // setAdditionalText(`Costo de Producción: ${moneyFormat(totalCost)}`);
    return tmp;
  }, [props.warranties]);

  const handleSale = e =>{
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ warrantyId: id, isSale: true });
    openFormDialog('NoteDialog');
  }

  const handleCourtesy = e =>{
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ warrantyId: id, isCourtesy: true });
    openFormDialog('NoteDialog');
  }

  const handleProductsDialog = e => {
    const { Pieces } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ Pieces });
    openFormDialog('ProductsDialog');
  }

  const handleConfirmation = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, _id: id, isStatus: true });
    setConfirmId(id);
  }

  const handleStatus = e => {
    props.update('warranties');
  }

  const handleCancelConfirmation = e => setConfirmId(0);

  // const filter = () => {
  //   openFormDialog('WarrantyFilter');
  // };

  return (
    <Fragment>
      <Module
        table="warrantyNotes"
        name="reparaciones listas para facturar"
        records={warranties}
        singularName="Reparación lista para facturar"
        title="Reparaciones listas para facturar"
        filter={false}
        create={false}
        edit={false}
        _delete={false}
        download={true}
        actions={actions}
      >
      </Module>
      <ProductsDialog />
      <NoteDialog />
      <ConfirmDialog _id={confirmId} handleAction={handleStatus} handleCancel={handleCancelConfirmation} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  warranties: state.records.warrantyNotes || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  update: (data) => dispatch(recordsActions.update(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Warranty);