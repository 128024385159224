import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField, TextField } from '../../_presentationals/Form';
import { closeFormDialog } from '../../_actions';

const CurrencyDialog = props => {
  const handleClick = () => props.closeFormDialog('CurrencyDialog');  
  return(
    <FormDialog 
      buttonLabel="Actualizar" 
      singular="CurrencyDialog" 
      title="Configuración de la Moneda"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
      <SelectField
        fields={[{
          id: 'currencyId',
          label: 'Moneda',
          options: props.currencies,
        }]}
      />
      <TextField
        fields={[{
          id:'exchangeRate',
          label: 'Tipo de Cambio',
          required: true
        }]}
      />
    </FormDialog>
  );
}

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(null, mapDispatchToProps)(CurrencyDialog);
