import React from "react";

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField } from '../../_presentationals/Form';

const availableMethods = [
  {key: "01", value: "Efectivo"},
  {key: "02", value: "Cheque nominativo"},
  {key: "03", value: "Transferencia electrónica de fondos"},
  {key: "04", value: "Tarjeta de crédito"},
  {key: "05", value: "Monedero electrónico"},
  {key: "06", value: "Dinero electrónico"},
  {key: "15", value: "Condonación"},
  {key: "17", value: "Compensación"},
  {key: "23", value: "Novación"},
  {key: "28", value: "Tarjeta de débito"},
  {key: "30", value: "Aplicación de anticipos"},
  {key: "31", value: "Intermediario pagos"},
  {key: "99", value: "Por definir"}
];

const aviableUseCdfi = [
  {
    "key": "G01",
    "value": "Adquisición de mercancias",
    
  },
  {
    "key": "G02",
    "value": "Devoluciones, descuentos o bonificaciones"
  },
  {
    "key": "G03",
    "value": "Gastos en general"
  },
  {
    "key": "I01",
    "value": "Construcciones"
  },
  {
    "key": "I02",
    "value": "Mobilario y equipo de oficina por inversiones"
  },
  {
    "key": "I03",
    "value": "Equipo de transporte"
  },
  {
    "key": "I04",
    "value": "Equipo de computo y accesorios",
  },
  {
    "key": "I05",
    "value": "Dados, troqueles, moldes, matrices y herramental"
  },
  {
    "key": "I06",
    "value": "Comunicaciones telefónicas"
  },
  {
    "key": "I07",
    "value": "Comunicaciones satelitales"
  },
  {
    "key": "I08",
    "value": "Otra maquinaria y equipo"
  },
  {
    "key": "D01",
    "value": "Honorarios médicos, dentales y gastos hospitalarios."
  },
  {
    "key": "D02",
    "value": "Gastos médicos por incapacidad o discapacidad"
  },
  {
    "key": "D03",
    "value": "Gastos funerales."
  },
  {
    "key": "D04",
    "value": "Donativos."
  },
  {
    "key": "D05",
    "value": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)."
  },
  {
    "key": "D06",
    "value": "Aportaciones voluntarias al SAR."
  },
  {
    "key": "D07",
    "value": "Primas por seguros de gastos médicos."
  },
  {
    "key": "D08",
    "value": "Gastos de transportación escolar obligatoria."
  },
  {
    "key": "D09",
    "value": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones."
  },
  {
    "key": "D10",
    "value": "Pagos por servicios educativos (colegiaturas)"
  },
  {
    "key": "P01",
    "value": "Por definir"
  }
];

const availableReference = [
  {key:"01", value:"Nota de crédito de los documentos relacionados"},
  {key:"02", value:"Nota de débito de los documentos relacionados"},
  {key:"03", value:"Devolución de mercancía sobre facturas o traslados previos"},
  {key:"04", value:"Sustitución de los CFDI previos"},
  {key:"05", value:"Traslados de mercancias facturados previamente"},
  {key:"06", value:"Factura generada por los traslados previos"},
  {key:"07", value:"CFDI por aplicación de anticipo"},
  {key:"08", value:"Factura generada por pagos en parcialidades"},
  {key:"09", value:"Factura generada por pagos diferidos"},
];

const Dialog = props => {
  return (
    <FormDialog  
      singular="ReInvoiceDialog" 
      title="Refacturar"
      table="invoices"
    >
      <SelectField
        fields={[{
          id: "paymentForm",
          label: "Forma de pago",
          options: availableMethods,
        }]}
      />
      <SelectField
        fields={[{
          id: "paymentMethodInvoice",
          label: "Método de Pago",
          options: [
          { key: 1, value: 'PUE' },
          { key: 2, value: 'PPD' }
          ],
        }]}
      />
      <SelectField
        fields={[{
          id: "useCdfi",
          label: "Uso de CDFI",
          options: aviableUseCdfi,
        }]}
      />
      <SelectField
        fields={[{
          id: "reference",
          label: "Tipo de relación",
          options: availableReference,
        }]}
      />
    </FormDialog>
  )
};

export default Dialog;