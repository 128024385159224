import { recordsConstants } from '../_constants';
import * as recordsServices from '../_services';
import { progressActions, validateFieldAction, alertActions, editValueAction } from '../_actions';

const create = table => (dispatch, getState) => {
  if(!table) return;

  const fields = getState().field.value;
  dispatch(progressActions.openDialog());
  recordsServices.create({table, ...fields, redirectId: getState().redirect.id, redirectTable: getState().redirect.table})
  .then(data => {
    dispatch(progressActions.closeDialog());
    
    if(data.errorCode){
      const error = JSON.parse(data.errorMessage);
      error.forEach(({ name, message }) => {
        dispatch(validateFieldAction({[name]: { isError: true, message } }));
      });
      !fields.creditNotes && dispatch(alertActions.error(data.message || "Ha ocurrido un error."));
      fields.creditNotes && dispatch(alertActions.error("Petición Cancelada."));
      return;
    }

    if (data.accountReceivableId) {
      dispatch(editValueAction({'accountReceivableId': data.accountReceivableId}));
    }

    if(data.courtesyId) {
      dispatch(editValueAction({ courtesyId: data.courtesyId }));
    }

    if(data.saleId && data.folio) {
      dispatch(editValueAction({saleId: data.saleId, folio: data.folio }));
    }

    if (data.paymentId) {
      dispatch(editValueAction({'paymentId': data.paymentId}));
    }

    if(data.invoices){
      dispatch(editValueAction({invoices: data.invoices}));
    }

    if (data.quotationId) {
      dispatch(editValueAction({quotationId: data.quotationId}));
    }

    if (data.total) {
      dispatch(editValueAction({totalSale: data.total}));
    }

    if(data.isSettled && data.isPPD) {
      dispatch(editValueAction({isSettled: data.isSettled }));
    }

    if(data.invoice && data.folioCp) {
      dispatch(editValueAction({ CpUuid: data.invoice, folioCp: data.folioCp }));
    }

    if(data.expenseId) dispatch(editValueAction({ recordId: data.expenseId }));
    
    if(data.saleAuthId) dispatch(editValueAction({ saleAuthId: data.saleAuthId }));
    
    dispatch(alertActions.clear());
    dispatch(alertActions.success(data.message || "Petición realizada con éxito."));
  });

}

const update = table => (dispatch, getState) => {
  if(!table) return;
  
  const fields = getState().field.value;
  const fieldsId = getState().field.id;

  if(!fields.id) return;

  dispatch(progressActions.openDialog());

  recordsServices.update({table, ...fields, fieldsId, redirectId: getState().redirect.id, redirectTable: getState().redirect.table})
  .then(data => {
    dispatch(progressActions.closeDialog());
    if(data.errorCode){
      const error = JSON.parse(data.errorMessage);
      error.forEach(({ name, message }) => {
        dispatch(validateFieldAction({[name]: { isError: true, message } }));
      });
      return;
    }
    dispatch(alertActions.clear());
    dispatch(alertActions.success(data.message || "Petición realizada con éxito."));
  });

}

const _delete = ({_id, table}) => (dispatch, getState) => {

  if(!table) return;

  dispatch(progressActions.open());

  recordsServices._delete({table, _id, redirectId: getState().redirect.id, redirectTable: getState().redirect.table})
  .then(data => {
    dispatch(progressActions.close());
    if(data.errorCode){
      const error = JSON.parse(data.errorMessage);
      error.forEach(({ name, message }) => {
        dispatch(validateFieldAction({[name]: { isError: true, message } }));
      });
      return;
    }
    dispatch(alertActions.clear());
    dispatch(alertActions.success(data.message || "Petición realizada con éxito."));
  });

}

const getAll = values => (dispatch, getState) => {
  const { table, alias } = values || {};
  if(!table) return;

  recordsServices.getAll({ ...values, redirectId: getState().redirect.id, redirectTable: getState().redirect.table })
  .then(data => {

    getState().progress.isLoading && dispatch(progressActions.close());
    dispatch({
      type: recordsConstants.GET_RECORDS,
      payload: { table: !alias ? values.table : alias, ...data }
    });
  });

}

const clearRecords = table => dispatch => dispatch({ type: recordsConstants.DELETE_RECORDS, payload: table })

export const recordsActions = {
	create, getAll, update, _delete, clearRecords
};
