const migrations = ({method, ...props}) => {
  switch (method) {
  	case 'PUT':
  		return `migrations/${props.id}`
  	case 'GET':
    default:
      if(props.getProducts) return `migrations/${props.id}/products`;
      return `migrations`;
  }
}

export default migrations