const api = ['firstName', 'lastName', 'role'];

const employees = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
      if(values.isTools && values.Tools) return { Tools: values.Tools };

      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key]; }
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      if (values['userId']) tmp['userId'] = values['userId'];
      return tmp;
    default:
      return tmp;
  }
}

export default employees;