import React, { useState, useEffect } from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';


import FormDialog from './../helpers/FormDialog';
import Autocomplete from './../helpers/Autocomplete';
import { getAllV2 } from '../../../_services/records.services';

const Employee = props => {

	const [ error, setError ] = useState(null);
  const [ value, setValue ] = useState(null);
  const [ employees, setEmployees ] = useState([]); 

  useEffect(() => {
    setValue(null);
  }, [props.codeToClear]);

  useEffect(() => {
    const f = async () => {
			try {
    await getAllV2('employees/sellers').then(data => {
      const tmp = data.rows.map(el => ({ label: `${el.firstName} ${el.lastName}`.trim(), value: el.id }))
      setEmployees(tmp);
    });
  } catch (error) {
    console.log(error)
  }
  
}
f();
  }, []);

  const handleAutocompleteChange = (e, name, value) => {
    setValue(value);
  }

  const handleAction = () => {
    try{
      if(!value) throw new Error('Selecciona un vendedor.');
      props.handleAction(value);
      setError(null);
    }catch(e) {
      setError(e.message);
    }
  }

	return (
    <FormDialog
      title='Vendedor'
      actionLabel='Seleccionar'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      <Autocomplete 
        icon={<AccountCircleIcon />}
        name='employeeId'
        label='Nombre del Vendedor' 
        onChange={handleAutocompleteChange}
        options={employees}
        value={value}
        error={error}
      />
    </FormDialog>
	)
}

export default Employee;