const logisticSale = (method, values) => {
	const tmp = {};
  switch (method) {
  	case 'PUT':
  		return { status: values.status, commentary: values.commentary, isSale: values.isSale, isSample: values.isSample, Products: values.Products };
    default:
      return tmp;
  }
}

export default logisticSale;
