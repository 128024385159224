import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const customers = ({method, ...props}) => {
  switch (method) {
    case 'POST':
      return `invoices/customized`;
    case 'GET':
      const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy-MM-dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy-MM-dd', {locale: localeES});
      return `invoices/customized?start=${start}&finish=${finish}`
    default:
      return `invoices/customized?start=${start}&finish=${finish}`
  }
}

export default customers;
