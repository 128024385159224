import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Select, { createFilter } from 'react-select';

import useTheme from '@material-ui/core/styles/useTheme';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import ErrorIcon from '@material-ui/icons/ErrorOutline';

import { editValueAction } from '../../../_actions';
import { useStyles } from './style';
import { components } from './_helpers';

function AutocompleteFieldMui({ label, _id, options, isMulti, dialog, icon, editValueAction, fieldValue, fieldValidation, fullWidth, required, variant }) {

  const classes = useStyles(variant)();
  const theme = useTheme();
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue(options.map(el => fieldValue[_id] === el.value && el));
  }, [fieldValue, options, _id]);
  
  const handleChangeValue = el => {
    editValueAction({ [_id]: el.value });
    editValueAction({ [`${_id}Label`]: el.label });
    Object.keys(el).map(key => !['label', 'value'].includes(key) && editValueAction({ [key]: el[key] }));
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      }
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  return (
    <Fragment>
      <Grid container alignItems={ dialog ? "flex-end" : "stretch"}>
        { dialog && 
          <Grid item xs={1}><Icon className="material-icons-outlined">{icon}</Icon></Grid> 
        }
        <Grid item xs={dialog ? 9 : 12}>
          <FormControl 
            fullWidth={ fullWidth || true } 
            required={ required || true } 
            error={ fieldValidation[_id] && fieldValidation[_id].isError }
          >
            <Select
              classes={classes}
              styles={selectStyles}
              inputId={_id}
              filterOption={createFilter({ ignoreAccents: false })}
              noOptionsMessage={() => "Ninguna opción disponible." }
              TextFieldProps={{
                label,
                variant,
                margin: (variant === 'outlined') ? 'dense' : 'none',
                InputLabelProps: {
                  htmlFor: _id,
                  shrink: true
                }
              }}
              menuPortalTarget={document.body}
              placeholder=""
              options={options}
              components={components}
              value={value || {}}
              onChange={handleChangeValue}
              isMulti={ isMulti }
            />
            { (fieldValidation[_id] && fieldValidation[_id].isError) && 
              <FormHelperText component="div">
                <Grid container item spacing={0} alignItems="center" >
                  <ErrorIcon fontSize="small" /> { fieldValidation[_id].message }
                </Grid>
              </FormHelperText> }
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
}

AutocompleteFieldMui.defaultProps = {
  dialog: true,
  variant: 'standard'
};

const mapStateToProps = state => ({ 
  fieldValue: state.field.value,
  fieldValidation: state.field.validation
});

const mapDispatchToProps = dispatch => ({ 
  editValueAction: data => dispatch(editValueAction(data))
});

export const AutocompleteField = connect(mapStateToProps, mapDispatchToProps)(AutocompleteFieldMui);