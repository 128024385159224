const physicalInventories = (method, values) => {
	const tmp = {};
	console.log(values);
  switch (method) {
    case 'POST':
      tmp['Products'] = values['Products'] || [];
      tmp['description'] = values['description'] || '';
      return tmp;
    default:
      return tmp;
    }
}

export default physicalInventories;