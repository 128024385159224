import React, { useState, useEffect } from 'react';

import format from 'date-fns/format';

import RoomIcon from '@material-ui/icons/RoomOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';

import FormDialog from './../helpers/FormDialog';
import TextField from './../helpers/TextField';
import DateTimeField from './../helpers/DateTimeField';
import SelectField from './../helpers/SelectField';

const defaultValues = {
  address: '',
  deliveryDate: null,
  deliveryCommentary: '',
  mobility: 1
}

const mobilityOptions = [
  { value: 1, label: 'Entrega' },
  { value: 2, label: 'Renovación' },
  { value: 3, label: 'Cobranza' }
];

const Setting = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  useEffect(() => {
    if(props.address.length > 0){
      setValues(values => ({ ...values, address: props.address }));
    }
  }, [props.address]);
  
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));

    if(name === 'mobility' && parseInt(value) !== 1){
      setValues(values => ({ ...values, address: '', deliveryCommentary: '', deliveryDate: null }));
    }
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleAction = () => {
    try{
      
      // if(!values.address && parseInt(values.mobility) === 1){
      //   const e = new Error('Proporciona un domicilio.');
      //   e.name = 'address';
      //   throw e;
      // }

      // if(!values.deliveryDate && parseInt(values.mobility) === 1){
      //   const e = new Error('Selecciona una fecha de entrega.');
      //   e.name = 'deliveryDate';
      //   throw e;
      // }

      props.handleAction(values);
    }catch(e) {
      setErrors({ [e.name]: e.message  });
    }
  }

	return (
    <FormDialog
      title='Configuración'
      actionLabel='Seleccionar'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      <SelectField 
        icon={<LocalShippingIcon />}
        name='mobility'
        label='Movilidad' 
        onChange={handleChange}
        options={mobilityOptions}
        value={values.mobility}
      />
      <TextField
        icon={<RoomIcon />}
        name='address'
        onChange={handleChange}
        value={values.address}
        error={errors.address}
        disabled={parseInt(values.mobility) !== 1 ? true : false}
        label='Domicilio de Entrega'
      />
      <DateTimeField
        icon={<EventIcon />}
        name='deliveryDate'
        label='Fecha y Hora de Entrega'
        onChange={handleDateChange}
        value={values.deliveryDate}
        disabled={parseInt(values.mobility) !== 1 ? true : false}
        error={errors.deliveryDate}
      />
      <TextField
        name='deliveryCommentary'
        value={values.deliveryCommentary}
        error={errors.deliveryCommentary}
        onChange={handleChange}
        disabled={parseInt(values.mobility) !== 1 ? true : false}
        label='Comentario de la Logística'
      />
    </FormDialog>
	)
}

export default Setting;