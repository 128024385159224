import React, { useMemo, Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';

import randomize from 'randomatic';

import localeES from 'date-fns/locale/es';
import format from 'date-fns/format';

import ToysIcon from '@material-ui/icons/ToysOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import Receipt from '@material-ui/icons/ReceiptOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';

import Module from '../../_presentationals/Module'; 

import { openFormDialog  } from '../../_actions';
import * as recordsServices from './../../_services/records.services';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import ProductCollection from './../LogisticCollection.v2/product.dialog.js';
import EditCollection from './../LogisticCollection.v2/edit.dialog.js';
import Edit from './edit.dialog.js';
import ContractPDF from './contract.pdf.js';
import DeliveryAndCollectionPDF from './deliveryAndCollection.pdf.js';
import FailurePDF from './collection.pdf.js';

import { moneyFormat } from '../../_helpers/moneyFormat';
import { rentPlanToLabel } from '../../_helpers/rentPlanToLabel';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';

import Filter from './filter.dialog';
import Manufacture from './manufacture.dialog';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Logistic = ({openFormDialog, ...props}) => {

  const [ actions, setActions ] = useState([]);
  const [ logisticId, setLogisticId ] = useState(0);
  const [ deliveryId, setDeliveryId ] = useState(0);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ employees, setEmployees ] = useState([]);

  useEffect(() => {

    const tmpAction = [
      { icon: <DescriptionIcon fontSize="small" />, label: "Documento de Entrega y Recolección", handle: handleDoc },
      { showIf: true, icon: <Receipt fontSize="small" />, label: "Contrato PDF", handle: contractTicket },
      { 
        icon: <ToysIcon fontSize="small" />, 
        label: "Remplazar equipos", 
        handle: handleReplace,
        showIf: true
      },
      { 
        icon: <LocalShippingIcon fontSize="small" />, 
        label: "Entregar Productos", 
        handle: deliveryProducts,
        label1: "Recolectar Productos",
        handle1: collectionProducts,
      },
      { 
        icon: <EditIcon fontSize="small" />, 
        label: "Configurar", 
        handle: handleEditDelivery,
        handle1: handleEditCollection
      },
    ];

    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 

  useEffect(() => {
    recordsServices.getAllV2('employees/type/6').then(data => {
      const tmp = data.rows.map(el => ({ label: `${el.firstName} ${el.lastName}`.trim(), value: el.id }))
      setEmployees(tmp);
    });
  }, []);

  const logistics = useMemo(() => 
    props.logistic.count > 0 ? props.logistic.rows.map(el => {
      const plan = parseInt(el.type) === 5 
        ? 'Falla de equipo'
        : el.rentPlan 
          ? rentPlanToLabel(el.rentPlan)[0]
          : parseInt(el.type) === 2
            ? 'Cortesía'
            : parseInt(el.type) === 3
              ? 'Consignación'
              : parseInt(el.type) === 4
                ? 'Muestra'
                : parseInt(el.type) === 6
                  ? 'Reemplazo'
                  : 'Venta';

      return {
        ID: { 
          show: false,
          value: el.id,
          data: el,
          _alternative: el.status === 2 ? true : false,
          showIf: (el.rentPlan && el.status === 1) ? true : false
        },
        background: {
          value: el.emergency ? 'danger' : 'default',
          show: false,
        },
        Estatus: {
          value: el.status === 1 ? 'Entrega' : 'Recolección',
          noBreak: true,
        },
        Fecha: {
          value: el.statusDate ? format(new Date(el.statusDate), 'dd/MM/yyyy HH:mm', {locale: localeES}) : '-',
          _sort: 'date',
          noBreak: true,
        },
        Folio:{
          value: el.folio !== '' ? el.folio : '-',
          filter: true,
          _sort: 'text',
          noBreak: true
        },
        Plan: {
          value: plan,
          _sort: 'text',
          noBreak: true,
        },
        Equipos: {
          value: el.LogisticProducts.reduce((string, elCh) => {
            const name = elCh.Product ? elCh.Product.name : '-';
            const physicalSerie = elCh.Manufacture ? elCh.Manufacture.physicalSerie : '-';
            const product = name + ' (' + physicalSerie + '),';
            return string + ' ' + product;
          }, '').slice(0, -1),
          noBreak: true,
          _sort: 'text'
        },
        Cliente:{
          value: el.customer,
          filter: true,
         _sort: 'text',
         noBreak: true,
        },
        Dirección:{
          value: el.address ? el.address : '-',
          noBreak: true,
        },
        Cargo:{
          value: moneyFormat(el.total),
          alt: parseFloat(parseFloat(el.total).toFixed(2)),
          align: 'right',
          _sort: 'alt'
        },
        Abonado:{
          value: moneyFormat(el.totalPayment),
          alt: parseFloat(parseFloat(el.totalPayment).toFixed(2)),
          align: 'right',
          _sort: 'alt'
        },
        Saldo:{
          value: moneyFormat(el.balance),
          alt: parseFloat(parseFloat(el.balance).toFixed(2)),
          align: 'right',
          _sort: 'alt'
        }
      }
    }) : []
  , [props.logistic]);

  const handleActionDelivery = async logisticId => {
    try{
      const response = await recordsServices.updateV2(`v2/logistics/${logisticId}/delivery`);
      if(response.errorCode) {
        throw new Error(response.errorMessage);
      }
      const tmpCode = randomize('*', 10);
      setRefreshCode(tmpCode);
      setDeliveryId(0);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: 'Los productos se entregaron exitosamente', 
        severity: 'success' 
      }));
    }catch(e){
      setDeliveryId(0);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));

    }
  }

  const handleEditDelivery = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setLogisticId(id);
    openFormDialog('Edit');
  };

  const handleEditCollection = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setLogisticId(id);
    openFormDialog('EditCollection');
  };

  const deliveryProducts = async e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setDeliveryId(id);
  }

  const handleCancelDelivery = () => {
    setDeliveryId(0);
  }

  const reload = () => {
    const tmpCode = randomize('*', 10);
    setRefreshCode(tmpCode);
    setLogisticId(0);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleDoc = e => {
    const { id, type } = JSON.parse(e.currentTarget.dataset.row);

    if(parseInt(type) === 5){
      FailurePDF(id);
      return
    }
    
    DeliveryAndCollectionPDF(id);
  };

  const contractTicket = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    ContractPDF(id);
  };

  const handleReplace = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setLogisticId(id);
    openFormDialog('Manufacture');
  }

  const collectionProducts = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setLogisticId(id);
    openFormDialog('Products');
  }

  const handleFilter = () => {
    openFormDialog('Filter');
  }

  return (
    <Fragment>
      <Module
        table="logisticv2"
        name="entregas y recolecciones"
        records={logistics}
        title="Entregas y Recolecciones"
        singularName="PDF"
        refreshCode={refreshCode}
        filter={handleFilter}
        edit={false}
        download={true}
        create={false}
        _delete={false}
        actions={actions}
      >
      </Module>
      <Edit logisticId={logisticId} Employees={employees} reload={reload} />
      <EditCollection logisticId={logisticId} Employees={employees} reload={reload} />
      <ProductCollection logisticId={logisticId} reload={reload} />
      <ConfirmDialog 
        _id={deliveryId} 
        handleCancel={handleCancelDelivery} 
        handleAction={handleActionDelivery} 
      />
      <Manufacture logisticId={logisticId} reload={reload} />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
      <Filter Employees={employees} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  logistic: state.records.logisticv2 || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  employeeId: state.field.value.employeeId || ''
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logistic);