import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog } from '../../_actions';

import { SelectField } from './../../_presentationals/Form/';

const Dialog = props => {

  const handdleFilter = () => {
    props.getRecords({ table: 'productManufactures', status: props.status });
    props.closeFormDialog('FilterDialog');
  };

	return (
	  <FormDialog  
      singular="FilterDialog" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <SelectField
        fields={[{
          id: "status",
          label: "Estatus",
          options: [
            { key: 1, value: 'Disponibles' },
            { key: 2, value: 'Todos' }
          ]
        }]}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({
  status: state.field.value.status || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);