import React, { useMemo, Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';

import { openFormDialog, editValueAction } from '../../_actions';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import Module from '../../_presentationals/Module';

import ProductsLogistic from './productsLogistic';
import CollectionLogistic from './collectionLogistic';
import EmployeeDelivery from './employeeDelivery';
import ChangeStatus from './changeStatus';

import AssignmentReturn from '@material-ui/icons/AssignmentReturn';
import ProductIcon from '@material-ui/icons/ToysOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import UpdateIcon from '@material-ui/icons/Update';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const LogisticIndependent = ({openFormDialog, editValueAction, ...props}) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [];

    if(privileges.logisticIndependent && privileges.logisticIndependent.employee){ 
      tmpAction.push({ icon: <AccountCircle fontSize="small" />, label: "Seleccionar Chofer", handle: handdleEmployee });
    }

    if(privileges.logisticIndependent && privileges.logisticIndependent.collection){ 
      tmpAction.push({ icon: <AssignmentReturn fontSize="small" />, label: "Recolectar Equipos", handle: handdleCollection });
    }

    if(privileges.logisticIndependent && privileges.logisticIndependent.products){ 
      tmpAction.push({ icon: <ProductIcon fontSize="small" />, label: "Consultar Equipos", handle: handdleProducts });
    }

    if(privileges.logisticIndependent && privileges.logisticIndependent.changeStatus){ 
      tmpAction.push({ icon: <UpdateIcon fontSize="small" />, label: "Cambiar Estatus", handle: handdleChangeStatus });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const logisticIndependent = useMemo(() =>
    props.logisticIndependent.count > 0 ? props.logisticIndependent.rows.map(el => ({
      ID: { 
        show: true,
        value: el.id,
        data: el
      },
      "Fecha de Entrega": {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy', {locale: localeES} ),
        _sort: 'date'
      },
      "Fecha de Recolección": {
        value: el.collectionDate ? `${format( parseISO(el.collectionDate), 'dd/MM/yyyy', {locale: localeES} )} ${ el.collectionTime ? el.collectionTime : '' }`: 'No especificada',
        _sort: 'date'
      },
      "Cliente": {
        value: `${el.Customer.firstName} ${el.Customer.lastName}`,
        filter: true,
        _sort: 'text'
      },
      "Product": {
        value: el.Product.name,
        filter: true,
        _sort: 'text'
      },
      "Modalidad": {
        value: el.rentPlan.label,
        filter: false,
        _sort: 'text'
      },
      Dirección: {
        value: `${el.addressLine1 !== '' ? `${el.addressLine1},` : ''} ${el.addressLine2 !== '' ? `${el.addressLine2}` : ''} ${el.city !== '' ? `${el.city},` : ''} ${ el.zipCode !== '' ? `${el.zipCode}` : '' }`,
        filter: true,
        _sort: 'text'
      },
      Chofer: {
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : 'No especificado',
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.logisticIndependent]);

  const handdleProducts = e => {
    const { id, Products } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, Products });
    openFormDialog('ProductsLogistic');
  };

  const handdleChangeStatus = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id });
    openFormDialog('ChangeStatus');
  };

  const handdleCollection = e => {
    const { id, addressLine1, addressLine2, zipCode, reference, city, countryId, stateId, receptionName, collectionDate, collectionTime, collectionCommentary } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, addressLine1, addressLine2, zipCode, reference, city, countryId, stateId, receptionName, collectionDate, collectionTime, collectionCommentary });
    openFormDialog('CollectionLogistic');
  };

  const handdleEmployee = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id });
    openFormDialog('EmployeeDelivery');
  };

  return (
    <Fragment>
      <Module
        edit={false}
        create={false}
        _delete={false}
        table="logisticIndependent"
        name="recolecciónes independientes"
        singularName="recolección independiente"
        filter={false}
        records={logisticIndependent}
        actions={actions}
      >
      </Module>
      <EmployeeDelivery />
      <ProductsLogistic />
      <CollectionLogistic />
      <ChangeStatus />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  logisticIndependent: state.records.logisticIndependent || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticIndependent);