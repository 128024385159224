const expensePayments = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
      return { payment: values.payment };
    default:
      return tmp;
  }
}

export default expensePayments;
