import React, { useEffect, Fragment, useMemo, useState  } from "react";
import { connect } from 'react-redux';
import { config } from './../../_helpers/config';

import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction, recordsActions } from '../../_actions';

import { format, endOfWeek, startOfWeek } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
 
import ZoomInIcon from '@material-ui/icons/ZoomIn';

import { FilterDialog }  from './filter';
import { moneyFormat } from '../../_helpers/moneyFormat';

const SalesReport = ({ getRecords, openFormDialog, editValueAction, _delete, ...props }) => {
  const [ START ] = useState(startOfWeek(new Date()));
  const [ FINISH ] = useState(endOfWeek(new Date()));

  const saleDetails = e => {
    const { Sale } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${Sale.id}`, '_blank');
  };

  useEffect(() => {
    getRecords({ table: 'invoices', creditNotes: true, start: START, finish: FINISH });
  }, [getRecords, START, FINISH]);

  const invoices = useMemo(() =>
    props.invoices.count > 0 ? props.invoices.rows.map(el => ({
      ID: { 
        show:false,
        value: el.id,
        data: el
      },
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date'
      },
      Folio: {
      	value: <a  href={el.uri} target="_blank" rel="noopener noreferrer">{el.folio}</a>,
        arrayValue: [el.folio],
        _sort: 'text'
      },
      NV: { 
        value: el.Sale.folio,
        filter: true,
        _sort: 'text'
      },
      Cliente: {
      	value: el.Sale.Customer.firstName+' '+el.Sale.Customer.lastName,
        filter: true,
        _sort: 'text'
      },
      Total: {
      	value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
        accumulate: true
      },
      Estatus: {
        value: el.deletedAt ? 'Cancelado' : 'Activo',
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.invoices]);

  const filter = () => {
    openFormDialog('CreditNotesFilter');
  };

  return (
    <Fragment>
      <Module
        name="reporte de notas de crédito"
        records={invoices}
        singularName="reporte"
        title="Filtrar Notas de Crédito"
        filter={filter}
        edit={false}
        create={false}
        _delete={false}
        download={true}
        actions={[{
          icon: <ZoomInIcon fontSize="small" />,
          label: "Consultar Venta",
          handle: saleDetails
        }]}
      >
      </Module>
      <FilterDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  invoices: state.records.invoices || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  _delete: value => dispatch(recordsActions._delete(value)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);