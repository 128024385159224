import React, { useEffect, useMemo, Fragment, useState } from "react";
import { connect } from 'react-redux';

import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import { styles } from './style';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';
import { recordsActions, editValueAction, alertActions } from './../../_actions';
import { Header } from './_Header';

import { GeneralDialog } from '../../_presentationals/GeneralDialog/index';
import { Snackbar } from '../../_presentationals/Snackbar';
import { config } from "../../_helpers";

const CheckIn = ({create, getRecords, classes, editValueAction, ...props }) => {

	const [ checkInsToPay, setCheckInsToPay ] = useState({});
	const [ confirmId, setConfirmId ] = useState(0);
	const [ isInvoiceDialogOpen, setIsInvoiceDialogOpen ] = useState(false);
  const [ generatedInvoices, setGeneratedInvoices ] = useState([]);
	
	useEffect(() => {
		getRecords({ table: 'sales', withoutInvoice:true });	
	}, [getRecords]);

	useEffect(() => {
    if(props.alert === "success" && props.invoices.length > 0){
      setGeneratedInvoices(props.invoices);
    	setIsInvoiceDialogOpen(true);
      editValueAction({ invoices: [] });
    }
  }, [props.alert, props.invoices, editValueAction]);

  useEffect(() => {
  	if(props.alert === "success"){
      getRecords({ table: 'sales', withoutInvoice:true });
    }
  },[props.alert, getRecords])

	const handlePayment = () => {
		const checkIns = Object.keys(checkInsToPay).filter(el => checkInsToPay[el] && el).map(el => el);
		if(checkIns.length === 0) return;
		editValueAction({ checkIns, withoutInvoice:true });
		
		setTimeout(function(){
      props.pendingMessage("Espere un momento, estamos emitiendo las facturas electrónicas.");
    }, 1000);

		create('invoices');
		setConfirmId(0);
	};

	const handleCancelPayment = e => {
		setConfirmId(0);
	};
	
	const handleConfirmPayment = e => {
		setConfirmId(1);
	};

	const handleCancelInvoices = () => {
    setIsInvoiceDialogOpen(false);
  };

	const tableRow = useMemo(() => { 
		
		const handleChange = name => event => {
		  setCheckInsToPay({ ...checkInsToPay, [name]: event.target.checked });
		};
		
		return props.sales.count > 0 && props.sales.rows.map(el => {
			return <TableRow key={el.id}>
				<TableCell>
					<Checkbox
		        onChange={handleChange(el.id)}
		        inputProps={{ 'aria-label': 'primary checkbox' }}
		      />
				</TableCell>
				<TableCell>
					{ el.folio }
				</TableCell>
				<TableCell>
					{format(el.createdAt, "DD MMMM YYYY - HH:mm", {locale: localeES})} 
				</TableCell>
				<TableCell>
					{el.status.label}
				</TableCell>
				<TableCell>
					{"$" + el.total + el.currencyKey}
				</TableCell>
			</TableRow>
		})
	}, [props.sales, setCheckInsToPay, checkInsToPay]);
	
	return (
		<Fragment>
			<Header handleClick={handleConfirmPayment} />
			<Table className={classes.table}>
	    	<TableHead>
	    		<TableRow>
	    			<TableCell className={classes.headerTitle}></TableCell>
	    			<TableCell className={classes.headerTitle}>Folio</TableCell>
	    			<TableCell className={classes.headerTitle}>Fecha</TableCell>
	    			<TableCell className={classes.headerTitle}>Estado</TableCell>
	    			<TableCell className={classes.headerTitle}>Total</TableCell>
	    		</TableRow>
	    	</TableHead>
	    	<TableBody>
	    		{tableRow}
	    	</TableBody>
	    </Table>
	   	<ConfirmDialog _id={confirmId} handleAction={handlePayment} handleCancel={handleCancelPayment} />
	   	<GeneralDialog isOpen={isInvoiceDialogOpen} title={`Facturas Electrónicas:`} handleCancel={handleCancelInvoices}>
      	{generatedInvoices.map(el => <a key={el.invoiceId} className={ classes.spacing } href={`${config.apiUrl}/files/invoices/${el.invoiceId}/pdf`} rel="noopener noreferrer" target="_blank">{el.folio}</a>)}
    	</GeneralDialog>
    	<Snackbar />
    </Fragment>
	);

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
	alert: state.alert.type,
	invoices: state.field.value.invoices || [],
  sales: state.records.sales || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  pendingMessage: value => dispatch(alertActions.pending(value)),
  create: table => dispatch(recordsActions.create(table))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckIn));