import React, { useState, useEffect } from 'react';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHorizOutlined';

import FormDialog from './../helpers/FormDialog';
import SelectField from './../helpers/SelectField';
import TextField from './../helpers/TextField';

const defaultValues = {
  currency: 'MXN',
  exchange: 1
}

const currencyOptions = [
  { value: 'MXN', label: "MXN Peso Mexicano" },
  { value: 'USD', label: "USD Dolar Americano" },
];

const Currency = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));

    if(name === 'currency' && value === 'MXN'){
      setValues(values => ({ ...values, exchange: 1 }));
    }
  }

  const handleAction = () => {
    try{
      if(isNaN(parseFloat(values.exchange)) || parseFloat(values.exchange) <= 0) 
        throw new Error('Proporciona un tipo de cambio válido.');
      props.handleAction(values);
      setErrors({});
    }catch(e) {
      setErrors({ exchange: e.message });
    }
  }

	return (
    <FormDialog
      title='Moneda y Tipo de cambio'
      actionLabel='Seleccionar'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      <SelectField 
        icon={<MonetizationOnIcon />}
        name='currency'
        label='Moneda' 
        onChange={handleChange}
        options={currencyOptions}
        value={values.currency}
      />
      <TextField 
        icon={<SwapHorizIcon />}
        name='exchange'
        label='Tipo de Cambio'
        type='number' 
        onChange={handleChange}
        value={values.exchange}
        error={errors.exchange}
      />

    </FormDialog>
	)
}

export default Currency;