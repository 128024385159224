import React, { useState, useEffect, Fragment } from 'react';

import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { moneyFormat } from './../../../../_helpers/moneyFormat';

import IconButton from './../../helpers/IconButton';

import ItemConfig from './ItemConfig';

const defaultValues = {
	subtotal: 0,
	discount: 0,
	iva: 0,
	total: 0
}

const TableComponent = props => {

	const [ values, setValues ] = useState(defaultValues);
	const [ isOpen, setIsOpen ] = useState([false, null]);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	useEffect(() => {
		const tmp = props.cartItems.reduce((object, el) => {
			const iva = (parseFloat(el.amount) - parseFloat(el.tmpDiscount)) * (parseFloat(props.iva) / 100);
			const discount = parseFloat(el.tmpDiscount);
			const amount = parseFloat(el.amount);
			return {
				subtotal: parseFloat(object.subtotal) + amount,
				discount: parseFloat(object.discount) + discount,
				iva: parseFloat(object.iva) + iva,
				total: parseFloat(object.total) + (amount - discount + iva)
			};
		}, defaultValues);
		setValues(tmp);
	}, [props.cartItems, props.iva]);

	const handleConfigItem = (e, params) => {
		setIsOpen([true, params])
	}

	const handleDeleteItem = (e, position) => {
		props.removeItem(position);
	}

	const handleClose = () => {
		setIsOpen([false, null]);
	}

	const handleAction = (data) => {
		props.updateItem && props.updateItem(data);
		handleClose();
	}
	
	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Acciones</TableCell>
	            <TableCell>Producto</TableCell>
	            <TableCell align="center">Cant.</TableCell>
	            <TableCell align="center">Descto.</TableCell>
	            <TableCell align="right">{props.costSettings ? 'C. Unitario' : 'P. Unitario'}</TableCell>
	            <TableCell align="right">{props.costSettings ? 'Costo' : 'Importe'}</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.cartItems.map((el, i) => 
	      				<TableRow key={i}>
	      					<TableCell>
	      						{	!props.noConfig &&
		      						<IconButton 
		      							size='small'
		      							label='Configuración'
		      							icon={<SettingsIcon />}
		      							action={handleConfigItem}
		      							disabled={parseInt(el.product.type) === 1 ? false : true}
		      							params={{ 
		      								position: i, 
		      								productId: el.product.id, 
		      								prices: el.product.prices,
		      								config: el.config
		      							}}
		      						/>
	      						}
	      						<IconButton 
	      							label='Eliminar'
	      							icon={<DeleteOutlineIcon />}
	      							params={i}
	      							action={handleDeleteItem}
	      							size='small'
	      						/>
	      					</TableCell>
			            <TableCell>{el.product.name}</TableCell>
			            <TableCell align="center">{el.qty}</TableCell>
			            <TableCell align="center">{moneyFormat(el.discount)}%</TableCell>
			            <TableCell align="right">{moneyFormat(el.price)}</TableCell>
			            <TableCell align="right">{moneyFormat(el.amount)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        	<TableRow>
	        		<TableCell colSpan={5} align="right">Subtotal:</TableCell>
	            <TableCell align="right">{props.currency} {moneyFormat(values.subtotal)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={5} align="right">Dscto:</TableCell>
	            <TableCell align="right">{props.currency} {moneyFormat(values.discount)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={5} align="right">IVA:</TableCell>
	            <TableCell align="right">{props.currency} {moneyFormat(values.iva)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={5} align="right"><strong>Total:</strong></TableCell>
	            <TableCell align="right"><strong>{props.currency} {moneyFormat(values.total)}</strong></TableCell>
	        	</TableRow>
	        </TableBody>
				</Table>
			</TableContainer>
			<ItemConfig
				isOpen={isOpen[0]} 
				params={isOpen[1]} 
				handleClose={handleClose} 
				handleAction={handleAction} 
				mobility={props.mobility}
				deliveryDate={props.deliveryDate}
				codeToClear={props.codeToClear}
				isCourtesy={props.isCourtesy}
				isLend={props.isLend}
			/>
		</Fragment>
	)
}

export default TableComponent;