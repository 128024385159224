import React, { useMemo, useEffect } from "react";
import { connect } from 'react-redux';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import { moneyFormat } from '../../_helpers/moneyFormat';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

import { recordsActions } from '../../_actions';

const availableMethods = [
  {key: "01", value: "Efectivo"},
  {key: "02", value: "Cheque nominativo"},
  {key: "03", value: "Transferencia electrónica de fondos"},
  {key: "04", value: "Tarjeta de crédito"},
  {key: "05", value: "Monedero electrónico"},
  {key: "06", value: "Dinero electrónico"},
  {key: "08", value: "Vales de despensa"},
  {key: "12", value: "Dación en pago"},
  {key: "13", value: "Pago por subrogación"},
  {key: "14", value: "Pago por consignación"},
  {key: "15", value: "Condonación"},
  {key: "17", value: "Compensación"},
  {key: "23", value: "Novación"},
  {key: "24", value: "Confusión"},
  {key: "25", value: "Remisión de deuda"},
  {key: "26", value: "Prescripción o caducidad"},
  {key: "27", value: "A satisfacción del acreedor"},
  {key: "28", value: "Tarjeta de débito"},
  {key: "29", value: "Tarjeta de servicios"},
  {key: "30", value: "Aplicación de anticipos"},
  {key: "31", value: "Intermediario pagos"},
  {key: "99", value: "Por definir"}
];

const IndependentPayments = ({ getRecords, ...props }) => {

  const independentPayments = useMemo(() =>
    props.independentPayments.count > 0 ? props.independentPayments.rows.map(el => ({
      ID: { 
        value: el.id,
        data: el,
        show: false
      },
      Fecha:{
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        _sort: 'date'
      },
      Folio: {
        value: el.externalFolio,
        filter: true,
        _sort: 'text'
      },
      Total: {
        value: moneyFormat(el.amount),
        alt: parseFloat(parseFloat(el.amount).toFixed(2)),
        align: 'right',
        filter: true,
        _sort: 'alt',
        accumulate: true
      },
      "Forma de pago":{
        value: el.paymentMethodLabel,
        filter: true,
        _sort: 'text'
      },
      Cliente:{
        value: el.Customer.firstName+' '+el.Customer.lastName,
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.independentPayments]);
  

  useEffect(() => { getRecords({ table: 'customers' }); }, [ getRecords ]);
  const availableCustomers = useMemo(() => {
    return props.customers.rows ? props.customers.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.customers]);

  return (
    <Module
      table="independentPayments"
      name="abonos independientes"
      singularName="abono independiente"
      filter={false}
      download={true}
      title="Crear Abono Independiente"
      records={independentPayments}
    >
      <Autocomplete
        label="Cliente" 
        _id="customerId"
        options={ availableCustomers }
      />
      <TextField
        fields={[{
          id: "externalFolio", 
          label: "Folio"
        }]}
      />
      <TextField
        fields={[{
          id: "amount", 
          label: "Abono"
        }]}
      />
      <SelectField
        fields={[{
          id: 'paymentMethod',
          label: 'Forma de pago',
          options: availableMethods
        }]}
      />
      <SelectField
        fields={[{
          id: 'currencyKey',
          label: 'Moneda',
          options: [
            {key: 'MXN', value: 'MXN'},
            {key: 'USD', value: 'USD'},
          ],
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "exchangeRate", 
          label: "Tipo de Cambio",
          required: false
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStatesToProps = state => ({
  independentPayments: state.records.independentPayments || defaultTableValue,
  customers: state.records.customers || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStatesToProps,mapDispatchToProps)(IndependentPayments);