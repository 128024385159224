import React, { useMemo, Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';

import randomize from 'randomatic';

import localeES from 'date-fns/locale/es';
import format from 'date-fns/format';

import EditIcon from '@material-ui/icons/EditOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';

import Module from '../../_presentationals/Module';

import { openFormDialog  } from '../../_actions';
import * as recordsServices from './../../_services/records.services';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import ProductDialog from './product.dialog.js';
import Edit from './edit.dialog.js';

import { moneyFormat } from '../../_helpers/moneyFormat';
import { rentPlanToLabel } from '../../_helpers/rentPlanToLabel';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';

// const session = JSON.parse(localStorage.getItem('session'));
// const privileges = session && JSON.parse(session.data.privileges);

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Logistic = ({openFormDialog, ...props}) => {

  const [ actions, setActions ] = useState([]);
  const [ logisticId, setLogisticId ] = useState(null);
  const [ deliveryLogisticId, setDeliveryLogisticId ] = useState(null);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ refreshCode, setRefreshCode ] = useState(null);

  useEffect(() => {

    const tmpAction = [
      { icon: <LocalShippingIcon fontSize="small" />, label: "Recolectar Productos", handle: handleProducts },
      { icon: <EditIcon fontSize="small" />, label: "Configurar", handle: handleEdit },
    ];

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const logistics = useMemo(() => 
    props.logistic.count > 0 ? props.logistic.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Recolección:{
        value: el.collectionDate ? format(new Date(el.collectionDate), 'dd/MM/yyyy HH:mm', {locale: localeES}) : '-',
        _sort: 'date',
        noBreak: true,
      },
      Folio:{
        value: el.folio,
        filter: true,
        _sort: 'text'
      },
      Plan:{
        value: !el.rentPlan ? 'Venta' : rentPlanToLabel(el.rentPlan)[0],
        _sort: 'text',
        noBreak: true,
      },
      Equipos:{
        value: el.LogisticProducts.reduce((string, elCh) => {
          const name = elCh.Product ? elCh.Product.name : '-';
          const physicalSerie = elCh.Manufacture ? elCh.Manufacture.physicalSerie : '-';
          const product = name + ' (' + physicalSerie + '),';
          return string + ' ' + product;
        }, '').slice(0, -1),
        noBreak: true,
        _sort: 'text'
      },
      Cliente:{
        value: el.customer,
        filter: true,
       _sort: 'text',
       noBreak: true,
      },
      Dirección:{
        value: el.address ? el.address : '-',
        noBreak: true,
      },
      Cargo:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      Abonado:{
        value: moneyFormat(el.totalPayment),
        alt: parseFloat(parseFloat(el.totalPayment).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      Saldo:{
        value: moneyFormat(el.balance),
        alt: parseFloat(parseFloat(el.balance).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      }
    })) : []
  , [props.logistic]);
  
  const handleEdit = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setLogisticId(id);
    openFormDialog('Edit');
  };

  const handleProducts = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setLogisticId(id);
    openFormDialog('Products');
  };

  const handleCancelDelivery = () => {
    setDeliveryLogisticId(null);
  }

  const handleActionDelivery = async logisticId => {
    try{
      await recordsServices.updateV2(`v2/logistics/${logisticId}/delivery`);
      const tmpCode = randomize('*', 10);
      setRefreshCode(tmpCode);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: 'Los productos se entregaron exitosamente', 
        severity: 'success' 
      }));
    }catch(e){
      alert(e.message);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const reload = (code) => {
    setRefreshCode(code);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  return (
    <Fragment>
      <Module
        table="logisticCollectionV2"
        name="recolecciónes pendientes"
        records={logistics}
        title="Recolecciónes Pendientes"
        singularName="PDF"
        refreshCode={refreshCode}
        filter={false}
        edit={false}
        download={true}
        create={false}
        _delete={false}
        actions={actions}
      >
      </Module>
      <Edit logisticId={logisticId} reload={reload} />
      <ProductDialog logisticId={logisticId} reload={reload} />
      <ConfirmDialog 
        _id={deliveryLogisticId} 
        handleCancel={handleCancelDelivery} 
        handleAction={handleActionDelivery} 
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  logistic: state.records.logisticCollectionV2 || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  employeeId: state.field.value.employeeId || ''
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logistic);