import React, { useMemo, Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';

import { TextField, SelectField } from './../../_presentationals/Form';
import { openFormDialog, editValueAction  } from '../../_actions';

import Module from '../../_presentationals/Module';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BuildIcon from '@material-ui/icons/BuildOutlined';

import Commissions from './commissions';
import Tools from './tools';
import { UsersSelectField } from '../../_helpers/getUsers';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Employee = ({ openFormDialog, editValueAction, ...props }) => {

  const [tools, setTools] = useState([]);
  const [actions, setActions] = useState([]);
  const users = UsersSelectField();

  useEffect(() => {

    const tmpAction = [];

    if(privileges.employees && privileges.employees.tools){ 
      tmpAction.push({ icon: <BuildIcon fontSize="small" />, label: "Herramientas", handle: handdleTool });
    }

    if(privileges.employees && privileges.employees.commissions){ 
      tmpAction.push({ icon: <AttachMoneyIcon fontSize="small" />, label: "Comisiones", handle: handdleCommission });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const employees = useMemo(() => 
    props.employees.count > 0 ? props.employees.rows.map(el => ({
      ID: { 
        value: el.id,
        data: {
          ...el,
          role: el.role.id,
          userId: el.User && el.User.id
        }
      }, 
      Empleado: {
       value: el.firstName + ' ' + el.lastName,
       filter: true,
       _sort: 'text'
      },
      Puesto: { 
        value: el.role.label,
        _sort: 'text'
      },
      Sucursal: { 
        value: el.Branch && el.Branch.name,
        _sort: 'text'
      }
    })) : []
  , [props.employees]);

  const handdleCommission = e => {
    const { id, Commissions } = JSON.parse(e.currentTarget.dataset.row);
    const commissions = {};
    Commissions && Commissions.map(el => (commissions[el.method] = el.commission));
    editValueAction({ ...commissions, id });
    openFormDialog('Commissions'); 
  };

  const handdleTool = e => {
    const { id, Tools } = JSON.parse(e.currentTarget.dataset.row);
    setTools(Tools);
    editValueAction({ id, _id: id, isTools: true });
    openFormDialog('ToolsDialog'); 
  };

  return (
    <Fragment>
      <Module
        table="employees"
        name="empleados"
        records={ employees }
        singularName="empleado"
        filter={false}
        title="Crear Empleado"
        download={true}
        actions={actions}
      >
        <TextField
          icon="account_circle"
          fields={[{
            id: "firstName", 
            label: "Nombre",
            autoFocus: true
          },{
            id: "lastName", 
            label: "Apellidos"
          }]}
        />
        <SelectField
          fields={[{
            id: 'role', 
            label: 'Tipo',
            options: [
              {key:1, value:"Comisionista"},
              {key:2, value:"Vendedor"},
              {key:3, value:"Ensamblaje"},
              {key:6, value:"Chofer"},
              {key:4, value:"Director Comercial"},
              {key:5, value:"Encargado de Sucursal"},
            ]
          }]}
        />
        <SelectField
          fields={[{
            id: 'userId', 
            label: 'Usuario',
            options: users,
            required: false
          }]}
        />

      </Module>
      <Commissions />
      <Tools dataTools={tools} />
    </Fragment>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  employees: state.records.employees || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(Employee);