import React, { useEffect, useState, Fragment } from "react";

import { connect } from 'react-redux';
import format from 'date-fns/format';

import EventIcon from '@material-ui/icons/EventOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import DateTimeField from './../Sales.v2/helpers/DateTimeField';
import TextField from './../Sales.v2/helpers/TextField';
import Autocomplete from './../Sales.v2/helpers/Autocomplete';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  "deliveryCommentary": "",
  "deliveryDate": null,
  "collectionEmployeeId": null,
  "address": "",
  "collectionDate": null,
  "collectionCommentary": ""
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Edit = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);

  useEffect(() => {
    props.logisticId && recordsServices.getAllV2(`v2/logistics/${props.logisticId}`).then(data => {
      const employee = props.Employees.find(el => el.value === data.collectionEmployeeId);
      setValues({
        collectionEmployeeId: employee ? employee : null,
        collectionDate: data.collectionDate,
        collectionCommentary: data.collectionCommentary ? data.collectionCommentary : '',
      })
    })
  }, [props.logisticId, props.Employees]);

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const updateLogistic = async () => {
    try{
      await recordsServices.updateV2(`v2/logistics/${props.logisticId}`, {
        ...values,
        collectionEmployeeId: values.collectionEmployeeId ? values.collectionEmployeeId.value : null
      });
      props.closeFormDialog('EditCollection');
      props.reload();
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: 'La recolección se actualizó correctamente.', severity: 'success' }));
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog 
        title="Configuración de Logística"
        name="editCollection"
        singular="EditCollection"
        handleCustomizedClick={updateLogistic}
      >
        <Autocomplete 
          icon={<AccountCircleIcon />}
          name='collectionEmployeeId'
          label='Chofer' 
          onChange={handleAutocompleteChange}
          options={props.Employees}
          value={values.collectionEmployeeId}
        />
        <DateTimeField
          icon={<EventIcon />}
          name='collectionDate'
          label='Fecha y Hora de Recolección'
          onChange={handleDateChange}
          value={values.collectionDate}
        />
        <TextField
          name='collectionCommentary'
          value={values.collectionCommentary}
          onChange={handleChange}
          label='Comentario para la recolección'
        />
        <Snackbar 
          isOpen={snackbar.isOpen} 
          label={snackbar.label} 
          severity={snackbar.severity} 
          time={3000} 
          handleClose={handleCloseSnackbar}
        />
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || []
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);