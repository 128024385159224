import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

export const styles = theme => ({
  default: {
    backgroundColor: theme.palette.dark,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
  	borderBottomLeftRadius: 0,
  	borderBottomRightRadius: 0
  },
  snackbar: {
    position: 'fixed', 
    bottom: 0
  }
});