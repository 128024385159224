import React from "react";

import { FormDialog } from '../../_presentationals/FormDialog';

import { SelectField, TextField } from './../../_presentationals/Form';

const StatusDialog = props =>
  <FormDialog 
    title="Actualizar Estatus"
    name="StatusDialog"
    singular="StatusDialog"
    table="compounds"
  >
    <SelectField
      fields={[{
        id: "status",
        label: "Estatus",
        options: [
          { key: 1, value: "Activo" },
          { key: 2, value: "Inactivo" }
        ],
      }]}
    />
    <TextField
      fields={[{
        id: "commentary",
        label: "Comentario"
      }]}
    />
  </FormDialog>

export default StatusDialog;