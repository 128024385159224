import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions  } from '../../_actions';

import { AutocompleteField } from './../../_presentationals/Form';

const EmployeeDialog = ({ getRecords, ...props }) => {

  useEffect(() => ( getRecords({ table: 'employees' }) ), [getRecords]);
  const employees = useMemo(() => {
    return props.employees.count > 0 ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.employees]);

  return (
    <FormDialog 
      title="Asignar Responsable"
      name="EmployeeDialog"
      singular="EmployeeDialog"
      table="compounds"
    >
      <AutocompleteField
        label="Empleado" 
        _id="employeeId"
        options={ employees }
      />
    </FormDialog>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  employees: state.records.employees || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDialog);