import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';

import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import { moneyFormat } from '../../_helpers/moneyFormat';

import FilterDialog from './filter';
import ExpenseTicket from '../Expense/expense.ticket';
import ExpensePdf from '../Expense/expense.pdf';

import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import PictureAsPdf from '@material-ui/icons/PictureAsPdfOutlined';

// const session = JSON.parse(localStorage.getItem('session'));
// const privileges = session && JSON.parse(session.data.privileges);

const ExpenseReport = ({getRecords, ...props}) => {
  const [ suppliers, setSuppliers ] = useState([]);

  useEffect(() => { getRecords({ table: 'suppliers' }); }, [getRecords]);
  useEffect(() => {
    const tmp = props.suppliers.count > 0 ? props.suppliers.rows.map(({id, tradename}) => ({ key: id, value: tradename })) : [];
    setSuppliers(tmp);
  }, [props.suppliers]);

  const expenses = useMemo(() =>
    props.expenseProductsReport.count > 0 ? props.expenseProductsReport.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Estatus:{
        value: el.status.label,
        _sort: 'text'
      },
      Fecha: { 
        value: format(parseISO(el.date), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date'
      },
      Folio: {
        value: el.serial,
        filter: true,
        _sort: 'text'
      },
      'Producto o Servicio': {
        value: el.type.id === 3 ?  (el.service || '') : (el.name || ''),
        filter: true,
        _sort: 'text'
      },
      Tipo: {
        value: el.type.label,
        _sort: 'text'
      },
      Metodo: {
        value: el.action.label,
        _sort: 'text'
      },
      Factura: {
        value: el.folio,
        filter: true,
        _sort: 'text'
      },
      Proveedor: {
        value: el.Supplier.tradename,
        filter: true,
        _sort: 'text'
      },
      Vehículo: {
        value: el.vehicle,
        filter: true,
        _sort: 'text'
      },
      Subtotal: {
        value: moneyFormat(el.subtotal),
        alt: parseFloat(parseFloat(el.subtotal).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      IVA: {
        value: moneyFormat(el.iva),
        alt: parseFloat(parseFloat(el.iva).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Total: {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt',
        accumulate: true
      },
      Abonado: {
        value: moneyFormat(el.payments),
        alt: parseFloat(parseFloat(el.payments).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Saldo: {
        value: moneyFormat(el.balance),
        alt: parseFloat(parseFloat(el.balance).toFixed(2)),
        align: "right",
        _sort: 'alt'
      }
    })) : []
  , [props.expenseProductsReport]);

  const filter = () => {
    props.openFormDialog('FilterDialog');
  }

  const handleTicket = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    ExpenseTicket(id)
  }

  const handlePdf = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    ExpensePdf(id)
  }

  return (
    <Fragment>
      <Module
        table="expenseProductsReport"
        name="Reporte de Gastos Por Producto"
        records={expenses}
        singularName="reporte"
        title="Filtrar"
        filter={filter}
        edit={false}
        create={false}
        download={true}
        _delete={false}
        actions={[
          { icon: <ReceiptIcon fontSize="small" />, label: "Ticket", handle: handleTicket },
          { icon: <PictureAsPdf fontSize="small" />, label: "PDF", handle: handlePdf },
        ]}
      ></Module>
      <FilterDialog suppliers={suppliers} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  expenseProductsReport: state.records.expenseProductsReport || defaultTableValue,
  suppliers: state.records.suppliers || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: data => dispatch(openFormDialog(data)),
  editValueAction: value => dispatch(editValueAction(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(ExpenseReport);