import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';
import { isDate, format, parseISO } from 'date-fns';
import localeES from 'date-fns/locale/es';
import Link from '@material-ui/core/Link';

import { config } from './../../_helpers/config';
import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction, recordsActions } from '../../_actions';

import { CreditNotesDialog } from './creditNotesDialog';
import ReInvoiceDialog from './reInvoice.dialog';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog/index';

import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';

import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import Autorenew from '@material-ui/icons/Autorenew';

import { moneyFormat } from '../../_helpers/moneyFormat';
import { FilterDialog }  from './filter'; 

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const status = ['Cancelado','Activo','Cancelado'];

const SalesReport = ({ getRecords, openFormDialog, editValueAction, _delete, ...props }) => {
	const [ subtitle, setSubtitle ] = useState('');
  const [ confirmId, setConfirmId ] = useState(0);
  const [ dataInvoice, setDataInvoice ] = useState({});
  const [ actions, setActions ] = useState([]);
  const [ total, setTotal ] = useState(0);

  useEffect(() => {

    const tmpAction = [
      { icon: <DescriptionIcon fontSize="small" />, label: "Descargar XML", handle: handelXml },
      { icon: <Autorenew fontSize="small" />, label: "Re-Facturar", handle: handleReInvoice }
    ];

    if(privileges.invoices && privileges.invoices.creditNote) {
      tmpAction.push({ icon: <ReceiptIcon fontSize="small" />, label: "Emitir Nota de Crédito", handle: details });
    }

    if(privileges.invoices && privileges.invoices.delete){ 
      tmpAction.push({ icon: <CancelIcon fontSize="small" />, label: "Cancelar Factura Electrónica", handle: handleCancelInvoice });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const details = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    if(data.isAdjust || [2,3].includes(parseInt(data.type))) return;
    setDataInvoice(data);
    openFormDialog('creditNotesDialog');
  };

  const handleCancelInvoice = e => {
    const { id, isAdjust, oneSale } = JSON.parse(e.currentTarget.dataset.row);
    if(isAdjust || !oneSale) return;
    setConfirmId(id);
  }

  const handleDelete = id => {
    _delete({ _id: id, table: 'invoices' });
    setConfirmId(0);
  };

  // const saleDetails = e => {
  //   const { Sale } = JSON.parse(e.currentTarget.dataset.row);
  //   window.open(`${config.apiUrl}/notes/sales/${Sale.id}`, '_blank');
  // };

  const handelXml = e => {
    const { invoiceId } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/files/invoices/${invoiceId}/xml`, '_blank');
  };

  const filter = () => {
    openFormDialog('InvoicesFilter');
  };

  const handleReInvoice = e => {
    const { id, isAdjust, type } = JSON.parse(e.currentTarget.dataset.row);
    if(isAdjust || [2,3].includes(parseInt(type))) return;
    editValueAction({ id, _id: id });
    openFormDialog('ReInvoiceDialog');
  };

  const handleCancel = e => setConfirmId(0);

  const invoices = useMemo(() => {
    setTotal(0);

    const tmp = props.invoices.tmpRes ? props.invoices.tmpRes.reduce((newArray,el) => {
      const data = newArray.find(tmp => 
        tmp.invoiceId === el.invoiceId && parseInt(tmp.saleId) !== parseInt(el.saleId) && parseFloat(el.total) > 0
      );
      if(data) return newArray;
      return [ ...newArray, { invoiceId: el.invoiceId, total: el.total, serial: el.serial }];
    },[]) : [];
    const tmpTotal = tmp.reduce((total,tmp) => (parseFloat(total) + parseFloat(tmp.total)),0);
    setTotal(tmpTotal);

    return props.invoices.count > 0 ? props.invoices.rows.map(el => { 
      return {
        ID: { 
          show:false,
          value: el.id,
          data: el
        },
        Estatus: {
          value: status[parseInt(el.status)],
          filter: true,
          _sort: 'text'
        },
        Fecha: { 
          value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
          _sort: 'date'
        },
        Notas:{
          align: 'center',
          value: el.Sales.map(el => <Fragment key={el.folio}><Link href={`${config.apiUrl}/notes/sales/${el.id}`} target="_blank">{el.folio}</Link><br/></Fragment>),
          arrayValue: el.Sales.map(el => el.folio),
          _sort: 'text'
        },
        Folio: {
        	value: <a  href={el.uri} target="_blank" rel="noopener noreferrer">{el.folio}</a>,
          arrayValue: [el.folio],
          _sort: 'text'
        },
        Clientes: {
        	value: el.Customers.map(el => <Fragment key={el}>{el}<br/></Fragment>),
          arrayValue: el.Customers.map(el => el),
          _sort: 'text'
        },
        Total: {
        	value: moneyFormat(el.total),
          alt: parseFloat(parseFloat(el.total).toFixed(2)),
          align: "right",
          _sort: 'alt'
        }
      }
    }) : [];

  }, [props.invoices]);

  useEffect(() => {
    if(isDate(props.start) && isDate(props.finish)) {
      setSubtitle(`${format(new Date(props.start),'dd/MM/yyyy')} al ${format(new Date(props.finish),'dd/MM/yyyy')}`);
    }
  },[props.start,props.finish])

  return (
    <Fragment>
      <Module
        table="invoices"
        subtitle={subtitle}
        name="reporte de facturas"
        // additionalText={`Total: ${moneyFormat(parseFloat(total))} | F: ${moneyFormat(parseFloat(props.invoices.totalInvoice))} | Cancelado: ${moneyFormat(parseFloat(props.invoices.totalCancelled))} | NC: ${moneyFormat(parseFloat(props.invoices.totalCreditNote))}`}
        additionalText={`Total: ${moneyFormat(parseFloat(total))}`}
        records={invoices}
        singularName="reporte"
        title="Filtrar Facturas"
        filter={filter}
        create={false}
        edit={false}
        _delete={false}
        download={true}
        actions={actions}
      ></Module>
      <ConfirmDialog _id={confirmId} handleAction={handleDelete} handleCancel={handleCancel} />
      <CreditNotesDialog data={dataInvoice} />
      <FilterDialog />
      <ReInvoiceDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: [], totalInvoice: 0, totalCancelled: 0, totalCreditNote: 0};

const mapStateToProps = state => ({
  invoices: state.records.invoices || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  _delete: value => dispatch(recordsActions._delete(value)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);