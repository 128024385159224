import React, { Fragment, useState, useMemo } from "react";
import { connect } from 'react-redux';
import { recordsActions } from '../../_actions';

import Icon from '@material-ui/core/Icon';
import { TextField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import Grid from '@material-ui/core/Grid';
import { ListItemAdd } from './listItemAdd';
import { editValueAction } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Product = ({ products,getRecords, editValueAction, ...props }) => {
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  
  const handleAddProduct = e => {
    const { productId, productIdLabel, quantity = 0 } = props.selectedProduct;

    const product = { productId, name: productIdLabel, quantity: parseFloat(quantity) };

    local && local.push(product);
    editValueAction({ Products: local, productId: null, quantity: 0 });
    setQty(JSON.stringify(local));
    setLocal(local);
  };

  const tableRows = useMemo(() => {    
    const handleDeleteItem = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal(tmp);
    }; 

    return local.map((el, index) => {
      return (<TableRow key={index}>
        {props.type === 3  && (
          <TableCell component="th" scope="row">
            {el.vehicle}
          </TableCell>
        )} 
        <TableCell component="th" scope="row">
          {el.name}
        </TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteItem} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local,qty]);

  return (
    <Fragment>
      <TextField
        fields={[{
          id: "description", label: "Decripción"
        }]}
      />
      <ListItemAdd handleClick={handleAddProduct}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              label="Producto"
              _id="productId"
              dialog={false}
              options={products}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              dialog={false}
              fields={[{
                id: "quantity", label: "Cant.",
                variant: "outlined", margin: "dense", grid: 12
              }]}
            />
          </Grid>
        </Grid>
      </ListItemAdd>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell align="center">Cant.</TableCell>
            <TableCell align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity
  }
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Product);