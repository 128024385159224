import React from "react";
import { connect } from 'react-redux';

import { endOfWeek, startOfWeek } from 'date-fns';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog } from '../../_actions';

import { DateField, SelectField } from '../../_presentationals/Form';

const Dialog = ({suppliers, ...props}) => {

  const handdleFilter = e => {
    props.getRecords({ table: 'expenseReport', start: props.start, finish: props.finish,
      supplierId: props.supplierId, type: props.type, action: props.action, status: props.status
    });
    props.closeFormDialog('FilterDialog');
  };

	return (
	  <FormDialog  
      singular="FilterDialog" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
      <SelectField
        fields={[{
          id: 'supplierId', 
          label: 'Proveedor',
          options: [{key: 0, value: 'Todos'}, ...suppliers]
        }]}
      />
      <SelectField
        fields={[{
          id: 'type', 
          label: 'Tipo',
          options: [
            { key: 0, value: 'Todos' },
            { key: 2, value: 'Administrativo' },
            { key: 1, value: 'Almacén' },
            { key: 3, value: "Vehículo" },
            { key: 4, value: "Consumible" }
          ]
        }]}
      />
      <SelectField
        fields={[{
          id: 'action', 
          label: 'Metodo',
          options: [
            { key: 0, value: 'Todos' },
            { key: 1, value: 'Contado' },
            { key: 2, value: 'Crédito' }
          ]
        }]}
      />
      <SelectField
        fields={[{
          id: 'status', 
          label: 'Estatus',
          options: [
            { key: 0, value: 'Todos' },
            { key: 1, value: 'Pendiente de Pagar' },
            { key: 2, value: 'Pagado' }
          ]
        }]}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  start: state.field.value.start || startOfWeek(new Date()),
  finish: state.field.value.finish || endOfWeek(new Date()),
  supplierId: state.field.value.supplierId || 0,
  type: state.field.value.type || 0,
  action: state.field.value.action || 0,
  status: state.field.value.status || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);