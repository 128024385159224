import React, { useEffect, useMemo, Fragment } from "react";
import { connect } from 'react-redux';

import { openFormDialog, editValueAction, redirectActions, recordsActions } from '../../_actions';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import Module from '../../_presentationals/Module';
import { history } from './../../_helpers';

import LogLogistic from './logLogistic';

import ListAltIcon from '@material-ui/icons/ListAlt';
import ProductIcon from '@material-ui/icons/ToysOutlined';

const LogisticFailure = ({openFormDialog, editValueAction, ...props}) => {
  useEffect(() => {
    props.redirect.id === "" && history.push('/Logistic/Report');
  }, [props.redirect.id]);

  const logisticSale = useMemo(() =>
    props.logisticSale.count > 0 ? props.logisticSale.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      "Fecha de Entrega": {
        value: el.deliveryDate !== " " ? `${format( parseISO(el.deliveryDate), 'dd/MM/yyyy', {locale: localeES} )} ${el.deliveryTime}`: 'No especificada'
      },
      "Producto":{
        value: `${ el.SaleDetail.Product.name} (${el.SaleDetail.quantity}) (${el.SaleDetail.isRent ? "Renta" : "Venta"})`,
        filter: true,
        _sort: 'text'
      },
      Dirección: {
        value: el.addressLine1+(el.addressLine2 !== "" ? ', '+el.addressLine2 : "")+(el.city !== "" ? ', '+el.city : "")+(el.zipCode !== "" ? ', '+el.zipCode : "")+ (el.State !== null ? ', '+el.State.name : "")
      },
      Chofer: {
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : 'No especificado',
        filter: true,
        _sort: 'text'
      },
      Estatus: {
        value: el.status.label,
        _sort: 'text'
      }
    })) : []
  , [props.logisticSale]);

  const handdleLog = e => {
    const { id, logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, logs });
    openFormDialog('LogLogistic');
  };

  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, data, table: 'logisticProduct', returnTo: '/Logistic/Report/Deliveries', returnToLabel: 'Logísticas'});
    history.push('/Logistic/Report/Deliveries/Products');
  };

  return (
    <Fragment>
      <Module
        edit={false}
        create={false}
        _delete={false}
        table="logisticSale"
        name="Logísticas"
        singularName="Logística"
        filter={false}
        records={logisticSale}
        actions={[
          {
            icon: <ListAltIcon fontSize="small" />,
            label: "Bitácora",
            handle: handdleLog
          },
          {
            icon: <ProductIcon fontSize="small" />,
            label: "Productos",
            handle: redirect
          }
        ]}
      >
      </Module>
        <LogLogistic />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  redirect: state.redirect,
  logisticSale: state.records.logisticSale || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticFailure);