import React, { useState, Fragment } from 'react';

import * as recordsServices from './../../../_services/records.services';

import FormDialog from './../helpers/FormDialog';
import TextField from './../helpers/TextField';
import Snackbar from './../helpers/Snackbar';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Authorization = props => {

	const [ value, setValue ] = useState('');
	const [ error, setError ] = useState(null);
	const [ isDisabled, setIsDisabled ] = useState(false);
	const [ snackbar, setSnackbar ] = useState(defaultSnackbar);

	const handleChange = e => {
    const value = e.target.value;
    setValue(value);
  }
	
	const handleAction = async () => {
		try{

			if(value === ''){
				throw new Error('Escribe una justificación.');
			}

			setIsDisabled(true);
			const response = await recordsServices.updateV2(`v2/saleAuths/${props.id}/authCommentary`, { authCommentary: value }).then(data => {
				setIsDisabled(false);
				return data;
			});
			setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: response.message, severity: 'success' }));
			props.handleClose();
			setError('');
		}catch(e){
			setIsDisabled(false);
			setError(e.message);
		}
	}

	const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

	return (
		<Fragment>
			<FormDialog
	      title='Justificación'
	      isOpen={props.isOpen}
	      handleClose={props.handleClose}
	      handleAction={handleAction}
	      actionLabel='Enviar'
	      disabled={isDisabled}
	    >
	    	<TextField 
	        name='justification'
	        label='Escribe una justificación'
	        onChange={handleChange}
	        value={value}
	        error={error}
	        noFormat
	        variant='outlined'
	      />
	    </FormDialog>
	    <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
	)
}

export default Authorization;