const compounds = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'PUT':
      if(values.status) return { status: values.status, commentary: values.commentary }
      if(values.employeeId) return { employeeId: values.employeeId };
      break;
    default:
      return tmp;
  }
}

export default compounds;
