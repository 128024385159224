import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog, editValueAction } from '../../_actions';

import { DateField } from '../../_presentationals/Form';

const Component = props => {

  const handdleFilter = e => {
    const { start, finish } = props;
    props.editValueAction({ start: start, finish: finish });
    props.getRecords({ table: 'invoicesReview', start, finish });
    props.closeFormDialog('Filter');
  };

  return (
    <FormDialog  
      singular="Filter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
    </FormDialog>
  )
};

const mapStateToProps = state => ({ 
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date()
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);