import React, { Fragment, useEffect, useState, useMemo  } from "react";
import { connect } from 'react-redux';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Receipt from '@material-ui/icons/Receipt';

import Module from '../../_presentationals/Module';
import { openFormDialog } from '../../_actions';
import { config } from './../../_helpers/config';
import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import { CourtesyTicket } from './ticket';
import { FilterDialog }  from './filter';

const CourtesiesReport = props => {

  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [{
      icon: <Receipt fontSize="small" />,
      label: "Generar Ticket",
      handle: handdleTicket
    },{
      icon: <ZoomInIcon fontSize="small" />,
      label: "Consultar Venta",
      handle: details
    }];

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handdleTicket = (e) => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    CourtesyTicket(data.id);
  }

  const details = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/courtesies/${id}`, '_blank');
  };  

  const filter = () => {
    props.openFormDialog('SalesReportFilter');
  };
  
  const courtesies = useMemo(() => (   
    props.courtesies.count > 0 ? props.courtesies.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date'
      },
      Folio: {
       value: el.folio,
       filter: true,
       _sort: 'text'
      },
      Cliente: {
       value: el.Customer ? `${el.Customer.firstName} ${el.Customer.lastName}` : '',
       filter: true,
       _sort: 'text'
      },
      Usuario:{
        value: el.User ? `${el.User.firstName} ${el.User.lastName}` : '',
        _sort: 'text'
      },
      Vendedor:{
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : '',
        _sort: 'text'
      }
    })) : []
  ), [props.courtesies]);

  return (
    <Fragment>
      <Module
        table="courtesiesReport"
        name="Reporte de Cortesias"
        records={courtesies}
        singularName="reporte"
        title="Filtrar Ventas"
        filter={filter}
        edit={false}
        create={false}
        download={true}
        _delete={true}
        actions={actions}
      ></Module>

        <FilterDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  courtesies: state.records.courtesiesReport || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourtesiesReport);