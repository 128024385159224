import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
	icon: {
		marginTop: 50,
		marginBottom: 20,
    fontSize: 200,
    color: grey[400]
  },
  layout: {
    width: 'auto',
    textAlign: 'center'
  }
});

export { styles };