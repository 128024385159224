const uri = ({method, ...props}) => {
  switch (method) {
    case 'POST':
      return `borrowers`;
    case 'GET':
      return `borrowers`
    case 'PUT':
      return `borrowers/${props._id}`;
    case 'DELETE':
      return `borrowers/${props._id}`;
    default:
      return 'borrowers';
  }
}

export default uri;
