import React from "react";
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormDialog } from '../../_presentationals/FormDialog';
import { moneyFormat } from '../../_helpers/moneyFormat';

const ProductsDialog = props => {
  return (
    <FormDialog 
      title="Productos"
      name="ProductsDialog"
      singular="ProductsDialog"
      showActionButton={false}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Producto o  Servicio</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Precio</TableCell>
            <TableCell align="right">Descuento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.Products && props.Products.map((el, i) => (
            <TableRow key={i}>
              <TableCell component="th" scope="row">{ el.service ? el.service : (el.name || '') }</TableCell>
              <TableCell align="right">{el.quantity}</TableCell>
              <TableCell align="right">{moneyFormat(el.price)}</TableCell>
              <TableCell align="right">{el.discount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FormDialog>
  );

}

const mapStateToProps = state => ({
  Products: state.field.value.Products || []
});


export default connect(mapStateToProps, null)(ProductsDialog);