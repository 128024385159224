import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';
import { config } from './../../_helpers/config';

import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction, recordsActions } from '../../_actions';

import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';

import { moneyFormat } from '../../_helpers/moneyFormat';
import { FilterDialog }  from './filter';
import ConceptsDialog  from './concepts.dialog';

const SalesReport = ({ getRecords, openFormDialog, editValueAction, _delete, ...props }) => {
  const [actions, setActions] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const tmpAction = [
      { icon: <DescriptionIcon fontSize="small" />, label: "Descargar XML", handle: handelXml },
    ];
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelXml = e => {
    const { invoiceId } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/files/invoices/${invoiceId}/xml`, '_blank');
  };

  const filter = () => {
    openFormDialog('InvoicesFilter');
  };

  const handleCustomCreate = () => {
    openFormDialog('conceptsDialog');
  }

  const invoices = useMemo(() => {
    setTotal(0);
    return props.invoices.count > 0 ? props.invoices.rows.map(el => { 
      setTotal(total => (!!el.deletedAt ? parseFloat(total) : parseFloat(total) + parseFloat(el.total)));
      return {
        ID: { 
          show:false,
          value: el.id,
          data: el,
          showIf: el.sale ? true : false,
        },
        Estatus: {
          value: el.deletedAt ? 'Cancelado' : 'Activo',
          filter: true,
          _sort: 'text',
          noBreak: true
        },
        Fecha: { 
          value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
          _sort: 'date',
          noBreak: true
        },
        Folio: {
          value: <a href={el.uri} target="_blank" rel="noopener noreferrer">{el.folio}</a>,
          alt: el.folio,
          _sort: 'text',
          noBreak: true
        },
        Cliente: {
          value: el.Customer.firstName+' '+el.Customer.lastName,
          _sort: 'text'
        },
        RFC: {
          value: el.Customer.BillingInformation ? el.Customer.BillingInformation.identifier : '',
          _sort: 'text'
        },
        Driección: {
          value: el.Customer.CustomerAddress ? `${el.Customer.CustomerAddress.addressLine1} ${el.Customer.CustomerAddress.addressLine1}` : '',
          _sort: 'text'
        },
        Subtotal: {
          value: moneyFormat(el.subtotal),
          alt: parseFloat(parseFloat(el.subtotal).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Descuento: {
          value: moneyFormat(el.discount),
          alt: parseFloat(parseFloat(el.discount).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Impuestos: {
          value: moneyFormat(el.taxes),
          alt: parseFloat(parseFloat(el.taxes).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Total: {
          value: moneyFormat(el.total),
          alt: parseFloat(parseFloat(el.total).toFixed(2)),
          align: "right",
          _sort: 'alt',
          accumulate: true
        }
      }
    }) : []
  }, [props.invoices]);

  return (
    <Fragment>
      <Module
        table="customizedInvoices"
        name="facturas personalizadas"
        additionalText={`Total: ${moneyFormat(total)}`}
        records={invoices}
        singularName="Factura personalizada"
        title="Filtrar Facturas"
        filter={filter}
        create={true}
        handleCustomCreate={handleCustomCreate}
        edit={false}
        _delete={false}
        download={true}
        actions={actions}
      ></Module>
      <FilterDialog />
      <ConceptsDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  invoices: state.records.customizedInvoices || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  _delete: value => dispatch(recordsActions._delete(value)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);