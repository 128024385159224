import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';

import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { format } from 'date-fns'
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';

import Module from '../../_presentationals/Module';
import {  SelectField, TextField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { recordsActions, editValueAction, openFormDialog } from '../../_actions';
import { moneyFormat } from '../../_helpers/moneyFormat';

import Log from '../Manufacture/log';
import ConfirmDialog from '../Manufacture/_ConfirmDialog'; 
import Warranty from '../Manufacture/warranty';
import DeleteManucfacture from '../Manufacture/_deleteManufacture';
import { FilterDialog }  from '../Manufacture/filter';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Manufacture = ({ getRecords, updateRecords, editValueAction, clearRecords, openFormDialog, ...props }) => {
  const [ spareProducts, setSpareProducts ] = useState([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [];

    if(privileges.manufactures && privileges.manufactures.finishWarranty){ 
      tmpAction.push({ icon: <DoneAllIcon fontSize="small" />, label: "Finalizar Ensamble", handle: handleConfirm });
    }

    if(privileges.manufactures && privileges.manufactures.log){ 
      tmpAction.push({ icon: <ListAltIcon fontSize="small" />, label: "Consultar Historial", handle: handdleLog });
    }

    if(privileges.manufactures && privileges.manufactures.warranty){ 
      tmpAction.push({ icon: <HistoryIcon fontSize="small" />, label: "Enviar a Garantía", handle: handleWarranty });
    }

    if(privileges.manufactures && privileges.manufactures.delete){ 
      tmpAction.push({ icon: <DeleteIcon fontSize="small" />, label: "Eliminar Equipo", handle: handleDelete });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => { 
    editValueAction({ type: 1 });
    !props.isOpen.Warranty && getRecords({ table: 'products', type: 1 });
  },[ getRecords, props.alert.type, props.isOpen, editValueAction ]);


  useEffect(() => { 
    getRecords({ table: 'products', type: 1 });
    getRecords({ table: 'employees', employeeRole: 3 });
  }, [getRecords, clearRecords]);

  const products = useMemo(() => {
    return props.products.count > 0 ? props.products.rows.map(({ id, name, rentPrice }) => ({ value: id, label: name, rentPrice })) : [];
  }, [props.products]);

  const availableEmployees = useMemo(() => {
    return props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.employees]);


  const manufactures = useMemo(() =>
    props.manufactures.count > 0 ? props.manufactures.rows.map(el => ({
      ID:{
        show: true,
        data: el,
        value: el.id
      },
      "Fecha de ensamble":{
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date',
      },
      Serial:{
        value: el.serial,
        filter: true,
       _sort: 'text'
      },
      "Serie Física":{
        value: el.physicalSerie,
        filter: true,
       _sort: 'text'
      },
      Producto:{
        value: el.Product.name,
        filter: true,
       _sort: 'text'
      },
      "Ensamblado Por":{
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : "",
        _sort: 'text'
      },
      Costo: {
        value: moneyFormat(el.productionCost),
        alt: parseFloat(parseFloat(el.productionCost).toFixed(2)),
        align: 'right',
        accumulate: true, accumulateLabel: 'Costo de Producción',
        _sort: 'alt',
      },
      Sucursal:{
        value: el.Branch.name,
       _sort: 'text'
      },
      Estatus:{
        value: el.statusLabel,
       _sort: 'text'
      }
    })) : []
  ,[props.manufactures]);

  const handleConfirm = e => {
    const { id, physicalSerie, status } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, physicalSerie, status } );
    openFormDialog('EditManufacture');
  }

  const handleWarranty = e => {
    const { id, status } = JSON.parse(e.currentTarget.dataset.row);
    if(parseInt(status) === 6) return;
    editValueAction({ manufactureId: id, isWarranty: true });
    openFormDialog('Warranty');
  }

  const handleDelete = e => {
    const { id, Pieces } = JSON.parse(e.currentTarget.dataset.row);
    setSpareProducts(Pieces);
    editValueAction({ _id: id });
    openFormDialog('_delete');
  }

  const handdleLog = e => {
    const { logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ logs });
    openFormDialog('Log');
  };

  const filter = () => {
    openFormDialog('ManufacturesFilter');
  };

  return (
    <Fragment>
      <Module
        table="manufacturesSale"
        name="Equipos Para Venta"
        records={manufactures}
        singularName="Equipo"
        filter={filter}
        edit={false}
        _delete={false}
        title="Ensamblar Producto"
        download={true}
        actions={actions}
      >
        <SelectField
          fields={[{
            id: "type",
            label: "Tipo",
            options: [{
              key: 1,
              value: 'Nuevo'
            },{
              key: 2,
              value: 'Ensamblado'
            }]
          }]}
        />
        <Autocomplete
          icon="poll"
          label="Producto" 
          _id="productId"
          options={ products }
        />
        <TextField
          fields={[{
            id: "physicalSerie",
            label: "Serie Física"
          }]}
        />
        <Autocomplete
          icon="user"
          label="Ensamblador" 
          _id="employeeId"
          options={ availableEmployees }
        />
        <SelectField
          fields={[{
            id: 'status',
            label: "Renta o Venta",
            options: [
              {key:2, value:"Disponible para Venta"},
              {key:3, value:"Disponible para Renta"}
            ]
          }]}
        />
        
      </Module>
      <Log />
      <ConfirmDialog />
      <Warranty />
      <FilterDialog products={products} table='manufacturesSale' />
      <DeleteManucfacture spareProducts={spareProducts} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  status: state.field.value.status || 0,
  type: state.field.value.type || 0,
  products: state.records.products || defaultTableValue,
  productId: state.field.value.productId || 0,
  employees: state.records.employees || defaultTableValue,
  manufactures: state.records.manufacturesSale || defaultTableValue,
  alert: state.alert,
  isOpen: state.formDialog, 
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  updateRecords: value => dispatch(recordsActions.update(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Manufacture);