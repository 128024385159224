import React, { PureComponent } from "react";

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './style';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import SchoolIcon from '@material-ui/icons/School';
import BookIcon from '@material-ui/icons/Book';
import GavelIcon from '@material-ui/icons/Gavel';

import Typography from '@material-ui/core/Typography';

import { history } from './../../_helpers';
import logo from './../../_helpers/files/logo.png';

class Background extends PureComponent {
  
  handleRoute = (e, value) => {
    history.push(value);
  }

  render() {
    const { classes, children, footer, actions } = this.props;
    return (
      <div className={ classes.background }>
      	<div className={ classes.header }>
      		<img src={ logo } className={ classes.logo } alt="logo" />
      	</div>
      	<Grid container justifyContent="center" spacing={0}>
      		<Grid item md={5} xs={11}>
		        <Card className={ classes.body }>
              <CardContent>
		            { children }
              </CardContent>
		        </Card>
            <Typography component="h2" variant="body1" className={classes.footer} align="center" gutterBottom>
		          { footer }
            </Typography>
		      </Grid>
        </Grid>
        {actions.length > 0 && (
          <BottomNavigation className={ classes.navigation } onChange={this.handleRoute} showLabels>
            { 
              actions.map(el =>
                <BottomNavigationAction key={ el.label } value={ el.route } className={ classes.navigationAction } label={el.label} icon={el.icon} />
              )
            }
            <BottomNavigationAction className={ classes.navigationAction } value="/public/docs" label="Documentación" icon={<SchoolIcon />} />
            <BottomNavigationAction className={ classes.navigationAction } value="/public/privacy" label="Privacidad" icon={<BookIcon />} />
            <BottomNavigationAction className={ classes.navigationAction } value="/public/terms" label="Términos" icon={<GavelIcon />} />
          </BottomNavigation>
        )}
      </div>
    );
  }
}

Background.propTypes = {
  actions: PropTypes.array.isRequired
};

Background.defaultProps = {
  actions: []
};

export default withStyles(styles)(Background);