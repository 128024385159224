import React, { Fragment, useMemo, useState } from "react";
import { connect } from 'react-redux';
import randomize from 'randomatic';

import Snackbar from './../Sales.v2/helpers/Snackbar';
import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { openFormDialog } from '../../_actions';

import AuthDialog from './auth.dialog.js';

import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
  const [ pendingSaleId, setPendingSaleId ] = useState(null);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ refreshCode, setRefreshCode ] = useState(null);
  

  const pendingSalesV2 = useMemo(() =>
    props.pendingSalesV2.count > 0 ? props.pendingSalesV2.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      'Primera Factura': {
        value: `${el.PrincipalSale.folio}-${el.PrincipalSale.serial}`,
        _sort: 'text'
      },
      Cliente: {
        value: `${el.Customer.firstName} ${el.Customer.lastName}`,
        _sort: 'text'
      },
      Pago: {
        value: `${el.payment}/${el.totalPayments}`,
        _sort: 'text'
      },
      Total: {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      }
    })) : []
  , [props.pendingSalesV2]);

  const handleAuthorize = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setPendingSaleId(id);
    props.openFormDialog('SaleDialog');
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const reload = () => {
    const tmpCode = randomize('*', 10);
    setRefreshCode(tmpCode);
    setPendingSaleId(0);
  }

  return (
    <Fragment>
      <Module
        table="pendingSalesV2"
        name="Rentas Mensuales Pendientes"
        records={pendingSalesV2}
        singularName="Renta Mensuale Pendiente"
        refreshCode={refreshCode}
        create={false}
        edit={false}
        _delete={true}
        download={true}
        filter={false}
        actions={[
          { icon: <DoneAllIcon fontSize="small" />, label: "Cobrar", handle: handleAuthorize }
        ]}
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
      <AuthDialog pendingSaleId={pendingSaleId} reload={reload}/>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  pendingSalesV2: state.records.pendingSalesV2 || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Component);

