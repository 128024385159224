import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';

import { progressActions, recordsActions } from '../../_actions';
import { EmptyTable } from '../../_presentationals/EmptyTable';
import { TableModule } from '../../_presentationals/Table';

import { FormDialog } from '../FormDialog';
import { Snackbar } from '../Snackbar';

const Module = props => {
  
  const { 
    progress,
    records,
    table,
    openProgressBar,
    clearRecords,
    getRecords,
    alert,
    filter,
    widthDialog,
    additionalAction,
    refreshCode
  } = props;
  
  useEffect(() => {
    openProgressBar();
  }, [openProgressBar]);

  useEffect(() => {
    getRecords({ table });
    return () => clearRecords(table); 
  }, [getRecords, table, clearRecords]);
  
  useEffect(() => {
    (refreshCode || alert === 'success') && getRecords({ table });

  }, [getRecords, table, alert, refreshCode]);
  
  if(!progress && records.length === 0 && !filter){
    const { name, singularName, title, children, create } = props;
    return (
      <Fragment>
        <EmptyTable
          plural={name}
          singular={singularName}
          create={create}
          handleCustomCreate={props.handleCustomCreate}
          filter={filter}
        />
        <FormDialog
          table={ table }
          title={ title }
          singular={ singularName }
          widthDialog={ widthDialog }
        >
          { children }
        </FormDialog>
        <Snackbar />
      </Fragment>
    );
  }

  if((!progress && records.length > 0) || filter){
    const { handleCustomCreate, name, subtitle, singularName, download, tableStructure, updateStructure, edit, _delete, title, children, create, actions, totals } = props;
    const additionalText = props.additionalText || "";
    
    return (
      <Fragment>
        <TableModule
          table={ table }
          subtitle={ subtitle }
          name={ name }
          singularName={ singularName }
          structure={ tableStructure }
          edit={edit}
          _delete={_delete}
          updateStructure={ updateStructure }
          filter={ filter }
          additionalAction={additionalAction}
          actions={ actions }
          create={ create }
          handleCustomCreate={handleCustomCreate}
          records={ records }
          download={ download }
          additionalText={additionalText}
          totals={totals}
        />
        <FormDialog
          table={ table }
          title={ title }
          singular={ singularName }
          widthDialog={ widthDialog }
        >
          { children }
        </FormDialog>
        <Snackbar />
      </Fragment>
    );
  }

  return "";

};

const mapStateToProps = state => ({
  alert: state.alert.type,
  progress: state.progress.isLoading
});

const mapDispatchToProps = dispatch => ({
  openProgressBar: () => dispatch(progressActions.open()),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Module);