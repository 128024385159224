import React, { useMemo, Fragment } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';

import { recordsActions, editValueAction, openFormDialog } from '../../_actions';

import Credentials from './credentials';

import { BranchesSelectField } from '../../_helpers/getBranches';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const User = ({editValueAction, ...props}) => {

  const branches = BranchesSelectField();

  const users = useMemo(() => 
    props.users.count > 0 ? props.users.rows.map(el => ({
      ID: { 
        value: el.id,
        data: {
          branchId: el.Branch.id,
          ...el
        }
      }, 
      Nombre: {
       value: el.firstName+' '+el.lastName,
       filter: true,
       _sort: 'text'
      },
      Usuario:{
        value: el.username,
        filter: true,
       _sort: 'text'
      },
      Sucursal: {
        value: el.Branch.name,
       _sort: 'text'
      }
    })) : []
  , [props.users]);

  const handleCredentials = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id });
    props.openFormDialog('Credentials');
  }

  return (
    <Fragment>
      <Module
        table="users"
        name="usuarios"
        records={users}
        singularName="usuario"
        filter={false}
        title="Crear Usuario"
        actions={
          (privileges.users && privileges.users.privileges) && [{
            icon: <SecurityIcon fontSize="small" />,
            label: "Credenciales",
            handle: handleCredentials
          }]
        }
        download={true}
      >
        <TextField
          icon="account_circle"
          fields={[{ 
            id: "firstName", 
            label: "Nombre",
            autoFocus: true
          },
          {
            id: "lastName", 
            label: "Apellido(s)"
          }]}
        />
        <TextField
          icon="fingerprint"
          fields={[{
            id: "username", 
            label: "Usuario (Correo Institucional)"
          }]}
        />
        {
          !Object.keys(props.field).includes('id') &&
            <TextField
              fields={[{
                id: "password", 
                label: "Contraseña",
                type: "password"
              }]}
            />
        }
        <SelectField
          icon="store"
          fields={[{
            id: 'branchId', 
            label: 'Sucursal',
            options: branches,
          }]}
        />

      </Module>
      <Credentials />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  field: state.field.value,
  userDialog: state.formDialog.usuario,
  users: state.records.users || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: value => dispatch(editValueAction(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(User);