const logisticCollection = ({method, ...props}) => {
	switch (method) {
		case 'PUT':
			return `logistics/${props._id}`
    default:
    	if(props.deliveries && props.employeeId) return `logistics/collections/report/${props.employeeId}`;
    	if(props.deliveries) return `logistics/collections/report`;
      return 'logistics?status=2';
  }
}

export default logisticCollection;