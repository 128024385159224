import { recordsConstants } from '../_constants';

const initialState = {};

export const records = (state = initialState, action) => {
	const _array = state;
  switch (action.type) {
    case recordsConstants.GET_RECORDS:
    	_array[action.payload.table] = action.payload; 
      return { ...state, ..._array };
    case recordsConstants.DELETE_RECORDS:
    	_array[action.payload.table] = {};
      return { ..._array };
    default:
      return state
  }
};