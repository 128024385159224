import React, { Fragment, useMemo, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { config } from './../../_helpers/config';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { format, endOfWeek, startOfWeek } from 'date-fns';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { recordsActions, openFormDialog } from '../../_actions';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Receipt from '@material-ui/icons/Receipt';

import { PaymentTicket } from './ticket';
import { FilterDialog }  from './filter';

const PaymentReport = ({ getRecords, ...props }) => {
  const [ START ] = useState(startOfWeek(new Date()));
  const [ FINISH ] = useState(endOfWeek(new Date()));

  useEffect(() => {
    getRecords({ table: 'payments', start: START, finish: FINISH });
  }, [getRecords, START, FINISH]);

  const handdleTicket = (e) => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    if(data.method.id !== '100') PaymentTicket(data.id);
  }
  
  const payments = useMemo(() =>
    props.payments.count > 0 ? props.payments.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      Fecha: {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        _sort: 'date'
      },
      Folio: {
        value: el.folio,
        filter: true,
        _sort: 'text'
      },
      NV: {
        value: el.saleFolio,
        filter: true,
        _sort: 'text'
      },
      CP: {
        value: el.uri ? <a  href={el.uri} target="_blank" rel="noopener noreferrer">{el.folioCp}</a> : '',
        _sort: 'text'
      },
      Cliente: {
       value: el.AccountReceivable.Customer.fullName,
       filter: true,
       _sort: 'text'
      },
      Usuario: {
        value: `${el.User.firstName} ${el.User.lastName}`,
        filter: true,
        _sort: 'text'
      },
      Importe: {
        value: moneyFormat(el.AccountReceivable.Sale.total),
        alt: parseFloat(parseFloat(el.AccountReceivable.Sale.total).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      Abono:{
        value: moneyFormat(el.payment),
        alt: parseFloat(parseFloat(el.payment).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      "Total Abonado":{
        value: moneyFormat(el.totalPayment),
        alt: parseFloat(parseFloat(el.totalPayment).toFixed(2)),
        align: 'right',
        _sort: 'alt',
        accumulate: true
      },
      Saldo: {
        value: moneyFormat(el.balance),
        alt: parseFloat(parseFloat(el.balance).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      "Forma de pago": {
        value: el.method.label,
       filter: true,
       _sort: 'text'
      }
    })) : []
  , [props.payments]);

  const details = e => {
    const { AccountReceivable } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${AccountReceivable.saleId}`, '_blank');
  };
  
  // const handdleFilter = e => {
  //   const { start, finish } = props.fields;
  //   getRecords({ table: 'payments', start, finish });
  // };

  const filter = () => {
    props.openFormDialog('PaymentFilter');
  };

  return (
    <Fragment>
      <Module
        table="payments"
        name="reporte de abonos"
        records={payments}
        singularName="reporte"
        title="Filtrar Abonos"
        filter={filter}
        create={false}
        edit={false}
        _delete={false}
        download={true}
        actions={[
          {
            icon: <Receipt fontSize="small" />,
            label: "Ticket",
            handle: handdleTicket
          },
          {
            icon: <ZoomInIcon fontSize="small" />,
            label: "Consultar Venta",
            handle: details
          }
        ]}
      ></Module>
      <FilterDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  payments: state.records.payments || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReport);