import { format } from "date-fns";
import locale from 'date-fns/locale/es';

const sales = ({method, ...props}) => {
  switch (method) {
    case 'DELETE': 
      return `v2/saleAuths/${props._id}`;
    default:
    	if(props.filter) {
	      const startFormat = props.start ? new Date(props.start) : new Date();
	      const finishFormat = props.finish ? new Date(props.finish) : new Date();

	      const start = format(startFormat, 'yyyy/MM/dd', {locale});
	      const finish = format(finishFormat, 'yyyy/MM/dd', {locale});

	      return `v2/saleAuths?start=${start}&finish=${finish}`;
    	}
    	return `v2/saleAuths/actives`;
  }
}

export default sales;