const physicalInventories = ({method, ...props}) => {
  switch (method) {
    case 'GET':
     return `physicalInventories`;
    default:
      return `physicalInventories`;
  }
}

export default physicalInventories;
