
const lends = ({method, ...props}) => {
  switch (method) {
    case 'DELETE': 
      return `v2/lends/${props._id}`;
    default:
      return `v2/lends?status=1`;
  }
}

export default lends;