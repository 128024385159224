import React, { useState, Fragment } from "react";
import { connect } from 'react-redux';

import { FormDialog } from './../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import TextField from './../Sales.v2/helpers/TextField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import InvoiceData from './../Sales.v2/InvoiceData';

const defaultValues = {
  commentary: ""
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isInvoiceDataOpen, setIsInvoiceDataOpen ] = useState([false, null]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleInvoiceDataClose = () => {
    setIsInvoiceDataOpen([false, null]);
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const handleAction = async () => {
    try{
      setIsLoading(true);
      await recordsServices.updateV2(`v2/pendingSales/${props.pendingSaleId}`,values).then(data => {
        setSnackbar(snackbar => ({ 
          ...snackbar, 
          isOpen: true, 
          label: 'El CFDi fue emitido con éxito', 
          severity: 'success' 
        }));
        setIsLoading(false);
        props.reload();
        setValues(defaultValues);
        props.closeFormDialog('SaleDialog');
        setIsInvoiceDataOpen([true, data.uuid]);

      });
    }catch(e){
      alert(e.message);
      setIsLoading(false);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog 
        title="Datos de facturación"
        name="SaleDialog"
        isLoading={isLoading}
        singular="SaleDialog"
        handleCustomizedClick={handleAction}
      >
        <TextField 
          name='commentary'
          label='Comentario'
          onChange={handleChange}
          value={values.commentary}
        />
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
      <InvoiceData isOpen={isInvoiceDataOpen[0]} uuid={isInvoiceDataOpen[1]} handleClose={handleInvoiceDataClose} />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);