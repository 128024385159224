import React, { useState, useEffect, Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import randomize from 'randomatic';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import EditIcon from '@material-ui/icons/EditOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AppsIcon from '@material-ui/icons/AppsOutlined';
// import UpdateIcon from '@material-ui/icons/UpdateOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';

import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';
import { ConfirmDialog } from './../../_presentationals/ConfirmDialog';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

import * as recordsServices from './../../_services/records.services';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';

import DeliveryDialog from './delivery.dialog';
import StatusDialog from './status.dialog';
import ProductDialog from './product.dialog';
import CloseDialog from './close.dialog';
import Edit from './edit.dialog';

const statusLabel = ['No Definido','Abierta','Autorizado','No Autorizado','Autorizado','No Autorizado','Cerrado','Facturado','Pendiente de Entrega','Entregado'];

const ServiceOrder = ({ getRecords, openFormDialog, editValueAction, ...props }) => {
  
  const [ customerOptions, setCustomerOptions ] = useState([]);
  const [ productOptions, setProductOptions ] = useState([]);
  const [ serviceOrderId, setServiceOrderId ] = useState(null);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ reopenId, setReopenId ] = useState(0);

  useEffect(() => {
    recordsServices.getAllV2('groups/customers').then(data => {
      const tmp = data.rows.map(el => ({
        label: `${el.CustomerPhones && el.CustomerPhones[0].phone} ${el.firstName} ${el.lastName}`.trim(), 
        value: el.id
      }))
      setCustomerOptions(tmp);
    });
    recordsServices.getAllV2('products/type/1').then(data => {
      const tmp = data.rows.map(el => ({
        label: el.name, 
        value: el.id
      }))
      setProductOptions(tmp);
    });
  }, []);

  const serviceOrder = useMemo(() => 
    props.serviceOrder.count > 0 ? props.serviceOrder.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el,
        showIf: parseInt(el.status) === 7 ? true : false,
        _alternative: parseInt(el.status) === 6 ? true : false,
      }, 
      "Creado el": {
        value: format(parseISO(el.createdAt), 'dd/MM/yyyy HH:ss', {locale: localeES}),
        _sort: 'date',
        noBreak: true
      },
      Folio: {
        value: el.folio + '-' + el.serial,
        noBreak: true
      },
      Cliente: {
        value: el.Customer ? el.Customer.firstName + ' ' + el.Customer.lastName : '-',
        filter: true,
        _sort: 'text'
      },
      Equipo: {
        value: el.Product ? el.Product.name : '-',
        filter: true,
        _sort: 'text'
      },
      Serie: {
        value: el.physicalSerie,
        _sort: 'text'
      },
      Descripción: {
        value: el.description
      },
      "Registrado por": {
        value: el.User ? el.User.firstName + ' ' + el.User.lastName : '-',
        noBreak: true
      },
      Estatus: {
        value: statusLabel[parseInt(el.status)],
        noBreak: true
      },
      
    })) : []
  , [props.serviceOrder]);

  const handleClose = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setServiceOrderId(id);
    openFormDialog('Close');
  }

  const handleOpen = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setReopenId(id);
  }

  const handleReopen = async id => {
    setReopenId(0);
    const respose = await recordsServices.updateV2(`v2/serviceOrders/${id}/status?status=1`);
    if(respose.errorCode) throw new Error(respose.errorMessage);
    const tmpCode = randomize('*', 10);
    setRefreshCode(tmpCode);
  }

  const handleCloseReopen = id => {
    setReopenId(0);
  }

  const handleDelivery = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setServiceOrderId(id);
    openFormDialog('Delivery');
  }

  // const handleStatus = e => {
  //   const { id } = JSON.parse(e.currentTarget.dataset.row);
  //   setServiceOrderId(id);
  //   openFormDialog('Status');
  // }

  const handleProducts = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setServiceOrderId(id);
    openFormDialog('Products');
  }

  const handleEdit = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setServiceOrderId(id);
    openFormDialog('Edit');
  }

  const reload = (code) => {
    setRefreshCode(code);
  };

  return (
    <Fragment>
      <Module
        table="serviceOrders"
        name="Orden de Servicio"
        records={serviceOrder}
        singularName="orden de servicio"
        refreshCode={refreshCode}
        edit={false}
        filter={false}
        title="Orden de servicio"
        download={true}
        actions={[
          { showIf: true, icon: <LocalShippingIcon fontSize="small" />, label: "Entregar Equipo", handle: handleDelivery },
          { 
            showIf: false,
            icon: <LockOpenIcon fontSize="small" />,
            icon1: <LockIcon fontSize="small" />,  
            label: "Cerrar Orden de Servicio", 
            label1: "Abrir Orden de Servicio", 
            handle: handleClose,
            handle1: handleOpen
          },
          { icon: <AppsIcon fontSize="small" />, label: "Productos", handle: handleProducts },
          { icon: <EditIcon fontSize="small" />, label: "Editar", handle: handleEdit },
        ]}
      >
        <Autocomplete
          icon="person"
          label="Cliente" 
          _id="customerId" 
          options={customerOptions}
          disabledOnUpdate={true}
        />
        <Autocomplete
          icon="toys"
          label="Equipo" 
          _id="productId" 
          options={productOptions}
          disabledOnUpdate={true}
        />
        <TextField
          fields={[{
            id: "physicalSerie",
            label: "Serie de Equipo"
          }]}
          disabledOnUpdate={true}
        />
        <TextField
          fields={[{
            id: "description",
            label: "Descripción de la Falla"
          }]}
        />
      </Module>
      <Edit reload={reload} id={serviceOrderId} />
      <DeliveryDialog reload={reload} serviceOrderId={serviceOrderId} />
      <CloseDialog reload={reload} serviceOrderId={serviceOrderId} />
      <StatusDialog reload={reload} serviceOrderId={serviceOrderId} />
      <ProductDialog id={serviceOrderId} />
      <ConfirmDialog _id={reopenId} handleAction={handleReopen} handleCancel={handleCloseReopen} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  serviceOrder: state.records.serviceOrders || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(ServiceOrder);
