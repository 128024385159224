import React, { Fragment, useMemo, useState  } from "react";
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import localeES from 'date-fns/locale/es';
import randomize from 'randomatic';

// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutlineOutlined';

import Module from '../../_presentationals/Module';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';
import * as recordsServices from './../../_services/records.services';
import { openFormDialog, editValueAction, recordsActions } from '../../_actions';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import Filter from './filter.dialog';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
  const [ confirmId, setConfirmId ] = useState(null);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);

  const consignmentReportV2 = useMemo(() => {
    return props.consignmentReportV2.count > 0 ? props.consignmentReportV2.rows.map(el => { 
      return {
        ID: { 
          show:false,
          value: el.id,
          data: el,
          showIf: el.sale ? true : false,
        },
        Fecha: { 
          value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
          _sort: 'date',
          noBreak: true
        },
        Folio: {
          value: `${el.folio}-${el.serial}`,
          _sort: 'text',
          noBreak: true
        },
        Prestario: {
        	value: el.Borrower.name || '-',
          _sort: 'text'
        },
        Usuario: {
          value: `${el.User.firstName} ${el.User.lastName}` || '-',
          _sort: 'text'
        }
      }
    }) : [];

  }, [props.consignmentReportV2]);

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleCancel = () => setConfirmId(0);

  // const handleDelete = e => {
  //   const { id } = JSON.parse(e.currentTarget.dataset.row);
  //   setConfirmId(id);
  // }

  const handleAction = async () => {
    try{
      const response = await recordsServices._deleteV2(`v2/lends/${confirmId}`);
      if(response.errorCode) throw new Error(response.errorMessage);
      setConfirmId(0);
      const tmpCode = randomize('*', 10);
      setRefreshCode(tmpCode);

      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: 'La consignación fue eliminada exitosamente', 
        severity: 'success' 
      }));
    }catch(e){
      setConfirmId(0);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleFilter = () => {
    props.openFormDialog('Filter');
  }

  return (
    <Fragment>
      <Module
        table="consignmentReportV2"
        name="Reporte de Consignaciones"
        records={consignmentReportV2}
        refreshCode={refreshCode}
        singularName="Reporte"
        title="Filtrar Consignaciones"
        filter={handleFilter}
        create={false}
        _delete={false}
        edit={false}
        download={true}
        actions={[
          { /*icon: <DeleteOutlineIcon fontSize="small" />, label: "Cancelar", handle: handleDelete*/ }
        ]}
      />
      <Filter />
      <ConfirmDialog 
        _id={confirmId} 
        handleCancel={handleCancel} 
        handleAction={handleAction} 
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: [] };

const mapStateToProps = state => ({
  consignmentReportV2: state.records.consignmentReportV2 || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  _delete: value => dispatch(recordsActions._delete(value)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);