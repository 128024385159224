const independentPayments = ({method, ...props}) => {
  switch (method) {
  	case 'PUT':
  	case 'DELETE':
      return `independentPayments/${props._id}`;
  	default:
      return 'independentPayments';
  }
}

export default independentPayments;