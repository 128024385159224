import React, { useEffect, Fragment, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { config } from './../../_helpers/config';
import { recordsActions, openFormDialog } from '../../_actions';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { format, endOfWeek, startOfWeek } from 'date-fns';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { FilterDialog }  from './filter';

const RentReport = ({ getRecords, ...props }) => {
  const [ START ] = useState(startOfWeek(new Date()));
  const [ FINISH ] = useState(endOfWeek(new Date()));

  useEffect(() => {
    getRecords({ table: 'rents', start: START, finish: FINISH });
    getRecords({ table: 'employees', sellers: true });
  }, [getRecords, START, FINISH]);

  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  const rents = useMemo(() =>
    props.rents.count > 0 ? props.rents.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      Fecha: {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        _sort: 'date'
      },
      "NV": {
        value: el.folio,
        filter: true,
        _sort: 'text'
      },
      Cliente: {
       value: el.customer,
       filter: true,
       _sort: 'text'
      },
      "Importe NV": {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt',
        accumulate: true,
        accumulateLabel: 'Total'
      },
      Usuario: {
        value: el.user,
        filter: true,
        _sort: 'text'
      },
      Vendedor: {
        value: el.employee,
        filter: true,
        _sort: 'text'
      },
      "Cant.": {
        value: el.quantity,
        alt: parseFloat(el.quantity),
        align: "center",
        _sort: 'number'
      },
      Producto: {
        value: el.product,
        filter: true,
        _sort: 'text'
      },
      Modalidad: {
        value: el.method,
        _sort: 'text'
      },
      Precio: {
        value: moneyFormat(el.price),
        alt: parseFloat(parseFloat(el.price).toFixed(2)),
        align: 'right',
        _sort: 'alt'
      },
      "Notas Emitidas": {
        value: el.isRent ? `${el.paymentsMade}/${el.payments}` : '-',
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.rents]);
  
  const salePDF = e => {
    const { saleId } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${saleId}`, '_blank');
  };

  const filter = () => {
    props.openFormDialog('RentFilter');
  };

  return (
    <Fragment>
      <Module
        table="rents"
        name="reporte de rentas"
        records={rents}
        singularName="reporte"
        title="Filtrar Rentas"
        filter={filter}
        create={false}
        edit={false}
        _delete={false}
        download={true}
        actions={[{
          icon: <ZoomInIcon fontSize="small" />,
          label: "Consultar Venta",
          handle: salePDF
        }]}
      ></Module>
      <FilterDialog employees={employees} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  rents: state.records.rents || defaultTableValue,
  employees: state.records.employees || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RentReport);