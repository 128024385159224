import { useState, useEffect } from "react";
import * as recordsServices from '../_services';

export const UsersSelectField = () => {
	const [ users, setUsers ] = useState([{ key: '', value: '' }]);

	useEffect(() => {
		recordsServices.getAll({ table: 'users' }).then(async el => {
			const data = await Promise.all(el.rows.map(({id, firstName, lastName}) => ({ key: id, value: `${firstName} ${lastName}` })));
			data.length > 0 && setUsers(data);
		});
	}, []);
	
	return users;
}