import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog } from '../../_actions';

import { DateField, SelectField } from '../../_presentationals/Form';

const statusOptions = [
  { value: 'En espera', key: 1 },
  { value: 'Autorizado', key: 4 },
  { value: 'No Autorizado', key: 5 },
  { value: 'Cerrado', key: 6 },
  { value: 'Facturado', key: 7 },
  { value: 'En espera de entrega', key: 8 },
  { value: 'Entregado', key: 9 },
]

const Component = props => {

  const handdleFilter = e => {
    const { start, finish, status } = props;
    props.getRecords({ table: 'serviceOrdersReport', start, finish, status });
    props.closeFormDialog('Filter');
  };

	return (
	  <FormDialog
      singular="Filter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
      <SelectField
        fields={[{
          id: 'status',
          label: "Estatus",
          options: statusOptions
        }]}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
  status: state.field.value.status || null
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);