import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';

import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { openFormDialog } from '../../_actions';

import SaleDialog from './sale.dialog';

const Component = ({ openFormDialog, ...props }) => {
  const [ serviceOrderId, setServiceOrderId ] = useState(null);
  const [ actions, setActions ] = useState([]);
  const [ refreshCode, setRefreshCode ] = useState(null);

  useEffect(() => {
    const tmpAction = [
      { icon: <DescriptionOutlined fontSize="small" />, label: "Facturar Orden de Servicio", handle: handleSale },
    ];
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const serviceOrdersClosed = useMemo(() =>
    props.serviceOrdersClosed.count > 0 ? props.serviceOrdersClosed.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      Folio:{
        value: `${el.folio}-${el.serial}`,
        filter: true,
       _sort: 'text'
      },
      Cliente:{
        value: `${el.Customer.firstName} ${el.Customer.lastName}`,
        filter: true,
       _sort: 'text'
      },
      Equipo:{
        value: el.Product.name,
       _sort: 'text'
      },
      Serie:{
        value: el.physicalSerie,
       _sort: 'text'
      },
      Subtotal: {
        value: moneyFormat(el.subtotal),
        alt: parseFloat(parseFloat(el.subtotal).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Descuento: {
        value: moneyFormat(el.discount),
        alt: parseFloat(parseFloat(el.discount).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Impuestos: {
        value: moneyFormat(el.taxes),
        alt: parseFloat(parseFloat(el.taxes).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Total: {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt'
      }
    })) : []
  ,[props.serviceOrdersClosed]);

  const handleSale = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setServiceOrderId(id);
    openFormDialog('Sale');
  }

  const reload = (code) => {
    setRefreshCode(code);
  };

  return (
    <Fragment>
      <Module
        table="serviceOrdersClosed"
        title="Facturar Orden de Servicio"
        name="Facturar Orden de Servicio"
        singularName="Facturar Orden de Servicio"
        refreshCode={refreshCode}
        records={serviceOrdersClosed}
        filter={false}
        edit={false}
        create={false}
        _delete={false}
        download={true}
        actions={actions}
      >
      </Module>
      <SaleDialog reload={reload} serviceOrderId={serviceOrderId} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  serviceOrdersClosed: state.records.serviceOrdersClosed || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Component);