import React, { useMemo } from "react";
import { connect } from 'react-redux';
import { recordsActions, openFormDialog } from '../../_actions';

import locale from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';

const Component = props => {
  const expireds = useMemo(() =>
    props.expireds.count > 0 ? props.expireds.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      "Fecha": {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale} ),
        _sort: 'date'
      },
      "Expiración": {
        value: format( parseISO(el.expiredDate), 'dd/MM/yyyy', {locale} ),
        _sort: 'date'
      },
      Folio: {
        value: <a href={el.uri} target="_blank" rel="noopener noreferrer">{el.folio}</a>,
        alt: el.folio,
        _sort: 'text',
        noBreak: true
      },
      Abonado: {
        value: moneyFormat(el.totalPayment),
        alt: parseFloat(parseFloat(el.totalPayment).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },  
      Cliente: {
       value: el.customer,
       filter: true,
       _sort: 'text'
      },
      "Importe": {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt'
      },
      Usuario: {
        value: el.user,
        filter: true,
        _sort: 'text'
      },
      Vendedor: {
        value: el.employee,
        filter: true,
        _sort: 'text'
      },
      
    })) : []
  , [props.expireds]);

  return (
    <Module
      table="expireds"
      name="Ventas Expiradas"
      records={expireds}
      singularName="reporte"
      title="Filtrar Ventas"
      filter={false}
      create={false}
      edit={false}
      _delete={false}
      download={true}
    ></Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  expireds: state.records.expireds || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);