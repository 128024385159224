import React, { useMemo } from "react";
import { connect } from 'react-redux';
import { recordsActions } from '../../_actions';

import { FormDialog } from './../../_presentationals/FormDialog';
import { moneyFormat } from '../../_helpers/moneyFormat';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import Icon from '@material-ui/core/Icon';
import { TextField } from './../../_presentationals/Form';
import { editValueAction, closeFormDialog } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const PaymentDialog = ({ ExpensePayments, ...props }) => {

  const tableRows = useMemo(() => {    
    const handleDeleteProduct = e => {
      const id = e.currentTarget.dataset.id;
      props.editValueAction({ id, _id: id });
      props.closeFormDialog('PaymentDialog');
      props.update('expensePayments');
    };

    return ExpensePayments.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell>{format(parseISO(el.createdAt), 'dd/MM/yyyy', {locale: localeES})}</TableCell>
        <TableCell align="right">{moneyFormat(el.payment)}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} data-id={el.id} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small" >delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExpensePayments]);

  return (
    <FormDialog 
      name="PaymentDialog"
      singular="PaymentDialog"
      title="Pago a Cuenta Por Pagar"
      table="expensePayments"
    >
      <TextField
        fields={[{
          id: "payment", 
          label: "Pago",
          autoFocus: true
        }]}
      />
      <br />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell align="right">Pago</TableCell>
            <TableCell align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </FormDialog>
  );
}

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  update: table => dispatch(recordsActions.update(table)),
});


export default connect(null, mapDispatchToProps)(PaymentDialog);