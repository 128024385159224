import React, { useEffect, useState } from "react";
import randomize from 'randomatic';

import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import ProductComponent from './Products';

const Component = props => {
  
  const [ Products, setProducts ] = useState([]);
  const [ refreshCode, setRefreshCode ] = useState(null);

  useEffect(() => {
    props.logisticId && recordsServices.getAllV2(`v2/logistics/${props.logisticId}`).then(data => {
      setProducts(data.LogisticProducts);
    })
  }, [props.logisticId,refreshCode]);

  const reload = (reloadModule) => {
    if(reloadModule) {
      props.closeFormDialog('Products');
      props.reload();
      return;
    }
    const tmpCode = randomize('*', 10);
    setRefreshCode(tmpCode);
  }

  return (
    <FormDialog 
      title="Productos"
      name="products"
      singular="Products"
      showActionButton={false}
    >
      <ProductComponent logisticId={props.logisticId} Products={Products} reload={reload} />
    </FormDialog>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || []
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);