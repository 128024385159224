import React, { useState, useEffect } from 'react';

import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';

import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import ToysIcon from '@material-ui/icons/ToysOutlined';

import Alert from '@material-ui/lab/Alert';

import * as recordsServices from './../../../../../_services/records.services';

import FormDialog from './../../../helpers/FormDialog';
import rentPriceToLabel from './../../../helpers/general/rentPriceToLabel';
import SelectField from './../../../helpers/SelectField';
import TextField from './../../../helpers/TextField';
import Autocomplete from './../../../helpers/Autocomplete';

const defaultValues = {
  methodProduct: 1,
  rentPlan: "",
  planQuantity: 1,
  pricePlan: 0,
  days: 0,
  manufactureId: null
}

const methodProductOptions = [
  { value: 1, label: 'Venta' },
  { value: 2, label: 'Renta' },
];

const ItemConfig = props => {

  const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  const [ rentOptions, setRentOptions ] = useState([]);
  const [ manufactures, setManufactures ] = useState([]);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  useEffect(() => {
    if(props.params && props.params.prices && props.params.prices.rentPrice) {
      const tmp = props.params.prices.rentPrice.map(el => ({
        value: el.method, label: rentPriceToLabel(el.method)
      }));
      setRentOptions(tmp);
    }
    if(props.params && props.params.config){
      setValues({
        methodProduct: props.params.config.methodProduct,
        rentPlan: props.params.config.rentPlan,
        planQuantity: props.params.config.planQuantity,
        pricePlan: props.params.config.pricePlan,
        days: props.params.config.days
      });
    }else {
      setValues(defaultValues);
    }
  }, [props.params]);

  useEffect(() => {
    if(
      props.params 
      && props.params.productId 
      && props.deliveryDate 
      && parseInt(props.mobility) === 1
      && (
        ( parseInt(values.methodProduct) === 2
          && values.rentPlan !== "" 
          && parseInt(values.planQuantity) >= 1
        ) || parseInt(values.methodProduct) === 1
      )
    ){
      setValues(values => ({ ...values, manufactureId: null }));
      const status = parseInt(values.methodProduct) === 1 ? 2 : 3;
      const deliveryDate = format(new Date(props.deliveryDate),'yyyy/MM/dd',{locale: localeES});
      const uri = !props.isLend
        ? `v2/manufactures/availables?productId=${props.params.productId}&deliveryDate=${deliveryDate}&status=${status}&rentPlan=${values.rentPlan}&rentPlanQuantity=${values.planQuantity}`
        : `v2/manufactures/availables?productId=${props.params.productId}&deliveryDate=${deliveryDate}&status=3`;
      recordsServices.getAllV2(uri).then(data => {
      // recordsServices.getAllV2(`products/${props.params.productId}/manufactures/available/status/${status}?deliveryDate=${deliveryDate}&method=${values.rentPlan}&quantity=${values.planQuantity}`).then(data => {
        const tmp = data.map(el => ({
          value: el.id, label: `${el.Product.name} (Serie ${el.physicalSerie})`
        }));
        setManufactures(tmp);
        if(props.params.config && props.params.config.manufactureId){
          const isExist = tmp.find(elCh => elCh.value === props.params.config.manufactureId.value);
          if(isExist){
            setValues(values => ({ ...values, manufactureId: isExist }));
          }
        }
      });
    }
  }, [values.methodProduct, props.params, props.deliveryDate, props.mobility, values.planQuantity, values.rentPlan, props.isLend]);

  const handleAction = () => {
    try{
      
      if(values.rentPlan === '' && parseInt(values.methodProduct) === 2){
        const e = new Error('Selecciona un plan de renta para el equipo.');
        e.name = 'rentPlan';
        throw e;
      }

      if(parseInt(values.planQuantity) <= 0 && parseInt(values.methodProduct) === 2){
        const e = new Error('Proporciona una cantidad una cantidad mayor o igual a cero.');
        e.name = 'planQuantity';
        throw e;
      }

      if(parseFloat(values.pricePlan) <= 0 && parseInt(values.methodProduct) === 2){
        const e = new Error('Proporciona un precio mayor o igual a cero.');
        e.name = 'pricePlan';
        throw e;
      }

      if(!values.manufactureId && parseInt(props.mobility) === 1){
        const e = new Error('Selecciona la serie para el equipo.');
        e.name = 'manufactureId';
        throw e;
      }
      
      const position = props.params.position;
      const price = parseInt(values.methodProduct) === 1 && props.params.prices.tmpSalePrice

      props.handleAction({ position, ...values, price });
      setValues(defaultValues);
      setErrors({});
    } catch(e) {
      console.log(e.message);
      setErrors({ [e.name]: e.message });
    }
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if(name === 'rentPlan') {
      setManufactures([]);
      const tmp = props.params.prices.rentPrice.find(el => el.method === value);
      setValues(values => ({ ...values, pricePlan: tmp.price, days: tmp.days, manufactureId: null }));
    }

    if(name === 'methodProduct'){
      setManufactures([]);
      setValues(values => ({ ...values, manufactureId: null }));
    }

    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
    <FormDialog
      title='Configuración del Producto'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    > 
      {
        (props.params && !props.deliveryDate && parseInt(props.mobility) === 1) && 
          <div>
            <Alert elevation={0} variant="filled" severity="warning">
              Aún no seleccionas una fecha de entrega, esta acción es obligatoria para poder consultar las series del producto.
            </Alert>
            <br/>
          </div>
      }
      {
        !props.isLend &&
          <SelectField 
            icon={<CategoryIcon />}
            name='methodProduct'
            label='Modalidad' 
            onChange={handleChange}
            options={methodProductOptions}
            value={values.methodProduct}
            error={errors.methodProduct}
          />
      }
      {
        !props.isLend &&
          <SelectField 
            name='rentPlan'
            label='Plan de Renta' 
            onChange={handleChange}
            options={rentOptions}
            disabled={values.methodProduct === 1 ? true : false}
            error={errors.rentPlan}
            value={values.rentPlan}
          />
      }
      {
        !props.isLend &&
          <TextField
            name='planQuantity'
            label='Cantidad de Planes de Renta'
            onChange={handleChange}
            disabled={values.methodProduct === 1 ? true : false}
            error={errors.planQuantity}
            value={values.planQuantity}
          />
      }
      { (!props.isCourtesy && !props.isLend) &&
        <TextField
          name='pricePlan'
          label='Precio por Plan de Renta'
          onChange={handleChange}
          disabled={values.methodProduct === 1 ? true : false}
          error={errors.pricePlan}
          value={values.pricePlan}
        />
      }
      { props.mobility &&
        <Autocomplete
          icon={<ToysIcon />}
          name='manufactureId'
          label='Serie del Producto'
          onChange={handleAutocompleteChange}
          value={values.manufactureId}
          options={manufactures}
          error={errors.manufactureId}
          disabled={parseInt(props.mobility) !== 1 ? true : false}
        />
      }
    </FormDialog>
	)
}

export default ItemConfig;