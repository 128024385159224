const saleAuths = (method, values) => {
  switch (method) {
  	case 'PUT':
  		if(values.justification){
  			return { authCommentary: values.justification || '' };
  		}
  		return {}
    default:
      return {};
    }
}

export default saleAuths;