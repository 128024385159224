import React from "react";
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import { FormDialog } from '../../_presentationals/FormDialog';
import { styles } from './styles';
import { recordsActions, closeFormDialog } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

const Dialog = ({ products, table='manufactures', ...props}) => {

  const handdleFilter = e => {
    const { productId } = props;
    props.getRecords({ table, productId, filter: true });
    props.closeFormDialog('ManufacturesFilter');
  };

	return (
	  <FormDialog  
      singular="ManufacturesFilter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <Autocomplete
        label="Producto" 
        _id="productId"
        options={ products }
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({
  productId: state.field.value.productId || null
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const FilterDialog = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Dialog));