import React from "react";
import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField } from '../../_presentationals/Form';

const Component = props =>
  <FormDialog 
    table="pendingSales"
    singular="CreateDialog" 
    title="¡Un momento!"
    primaryButtonLabel="Si, deseo continuar"
  >
    Esta acción no podrá ser revertida, ¿Estás seguro que deseas continuar?
    <br/>
    <TextField
      dialog={false}
      fields={[{
        id: "commentary", 
        label: "Comentario",
        multiline: true
      }]}
      noFormat={true}
    />


  </FormDialog>

export default Component;