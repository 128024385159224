import React, { useEffect, useMemo, Fragment } from "react";
import { connect } from 'react-redux';

import { openFormDialog, editValueAction, redirectActions, recordsActions } from '../../_actions';

import Module from '../../_presentationals/Module';
import { history } from './../../_helpers';

import ChangeStatusProduct from './changeStatusProduct';
import LogProduct from './logProduct';

import ListAltIcon from '@material-ui/icons/ListAlt';
import UpdateIcon from '@material-ui/icons/Update';

const LogisticSale = ({openFormDialog, editValueAction, ...props}) => {
  useEffect(() => {
    props.redirect.id === "" && history.push('/Logistic/Failure/Deliveries');
  }, [props.redirect.id]);

  const logisticProduct = useMemo(() =>
    props.logisticProduct.count > 0 ? props.logisticProduct.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      "Producto":{
        value: el.name
      },
      "Serie Física": {
        value: el.physicalSerie ? el.physicalSerie : 'No asiganada'
      },
      Estatus: {
        value: el.status.label
      }
    })) : []
  , [props.logisticProduct]);

  const handdleLog = e => {
    const { id, logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, logs });
    openFormDialog('LogProduct');
  };

  const handdleChangeStatus = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id });
    openFormDialog('ChangeStatusProduct');
  };
  
  return (
    <Fragment>
      <Module
        edit={false}
        create={false}
        _delete={false}
        table="logisticProduct"
        name="Productos"
        singularName="Producto"
        filter={false}
        records={logisticProduct}
        actions={[
          {
            icon: <ListAltIcon fontSize="small" />,
            label: "Bitácora",
            handle: handdleLog
          },
          {
            icon: <UpdateIcon fontSize="small" />,
            label: "Cambiar Estatus",
            handle: handdleChangeStatus
          }
        ]}
      >
      </Module>
      <Fragment>
        <ChangeStatusProduct />
        <LogProduct />
      </Fragment>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  redirect: state.redirect,
  logisticProduct: state.records.logisticProduct || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticSale);