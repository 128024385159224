import { isMobile } from "react-device-detect";
export const styles = theme => ({
  addButton: {
    background: "#e6e6e6",
    "&:hover": {
      background: "#d3d3d3"
    }
  },
  form: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5)
  },
  header: {
    paddingTop: isMobile ? theme.spacing(2) : theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(1.5)
  },
  headerButtons: {
    textAlign: "right"
  },
  headerTitle: {
    fontSize: "15px",
    color: "#141414"
  },
  listItem: {
    paddingTop: 0, 
    paddingBottom: 0
  },
  menuOptions: {
    marginTop: theme.spacing(5)
  },
  card: {
    marginTop: theme.spacing(2),
    minWidth: 275,
  },
  noPadding: {
    padding: 0
  },
  productItem: {
    width: '96%',
    padding: 0
  },
  tableCell: {
    padding: 0, paddingLeft: 5
  },
  spacing: {
    marginRight: theme.spacing(0.5)
  },
  tooltip: {
    marginTop: theme.spacing(0.5)
  }
});