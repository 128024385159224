import React, { useState, useEffect, Fragment } from "react";

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import * as recordsServices from '../../_services';
import { chooseUrl } from './../../_helpers';
import Snackbar from './../../_containers/Sales.v2/helpers/Snackbar';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}

const DeleteDialogModule = ({_id, table, _delete, ...props}) => {

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);

  useEffect(() => {
    (_id > 0) && setIsOpenDialog(true);
    (_id === 0) && setIsOpenDialog(false);
  }, [_id]);

  const handleClose = () => {
    setIsOpenDialog(false);
    console.log(props);
    props.handleCloseDelete();
  }

  const handleDelete = async () => {
    try{
      setIsLoading(true);
      const response = await recordsServices._deleteV2(chooseUrl[table]({ method: 'DELETE', _id })).then(data => {
        setIsLoading(false);
        handleClose();
        
        return data;
      }); 
      if(response.errorCode){
        throw new Error(response.errorMessage);
      }
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: response.message, severity: 'success' }));
    }catch(e){
      setIsLoading(false);
      handleClose();
      setSnackbar(snackbar => ({ ...snackbar, time: null, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  return (
    <Fragment>
      <Dialog
        open={isOpenDialog}
        fullWidth={true}
        maxWidth="xs"
        aria-labelledby="Eliminar registro"
        aria-describedby="Dialogo de confirmación para eliminar un registro."
      >
        { isLoading && <LinearProgress color='secondary' /> }
        <DialogTitle disableTypography={true}>¿Eliminar este registro?</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  )
}


export const DeleteDialog = DeleteDialogModule;