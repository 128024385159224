import React, { useEffect, useState } from "react";

import Divider from '@material-ui/core/Divider';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';

import Form from './../Sales.v2/Cart/Form';
import Table from './../Sales.v2/Cart/Table';

const _session = JSON.parse(localStorage.getItem('session'));
const ivaTax = _session ? parseFloat(_session.data.ivaTax) : 16;
//Iva puede ser configurado por el cliente

const Component = props => {
  
  const [ cartItems, setCartItems ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ getFromServer, setGetFromServer ] = useState(true);
  
  useEffect(() => {
    (getFromServer && props.id) && 
      recordsServices.getAllV2(`v2/internalServiceOrders/${props.id}`).then(data => {
        setGetFromServer(false);
        const tmp = data.InternalServiceOrderDetails.map(el => {

          const iva = parseFloat(el.price) / (1 + (parseFloat(ivaTax) / 100)) * (parseFloat(ivaTax) / 100);
          const price = parseFloat(el.price) - iva;
          const amount = parseFloat(el.quantity) * price;
          const discount = parseFloat(el.discount) / amount * 100;

          return {
            id: el.id,
            product: { name: el.Product.name },
            qty: parseFloat(el.quantity),
            tmpDiscount: parseFloat(el.discount),
            discount: parseFloat(discount),
            price: parseFloat(price),
            amount: parseFloat(amount),
          }
        });
        setCartItems(tmp);
      })
  }, [props.id, getFromServer]);

  const handleAdd = el => {
    setIsLoading(true);
    const body = {
      productId: el.product.id,
      quantity: el.qty,
      price: el.price,
      discount: el.discount,
    }
    recordsServices.updateV2(`v2/internalServiceOrders/${props.id}/products`, body).then(data => {
      setIsLoading(false);
      setGetFromServer(true);
    }).catch(e => {
      console.log(e.message);
      setIsLoading(false);
    });

  }

  const removeItemFromCart = index => {
    setIsLoading(true);
    const tmp = cartItems[index];
    recordsServices._deleteV2(`v2/internalServiceOrders/${props.id}/products/${tmp.id}`).then(data => {
      setIsLoading(false);
      setGetFromServer(true);
    }).catch(e => {
      console.log(e.message);
      setIsLoading(false);
    });
    
  }

  return (
    <FormDialog
      progress={isLoading}
      title="Productos"
      name="products"
      singular="Products"
      disableClick
      disableEscape
      widthDialog="md"
      showActionButton={false}
    >
      <Form handleAdd={handleAdd} loading={isLoading} costSettings />
      <Divider />
      <Table 
        noConfig
        costSettings
        iva={ivaTax} 
        currency={'MXN'} 
        cartItems={cartItems} 
        removeItem={removeItemFromCart}
      />
    </FormDialog>
  );

}

export default Component;