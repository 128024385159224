import React, { useEffect, useState, useMemo } from "react";
import { connect } from 'react-redux';

import Assignment from '@material-ui/icons/Assignment';
import Build from '@material-ui/icons/Build';

import Module from '../../_presentationals/Module';
import { TextField, AutocompleteField } from './../../_presentationals/Form';
import { history } from './../../_helpers';
import { redirectActions, recordsActions } from '../../_actions';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Vehicle = ({ getRecords, ...props }) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    let tmpAction = [];
    if(privileges.vehicleFailures && privileges.vehicleFailures.read){ 
      tmpAction = [ ...tmpAction, { icon: <Build fontSize="small" />, label: "Fallas del Vehículo", handle: redirectFailures } ];
    }
    if(privileges.vehicleExpenses && privileges.vehicleExpenses.read){ 
      tmpAction = [ ...tmpAction, { icon: <Assignment fontSize="small" />, label: "Gastos del Vehículo", handle: redirectExpenses } ];
    }
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vehicles = useMemo(() => 
    props.vehicles.count > 0 ? props.vehicles.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Chofer: {
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : "Indefinido",
        _sort: 'text'
      },
      Nombre: {
        value: el.name,
        _sort: 'text'
      },
      Marca: {
        value: el.brand,
        _sort: 'text'
      },
      Modelo: {
        value: el.model,
        _sort: 'text'
      },
      Año: {
        value: el.year,
        _sort: 'text'
      },
      serie: {
        value: el.serie,
        _sort: 'text'
      }
    })) : []
  , [props.vehicles]);

  const redirectExpenses = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, data: data, table: 'vehicleExpenses', returnTo: '/Vehicle', returnToLabel: 'Vehículos'});
    history.push('/Vehicle/Expense');
  };

  const redirectFailures = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, data: data, table: 'vehicleFailures', returnTo: '/Vehicle', returnToLabel: 'Vehículos'});
    history.push('/Vehicle/Failure');
  };

  useEffect(() => { getRecords({ table: 'employees', employeeRole: 6 }); }, [ getRecords ]);
  const availableEmployees = useMemo(() => {
    return props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.employees]);

  return (
    <Module
      table="vehicles"
      name="vehículos"
      singularName="vehículo"
      filter={false}
      title="Crear Vehículo"
      actions={actions}
      records={vehicles}
    >
      <AutocompleteField
        icon="account_circle"
        label="Chofer" 
        _id="employeeId"
        options={ availableEmployees }
      />
      <TextField
        fields={[{
          id: "name",
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        fields={[{
          id: "brand",
          label: "Marca",
          autoFocus: true
        }]}
      />
      <TextField
        fields={[{
          id: "model",
          label: "Modelo"
        }]}
      />
      <TextField
        fields={[{
          id: "year",
          label: "Año"
        }]}
      />
      <TextField
        fields={[{
          id: "color",
          label: "Color",
          required: false
        },{
          id: "serie",
          label: "Serie",
          required: false
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  vehicles: state.records.vehicles || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);