const warrantyNotes = (method, values) => {
	const tmp = {};
  switch (method) {
    case 'POST':
      if(values.isSale || values.isCourtesy) return { customerId: values.customerId, employeeId: values.employeeId, commentary: values.commentary };
      return tmp;
    default:
      return tmp;
  }
}

export default warrantyNotes;