import React, { useEffect, useState } from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { FormDialog } from '../../_presentationals/FormDialog';

import { moneyFormat } from './../../_helpers/moneyFormat';

import * as recordsServices from './../../_services/records.services';

const Products = props => {

  const [ products, setProducts ] = useState([]);

  useEffect(() => {
    if(props.serviceOrderId){
      recordsServices.getAllV2(`v2/serviceOrders/${props.serviceOrderId}`).then(data => {
        const tmp = data.ServiceOrderDetails.map(el => {
          return {
            id: el.id,
            name: el.Product ? el.Product.name : '-',
            qty: parseFloat(el.quantity),
            listPrice: parseFloat(el.listPrice),
            price: parseFloat(el.price),
            amount: parseFloat(el.total),
            isWarranty: el.isWarranty
          }
        });
        setProducts(tmp);
      });
    }
  }, [props.serviceOrderId]);
  
  return (
    <FormDialog 
      title="Productos Añadidos"
      name="product"
      singular="Product"
      showActionButton={false}
      widthDialog="md"
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell align="center">Cant.</TableCell>
            <TableCell align="right">Precio</TableCell>
            <TableCell align="right">Importe</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            products.map(el => 
              <TableRow key={el.id}>
                <TableCell>{el.name}</TableCell>
                <TableCell align="center">{el.qty}</TableCell>
                <TableCell align="right">${moneyFormat(el.price)}</TableCell>
                <TableCell align="right">${moneyFormat(el.amount)}</TableCell>
                <TableCell align="center">{ el.isWarranty ? 'Garantía' : '-' }</TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </FormDialog>
  );

}

export default Products;