import React from "react";
import { connect } from 'react-redux';


import { FormDialog } from '../../_presentationals/FormDialog';

import { SelectField, TextField } from './../../_presentationals/Form';

import { editValueAction } from './../../_actions';

const ChangeStatus = ({editValueAction, ...props}) => {
  
  const data = [{ key: 2, value: 'Entregado' }];
    
  return (
    <FormDialog 
      title="Actualizar Estatus"
      name="ChangeStatus"
      singular="ChangeStatus"
      table="logisticSale"
    >
      <SelectField
        fields={[{
          id: "status",
          label: "Estatus",
          options: data,
        }]}
      />
      <TextField
        fields={[{
          id: "commentary",
          label: "Comentario",
          required: false
        }]}
      />
    </FormDialog>
  );

}

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
});


export default connect(null, mapDispatchToProps)(ChangeStatus);