const countries = ({method, ...props}) => {
  switch (method) {
    case 'POST':
    case 'GET':
      return `locations/countries`
    case 'PUT':
    case 'DELETE':
      return `locations/countries/${props._id}`;
    default:
      return 'countries';
  }
}

export default countries;
