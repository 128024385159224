import React, { useEffect, useMemo, Fragment } from "react";
import { connect } from 'react-redux';

import { openFormDialog, editValueAction, redirectActions, recordsActions } from '../../_actions';
import Module from '../../_presentationals/Module';
import { history } from './../../_helpers';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import LogLogistic from './logLogistic';
import DeliverySale from './deliverySale';
import EmployeeDelivery from './employeeDelivery';
import { DeliveryTicket } from './deliveryTicket';
import ProductsDialog from './ProductsDialog';

import ListAltIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/EditOutlined';
import ProductIcon from '@material-ui/icons/ToysOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Receipt from '@material-ui/icons/Receipt';

const LogisticSale = ({openFormDialog, editValueAction, ...props}) => {

  useEffect(() => {
    props.redirect.id === "" && history.push('/Logistic');
  }, [props.redirect.id]);

  const logisticSale = useMemo(() =>
    props.logisticSale.count > 0 ? props.logisticSale.rows.map(el => ({
      ID: { 
        show: true,
        value: el.id,
        data: el
      },
      "Fecha de Entrega": {
        value: el.deliveryDate ? `${format( parseISO(el.deliveryDate), 'dd/MM/yyyy', {locale: localeES} )} ${el.deliveryTime}`: '-'
      },
      "Producto":{
        value: `${ el.SaleDetail.Product.name} (${el.SaleDetail.quantity}) (${el.SaleDetail.isRent ? "Renta" : "Venta"})`,
        filter: true,
        _sort: 'text'
      },
      Modalidad: {
        value: el.SaleDetail.rentPlan.label,
        filter: true,
        _sort: 'text'
      },
      Dirección: {
        value: el.addressLine1+(el.addressLine2 !== "" ? ', '+el.addressLine2 : "")+(el.city !== "" ? ', '+el.city : "")+(el.zipCode !== "" ? ', '+el.zipCode : "")+ (el.State !== null ? ', '+el.State.name : "")
      },
      Chofer: {
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : 'No especificado',
        filter: true,
        _sort: 'text'
      },
      Estatus: {
        value: el.status.label
      }
    })) : []
  , [props.logisticSale]);

  const handdleLog = e => {
    const { id, logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, logs });
    openFormDialog('LogLogistic');
  };

  const deliveryTicket = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    DeliveryTicket(id);
  };

  // const handdleCollection = e => {
  //   const { id, receptionName, collectionDate, collectionTime, collectionCommentary } = JSON.parse(e.currentTarget.dataset.row);
  //   editValueAction({ id, receptionName, collectionDate, collectionTime, collectionCommentary });
  //   openFormDialog('CollectionLogistic');
  // };

  const handdleDelivery = e => {
    const { id, addressLine1, addressLine2, city, zipCode, countryId, stateId, reference, deliveryDate, deliveryTime, deliveryCommentary } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, addressLine1, addressLine2, city, zipCode, countryId, stateId, reference, deliveryDate, deliveryTime, deliveryCommentary });
    openFormDialog('DeliverySale');
  };

  const handdleEmployee = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id });
    openFormDialog('EmployeeDelivery');
  };

  // const redirect = e => { 
  //   const { setRedirect } = props;
  //   const data = JSON.parse(e.currentTarget.dataset.row);
  //   setRedirect({id: data.id, data, table: 'logisticProduct', returnTo: '/Logistic/Deliveries', returnToLabel: 'Logísticas'});
  //   history.push('/Logistic/Deliveries/Products');
  // };

  const handleProducts = async (e) => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({
      id: data.id, dataProducts: data.Products, productId: data.SaleDetail.productId,
      isRent: data.SaleDetail.isRent, qtyProduct: data.Products.length,
      methodPlan: data.SaleDetail.methodPlan, planQuantity: data.SaleDetail.planQuantity,
      deliveryDate: data.deliveryDate || format(new Date(),'yyyy/MM/dd',{locale: localeES}),
    });
    openFormDialog('ProductsDialog');
  } 

  return (
    <Fragment>
      <Module
        edit={false}
        create={false}
        _delete={false}
        table="logisticSale"
        name="Logísticas"
        singularName="Logística"
        filter={false}
        records={logisticSale}
        actions={[
          {
            icon: <Receipt fontSize="small" />,
            label: "Ticket de Entrega",
            handle: deliveryTicket
          },
          {
            icon: <AccountCircle fontSize="small" />,
            label: "Chofer",
            handle: handdleEmployee
          },
          {
            icon: <EditIcon fontSize="small" />,
            label: "Editar",
            handle: handdleDelivery
          },
          {
            icon: <ProductIcon fontSize="small" />,
            label: "Productos",
            handle: handleProducts
          },
          {
            icon: <ListAltIcon fontSize="small" />,
            label: "Bitácora",
            handle: handdleLog
          }
        ]}
      >
      </Module>
        <EmployeeDelivery />
        <DeliverySale />
        <LogLogistic />
        <ProductsDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  redirect: state.redirect,
  logisticSale: state.records.logisticSale || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticSale);