const api = ['employeeId', 'customerId'];
// const productsWithPrice = [13,133,134,135];

const sales = (method, values) => {
   const tmp = {};
	switch (method) {
		case 'POST':
			Object.keys(values).forEach(key => {
      	  if(api.includes(key)) { tmp[key] = values[key];}
   	 	});
         tmp['catchment'] = values['method'];
   	 	tmp['isInvoice'] = values['isInvoice'] === 1 ? true : false;
         tmp['amountInvoice'] = values['amountInvoice'];
         tmp['currencyKey'] = values['currencyId'];
         tmp['exchangeRate'] = values['exchangeRate'];
         tmp['commentary'] = values['commentary'];
         tmp['rentPlanPaymentMethod'] = values['rentPlanPaymentMethod'] ? parseInt(values['rentPlanPaymentMethod']) : 1;
         tmp['daysToPay'] = values['daysToPay'] ? parseInt(values['daysToPay']) : 0;
   	 	tmp['Products'] = [];
   	 	values['Products'].forEach((ele,index) => {
   	 		const { PhysicalSeries, productId, quantity, isRented, addressLine1, addressLine2, city, zipCode, countryId, stateId, reference, deliveryDate, deliveryTime, deliveryComment, mobility } = ele;
   	 		const discount = ele.discount !== '' ? ele.discount : 0;
            tmp['Products'].push({productId, quantity, discount});
   	 		if (isRented) {
   	 			tmp['Products'][index]['rentPlan'] = [{ quantity: ele.planQuantity || 1, method: ele.method }];
   	 		}

            // if( productsWithPrice.includes(parseInt(productId)) ) {
            tmp['Products'][index]['priceProduct'] = ele.price;
            // }
            tmp['Products'][index]['mobility'] = mobility;
            tmp['Products'][index]['addressLine1'] = addressLine1 ? addressLine1 : "";
            tmp['Products'][index]['addressLine2'] = addressLine2 ? addressLine2 : "";
            tmp['Products'][index]['city'] = city ? city : "";
            tmp['Products'][index]['zipCode'] = zipCode ? zipCode : "";       
            tmp['Products'][index]['reference'] = reference ? reference : "";
            tmp['Products'][index]['deliveryDate'] = deliveryDate ? deliveryDate : "";
            tmp['Products'][index]['deliveryTime'] = deliveryTime ? deliveryTime : "";
            tmp['Products'][index]['deliveryComment'] = deliveryComment ? deliveryComment : "";
            tmp['Products'][index]['PhysicalSeries'] = PhysicalSeries || [];

            if (countryId) {
               tmp['Products']['countryId'] = countryId;
            }

            if (stateId) {
               tmp['Products']['stateId'] = stateId;
            }  


   	 	});
   	 	return tmp;
   	 default:
   	 	return tmp;
	}
}

export default sales;