import React, { useMemo, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { config } from './../../_helpers/config';

import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';

import PaymentIcon from '@material-ui/icons/Payment';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

import Module from '../../_presentationals/Module';
import { history } from './../../_helpers';
import { redirectActions } from '../../_actions';

import { moneyFormat } from '../../_helpers/moneyFormat';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const AccountsReceivable = props => {
  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [];

    if(privileges.accountsReceivables && privileges.accountsReceivables.ticket){ 
      tmpAction.push({ icon: <ZoomInIcon fontSize="small" />, label: "Generar Ticket", handle: details });
    }

    if(privileges.payments && privileges.payments.read){ 
      tmpAction.push({ icon: <PaymentIcon fontSize="small" />, label: "Abonos", handle: redirect });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const accountsReceivables = useMemo(() =>
    props.accountsReceivables.count > 0 ? props.accountsReceivables.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha:{
        value: format(new Date(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES}),
        _sort: 'date'
      },
      NV:{
        value: el.Sale.folio,
        filter: true,
        _sort: 'text'
      },
      Cliente:{
        value: el.Customer && el.Customer.firstName+' '+el.Customer.lastName,
        filter: true,
       _sort: 'text'
      },
      Vendedor: {
        value: el.Sale.Employee && `${el.Sale.Employee.firstName} ${el.Sale.Employee.lastName}`,
       _sort: 'text'
      },
      Importe: {
        align: "right",
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        _sort: 'alt'
      },
      Abonado: {
        align: "right",
        value: moneyFormat(el.totalPayment),
        alt: parseFloat(parseFloat(el.totalPayment).toFixed(2)),
        _sort: 'alt'
      },
      Saldo:{
        align: "right",
        value: moneyFormat(el.balance),
        alt: parseFloat(parseFloat(el.balance).toFixed(2)),
        _sort: 'alt',
        accumulate: true,
        accumulateLabel: 'Deuda Total'
      },
      Facturas:{
        value: el.invoices,
        arrayValue: el.invoices.map(el => el.folio),
        format: 'invoice',
        _sort: 'text'
      },
      background: {
        value: el.background,
        show: false
      }
    })) : []
  ,[props.accountsReceivables]);
  
  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, data: data, table: 'payments', returnTo: '/AccountsReceivable', returnToLabel: 'Cuentas por cobrar'});
    history.push('/AccountsReceivable/Payment');
  };

  const details = e => {
    const { saleId } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${saleId}`, '_blank');
  };

  return (
    <Module
      table="accountsReceivables"
      name="cuentas por cobrar"
      records={accountsReceivables}
      singularName="cuentas por cobrar"
      filter={false}
      title="Cuentas por Cobrar"
      actions={actions}
      create={false}
      edit={false}
      download={true}
      _delete={false}
    >
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  accountsReceivables: state.records.accountsReceivables || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  setRedirect: data => dispatch(redirectActions.setRedirect(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsReceivable);