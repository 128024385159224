import React, { useMemo, useEffect } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';

import { SelectField, TextField, DateField } from './../../_presentationals/Form';

import { editValueAction, recordsActions } from './../../_actions';

const DeliverySale = ({editValueAction, getRecords, clearRecords, ...props}) => {
  
  const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));
  
  useEffect(() => { getRecords({ table: 'countries' }); return () => clearRecords('countries'); }, [getRecords, clearRecords]);

  const countries = useMemo(() => {
    return props.countries.count > 0 ? props.countries.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.countries]);

  useEffect(() => { 
    getRecords({ table: 'states', countryId: props.countryId }); return () => clearRecords('states'); 
  }, [getRecords, props.countryId, clearRecords]);

  const states = useMemo(() => {
    return props.states.count > 0 ? props.states.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.states]);

  return (
    <FormDialog 
      title="Actualizar Entrega"
      name="Delivery"
      singular="DeliverySale"
      table="logistic"
    >
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1",
            label: "Calle y Número",
            multiline: true
          },
          {
            id: "addressLine2",
            label: "Colonia",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city",
            label: "Ciudad",
            required: false
          },
          {
            id: "zipCode",
            label: "Código Postal",
            required: false
          }
        ]}
      />
      <SelectField
        fields={[{
          id: 'countryId', 
          label: 'País',
          options: countries,
          required: false
        }]}
      />
      <SelectField
        fields={[{
          id: 'stateId',
          label: 'Estado',
          options: states,
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "reference",
          label: "Referencia",
          multiline: true,
          required: false
        }]}
      />
      <DateField
        id="deliveryDate"
        label="Fecha de Entrega"
        icon="today"
        disablePast={true}
      />
      <SelectField
        fields={[{
          id: 'deliveryTime',
          label: 'Hora de entrega',
          options: serviceTime,
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "deliveryCommentary",
          label: "Comentario",
          multiline: true,
          required: false
        }]}
      />
    </FormDialog>
  );

}


const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  countries: state.records.countries || defaultTableValue,
  states: state.records.states || defaultTableValue,
  countryId: state.field.value.countryId || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  editValueAction: data => dispatch(editValueAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(DeliverySale);