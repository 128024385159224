import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Routes from "./../../Routes";
import { withRouter } from 'react-router-dom';
import { styles } from './styles';

import Drawer from './drawer';
import AppBar from './appBar';
import { ProfilePicture } from './profile';

import { authActions, profileActions, openFormDialog } from './../../_actions';

import "./styles.css"; 

class Navigation extends PureComponent {
	
	state = {
		anchorEl: null,
		left: false,
	}

	componentDidMount = () => {
		const { getProfile } = this.props;
		getProfile();
	}

	handleAvatarDialog = () => {
		const { openFormDialog } = this.props;
		openFormDialog('Avatar');
	}

	handleClick = event => {
	  this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
	  this.setState({ anchorEl: null });
	};

	handleLogout = () => {
		this.props.logout();
	};

	toggleDrawer = (side, open) => () => {
	  this.setState({
      [side]: open,
    });
  };

	render(){
		const { classes } = this.props;
		const { anchorEl, left } = this.state;
		
		return (
			<div className={classes.root}>
	  	  <AppBar
			  	anchorEl={ anchorEl }
			  	handleClick={ this.handleClick }
			  	toggleDrawer={ this.toggleDrawer }
			  	handleClose={ this.handleClose }
			  	handleLogout={ this.handleLogout }
			  	handleAvatar={ this.handleAvatarDialog }
			  />
			  <Drawer
			  	toggleDrawer={ this.toggleDrawer }
			  	left={ left }
			  	handleLogout={ this.handleLogout }
			  	userInformation={ this.handleLogout }
			  	profile={ this.handleLogout }
			  />
        <div className={classes.content}>
          <div className={classes.toolbar} />
          <Routes />
        </div>
        <ProfilePicture 
		    	handleChangedAvatar={ this.handleChangedAvatar } 
		    	handleSubmit={ this.handleSubmit }
		    />
		  </div>
		);
	}
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	openFormDialog: (data) => dispatch(openFormDialog(data)),
	logout: () => dispatch(authActions.logout()),
	getProfile: () => dispatch(profileActions.get()),
});

export default withRouter(withStyles(styles)(connect(null, mapDispatchToProps)(Navigation)));
