import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField, AutocompleteField } from './../../_presentationals/Form';
import { recordsActions } from './../../_actions';

const NoteDialog = ({ getRecords, ...props }) => {

  useEffect(() => { getRecords({ table: 'sellers', sellers: true }); }, [ getRecords ]);
  const sellers = useMemo(() => {
    return props.sellers.rows ? props.sellers.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
  }, [props.sellers]);
  
  return (
    <FormDialog 
      title={`Emitir Nota de ${ props.isSale ? 'Venta' : 'Cortesía' }`}
      name="NoteDialog"
      singular="NoteDialog"
      table="warranties"
    >
      <AutocompleteField
        icon="account_circle"
        label="Vendedor" 
        _id="employeeId"
        options={ sellers }
      />
      <TextField
        fields={[{
          id: "commentary",
          label: "Comentario",
          required: false
        }]}
      />  
    </FormDialog>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  sellers: state.records.sellers || defaultTableValue,
  isSale: state.field.value.isSale || false,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteDialog);