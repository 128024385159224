import React from "react";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import LinearProgress from '@material-ui/core/LinearProgress';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const DialogTitle = withStyles(theme => ({
  linearProgress: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis'
  },
  typography: { margin: theme.spacing(2) },
  progress: { position: 'absolute', width: '100%' }
}))(props => {
  const { children, classes } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      { props.isLoading && <LinearProgress color="secondary" className={classes.progress} /> }
      <Typography className={ classes.typography } variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const useDialogContentStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: props => props.noPadding ? 0 : theme.spacing(3),
    paddingTop: props => props => props.noPadding ? 0 : theme.spacing(1)
  },
}));

const DialogContent = ({ noPadding, ...props }) => {
  const classes = useDialogContentStyles({ noPadding });
  return (
    <MuiDialogContent className={classes.root}>
      { props.children }
    </MuiDialogContent>
  ) 
};

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export { 
  DialogTitle,
  DialogContent,
  DialogActions
};