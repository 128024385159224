import React, { useState, useEffect, Fragment } from 'react';

import FormDialog from './../helpers/FormDialog';
import SelectField from './../helpers/SelectField';
import Snackbar from './../helpers/Snackbar';

import * as recordsServices from './../../../_services/records.services';

import Authorization from './../Authorization';
import InvoiceData from './../InvoiceData';

const paymentFormOptions = [ 
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"},
];

const useCfdiOptions = [
  { value: "G01", label: "Adquisición de mercancias" },
  { value: "G03", label: "Gastos en general" },
  { value: "I08", label: "Otra maquinaria y equipo" },
  { value: "I02", label: "Mobilario y equipo de oficina por inversiones" },
  { value: "P01", label: "Por definir" },
  { value: "S01", label: "Sin efectos fiscales (Usar con público en general)" },
];

const bankOptions = [
  { value: 1, label: "Scotiabank 4377 Culiacán" },
  { value: 2, label: "Scotiabank 2815 Culiacán" },
  { value: 3, label: "Banregio Culiacán" },
  { value: 4, label: "Bancomer 0499" },
  { value: 5, label: "Scotiabank 4285 Mazatlán" },
  { value: 6, label: "Banregio 0013 Mazatlán " },
  { value: 7, label: "Scotiabank 4393 Mexicali" },
  { value: 8, label: "Banregio Mexicali" },
  { value: 9, label: "Bancomer 0097 Hermosillo" },
  { value: 10, label: "Banregio Hermosillo" },
];

const defaultValues = {
  paymentForm: "03",
  useCfdi: "G03",
  bank: ""
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}

const ConfirmSale = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ isDisabled, setIsDisabled ] = useState(false);
  const [ isInvoiceDataOpen, setIsInvoiceDataOpen ] = useState([false, null]);
  const [ isAuthorizationOpen, setIsAuthorizationOpen ] = useState([false, null]);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleAuthotizationClose = () => {
    setIsAuthorizationOpen([false, null]);
  }

  const handleInvoiceDataClose = () => {
    setIsInvoiceDataOpen([false, null]);
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async () => {
    try{

      setIsDisabled(true);

      if(props.values.Cart.length === 0){
        throw new Error("Selecciona al menos un producto para la venta.");
      }

      if(!props.values.customerId){
        throw new Error('Selecciona un cliente para la venta.');
      }

      if(!props.values.employeeId){
        throw new Error('Selecciona un vendedor.');
      }

      if(parseInt(props.values.paymentMethod) > 1 && (isNaN(parseInt(props.values.daysToPay)) || parseInt(props.values.daysToPay) <= 0)){
        throw new Error('Proporciona un plazo para pagar.');
      }

      // if(values.bank === "" && parseInt(props.values.paymentMethod) === 1){
      //   throw new Error("Selecciona el banco en donde se realizó el pago.");
      // }

      // if(!props.values.deliveryDate && parseInt(props.values.mobility) === 1){
      //   throw new Error('Selecciona una fecha de entrega.');
      // }

      const Products = props.values.Cart.map(el => ({
        quantity: el.qty,
        price: parseFloat(el.price) + (parseFloat(el.price) * (parseFloat(props.iva) / 100)),
        rentPlan: el.rentPlan,
        rentPlanQuantity: el.planQuantity,
        productId: el.product.id,
        manufactureId: el.manufactureId,
        discount: el.discount
      }));

      const body = {
        Products,
        mobility: props.values.mobility,
        paymentType: props.values.paymentMethod,
        currencyKey: props.values.currency,
        exchangeRate: props.values.exchange,
        customerId: props.values.customerId,
        catchment: props.values.catchmentMethod,
        daysToPay: props.values.daysToPay,
        rentPlanPaymentMethod: props.values.rentPlanPaymentMethod,
        employeeId: props.values.employeeId,
        method: (parseInt(props.values.paymentMethod) === 3) ? 'PPD' : 'PUE',
        paymentForm: values.paymentForm,
        useCfdi: values.useCfdi,
        commentary: props.values.commentary,
        paymentMethod: values.paymentForm,
        deliveryDate: props.values.deliveryDate,
        deliveryCommentary: props.values.deliveryCommentary,
        address: props.values.address,
        bank: (values.paymentForm === '01' || values.bank === "") ? null : values.bank
      }

      const response = await recordsServices.createV2('v2/sales', body).then(data => {
        props.handleClose();
        setIsDisabled(false);
        return data;
      });

      if(response.errorCode) {
        throw new Error(response.errorMessage);
      }

      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: response.message, severity: 'success' }));

      props.clearForm();

      if(response.saleAuthId) {
        setIsAuthorizationOpen([true, response.saleAuthId]);
        return;
      }

      setIsInvoiceDataOpen([true, response.uuid]);

    }catch(e) {
      setIsDisabled(false);
      setSnackbar(snackbar => ({ ...snackbar, time: null, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

	return (
    <Fragment>
      <FormDialog
        title='Configuración del CFDi'
        actionLabel='Almacenar'
        isOpen={props.isOpen}
        disabled={isDisabled}
        handleClose={props.handleClose}
        handleAction={handleAction}
        disableEscapeKeyDown
      >
        <SelectField 
          name='paymentForm'
          label='Forma de Pago' 
          onChange={handleChange}
          options={paymentFormOptions}
          value={values.paymentForm}
        />
        <SelectField 
          name='useCfdi'
          label='Uso del CFDi' 
          onChange={handleChange}
          options={useCfdiOptions}
          value={values.useCfdi}
        />
        {
          (parseInt(props.values.paymentMethod) === 1 && values.paymentForm !== '01') &&
          <SelectField 
            name='bank'
            label='Banco' 
            onChange={handleChange}
            options={bankOptions}
            value={values.bank}
          />
        }
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
      <Authorization isOpen={isAuthorizationOpen[0]} id={isAuthorizationOpen[1]} handleClose={handleAuthotizationClose} />
      <InvoiceData isOpen={isInvoiceDataOpen[0]} uuid={isInvoiceDataOpen[1]} handleClose={handleInvoiceDataClose} />
    </Fragment>
	)
}

export default ConfirmSale;