const allManufactures = ({method, ...props}) => {
  switch (method) {
    case 'GET':
      const query = { 
        productId: props.productId,
        status: 'all'
      };

      Object.keys(query).forEach(key => (!query[key]) && delete query[key]);      
      let querys = '';
      Object.keys(query).forEach(key => {
        querys += `${key}=${query[key]}&`;
      });
      
      return `products/manufactures?${querys}`;
    default:
      return `products/manufactures`;
  }
}

export default allManufactures