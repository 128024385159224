const clasifications = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
    case 'PUT':
      return `clasifications/${props._id}`;
    default:
      return 'clasifications';
  }
}

export default clasifications;
