import jsPDF from 'jspdf';
import format from 'date-fns/format';

import { authHeader, config } from '../../_helpers';
import { handleFetchResponse } from './../../_services';

const getDataFromServer = id => {
	const method = 'GET';
	const url = `${config.apiUrl}/migrations/${id}`;

  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );

  return fetch(myRequest)
  	.then(handleFetchResponse({ url, method, headers: {} }))
  	.then(data => data);
}

const PhysicalInventoryReport = async id => {
	const data = await getDataFromServer(id);

	const doc = new jsPDF();

	const createdAt = format(new Date(data.createdAt), 'dd/MM/yyyy HH:mm');
	const updatedAt = data.updatedAt ? format(new Date(data.updatedAt), 'dd/MM/yyyy HH:mm') : '-';
	const defaultHeader = { 
		folio: data.folio, createdAt, updatedAt,
		user: data.user, confirmationUser: data.confirmationUser,
		branch: data.branch, migrationBranch: data.migrationBranch
	};
	
	header(doc,defaultHeader);

	let breakLine = 52;
	let tmpBreakLine;
	
	data.Products.sort((a, b) => {
	  var x = a.name.toLowerCase();
	  var y = b.name.toLowerCase();
	  if (x > y) {return -1;}
	  if (x < y) {return 1;}
	  return 0;
	});

	let productId = data.Products[0].productId;
	let tmpQty = 0;
	let tmpRecQty = 0;
	console.log(data.Products);
	data.Products.forEach( (el,i) => {
		productId = el.productId;
    tmpBreakLine = breakLine;
		tmpQty = parseInt(tmpQty) + parseInt(el.quantity);
		tmpRecQty = parseInt(tmpRecQty) + parseInt(el.reception || 0);
		
		doc.text(10, breakLine, el.name, 'left');
		doc.text(130, breakLine, `${el.quantity}`, 'center');
		doc.text(180, breakLine, `${el.reception || '-'}`, 'center');
		doc.line(10, tmpBreakLine + 2, 195, tmpBreakLine + 2);

		breakLine = tmpBreakLine + 6;
		if(breakLine >= 280) {
			breakLine = 52;
			doc.addPage();
			header(doc,defaultHeader);
		}
		
		if((data.Products[i + 1] && parseInt(productId) !== parseInt(data.Products[i + 1].productId)) || !data.Products[i + 1]) {
			doc.setFontStyle('bold');
			doc.setDrawColor(244,244,244);
		  doc.setFillColor(244,244,244);
		  doc.rect(10, breakLine - 3, 185, 4,'FD');

			doc.text(115, breakLine, 'Total', 'right');
			doc.text(130, breakLine, `${tmpQty}`, 'center');
			doc.text(180, breakLine, `${tmpRecQty}`, 'center');

			doc.setFontStyle('normal');
			doc.setDrawColor(0);
		  doc.setFillColor(0);

			doc.line(10, breakLine + 2, 195, breakLine + 2);
			breakLine = breakLine + 6;
			if(breakLine >= 280) {
				breakLine = 52;
				doc.addPage();
				header(doc,defaultHeader);
			}
			
			tmpQty = 0;
			tmpRecQty = 0;
		}
	});
	

	doc.output('dataurlnewwindow');
}

const header = (doc,data) => {
	//CABECERA
	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text("Reporte de Traspaso", 10, 15, 'left');
	doc.text(`Origen: ${data.branch}`, 10, 20, 'left');
	doc.text(`Destino: ${data.migrationBranch}`, 10, 25, 'left');
	doc.text(`Solicitud: ${data.createdAt}`, 195, 15, 'right');
	doc.text(`${data.user}`, 195, 20, 'right');
	doc.text(`Recepción: ${data.updatedAt}`, 195, 25, 'right');
	doc.text(`${data.confirmationUser}`, 195, 30, 'right');
	doc.text(data.folio, 10,30,'left');

	//TABLA
	doc.text("Producto", 10, 45, 'left');
	doc.text("Cantidad", 130, 45, 'center');
	doc.text("Recibido", 180, 45, 'center');
	doc.line(10, 48, 195, 48);

	doc.setFontSize(8);
	doc.setFontStyle("normal");
}

export default PhysicalInventoryReport;