import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { FormDialog } from './../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import Autocomplete from './../Sales.v2/helpers/Autocomplete';
import TextField from './../Sales.v2/helpers/TextField';
import SelectField from './../Sales.v2/helpers/SelectField';
import Snackbar from './../Sales.v2/helpers/Snackbar';
import IconButton from './../Sales.v2/helpers/IconButton';
import LinearForm from './../Sales.v2/helpers/LinearForm';

import InvoiceData from './../Sales.v2/InvoiceData';

const defaultValues = {
  bonus: 0,
  type: "",
  reference: "",
  Products: []
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success',
  time: 3000
}

const availableReference = [
  {value:"01", label:"Nota de crédito de los documentos relacionados"},
  {value:"02", label:"Nota de débito de los documentos relacionados"},
  {value:"03", label:"Devolución de mercancía sobre facturas o traslados previos"},
  {value:"04", label:"Sustitución de los CFDI previos"},
  {value:"05", label:"Traslados de mercancias facturados previamente"},
  {value:"06", label:"Factura generada por los traslados previos"},
  {value:"07", label:"CFDI por aplicación de anticipo"},
  {value:"08", label:"Factura generada por pagos en parcialidades"},
  {value:"09", label:"Factura generada por pagos diferidos"},
];

const typeOptions = [
  { value: 2, label: "Devolución" },
  { value: 3, label: "Bonificación" }
];

const defaultCart = {
  productId: null,
  qty: ''
}

const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ products, setProducts ] = useState([]);
  const [ cart, setCart ] = useState(defaultCart);
  const [ error, setError ] = useState([]);
  const [ qtyOptions, setQtyOptions ] = useState([]);
  const [ isInvoiceDataOpen, setIsInvoiceDataOpen ] = useState([false, null]);

  useEffect(() => {
    props.id && recordsServices.getAllV2(`v2/sales/${props.id}`, values).then(data => {
      const tmp = data.SaleDetailV2s.map(el => ({
        value: el.id, 
        label: el.Product 
          ? `${el.Product.name} (${el.quantity}) ${(parseInt(el.Product.type) === 1 && el.manufactureId) ? 'Serie ' + el.physicalSerie : ''}`
          : '-',
        qty: el.quantity
      }));
      setProducts(tmp)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]); 

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const createCreditNote = async () => {
    
    try{
      
      if(values.type === ''){
        const e = new Error('Selecciona el tipo de nota de crédito.');
        e.name = 'type';
        throw e;
      }
      
      if(values.reference === ''){
        const e = new Error('Selecciona el tipo de relación.');
        e.name = 'reference';
        throw e;
      }
      
      if(parseFloat(values.bonus) <= 0 && values.type === 3){
        const e = new Error('La bonificación debe ser mayor a cero.');
        e.name = 'bonus';
        throw e;
      }
      
      if(values.Products.length === 0 && values.type === 2){
        const e = new Error('Selecciona al menos un producto para su devolución.');
        e.name = 'productId';
        throw e;
      }
      setIsLoading(true);
      const response = await recordsServices.createV2(`v2/sales/${props.id}/creditNotes`, values).then(data => {
        setIsLoading(false);
        return data;
      }).catch(err => {
        setIsLoading(false);
        return err;
      });

      if(response.errorCode) throw new Error(response.errorMessage);

      props.handleAdd()
      setError({});
      setCart(defaultCart)
      setValues(defaultValues);
      setIsInvoiceDataOpen([true, response.uuid]);
      props.closeFormDialog('CreditNotesDialog');
    }catch(e){
      setIsLoading(false);
      setSnackbar(snackbar => ({ ...snackbar, time: null, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  const handleAutocompleteChange = (e, name, value) => {
    setCart(values => ({ ...values, [name]: value }));
    if(name === 'productId' && value){
      const tmp = [];
      for(let i = 1; i <= value.qty; i++){
        tmp[i - 1] = { value: i, label: i };
      }
      setQtyOptions(tmp);
    }else {
      setCart(defaultCart);
      setQtyOptions([]);
    }
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChangeCart = e => {
    const name = e.target.name;
    const value = e.target.value;
    setCart(values => ({ ...values, [name]: value }));
  }

  const handleAdd = data => {
    try{
      if(!cart.productId){
        const e = new Error('Selecciona un producto para devolver.');
        e.name = 'productId';
        throw e;
      }
      if(cart.qty === ''){
        const e = new Error('Selecciona la cantidad de productos a devolver.');
        e.name = 'qty';
        throw e;
      }

      const isRepeated = values.Products.find(el => parseInt(el.saleDetailId) === parseInt(cart.productId.value));
      if(isRepeated){
        const e = new Error('Este producto ya fue seleccionado, intenta con uno diferente.');
        e.name = 'productId';
        throw e;
      }

      setValues(values => ({ 
        ...values, 
        Products: [ ...values.Products, { 
          "saleDetailId": cart.productId.value,
          "name": cart.productId.label,
          "quantity": cart.qty 
        } ] 
      }));
      setError({});
      setCart(defaultCart);
    }catch(e){
      console.log(e.message)
      setError({ [e.name]: e.message });
    }
  }
  const handleDeleteItem = (e, saleDetailId) => {
    const tmp = values.Products.filter(el => el.saleDetailId !== parseInt(saleDetailId));
    setValues(values => ({ ...values, Products: tmp }));
  }

  const handleInvoiceDataClose = () => {
    setIsInvoiceDataOpen([false, null]);
  }

  return (
    <Fragment>
      <FormDialog 
        name="CreditNotesDialog"
        singular="CreditNotesDialog"
        title="Emitir nota de crédito"
        primaryButtonLabel='Emitir'
        isLoading={isLoading}
        handleCustomizedClick={createCreditNote}
      >
        <SelectField 
          name='type'
          label='Tipo de Nota de Crédito' 
          onChange={handleChange}
          options={typeOptions}
          value={values.type}
        />
        <SelectField 
          name='reference'
          label='Tipo de relación' 
          onChange={handleChange}
          options={availableReference}
          value={values.reference}
        />
        { values.type === 3 
          ? <TextField 
              name='bonus'
              label='Cantidad a Bonificar' 
              type='number'
              onChange={handleChange}
              value={values.bonus}
            />
          : values.type === 2 && 
            <Grid container spacing={1}>
              <Grid item md={1} />
              <Grid item md={10}>
                <br/>
                <LinearForm handleAdd={handleAdd} align='center'>
                  <Grid container spacing={1}>
                    <Grid item md={9}>
                      <Autocomplete 
                        name='productId'
                        label='Producto' 
                        onChange={handleAutocompleteChange}
                        options={products}
                        value={cart.productId}
                        error={error.productId}
                        variant='outlined'
                        size='small'
                        noFormat
                      /> 
                    </Grid>
                    <Grid item md={3}>
                      <SelectField 
                        name='qty'
                        label='Cant.' 
                        onChange={handleChangeCart}
                        options={qtyOptions}
                        value={cart.qty}
                        error={error.qty}
                        variant='outlined'
                        size='small'
                        noFormat
                      />
                    </Grid>
                  </Grid>
                </LinearForm>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Producto</TableCell>
                        <TableCell align="center">Cant.</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        values.Products.map(el => 
                          <TableRow key={el.saleDetailId}>
                            <TableCell>{el.name}</TableCell>
                            <TableCell align="center">{el.quantity}</TableCell>
                            <TableCell>
                              <IconButton 
                                label='Eliminar'
                                icon={<DeleteOutlineIcon />}
                                params={el.saleDetailId}
                                action={handleDeleteItem}
                                size='small'
                              />
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={1} />
            </Grid>
        }
        
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={snackbar.time} 
        handleClose={handleCloseSnackbar}
      />
      <InvoiceData isOpen={isInvoiceDataOpen[0]} uuid={isInvoiceDataOpen[1]} handleClose={handleInvoiceDataClose} />
    </Fragment>
  );

}

// `<Fragment>
//           <ListItemAdd handleClick={handleAddProduct}>
//             <Grid container spacing={1}>
//               <Grid item xs={8}>
//                   <AutocompleteField 
//                     label="Concepto" 
//                     _id="saleDetailId" 
//                     dialog={false}
//                     options={products}
//                     variant="outlined"
//                   />
//               </Grid>
//               <Grid item xs={4}>
//                 <SelectField
//                   fields={[{
//                     id: "quantity",
//                     label: "Cant.",
//                     options: quantityProducts,
//                   }]}
//                 />
//               </Grid>
//             </Grid>
//           </ListItemAdd>
//           <Table size="small">
//             <TableHead>
//               <TableRow>
//                 <TableCell>Producto</TableCell>
//                 <TableCell align="center">Cant.</TableCell>
//                 <TableCell align="right">Acciones</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {tableRows}
//             </TableBody>
//           </Table>
//         </Fragment>`

const mapStateToProps = state => ({
  fields: state.field.value || []
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);