import React, { useState, useEffect, useMemo, Fragment } from "react";
import { connect } from 'react-redux';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import Module from '../../_presentationals/Module';
import { TextField } from './../../_presentationals/Form';
import { history } from './../../_helpers';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { openFormDialog, editValueAction, recordsActions  } from '../../_actions';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog/index';

import StatusFailure from './statusFailure.dialog';

import UpdateIcon from '@material-ui/icons/Update';
import AttachMoney from '@material-ui/icons/AttachMoney';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const VehicleFailure = props => {
  const [ actions, setActions ] = useState([]);
  const [ confirmId, setConfirmId ] = useState(0);

  useEffect(() => {
    props.redirect.id === "" && history.push('/Vehicle');
  }, [props.redirect.id]);

  useEffect(() => {
    let tmpAction = [];
    if(privileges.vehicleFailures && privileges.vehicleFailures.changeStatus){ 
      tmpAction = [ ...tmpAction, { icon: <UpdateIcon fontSize="small" />, label: "Actualizar Estatus", handle: handleStatus } ];
    }
    if(privileges.vehicleExpenses && privileges.vehicleExpenses.create){ 
      tmpAction = [ ...tmpAction, { icon: <AttachMoney fontSize="small" />, label: "Crear Gasto", handle: handleConfirm } ];
    }
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatus = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    props.editValueAction({ id, _id: id });
    props.openFormDialog('statusFailure');
  }

  const handleConfirm = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setConfirmId(id);
  }

  const handleCancelExpense = () => {
    setConfirmId(0);
  };

  const handleExpense = () => {
    setConfirmId(0);
    props.editValueAction({ id: confirmId, _id: confirmId, toExpense: true });
    props.updateRecors('vehicleFailures');
  };

  const vehicleFailures = useMemo(() => 
    props.vehicleFailures.count > 0 ? props.vehicleFailures.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: {
        value: format(parseISO(el.createdAt), 'dd/MM/yyyy', {locale: localeES})
      },
      'Fecha de Reparación': {
        value: el.date ? format(parseISO(el.date), 'dd/MM/yyyy', {locale: localeES}) : ''
      },
      Descripción: {
        value: el.description
      },
      Estatus: {
        value: parseInt(el.status) === 1 ? 'En reparación' : 'Reparado'
      },
      Total:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right"
      },
    })) : []
  , [props.vehicleFailures]);

  return (
    <Fragment>
      <Module
        edit={true}
        table="vehicleFailures"
        name="fallas del vehículo"
        singularName="falla del vehículo"
        filter={false}
        title="Registrar falla del vehículo"
        records={vehicleFailures}
        actions={actions}
      >
        <TextField
          fields={[{
            id: "description",
            label: "Descripción"
          }]}
        />
        <TextField
          fields={[{
            id: "total",
            label: "Total",
            required: false
          }]}
        />
      </Module>
      <StatusFailure />
      <ConfirmDialog _id={confirmId} handleAction={handleExpense} handleCancel={handleCancelExpense} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  redirect: state.redirect,
  vehicleFailures: state.records.vehicleFailures || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  updateRecors: value => dispatch(recordsActions.update(value)),
});

export default connect(mapStateToProps,mapDispatchToProps)(VehicleFailure);