export const style = theme => ({
  addButton: {
    background: "#e6e6e6",
    "&:hover": {
      background: "#d3d3d3"
    },
    marginTop: theme.spacing(1)
  },
  productItem: {
    width: '96%',
    padding: 0
  },
  tooltip: {
    marginTop: theme.spacing(0.5)
  }
});