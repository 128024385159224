import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const consignmentReportV2 = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
  	  const lendId = props._id;
  		return `v2/lends/${lendId}`;
    default:
      const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy/MM/dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy/MM/dd', {locale: localeES});

      return `v2/lends/report?start=${start}&finish=${finish}&status=2&type=1`;
  }
}

export default consignmentReportV2;