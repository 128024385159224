import { format } from "date-fns";
import locale from 'date-fns/locale/es';

const pendingSales = ({method, ...props}) => {
  switch (method) {
  	case 'PUT':
  		return `pendingSales/${props._id}`;
    case 'GET':
    	if(props.filter) {
        const startFormat = props.start ? new Date(props.start) : new Date();
        const finishFormat = props.finish ? new Date(props.finish) : new Date();

        const start = format(startFormat, 'yyyy/MM/dd', {locale});
        const finish = format(finishFormat, 'yyyy/MM/dd', {locale});

        const query = { start, finish };
        Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null || query[key] === '' || query[key] === 0) && delete query[key]);      
        let querys = '';
        Object.keys(query).forEach(key => {
          querys += `${key}=${query[key]}&`;
        });
        return `pendingSales?${querys}`;
      }
      return `pendingSales/actives`;
    case 'DELETE':
  		return `pendingSales/${props._id}`;
    default:
      return 'pendingSales/actives';
  }
}

export default pendingSales;