import branches from './branches';
import states from './states';
import products from './products';
import pricesProducts from './pricesProducts';
import clasifications from './clasifications';
import countries from './countries';
import users from './users';
import groups from './groups';
import customers from './customers';
import manufactures from './manufacture';
import subClasifications from './subClasifications';
import employees from './employees';
import billings from './billings';
import spareProducts from './spareProducts';
import companies from './companies';
import sales from './sales';
import payments from './payments';
import accountsReceivables from './accountsReceivables';
import invoices from './invoices';
import quotations from './quotations';
import inventories from './inventories';
import cashClosings from './cashClosings';
import commission from './commission';
import logistic from './logistic';
import warranties from './warranties';
import suppliers from './suppliers';
import expenses from './expenses';
import vehicles from './vehicles';
import vehicleExpenses from './vehicleExpenses';
import logisticSale from './logisticSale';
import logisticProduct from './logisticProduct';
import logisticCollection from './logisticCollection';
import logisticFailure from './logisticFailure';
import logisticReport from './logisticReport';
import rents from './rents';
import details from './details';
import independentPayments from './independentPayments';
import soldManufactures from './soldManufactures';
import rentedManufactures from './rentedManufactures';
import failures from './failures';
import credentials from './credentials';
import courtesies from './courtesies';
import logisticCourtesy from './logisticCourtesy';
import consignments from './consignment';
import allManufactures from './allManufactures';
import productManufactures from './productManufactures';
import statusManufactures from './statusManufactures';
import consignmentManufactures from './consignmentManufactures';
import tools from './tools';
import logisticIndependent from './logisticIndependent';
import compounds from './compounds';
import courtesiesReport from './courtesiesReport';
import sellers from './sellers';
import consignedManufactures from './consignedManufactures';
import expensePayments from './expensePayments';
import warrantyNotes from './warrantyNotes';
import vehicleFailures from './vehicleFailures';
import expenseReport from './expenseReport';
import expenseProductsReport from './expenseProductsReport';
import debtsPays from './debtsPays';
import catchments from './catchments';
import catchmentsReport from './catchmentsReport';
import physicalInventories from './physicalInventories';
import migrations from './migrations';
import manufacturesSale from './manufacturesSale';
import manufacturesRent from './manufacturesRent';
import salesActive from './salesActive';
import logisticReplace from './logisticReplace';
import rentPlans from './rentPlans';
import rentActives from './rentActives';
import samples from './samples';
import sampleManufactures from './sampleManufactures';
import customizedInvoices from './customizedInvoices';
import saleAuths from './saleAuths';
import pendingSales from './pendingSales';
import countExpired from './countExpired';
import expireds from './expireds';
import logisticv2 from './logisticv2';
import logisticCollectionV2 from './logisticCollectionV2';
import saleAuthsV2 from './saleAuthsV2';
import replacesV2 from './replacesV2';
import replaceAuths from './replaceAuths';
import failuresv2 from './failuresv2';
import serviceOrders from './serviceOrders';
import internalServiceOrders from './internalServiceOrders';
import serviceOrdersClosed from './serviceOrdersClosed';
import receivablesv2 from './receivablesv2';
import salesv2 from './salesv2';
import cashClosingsV2 from './cashClosingV2';
import serviceOrdersReport from './serviceOrdersReport';
import pendingSalesV2 from './pendingSalesV2';
import commissionV2 from './commissionV2';
import paymentsV2 from './paymentsV2';
import invoicesReview from './invoicesReview';
import losgisticCompleted from './losgisticCompleted';
import courtesyAuths from './courtesyAuths';
import lends from './lends';
import manufactureV2 from './manufactureV2';
import manufactureRentV2 from './manufactureRentV2';
import manufactureSaleV2 from './manufactureSaleV2';
import serviceOrderAuth from './serviceOrderAuth';
import manufactureRepairV2 from './manufactureRepairV2';
import manufactureSamplesV2 from './manufactureSamplesV2';
import manufactureConsigmentV2 from './manufactureConsigmentV2';
import manufactureServiceV2 from './manufactureServiceV2';
import borrowers from './borrowers';
import quotationReportV2 from './quotationReportV2';
import courtesyReportV2 from './courtesyReportV2';
import consignmentReportV2 from './consignmentReportV2';
import sampleReportV2 from './sampleReportV2';
import manufactureSale from './manufactureSale';
import saleDetailsV2 from './saleDetailsV2';

export const chooseUrl = {
	clasifications,
	states,
	products,
	pricesProducts,
	countries,
	users,
	branches,
	groups,
	customers,
	manufactures,
	subClasifications,
	employees,
	billings,
	spareProducts,
	companies,
	sales,
	payments,
	accountsReceivables,
	invoices,
	inventories,
	cashClosings,
	logistic,
	commission,
	warranties,
	quotations,
	suppliers,
	expenses,
	vehicles,
	vehicleExpenses,
	logisticSale,
	logisticProduct,
	logisticCollection,
	logisticFailure,
	logisticReport,
	rents,
	details,
	independentPayments,
	soldManufactures,
	rentedManufactures,
	failures,
	credentials,
	courtesies,
	logisticCourtesy,
	consignments,
	allManufactures,
	productManufactures,
	statusManufactures,
	consignmentManufactures,
	tools,
	logisticIndependent,
	compounds,
	courtesiesReport,
	sellers,
	consignedManufactures,
	expensePayments,
	warrantyNotes,
	vehicleFailures,
	expenseReport,
	expenseProductsReport,
	debtsPays,
	catchments,
	catchmentsReport,
	physicalInventories,
	migrations,
	manufacturesSale,
	manufacturesRent,
	salesActive,
	logisticReplace,
	rentPlans,
	rentActives,
	samples,
	sampleManufactures,
	customizedInvoices,
	saleAuths,
	pendingSales,
	countExpired,
	expireds,
	logisticv2,
	logisticCollectionV2,
	saleAuthsV2,
	replacesV2,
	replaceAuths,
	failuresv2,
	serviceOrders,
	serviceOrdersClosed,
	receivablesv2,
	salesv2,
	cashClosingsV2,
	internalServiceOrders,
	serviceOrdersReport,
	pendingSalesV2,
	commissionV2,
	paymentsV2,
	invoicesReview,
	losgisticCompleted,
	courtesyAuths,
	lends,
	manufactureV2,
	manufactureRentV2,
	manufactureSaleV2,
	serviceOrderAuth,
	manufactureRepairV2,
	manufactureSamplesV2,
	manufactureConsigmentV2,
	manufactureServiceV2,
	borrowers,
	quotationReportV2,
	courtesyReportV2,
	consignmentReportV2,
	sampleReportV2,
	manufactureSale,
	saleDetailsV2
}
