const uri = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
      return `v2/serviceOrders/${props._id}`;
    case 'GET':
      return 'v2/serviceOrders/actives';
    default:
      return 'v2/serviceOrders';
  }
}

export default uri;