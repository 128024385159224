import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { TextField } from '../../_presentationals/Form';
import { closeFormDialog, recordsActions, editValueAction } from '../../_actions';

const AuthJustificationDialog = props => {
  
  const handleClick = () => {
    props.editValueAction({ id: props.saleAuthId });
    props.updateRecords('saleAuths');
    props.closeFormDialog('AuthJustificationDialog'); 
    props.handleAction();
  }
  return(
    <FormDialog 
      buttonLabel="Actualizar" 
      singular="AuthJustificationDialog" 
      title="Justificación"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
      <TextField
        fields={[{
          id:'justification',
          label: 'Escribe una justificación',
          required: true
        }]}
      />
    </FormDialog>
  );
}

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  updateRecords: value => dispatch(recordsActions.update(value)),
});

export default connect(null, mapDispatchToProps)(AuthJustificationDialog);
