const api = ['name','phone','addressLine1','addressLine2', 'city', 'zipCode','state'];

const body = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return tmp;
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      return tmp;
    default:
      return tmp;
  }
}

export default body;