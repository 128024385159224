const chooseURL = ({method, ...props}) => {
  switch (method) {
    case 'GET':
      return `products/${props.redirectId}/spareProducts`
    case 'POST':
    case 'PUT':
    case 'DELETE':
      const spareProductId = props.productId ? props.productId : props._id;
      return `products/${props.redirectId}/spareProducts/${spareProductId}`;
    default:
      return null;
  }
}

export default chooseURL;