import { format, endOfWeek, startOfWeek } from "date-fns";
import localeES from 'date-fns/locale/es';

const expenses = ({method, ...props}) => {
  switch (method) {
  	case 'GET':
  		const startFormat = props.start ? props.start : startOfWeek(new Date());
      const finishFormat = props.finish ? props.finish : endOfWeek(new Date());

      const start = format(startFormat, 'yyyy-MM-dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy-MM-dd', {locale: localeES});

      const query = { 
        report: true,
				start,
				finish,
				action: props.action,
				status: props.status,
				supplierId: props.supplierId,
      };
      if(props.type !== 0) query.type = props.type;
      
      let querys = '';
      Object.keys(query).forEach(key => {
        querys += `${key}=${query[key]}&`;
      });

      return `expenses?${querys}`;
  	default:
      return 'expenses';
  }
}

export default expenses;