const api = ['iva','saleId','customerId','paymentForm', 'useCdfi', 'commentary'];

const invoices = (method, values) => {

  const paymentMethod = parseInt(values.paymentMethodInvoice) === 2 ? "PPD" : "PUE";
  const tmp = {};
  switch (method) {
    case 'POST':
      tmp['paymentMethod'] = paymentMethod;
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return { ...tmp, Concepts: values.Concepts ? values.Concepts : [] };
    default:
      return tmp;
    }
}

export default invoices;