import React, { useMemo, Fragment } from "react";
import { connect } from 'react-redux';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format'; 

import Module from '../../_presentationals/Module';

import { openFormDialog } from '../../_actions';

import Filter  from './filter.dialog';


const Component = ({ ...props}) => {
  
  const payments  = useMemo(() =>
    props.losgisticCompleted.count > 0 ? props.losgisticCompleted.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      "Fecha": {
        value: format( parseISO(el.date), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        filter: true,
        _sort: 'date',
        noBreak: true
      },
      Tipo: {
        value: el.typeLabel
      },
      Factura: {
        value: el.SaleV2 ? `${el.SaleV2.folio}-${el.SaleV2.serial}`: '-',
        filter: true
      },
      Cliente: {
        value: el.Customer ? `${el.Customer.firstName}-${el.Customer.lastName}`: '-',
        filter: true
      },
      Cantidad: {
        value: el.LogisticProducts.filter(tmp => parseInt(tmp.status) === parseInt(el.status)).length,
        align: 'center'
      },
      Chofer: {
        value: el.employee ? el.employee : '-',
        filter: true
      },
      
    })) : []
  , [props.losgisticCompleted]);

  const filter = () => {
    props.openFormDialog('Filter');
  };

  return (
    <Fragment>
      <Module
        table="losgisticCompleted"
        name="Entregas y Recolecciones"
        records={payments}
        singularName="Filtrar"
        title="Entregas y Recolecciones"
        filter={filter}
        create={false}
        edit={false}
        download={true}
        _delete={false}
      />
      <Filter />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  losgisticCompleted: state.records.losgisticCompleted || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
