import React, { useState, useEffect } from 'react';

import FormDialog from './../helpers/FormDialog';
import SelectField from './../helpers/SelectField';
import TextField from './../helpers/TextField';

const defaultValues = {
  paymentMethod: 1,
  daysToPay: 0,
  rentPlanPaymentMethod: 1
}

const paymentMethodOptions = [
  { value: 1, label: "Contado" },
  { value: 2, label: "Contado por pagar" },
  { value: 3, label: "Crédito" },
];

const rentPlanPaymentMethodOptions = [
  { value: 1, label: 'Pagar mensualmente (Opción común)' },
  { value: 2, label: 'Pagar en una sola exhibición' },
];

const PaymentMethod = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  const [ isDisabled, setIsDisabled ] = useState(true);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));

    if(name === 'paymentMethod' && parseInt(value) === 1){
      setValues(values => ({ ...values, daysToPay: 0 }));
      setIsDisabled(true);
    }else{
      setIsDisabled(false);
    }
  }

  const handleAction = () => {
    try{
      if(parseInt(values.paymentMethod) > 1 && (isNaN(parseInt(values.daysToPay)) || parseInt(values.daysToPay) <= 0))
        throw new Error('Proporciona un plazo para pagar.');
      props.handleAction(values);
      setErrors({});
    }catch(e) {
      setErrors({ daysToPay: e.message });
    }
  }

	return (
    <FormDialog
      title='Moneda y Tipo de cambio'
      actionLabel='Seleccionar'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      <SelectField 
        name='paymentMethod'
        label='Método de Pago' 
        onChange={handleChange}
        options={paymentMethodOptions}
        value={values.paymentMethod}
      />
      <TextField 
        name='daysToPay'
        label='Plazo de Pago'
        type='number' 
        disabled={isDisabled}
        onChange={handleChange}
        value={values.daysToPay}
        error={errors.daysToPay}
      />
      <SelectField 
        name='rentPlanPaymentMethod'
        label='¿Cómo se pagará el plan de renta mensual?' 
        onChange={handleChange}
        options={rentPlanPaymentMethodOptions}
        value={values.rentPlanPaymentMethod}
      />
    </FormDialog>
	)
}

export default PaymentMethod;