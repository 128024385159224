import React, { Fragment, useEffect, useMemo, useState } from "react";

import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import PersonIcon from '@material-ui/icons/Person';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SettingsIcon from '@material-ui/icons/Settings';

import { openFormDialog, recordsActions, editValueAction, closeFormDialog, progressActions, alertActions } from './../../_actions';

import { TextField, AutocompleteField } from './../../_presentationals/Form';
import { styles } from './style';
import { Header } from './_Header';
import { ListItemEdit } from './_ListItemEdit';
import { ListItemAdd } from './_ListItemAdd';
import { CustomerDialog } from './_CustomerDialog';
import  SellerDialog  from './_SellerDialog';
import CurrencyDialog  from './_CurrencyDialog';
import ProductDialog  from './_ProductDialog';
import PaymentMethodDialog  from './_PaymentMethodDialog';
import SettingDialog  from './_SettingDialog';
import AuthJustificationDialog  from './_AuthJustificationDialog';
import { LastStepDialog }  from './_LastStepDialog';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog/index';
import { GeneralDialog } from '../../_presentationals/GeneralDialog/index';
import { Snackbar } from '../../_presentationals/Snackbar';
import { Ticket } from './ticket';
import { invoices } from './invoice';
import { PaymentTicket } from './../Payment/ticket';
import { config } from "../../_helpers";

const productsWithPrice = [13,133,134,135];

const _session = JSON.parse(localStorage.getItem('session'));
let ivaTax = _session ? parseFloat(_session.data.ivaTax) / 100 : .16;

const Sales = ({ classes, openFormDialog, getRecords, editValueAction, ...props }) => {
  const [ customIva, setCustomIva ] = useState(null);
  const [ isInvoiceDialogOpen, setIsInvoiceDialogOpen ] = useState(false);
  const [ generatedInvoices, setGeneratedInvoices ] = useState([]);
  const [ confirmId, setConfirmId ] = useState(0);
  const [ accountReceivableId, setAccountReceivableId ] = useState(0);
  const [ billingInformationId, setAccountbillingInformationId ] = useState(0);
  // const [ isDisabled, setIsDisabled ] = useState(true);
  const [ isRequired, setIsRequired ] = useState(false);
  const [ currentPaymentMethod, setCurrentPaymentMethod ] = useState('Contado');
  
  const [budget, setBudget] = useState({subtotal: 0, discount: 0, tax: 0, total: 0});
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [payment, setPayment] = useState(0);
  const [saleId, setSaleId] = useState(0);
  const [saleAuthId, setSaleAuthId] = useState(0);

  useEffect(() => {
    if(props.alert === "success" && parseInt(props.sellData.saleAuthId) > 0) {
      setSaleAuthId(props.sellData.saleAuthId);
      setTimeout(function(){
        openFormDialog('AuthJustificationDialog');
      }, 1000);
    }
  }, [props.sellData.saleAuthId, props.alert, openFormDialog])

  useEffect(() => {
    const daysToPay = isNaN(parseInt(props.fields.daysToPay)) ? 0 : props.fields.daysToPay;
    const tmp = parseInt(props.fields.paymentMethod) === 2
      ? `Crédito a ${daysToPay} días`
      : `Contado`;
    setCurrentPaymentMethod(tmp);
  }, [props.fields.paymentMethod, props.fields.daysToPay]);

  useEffect(() => {
    if(props.alert === "success" && props.invoices.length > 0){
      setGeneratedInvoices(props.invoices);
      setIsInvoiceDialogOpen(true);
      editValueAction({ invoices: [] });
    }
  }, [props.alert, props.invoices, editValueAction]);

  useEffect(() => {
    const handleError = props.errorMessage;
    let key = "";
    Object.keys(props.validation).forEach(el => { 
      if(props.validation[el].isError === true){
        key = el; 
      }
    });
    const message = errorMessage(key);
    if(key !== "" && message !== ""){
      handleError(message);
    }
  }, [props.validation, props.errorMessage]);

  useEffect(() => {
    getRecords({ table: 'customers' }); //1
    getRecords({ table: 'employees', sellers: true }); //2
    getRecords({ table: 'billings', SATKey: 'currency', alias: 'currencies' }); //3
    getRecords({ table: 'companies'}); //4
    getRecords({ table: 'products'}); //5
    editValueAction({ quantity: 1, currencyId: 'MXN', paymentType: 2, exchangeRate: 1 });
    editValueAction({ quantity: 1, currencyId: 'MXN', paymentType: 2, exchangeRate: 1 });
  }, [getRecords, editValueAction]);
    
  useEffect(() => {
    if(props.alert === "success" && parseInt(props.sellData.paymentId) > 0 ){
      PaymentTicket(props.sellData.paymentId);
    }
  }, [props.alert, props.sellData.paymentId]);

  useEffect(() => {
    if(props.alert === "success" && parseInt(props.sellData.saleId) > 0){
      setAccountReceivableId(props.sellData.accountReceivableId);
      setAccountbillingInformationId(props.billingInformationId);
      Ticket(props.sellData.saleId);
      setSaleId(props.sellData.saleId);
      setLocal([]);
      setQty(0);
      setPayment(props.sellData.totalSale);
      setBudget({subtotal: 0, discount: 0, tax: 0, total: 0});
      editValueAction({ saleId: 0 });
      setTimeout(function(){
        editValueAction({ quantity: 1, currencyId: 'MXN', exchangeRate: 1 });
        openFormDialog('LastStepDialog');
      }, 1000);
    }
  }, [props.alert, budget.total, props.sellData.saleId, props.billingInformationId, openFormDialog, editValueAction, props.sellData.accountReceivableId, props.sellData.totalSale]);
  // }, [props.alert, budget.total, props.sellData.saleId, props.billingInformationId, openFormDialog, editValueAction, props.sellData.accountReceivableId]);

  useEffect(() => {
    props.currencyId !== "MXN" && editValueAction({ exchangeRate: props.exchangeRate })
  }, [props.currencyId, props.exchangeRate, editValueAction]);

  const currentCurrency = useMemo(() => {
    if(props.fields.exchangeRate){}
    setBudget({subtotal: 0, discount: 0, tax: 0, total: 0});
    setLocal([]);
    return (currencyToLabel(props.currencyId));
  }, [props.currencyId, props.fields.exchangeRate]);

  const customers = useMemo(() => (
    props.customers.count > 0 ? props.customers.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName} ${el.CustomerPhones[0].phone}`, customerDiscount: el.discountRange, address: el.CustomerAddress, billingInformationId: (el.BillingInformation && el.BillingInformation.id) ? el.BillingInformation.id : 0, iva: el.iva })) : []
  ), [props.customers]);

  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  const currencies = useMemo(() => (
    props.currencies.count > 0 ? props.currencies.rows.map(el => ({ key: el.id, value: `${el.id} ${el.descripcion}`})) : []
  ), [props.currencies]);
  
  const products = useMemo(() => {
    return (props.products.count > 0 ? props.products.rows.map(el => {
      let label = '';
      switch(parseInt(el.type.id)) {
        case 1:
          label = `${el.name} (Renta: ${el.qtyForRent} | Venta: ${el.qtyForSale} )`;
          break;
        case 2:
          label = `${el.name} (Existencia: ${el.Inventories.length > 0 ? el.Inventories[0].quantity : 0})`;
          break;
        default:
          label = el.name;
          break;
      }

      return {
        value: el.id, 
        // label: el.type.id === 1 ? `${el.name} (Renta: ${el.qtyForRent} | Venta: ${el.qtyForSale} )` : `${el.name} (Existencia: ${el.Inventories[0].quantity})`,
        label,
        price: el.prices && parseFloat(el.prices.salePrice),
        rentPrice: el.prices && el.prices.rentPrice,
        availableDiscount: el.Clasification && el.Clasification.discount
      }
    }) : [])
  }, [props.products]);

  useEffect(() => {
    ivaTax = parseFloat(_session.data.ivaTax) / 100;
    if(props.customerId && customers.length > 0) {
      const tmpData = customers.find(tmp => parseInt(tmp.value) === parseInt(props.customerId));
      if(tmpData) {
        setCustomIva(parseFloat(tmpData.iva) !== parseFloat(_session.data.ivaTax) ? tmpData.iva : null);
        ivaTax = tmpData.iva ? (parseFloat(tmpData.iva) / 100) : ivaTax;
      }
    }
  },[props.customerId,customers]);

  const tableRows = useMemo(() => {
    if(qty){}

    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(local));
      setLocal([ ...tmp ]);
    };

    const handleConfigProduct = e => {
      const rentPrice = JSON.parse(e.currentTarget.dataset.rent_price);
      const isRented = e.currentTarget.dataset.is_rented;
      const salePrice = e.currentTarget.dataset.sale_price;
      const index = e.currentTarget.dataset.index;
      
      editValueAction({ salePrice, planQuantity: local[index]['planQuantity'] ||  1, rentPrices: [ rentPrice.length, rentPrice ], PhysicalSeries: local[index]['PhysicalSeries'], qtyProductSelected: local[index]['quantity'], productSelectedId: local[index]['productId'], methodProduct: isRented === "true" ? 2 : 1, index  });
      openFormDialog('ProductDialog');
    }

    let tmpSubtotal = 0, tmpDiscount = 0, tmpTax = 0;
    
    return local.map((el, index) => {
      // const price = el.price / props.fields.exchangeRate;
      const planQuantity = parseInt(local[index]['planQuantity']) > 1 ? `(${local[index]['planQuantity']})` : '';
      const tmpAmount = amount({quantity: el.quantity, price: el.price});
      const tmpDiscountPercentage = floatFormat(el.discount) / 100;
      const tmpProductTax = ((tmpAmount - (tmpAmount * tmpDiscountPercentage)) * ivaTax);
      tmpTax = parseFloat(tmpTax) + parseFloat(tmpProductTax);
      tmpSubtotal = parseFloat(tmpSubtotal) + parseFloat(tmpAmount);
      tmpDiscount = parseFloat(tmpDiscount) + (parseFloat(tmpAmount) * parseFloat(tmpDiscountPercentage));
      setBudget({ 
        subtotal: floatFormat(tmpSubtotal).toFixed(2), 
        discount: floatFormat(tmpDiscount).toFixed(2), 
        tax: floatFormat(tmpTax).toFixed(2),
        total: parseFloat(tmpSubtotal - tmpDiscount + tmpTax).toFixed(2)
      });
      
      return (<TableRow key={index}>
        <TableCell margin="none" className={ classes.tableCell }>
          <Tooltip title="Configurar" aria-label="Configurar" classes={{ tooltip: classes.tooltip }}>
            <IconButton style={{ margin: 0 }} onClick={handleConfigProduct} data-index={index} data-is_rented={el.isRented} data-sale_price={el.salePrice} data-rent_price={JSON.stringify(el.rentPrice)} aria-label="settings">
              <Icon className="material-icons-outlined" fontSize="small">settings</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar" aria-label="Eliminar" classes={{ tooltip: classes.tooltip }}>
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          {el.productIdLabel} {el.isRented ? `${el.methodLabel} ${planQuantity}` : "(Venta)"}
        </TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell align="right">{el.discount || 0}%</TableCell>
        <TableCell align="right">{(parseFloat(el.price) / (ivaTax + 1)).toFixed(2) + props.currencyId}</TableCell>
        <TableCell align="right">{amount({quantity: el.quantity, price: el.price, discount: el.discount}) + props.currencyId}</TableCell>
      </TableRow>);
    });

  }, [local, qty, classes, editValueAction, openFormDialog, props.currencyId]);
    
  const handleCancelInvoices = () => {
    setIsInvoiceDialogOpen(false);
  };

  const handleCloseConfigProduct = () => {
    local[props.index]['isRented'] = props.methodProduct === 1 ? false : true;
    let tmpPrices = 0;
    if(props.methodProduct === 1) 
      tmpPrices = props.salePrice;
    else
      tmpPrices = props.pricePlan;
      // props.rentPrices[1].forEach(el => { 
      //   if(el.method === props.rentOptions){
      //     tmpPrices = parseFloat(el.price || 0) * parseFloat(props.planQuantity || 1);
      //   }
      // });

    local[props.index]['mobility'] = props.fields.mobility;
    local[props.index]['price'] = tmpPrices / props.fields.exchangeRate;
    local[props.index]['method'] = props.rentOptions;
    local[props.index]['planQuantity'] = props.planQuantity;
    local[props.index]['methodLabel'] = methodToText(props.rentOptions);
    local[props.index]['addressLine1'] = props.fields.addressLine1 ? props.fields.addressLine1 : " ";
    local[props.index]['addressLine2'] = props.fields.addressLine2 ? props.fields.addressLine2 : " ";
    local[props.index]['city'] = props.fields.city ? props.fields.city : " ";
    local[props.index]['zipCode'] = props.fields.zipCode ? props.fields.zipCode : " ";
    local[props.index]['countryId'] = props.fields.countryId
    local[props.index]['stateId'] = props.fields.stateId;
    local[props.index]['reference'] = props.fields.reference ? props.fields.reference : " ";
    local[props.index]['deliveryDate'] = props.fields.deliveryDate ? props.fields.deliveryDate : " ";
    local[props.index]['deliveryTime'] = props.fields.deliveryTime ? props.fields.deliveryTime : " ";
    local[props.index]['deliveryComment'] = props.fields.deliveryComment ? props.fields.deliveryComment : " ";
    local[props.index]['PhysicalSeries'] = props.PhysicalSeries || [];
    setLocal(local);
    setQty(JSON.stringify(local));
    props.closeFormDialog("ProductDialog");
  }

  const OpenCustomerDialog = () => openFormDialog('CustomerDialog');
  const OpenSellerDialog = () => openFormDialog('SellerDialog');
  const OpenCurrencyDialog = () => openFormDialog('CurrencyDialog');
  const OpenPaymentMethodDialog = () => openFormDialog('PaymentMethodDialog');
  const OpenSettingsDialog = () => openFormDialog('SettingDialog');
  
  const handleConfirmSale = e => {
    const { id } = e.currentTarget.dataset;
    setConfirmId(id);
  }

  const handleCancelSale = e => {
    setConfirmId(0);
  };
  
  const handleAddProduct = () => {
    const { productId, productIdLabel, quantity = 1, discount = 0, price, rentPrice } = props.selectedProduct;
    if(!productId){ return; }
    // console.log(parseInt(quantity));
    local && local.push({ productId, productIdLabel, quantity: parseFloat(quantity), discount: parseFloat(discount).toFixed(2), price: (price / props.fields.exchangeRate ), salePrice: (price / props.fields.exchangeRate), rentPrice, isRented: false });
    setLocal(local);
    setQty(JSON.stringify(local));
    editValueAction({ productId: null, productIdLabel: '', quantity: 1, price: '0.00', rentPrice: [] });
    // setIsDisabled(true);
    setIsRequired(false);
  };

  const handleSell = () => {
    setConfirmId(0);
    editValueAction({ Products: local });
    props.createRecords('sales');
  };

  const handleLastStep = () => {
    // console.log("Identificador de CxC: " + accountReceivableId);
    editValueAction({ tmpSaleId:saleId, accountReceivableId });
    (props.fields.paymentType && props.fields.paymentType < 3) && props.createRecords('payments');
    if(props.fields.isInvoice && props.fields.isInvoice === 1){ 
      setTimeout(function(){
        props.pendingMessage("Espere un momento, estamos emitiendo las facturas electrónicas.");
      }, 1000);
      props.createRecords('invoices');
    }
    setAccountReceivableId(0);
    setAccountbillingInformationId(0);

    setTimeout(function(){
      editValueAction({ Products: [], quantity: 1, currencyId: 'MXN', paymentType: 2, exchangeRate: 1 });
    },1000);
    props.closeFormDialog('LastStepDialog');

  };

  const handleFinishAuth = () => {
    setTimeout(function(){
      editValueAction({ Products: [], quantity: 1, currencyId: 'MXN', paymentType: 2, exchangeRate: 1 });
      setSaleAuthId(0);
    },1000);
  }
  
  useEffect( () => {
    editValueAction({ quantity: 1 });
    if( productsWithPrice.includes(parseInt(props.fields.productId)) ) {
      // setIsDisabled(false);
      setIsRequired(true);
    }else {
      // setIsDisabled(true);
      setIsRequired(false);
    }
  },[props.fields.productId, editValueAction]);

  useEffect( () => {
    const tmp = props.fields.quantity;
    if(`${tmp}`.indexOf('.') > -1 && (parseInt(props.fields.productId) !== 13) ) {
        editValueAction({ quantity: `${tmp}`.replace('.','') });
    }
  },[props.fields.quantity, props.fields.productId, editValueAction]);

  const generateTicket = e => {
    const { id } = e.currentTarget.dataset;
    invoices(id);
  };
  return (
    <Fragment>
      <Header handleClick={handleConfirmSale} iva={customIva} />
      <List dense={true} className={ classes.noPadding }>
        <ListItemEdit
          icon={<PersonIcon />}
          title="Cliente"
          text={ props.customerIdLabel }
          handleClick={OpenCustomerDialog}
        />
        <CustomerDialog customers={customers} />
        <Divider />
        <ListItemEdit
          icon={<StoreMallDirectoryIcon />}
          title="Vendedor"
          text={ props.employeeIdLabel }
          handleClick={OpenSellerDialog}
        />
        <SellerDialog employees={employees} />
        <Divider />
        <ListItemEdit
          icon={<AttachMoneyIcon />}
          title="Moneda"
          text={currentCurrency}
          handleClick={OpenCurrencyDialog}
        />
        <CurrencyDialog currencies={currencies} />
        <Divider />
        <ListItemEdit
          icon={<AccountBalanceIcon />}
          title="Método de Pago"
          text={currentPaymentMethod}
          handleClick={OpenPaymentMethodDialog}
        />
        <PaymentMethodDialog />
        <Divider />
        <ListItemEdit
          icon={<SettingsIcon />}
          title="Configuración"
          text='Pagar plan de renta mensualmente o en una sola exhibición.'
          handleClick={OpenSettingsDialog}
        />
        <SettingDialog />
        <Divider />
        <ListItemAdd handleClick={handleAddProduct}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AutocompleteField 
                label="Producto" 
                _id="productId" 
                dialog={false}
                options={ products }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                dialog={false}
                fields={[{
                  id: "quantity", label: "Cant.",
                  variant: "outlined", margin: "dense", required: false, grid: 4
                },{
                  id: "discount", label: "Descuento (%)",
                  variant: "outlined", margin: "dense", required: false, grid: 4
                },{
                  id: "price", label: "Precio Unitario",
                  variant: "outlined", margin: "dense", disabled: false, required: isRequired, grid: 4
                }]}
              />
            </Grid>
          </Grid>
        </ListItemAdd>
      </List>
      <Divider />
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Acciones</TableCell>
            <TableCell>Producto</TableCell>
            <TableCell align="center">Cant.</TableCell>
            <TableCell align="right">Dscto.</TableCell>
            <TableCell align="right">Precio Unitario</TableCell>
            <TableCell align="right">Importe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { tableRows }
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell align="right">Subtotal:</TableCell>
            <TableCell align="right">{budget.subtotal + props.currencyId}</TableCell>
          </TableRow>
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell align="right">Dscto:</TableCell>
            <TableCell align="right">{budget.discount + props.currencyId}</TableCell>
          </TableRow>
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell align="right">IVA:</TableCell>
            <TableCell align="right">{budget.tax + props.currencyId}</TableCell>
          </TableRow>
          <TableRow selected={true}>
            <TableCell colSpan={4} />
            <TableCell style={{ fontWeight: 'bold' }} align="right">Total:</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">{budget.total + props.currencyId}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container spacing={2} style={{paddingLeft: 15, paddingRight: 15}}>
        <Grid item xs={12}>
          <TextField
            dialog = {false}
            fields={[{
              id: "commentary", 
              label: "Comentario",
              variant: "outlined", 
              margin: "dense", 
              required: false
            }]}
          />   
        </Grid>
      </Grid>
      <ProductDialog rentPlanPaymentMethod={props.fields.rentPlanPaymentMethod} dataSeries={props.PhysicalSeries} qtyProduct={props.qtyProductSelected} productId={props.productSelectedId} handleClick={handleCloseConfigProduct} Address={props.fields.address} />
      <ConfirmDialog _id={confirmId} handleAction={handleSell} handleCancel={handleCancelSale} />
      <LastStepDialog handleClick={handleLastStep} total={payment} billingInformationId={billingInformationId}/>
      <GeneralDialog isOpen={isInvoiceDialogOpen} title={`Facturas Electrónicas:`} handleCancel={handleCancelInvoices}>
        {generatedInvoices.map((el, i) => {
          return(
            <Fragment key={el.invoiceId}>
              <Link className={ classes.spacing } href={`${config.apiUrl}/files/invoices/${el.invoiceId}/pdf`} target="_blank">P-{el.folio}</Link>
              <Link data-id={el.invoiceId} component="button" onClick={generateTicket}>T-{el.folio}</Link>
            </Fragment>
          );
        })}
      </GeneralDialog>
      <AuthJustificationDialog saleAuthId={saleAuthId} handleAction={handleFinishAuth} />
      <Snackbar />
    </Fragment>
  );
}

const methodToText = value => {
  let label = "";
  switch (value){
    case "rentPerEvent":
      label = "Renta por evento";
      break;
    case "rentPer1Month":
      label = "Renta por 1 mes (Comercial)";
      break;
    case "rentPer2Months":
      label = "Renta por 2 meses (Comercial)";
      break;
    case "rentPer3Months":
      label = "Renta por 3 meses (Comercial)";
      break;
    case "rentPer4Months":
      label = "Renta por 4 meses (Comercial)";
      break;
    case "rentPer5Months":
      label = "Renta por 5 meses (Comercial)";
      break;
    case "rentPer6Months":
      label = "renta por 6 meses (Comercial)";
      break;
    case "rentPer7Months":
      label = "Renta por 7 meses (Comercial)";
      break;
    case "rentPer8Months":
      label = "Renta por 8 meses (Comercial)";
      break;
    case "rentPer9Months":
      label = "Renta por 9 meses (Comercial)";
      break;
    case "rentPer10Months":
      label = "Renta por 10 meses (Comercial)";
      break;
    case "rentPer11Months":
      label = "Renta por 11 meses (Comercial)";
      break;
    case "rentPer12Months":
      label = "Renta por 12 meses (Comercial)";
      break;
    case "rentPerEventIndustry":
      label = "Renta por evento";
      break;
    case "rentPer1MonthIndustry":
      label = "Renta por 1 mes (Industrial)";
      break;
    case "rentPer2MonthsIndustry":
      label = "Renta por 2 meses (Industrial)";
      break;
    case "rentPer3MonthsIndustry":
      label = "Renta por 3 meses (Industrial)";
      break;
    case "rentPer4MonthsIndustry":
      label = "Renta por 4 meses (Industrial)";
      break;
    case "rentPer5MonthsIndustry":
      label = "Renta por 5 meses (Industrial)";
      break;
    case "rentPer6MonthsIndustry":
      label = "renta por 6 meses (Industrial)";
      break;
    case "rentPer7MonthsIndustry":
      label = "Renta por 7 meses (Industrial)";
      break;
    case "rentPer8MonthsIndustry":
      label = "Renta por 8 meses (Industrial)";
      break;
    case "rentPer9MonthsIndustry":
      label = "Renta por 9 meses (Industrial)";
      break;
    case "rentPer10MonthsIndustry":
      label = "Renta por 10 meses (Industrial)";
      break;
    case "rentPer11MonthsIndustry":
      label = "Renta por 11 meses (Industrial)";
      break;
    case "rentPer12MonthsIndustry":
      label = "Renta por 12 meses (Industrial)";
      break;
    default:
      label = "Indefinido";
      break;
  }
  return label;
};

/*const methodToNumber = (value) => {
  let number = 0;
  switch (value){
    case "rentPerEvent":
      number = 1;
      break;
    case "rentPer1Month":
      number = 1;
      break;
    case "rentPer4Months":
      number = 4;
      break;
    case "rentPer6Months":
      number = 6;
      break;
    case "rentPer12Months":
      number = 12;
      break;
    case "rentPer8Months":
      number = 8;
      break;

      case "rentPerEventIndustry":
      number = 1;
      break;
    case "rentPer1MonthIndustry":
      number = 1;
      break;
    case "rentPer4MonthsIndustry":
      number = 4;
      break;
    case "rentPer6MonthsIndustry":
      number = 6;
      break;
    case "rentPer12MonthsIndustry":
      number = 12;
      break;
    case "rentPer8MonthsIndustry":
      number = 8;
      break;
    default:
      number = 0;
      break;
  }
  return number;
};*/

const currencyToLabel = value => {
  switch(value){
    case 'MXN': return 'MXN Peso Mexicano';
    default: return 'USD Dolar Americano';
  }
}

const errorMessage = key => {
  switch(key){
    case "Products":
      return "Selecciona al menos un producto para su venta.";
    case "userId":
      return "Selecciona un vendedor.";
    case "currencyKey":
      return "Selecciona una moneda.";
    case "customerId":
      return "Selecciona un cliente.";
    case "catchment":
      return "Selecciona un método de captación.";
    case "quantityInvoice":
      return "Error al generar CFDi (Desglose de facturas debe ser mayor a 0).";
    case "paymentForm":
      return "Error al generar CFDi (Forma de Pago no seleccionado).";
    case "useCdfi":
      return "Error al generar CFDi (Uso de CFDi no seleccionado).";
    case "method":
      return "Error al generar el abono (Método de Pago no seleccionado).";
    case "PhysicalSeries":
      return "Seleccione las series correspondientes.";
    case "onlyOneDiscount":
      return "Solo se puede aplicar un descuento o un cambio de precio a la vez.";
    case "Error":
      return "Error desconocido, favor de reportar."
    default:
      return "";
  }
}

const defaultTableValue = {count: 0, rows: []};
const floatFormat = number => !isNaN(parseFloat(number)) ? parseFloat(number) : 0
const amount = ({ quantity, price, discount }) => {
  quantity = floatFormat(quantity);
  discount = floatFormat(discount);
  price = floatFormat(price / (ivaTax + 1) );
  const amount = price * quantity;
  return parseFloat(amount - (amount * (discount / 100))).toFixed(2);
}
 
const mapStateToProps = state => ({ 

  rentPlanPaymentMethod: state.field.value.rentPlanPaymentMethod || 1,
  paymentMethod: state.field.value.paymentMethod || 1,
  daysToPay: state.field.value.daysToPay || 0,

  customerId: state.field.value.customerId || null,
  invoices: state.field.value.invoices || [],
  validation: state.field.validation,

  rentPrices: state.field.value.rentPrices || [0, []],
  salePrice: state.field.value.salePrice,
  method: state.field.value.method,
  methodProduct: state.field.value.methodProduct,
  PhysicalSeries: state.field.value.PhysicalSeries || [],
  index: state.field.value.index,
  rentOptions: state.field.value.rentOptions,
  pricePlan: state.field.value.pricePlan,
  planQuantity: state.field.value.planQuantity || 1,

  productSelectedId: state.field.value.productSelectedId || 0,
  qtyProductSelected: state.field.value.qtyProductSelected || 0,

  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity, 
    discount: state.field.value.discount, 
    price: state.field.value.price, 
    rentPrice: state.field.value.rentPrice
  },
  
  sellData: {
    totalSale: state.field.value.totalSale,
    saleId: state.field.value.saleId, 
    folio: state.field.value.folio, 
    accountReceivableId: state.field.value.accountReceivableId, 
    Products: state.field.value.Products, 
    customerIdLabel: state.field.value.customerIdLabel,
    employeeIdLabel: state.field.value.employeeIdLabel,
    paymentId: state.field.value.paymentId,
    saleAuthId: state.field.value.saleAuthId
  },

  products: state.records.products || defaultTableValue,
  customers: state.records.customers || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  currencies: state.records.currencies || defaultTableValue,
  
  alert: state.alert.type,

  exchangeRate: (state.records.companies && state.records.companies.exchangeRate) ? state.records.companies.exchangeRate : 1,

  currencyId: state.field.value.currencyId ? state.field.value.currencyId : 'MXN',
  currencyType: state.field.value.currencyType ? state.field.value.currencyType : 1,

  billingInformationId: state.field.value.billingInformationId ? state.field.value.billingInformationId : 0,
  customerIdLabel: state.field.value.customerIdLabel ? state.field.value.customerIdLabel : 'Público en General',
  employeeIdLabel: state.field.value.employeeIdLabel ? state.field.value.employeeIdLabel : 'No Asignado',

  fields: state.field.value,

  progress: state.progress.isLoadingDialog

});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  createRecords: value => dispatch(recordsActions.create(value)),
  openProgressBar: () => dispatch(progressActions.open()),
  closeProgressBar: () => dispatch(progressActions.close()),
  errorMessage: value => dispatch(alertActions.error(value)),
  pendingMessage: value => dispatch(alertActions.pending(value)),
  clear: () => dispatch(alertActions.clear()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Sales));