import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions,  closeFormDialog } from '../../_actions';
import * as recordsServices from './../../_services/records.services';

import { DateField } from '../../_presentationals/Form';
import { SelectField } from '../../_presentationals/Form';

import Autocomplete from './../Sales.v2/helpers/Autocomplete';

const Component = props => {

  const [ employees, setEmployees ] = useState([]);
  const [ value, setValue ] = useState(null);

  useEffect(() => {
    recordsServices.getAllV2('employees/type/6').then(data => {
      const tmp = data.rows.map(el => ({ label: `${el.firstName} ${el.lastName}`.trim(), value: el.id }))
      setEmployees(tmp);
    });
  }, []);

  const handdleFilter = e => {
    const { start, finish, status } = props;
    props.getRecords({ table: 'losgisticCompleted', start, finish, status, employeeId: value });
    props.closeFormDialog('Filter');
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValue(value);
  }

	return (
	  <FormDialog
      singular="Filter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
      <SelectField
        fields={[{
          id: 'status', 
          label: 'Estatus',
          options: [
            { key: 0, value: 'Todos' },
            { key: 2, value: 'Entregas' },
            { key: 3, value: 'Recolecciones' }
          ]
        }]}
      />
      <Autocomplete 
        icon={<AccountCircleIcon />}
        name='employeeId'
        label='Nombre del Chofer' 
        onChange={handleAutocompleteChange}
        options={employees}
        value={value}
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
  status: state.field.value.status || 0
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);