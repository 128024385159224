import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField } from '../../_presentationals/Form';
import { closeFormDialog } from '../../_actions';

const rentPlanPaymentMethodOptions = [
  { key: 1, value: 'Pagar mensualmente (Opción común)' },
  { key: 2, value: 'Pagar en una sola exhibición' },
];

const SettingDialog = props => {
  
  const handleClick = () => { props.closeFormDialog('SettingDialog'); } 
  return(
    <FormDialog 
      buttonLabel="Actualizar" 
      singular="SettingDialog" 
      title="Configuración General"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
      <SelectField
        fields={[{
          id: 'rentPlanPaymentMethod',
          label: '¿Cómo se pagará el plan de renta mensual?',
          options: rentPlanPaymentMethodOptions,
        }]}
      />
    </FormDialog>
  );
}

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(null, mapDispatchToProps)(SettingDialog);
