import React, { useEffect, useMemo, Fragment, useState } from "react";
import { connect } from 'react-redux';

import { openFormDialog, editValueAction, redirectActions, recordsActions } from '../../_actions';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import Module from '../../_presentationals/Module';
import { history } from './../../_helpers';

import LogLogistic from './logLogistic';
import CollectionLogistic from './collectionLogistic';
import EmployeeDelivery from './employeeDelivery';
import { CollectionTicket } from './collectionTicket';

import ListAltIcon from '@material-ui/icons/ListAlt';
import ProductIcon from '@material-ui/icons/ToysOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Receipt from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/EditOutlined';

const LogisticSale = ({openFormDialog, editValueAction, ...props}) => {

  const [actions, setActions] = useState([]);

  useEffect(() => {

    const tmpAction = [
      { icon: <Receipt fontSize="small" />, label: "Ticket de Entrega", handle: collectionTicket },
      { icon: <AccountCircle fontSize="small" />, label: "Seleccionar Chofer", handle: handdleEmployee },
      { icon: <EditIcon fontSize="small" />, label: "Editar", handle: handdleCollection },
      { icon: <ProductIcon fontSize="small" />, label: "Consultar Equipos", handle: redirect },
      { icon: <ListAltIcon fontSize="small" />, label: "Revisar Historial", handle: handdleLog }
    ];

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    props.redirect.id === "" && history.push('/Logistic/Collection');
  }, [props.redirect.id]);

  const logisticSale = useMemo(() =>
    props.logisticSale.count > 0 ? props.logisticSale.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: {
        value: el.collectionDate ? `${format( parseISO(el.collectionDate), 'dd/MM/yyyy', {locale: localeES} )} ${el.collectionTime}`: 'No especificada',
        _sort: 'date'
      },
      "Producto":{
        value: `${ el.SaleDetail.Product.name} (${el.SaleDetail.quantity}) (${el.SaleDetail.isRent ? "Renta" : "Venta"})`,
        filter: true,
        _sort: 'text'
      },
      Dirección: {
        value: el.addressLine1+(el.addressLine2 !== "" ? ', '+el.addressLine2 : "")+(el.city !== "" ? ', '+el.city : "")+(el.zipCode !== "" ? ', '+el.zipCode : "")+ (el.State !== null ? ', '+el.State.name : "")
      },
      Chofer: {
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : 'No especificado',
        filter: true,
        _sort: 'text'
      },
      Estatus: {
        value: el.status.label,
        _sort: 'text'
      }
    })) : []
  , [props.logisticSale]);

  const handdleLog = e => {
    const { id, logs } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, logs });
    openFormDialog('LogLogistic');
  };

  const handdleCollection = e => {
    const { id, receptionName, collectionDate, collectionTime, collectionCommentary } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id, receptionName, collectionDate, collectionTime, collectionCommentary });
    openFormDialog('CollectionLogistic');
  };

  const handdleEmployee = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ id });
    openFormDialog('EmployeeDelivery');
  };

  const collectionTicket = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    CollectionTicket(id);
  };

  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    setRedirect({id: data.id, data, table: 'logisticProduct', returnTo: '/Logistic/Collection/Deliveries', returnToLabel: 'Logística'});
    history.push('/Logistic/Collection/Deliveries/Products');
  };

  return (
    <Fragment>
      <Module
        edit={false}
        create={false}
        _delete={false}
        table="logisticSale"
        name="Logísticas"
        singularName="Logística"
        filter={false}
        records={logisticSale}
        actions={actions}
      >
      </Module>
        <EmployeeDelivery />
        <CollectionLogistic />
        <LogLogistic />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  redirect: state.redirect,
  logisticSale: state.records.logisticSale || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticSale);