import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { AutocompleteField, SelectField } from '../../_presentationals/Form';
 
import { closeFormDialog } from '../../_actions';

const Dialog = props => {
  const handleClick = () => props.closeFormDialog('CustomerDialog');  
  return (
	  <FormDialog 
      buttonLabel="Actualizar" 
      singular="CustomerDialog" 
      title="Configuración del Cliente"
      handleCustomizedClick={handleClick}
      clearDataAfterClose={false}
    >
	    <AutocompleteField 
	    	label="Nombre" 
	    	_id="customerId" 
	    	options={props.customers}
	    />
	    <SelectField
          fields={[{
            id: "method",
            label: "Método de Captación",
            options: [
              { key: 1, value: "Cliente" },
              { key: 2, value: "Internet" },
              { key: 3, value: "Distribuidor" },
              { key: 4, value: "Página web" },
              { key: 5, value: "Comisionista" },
              { key: 6, value: "Recomendación" },
              { key: 7, value: "Publicidad en carros" },
              { key: 8, value: "Seguimiento de cliente" },
              { key: 9, value: "Redes sociales empresa" },
              { key: 10, value: "Redes sociales personales" },
              { key: 11, value: "Publicidad fachada de la empresa" },
              { key: 12, value: "Publicidad fuera de la sucursal" },
              { key: 13, value: "No identificado" },
            ],
          }]}
        />
	  </FormDialog>
	)
};

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const CustomerDialog = connect(null, mapDispatchToProps)(Dialog);