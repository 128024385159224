import React, { useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, List, Grid}from '@material-ui/core';
import {styles} from './styles';
import Paper from '@material-ui/core/Paper';
import { recordsActions  } from './../../_actions';
import EditExchangeRate from './editExchangeRate';
import EditCreditCommission from './editCreditCommission';

const Preferences = ({ getRecords, classes, ...props}) => {

  useEffect(() => {
    getRecords({ table: 'companies'});
  }, [getRecords]);

  return (
     <Fragment>
      <main className={classes.main}>
        <Typography variant="h5" gutterBottom>
            Configuración de la Empresa
        </Typography>
        <p className={classes.textColor} >
          Aquí podrás editar la configuración la cual será aplicada en todo el sistema.
        </p>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <List>
                <Typography variant="h5">
                  Variables de Configuración
                </Typography>
                <p className={classes.textColor}>
                  Edita la configuración y da clic en "Actualizar"
                </p>
                {Object.keys(props.companies).length > 0 && 
                (
                  <Fragment>
                    <EditExchangeRate exchangeRate={props.companies.exchangeRate}/>
                    <EditCreditCommission creditCommission={props.companies.CreditCommissions} />
                  </Fragment>
                )}
              </List>
            </Paper>
          </Grid>
          
        </Grid>
      </main>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  companies: state.records.companies || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Preferences));