import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';

import { ListItem, ListItemText, Grid}from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { TextField } from '../../_presentationals/Form';
import { FormDialog } from './../../_presentationals/FormDialog';
import { openFormDialog, editValueAction } from './../../_actions';

const EditExchangeRate = ({openFormDialog, editValueAction, ...props}) => {

  useEffect(() => {
    editValueAction({id: 1, exchangeRate: props.exchangeRate});
  }, [editValueAction, props.exchangeRate]);

  const handleClick = () => {
    openFormDialog('EditExchangeRate');
  };
  
  return(
    <Fragment>
      <ListItem
        divider 
        button 
        onClick={handleClick}
      >
        <Grid item xs={2}>
          Tipo de Cambio
        </Grid>
        <ListItemText 
          inset
          primary={`USD (${props.exchangeRate ? props.exchangeRate : 0})`}
        />
        <EditIcon />
      </ListItem>
      <FormDialog
        title="Editar el tipo de cambio"
        singular='EditExchangeRate'
        table='companies'
      >
        <TextField
          fields={[{
            id:'exchangeRate',
            label: 'Tipo de Cambio',
            required: true
          }]}
        />
      </FormDialog>
    </Fragment>
  );  
}

const mapDispatchToProps = dispatch => ({  
  editValueAction: data => dispatch(editValueAction(data)),  
  openFormDialog: data => dispatch(openFormDialog(data))
});

export default connect(null, mapDispatchToProps)(EditExchangeRate);
