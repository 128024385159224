import jsPDF from 'jspdf';
import { authHeader, config } from '../../_helpers';
import { handleFetchResponse } from './../../_services';
import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { moneyFormat } from '../../_helpers/moneyFormat';

const getDataFromServer = cashClosingId => {
	const method = 'GET';
	const url = `${config.apiUrl}/cashClosings/${cashClosingId}`;
  const myRequest = new Request(
    url, { method, headers: { ...authHeader() } }
  );
  return fetch(myRequest)
  	.then(handleFetchResponse({ url, method, headers: {} }))
  	.then(data => data);
}

export const Details = async (cashClosingId, type) => {
	const data = await getDataFromServer(cashClosingId);
	const open = format(parseISO(data.open), 'dd/MM/yyyy HH:mm:ss', {locale: localeES});
	const close = format(parseISO(data.close), 'dd/MM/yyyy HH:mm:ss', {locale: localeES});
	const closeFormat = format(parseISO(data.close), 'dd/MM/yyyy', {locale: localeES});
	type = type === 'Corte de Usuario' ? 'por Usuario' : 'Terminal';
	var doc = new jsPDF();

	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text(`Corte de Movimientos ${type}`, 100, 15, 'center');
	doc.setFontStyle("normal");
	doc.text(`${close}`, 195, 15, 'right');
	doc.setFontStyle("bold");

	doc.text("Cantidad", 130, 23, 'center');
	doc.text("Total", 165, 23, 'right');
	doc.line(25, 25, 170, 25);

	doc.text("Ventas Totales", 30, 30, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantitySale}`, 130, 30, 'center');
	doc.text(moneyFormat(data.totalSale), 165, 30, 'right');

	doc.setFontStyle("bold");
	doc.text("-", 35, 35, 'left');
	doc.text("Ventas a Crédito", 40, 35, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantityCredit}`, 130, 35, 'center');
	doc.text(moneyFormat(data.totalCredit), 165, 35, 'right');

	doc.setFontStyle("bold");
	doc.text("-", 35, 40, 'left');
	doc.text("Ventas a Contado", 40, 40, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantityCounted}`, 130, 40, 'center');
	doc.text(moneyFormat(data.totalCounted), 165, 40, 'right');

	doc.setFontStyle("bold");
	doc.text("-", 35, 45, 'left');
	doc.text("Descuentos", 40, 45, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantityDiscount}`, 130, 45, 'center');
	doc.text(moneyFormat(data.totalDiscount), 165, 45, 'right');
	doc.line(25, 47, 170, 47);

	doc.setFontStyle("bold");
	doc.text("Facturas Emitidas", 30, 52, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantityInvoice}`, 130, 52, 'center');
	doc.text(moneyFormat(data.totalInvoice), 165, 52, 'right');

	doc.setFontStyle("bold");
	doc.text("Notas de Crédito", 30, 57, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantityNoteCredit}`, 130, 57, 'center');
	doc.text(moneyFormat(data.totalNoteCredit), 165, 57, 'right');
	doc.line(25, 59, 170, 59);

	doc.setFontStyle("bold");
	doc.text("Total de Abonos", 30, 64, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantityPayment}`, 130, 64, 'center');
	doc.text(moneyFormat(data.totalPayment), 165, 64, 'right');

	let breakLine = 69;
	
	Object.keys(data.paymentsMethod).forEach(key => { 
		const { description, total, count } = data.paymentsMethod[key];
		
		doc.setFontStyle("bold");
		doc.text("-", 35, breakLine, 'left');
		doc.text(`${description}`, 40, breakLine, 'left');
		doc.setFontStyle("normal");
		doc.text(`${count}`, 130, breakLine, 'center');
		doc.text(moneyFormat(total), 165, breakLine, 'right');
		breakLine += 5;
	});

	// breakLine += breakLine === 69 ? 0 : 5;

	doc.setFontStyle("bold");
	doc.text("Total de Abonos Independientes", 30, breakLine, 'left');
	doc.setFontStyle("normal");
	doc.text(`${data.quantityIndependentPayment}`, 130, breakLine, 'center');
	doc.text(moneyFormat(data.totalIndependentPayment), 165, breakLine, 'right');

	breakLine += 5;

	Object.keys(data.independentsPaymentMethod).forEach(key => { 
		const { description, total, count } = data.independentsPaymentMethod[key];
		
		doc.setFontStyle("bold");
		doc.text("-", 35, breakLine, 'left');
		doc.text(`${description}`, 40, breakLine, 'left');
		doc.setFontStyle("normal");
		doc.text(`${count}`, 130, breakLine, 'center');
		doc.text(moneyFormat(total), 165, breakLine, 'right');
		breakLine += 5;
	});

	// breakLine += breakLine === 74 ? 0 : 5;

	doc.setFontStyle("bold");
	doc.text("Fecha inicial", 30, breakLine + 5, 'left');

	doc.setFontStyle("normal");
	doc.text(`${open}`, 60, breakLine + 5, 'left');

	doc.setFontStyle("bold");
	doc.text("Fecha final", 30, breakLine + 10, 'left');

	doc.setFontStyle("normal");
	doc.text(`${close}`, 60, breakLine + 10, 'left');

	doc.setFontStyle("bold");
	doc.text("Sucursal", 30, breakLine + 15, 'left');

	doc.setFontStyle("normal");
	doc.text(`${data.branch}`, 60, breakLine + 15, 'left');

	if(data.seller){
		doc.setFontStyle("bold");
		doc.text("Vendedor", 30, breakLine + 20, 'left');

		doc.setFontStyle("normal");
		doc.text(`${data.seller}`, 60, breakLine + 20, 'left');

		breakLine += 5;
	}

	breakLine = breakLine + 25;
	doc.setFontStyle("bold");
	
	doc.text("NV", 25, breakLine, 'left');
	doc.text("Factura(s)", 70, breakLine, 'center');
	doc.text("Importe", 110, breakLine, 'right');
	doc.text("Fecha", 165, breakLine, 'right');
	doc.line(25, breakLine+2, 170, breakLine + 2);

	breakLine = breakLine + 6;
	let tmpBreakLine;
	data.saleNotes.forEach(({note, invoices, amount, date}) => { 
		tmpBreakLine = breakLine;
		doc.setFontStyle("normal");
		doc.text(`${note}`, 25, breakLine, 'left');
		
		invoices.forEach(({folio}) => {
			doc.text(folio, 70, tmpBreakLine, 'right');
			tmpBreakLine += 5;
		});

		if(invoices.length > 0){
			tmpBreakLine-=5;
		}

		doc.text(moneyFormat(amount), 110, breakLine, 'right');
		doc.text(format(parseISO(date), 'dd/MM/yyyy HH:mm', {locale: localeES}), 165, breakLine, 'right');
		doc.line(25, tmpBreakLine + 2, 170, tmpBreakLine + 2);
		breakLine = tmpBreakLine + 7;
	});

	doc.save(`corde_de_movimeintos_${closeFormat}.pdf`);
}
