import React, { Fragment, useMemo, useState, useEffect  } from "react";
import { connect } from 'react-redux';
import { isDate, format, parseISO } from 'date-fns';
import localeES from 'date-fns/locale/es';

import Link from '@material-ui/core/Link';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleIcon from '@material-ui/icons/PictureAsPdf';
import Receipt from '@material-ui/icons/Receipt';

import Module from '../../_presentationals/Module';
import {ConfirmDialog} from '../../_presentationals/ConfirmDialog/index';
import { recordsActions, openFormDialog, editValueAction, alertActions } from '../../_actions';
import { config } from './../../_helpers/config';
import { Snackbar } from '../../_presentationals/Snackbar';
import { moneyFormat } from '../../_helpers/moneyFormat';

import { SaleTicket } from './ticket';
import { BillingDialog }  from './billingDialog';
import { FilterDialog }  from './filter';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const SalesReport = ({getRecords, _delete, clearRecords, openFormDialog, editValueAction, update, ...props }) => {
  const [ subtitle, setSubtitle ] = useState('');
  const [ confirmId, setConfirmId ] = useState(0);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    getRecords({ table: 'employees', sellers: true });
  }, [getRecords, editValueAction]);
  
  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  useEffect(() => {

    const tmpAction = [{
      icon: <Receipt fontSize="small" />,
      label: "Generar Ticket",
      handle: handdleTicket
    },{
      icon: <ZoomInIcon fontSize="small" />,
      label: "Consultar Venta",
      handle: details
    }];

    if(privileges.salesReport && privileges.salesReport.bill){ 
      tmpAction.push({ icon: <CheckCircleIcon fontSize="small" />, label: "Emitir Factura", handle: billing });
    }

    if(privileges.salesReport && privileges.salesReport.delete){ 
      tmpAction.push({ icon: <CancelIcon fontSize="small" />, label: "Cancelar Venta", handle: handleConfirmPayment });
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleConfirmPayment = e => {
    const { id, isInvoiceLog } = JSON.parse(e.currentTarget.dataset.row);
    if(isInvoiceLog) return;
    setConfirmId(id);
  }

  const handleDelete = id => {
    editValueAction({ id });
    update('sales');
    setConfirmId(0);
  };

  const handleCancelPayment = e => {
    setConfirmId(0);
  };

  const handdleTicket = (e) => {
    const { id, isInvoiceLog, saleId } = JSON.parse(e.currentTarget.dataset.row);
    SaleTicket(isInvoiceLog ? saleId : id);
  }

  const details = e => {
    const { id, isInvoiceLog, saleId } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${isInvoiceLog ? saleId : id}`, '_blank');
  };

  const billing = e => {
    const { Customer, id, isInvoiceLog } = JSON.parse(e.currentTarget.dataset.row);
    if(isInvoiceLog) return;
    const { BillingInformation } = Customer || {};
    editValueAction({tmpSaleId: id});
    BillingInformation && openFormDialog('BillingDialog');
  }

  useEffect(() => {
    const handleError = props.errorMessage;
    let key = "";
    Object.keys(props.validation).forEach(el => { 
      if(props.validation[el].isError === true){
        key = el; 
      }
    });
    const message = errorMessage(key);
    if(key !== "" && message !== ""){
      handleError(message);
    }
  }, [props.validation, props.errorMessage]);

  const filter = () => {
    openFormDialog('SalesReportFilter');
  };

  useEffect(() => {
    if(isDate(props.start) && isDate(props.finish)) {
      setSubtitle(`${format(new Date(props.start),'dd/MM/yyyy')} al ${format(new Date(props.finish),'dd/MM/yyyy')}`);
    }
  },[props.start,props.finish])

  const sales = useMemo(() =>
    props.sales.count > 0 ? props.sales.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Estatus:{
        value: el.status.label,
        _sort: 'text'
      },
      Fecha: { 
        value: format(parseISO(el.createdAt), "dd/MM/yyyy HH:mm", {locale: localeES}),
        _sort: 'date'
      },
      NV: {
       value: el.folio,
       filter: true,
       _sort: 'text'
      },
      Facturas:{
        align: 'center',
        value: el.Invoices.map(el => <Fragment key={el.folio}><Link href={el.uri} target="_blank">{el.folio}</Link><br/></Fragment>),
        arrayValue: el.Invoices.map(el => el.folio),
        _sort: 'text'
      },
      Cliente: {
       value: el.Customer.firstName + ' ' + el.Customer.lastName,
       filter: true,
       _sort: 'text'
      },
      "Importe sin IVA":{
        value: moneyFormat(el.totalRaw),
        align: "right",
        alt: parseFloat(parseFloat(el.totalRaw).toFixed(2)),
        _sort: 'alt',
        accumulate: true
      },
      Descuento:{
        value: moneyFormat(el.amountDiscount),
        align: "right",
        alt: parseFloat(parseFloat(el.amountDiscount).toFixed(2)),
        _sort: 'alt'
      },
      Subtotal:{
        value: moneyFormat(el.subTotal),
        align: "right",
        alt: parseFloat(parseFloat(el.subTotal).toFixed(2)),
        _sort: 'alt'
      },
      IVA:{
        value: moneyFormat(el.taxes),
        align: "right",
        alt: parseFloat(parseFloat(el.taxes).toFixed(2)),
        _sort: 'alt'
      },
      Total:{
        value: moneyFormat(el.total),
        align: "right",
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        _sort: 'alt',
        accumulate: true
      },
      Usuario:{
        value: `${el.User.firstName} ${el.User.lastName}`,
        _sort: 'text'
      },
      Vendedor:{
        value: parseInt(el.status.id) !== 3 ? `${el.Employee.firstName} ${el.Employee.lastName}` : '',
        _sort: 'text'
      }
    })) : []
  , [props.sales]);

  return (
    <Fragment>
      <Module
        table="sales"
        subtitle={subtitle}
        name="Reporte de Notas de Venta"
        records={sales}
        singularName="reporte"
        title="Filtrar Ventas"
        filter={filter}
        edit={false}
        create={false}
        download={true}
        _delete={false}
        actions={actions}
      ></Module>

      <ConfirmDialog _id={confirmId} handleAction={handleDelete} handleCancel={handleCancelPayment} />
      <BillingDialog />
      <Snackbar />
      <FilterDialog employees={employees} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const errorMessage = key => {
  switch(key){
    case "saleId":
      return "No es posible eliminar esta nota de venta, sugerimos emitir una nota de crédito.";
    case "Error":
      return "Error desconocido, favor de reportar."
    default:
      return "";
  }
}

const mapStateToProps = state => ({
  sales: state.records.sales || defaultTableValue,
  validation: state.field.validation,
  employees: state.records.employees || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  update: (data) => dispatch(recordsActions.update(data)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  errorMessage: value => dispatch(alertActions.error(value)),
  _delete: value => dispatch(recordsActions._delete(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);


// "Importe sin IVA":{
//         value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.totalRaw),
//         align: "right",
//         alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.totalRaw).toFixed(2)),
//         _sort: 'alt',
//         accumulate: true
//       },
//       Descuento:{
//         value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.amountDiscount),
//         align: "right",
//         alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.amountDiscount).toFixed(2)),
//         _sort: 'alt'
//       },
//       Subtotal:{
//         value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.subTotal),
//         align: "right",
//         alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.subTotal).toFixed(2)),
//         _sort: 'alt'
//       },
//       IVA:{
//         value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.taxes),
//         align: "right",
//         alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.taxes).toFixed(2)),
//         _sort: 'alt'
//       },
//       Total:{
//         value: parseInt(el.status.id) === 3 ? moneyFormat(0.00) : moneyFormat(el.total),
//         align: "right",
//         alt: parseInt(el.status.id) === 3 ? parseFloat(0) : parseFloat(parseFloat(el.total).toFixed(2)),
//         _sort: 'alt',
//         accumulate: true
//       },