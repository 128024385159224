const customers = ({method, ...props}) => {
  switch (method) {
    case 'POST':
      return `groups/${props.groupId}/customers`;
    case 'GET':
      return `groups/customers`
    case 'PUT':
      return `groups/${props.groupId}/customers/${props._id}`;
    case 'DELETE':
      return `groups/customers/${props._id}`;
    default:
      return 'customers';
  }
}

export default customers;
