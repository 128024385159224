import React, { useMemo, Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import randomize from 'randomatic';

import { parseISO } from 'date-fns';
import locale from 'date-fns/locale/es';
import format from 'date-fns/format';

import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';

import Module from '../../_presentationals/Module';

import { openFormDialog  } from '../../_actions';

import Authorize from './auth.dialog';

const defaultValues = {
  logisticProductId: null,
  logisticId: null,
  productId: null
}

const Logistic = ({openFormDialog, ...props}) => {

  const [ actions, setActions ] = useState([]);
  const [ replaceAuthId, setReplaceAuthId ] = useState(defaultValues);
  const [ refreshCode, setRefreshCode ] = useState(null);

  useEffect(() => {
    const tmpAction = [
      { icon: <DoneAllIcon fontSize="small" />, label: "Autorizar", handle: handleAuthorize },
    ];
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const replaceAuths = useMemo(() => 
    props.replaceAuths.count > 0 ? props.replaceAuths.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale} ),
        _sort: 'date'
      },
      Producto: {
        value: el.Product.name,
        filter: true,
        _sort: 'text'
      },
      Serie: {
        value: el.PreviousManufacture.physicalSerie,
        filter: true,
        _sort: 'text'
      },
      Reemplazo: {
        value: el.Manufacture.physicalSerie,
        filter: true,
        _sort: 'text'
      },
      'Justificación': {
        value: el.authCommentary,
       filter: true,
       _sort: 'text'
      },
      Usuario: {
        value: `${el.User.firstName} ${el.User.lastName}`,
        filter: true,
        _sort: 'text'
      },
      Factura: {
        value: `${el.SaleDetailV2.SaleV2.folio}-${el.SaleDetailV2.SaleV2.serial}`,
      },
      Cliente:{
        value: `${el.SaleDetailV2.SaleV2.Customer.firstName} ${el.SaleDetailV2.SaleV2.Customer.lastName}`,
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.replaceAuths]);


  const handleAuthorize = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setReplaceAuthId(id);
    openFormDialog('Authorize');
  }

  const reload = () => {
    const tmpCode = randomize('*', 10);
    setRefreshCode(tmpCode);
  };


  return (
    <Fragment>
      <Module
        table="replaceAuths"
        name="Autorización de Reemplazo"
        records={replaceAuths}
        title="Autorización de Reemplazo"
        singularName="PDF"
        refreshCode={refreshCode}
        filter={false}
        edit={false}
        download={true}
        create={false}
        _delete={true}
        actions={actions}
      >
      </Module>
      <Authorize id={replaceAuthId} reload={reload}/>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  replaceAuths: state.records.replaceAuths || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logistic);