import { format } from "date-fns";
import localeES from 'date-fns/locale/es';

const commission = ({method, ...props}) => {
	switch (method) {
    case 'DELETE':
    case 'PUT':
      return `employees/sellers/${props._id}`
    case 'GET':
      const startFormat = props.start ? new Date(props.start) : new Date();
      const finishFormat = props.finish ? new Date(props.finish) : new Date();

      const start = format(startFormat, 'yyyy/MM/dd', {locale: localeES});
      const finish = format(finishFormat, 'yyyy/MM/dd', {locale: localeES});

      return `employees/sellers/commission?start=${start}&finish=${finish}&employeeId=${props.employeeId}`
    default:
      return 'employees';
  }
}

export default commission;