import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';

import { recordsActions, closeFormDialog } from '../../_actions';

import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { LogisticReport } from './logisticReport';

const Dialog = ({employees, ...props}) => {

  const handdleFilter = e => {
    LogisticReport(props.employeeId);
  };

	return (
	  <FormDialog  
      singular="LogisiticReportDialog" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <Autocomplete
        label="Chofer" 
        _id="employeeId"
        options={ employees }
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  employeeId: state.field.value.employeeId || null
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const FilterDialog = connect(mapStateToProps, mapDispatchToProps)(Dialog);