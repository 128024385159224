import React, { useEffect, Fragment, useState, useMemo } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import Icon from '@material-ui/core/Icon';
import { TextField, AutocompleteField } from './../../_presentationals/Form';
import Grid from '@material-ui/core/Grid';
import { ListItemAdd } from './listItemAdd';
import { editValueAction, recordsActions } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Tools = ({ dataTools, getRecords, editValueAction, create, ...props }) => {

  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [isAdd, setAdd] = useState(false);

  const handleAddTool = e => {
    setAdd(true);
    const { toolId, toolIdLabel, quantity = 1, commentaryTool } = props.selectedTool;
    if( !toolId || isNaN(parseInt(quantity)) ) return;

    local && local.push({ toolId, toolIdLabel, quantity: parseInt(quantity), commentary: commentaryTool });
    editValueAction({ Tools: local, isTools: true, toolId: null, quantity: '', commentaryTool: '' });
    setQty(JSON.stringify(local));
    setLocal(local);
  };

  useEffect( () => {
    if(props.alert.type === 'success') {
      editValueAction({Tools: [], isTools: false, toolId: null, quantity: '', commentaryTool: ''});
      setLocal([]);
      setQty(0);
      setAdd(false);
    }
  }, [props.alert, editValueAction]);

  useEffect(() => {
    if(!isAdd) {
      const tmp = dataTools;
      setQty(JSON.stringify(tmp));
      setLocal(tmp);
      editValueAction({ Tools: tmp });
    }
  },[dataTools, editValueAction, isAdd]);

  useEffect( () => ( getRecords({ table: 'tools' }) ),[ getRecords ]);
  const tools = useMemo(() => {
    return props.tools.rows ? props.tools.rows.map(({ id, name }) => ({ value: id, label: name })) : [];
  }, [props.tools]);

  const tableRows = useMemo(() => {

    const handleDeleteTool = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal([ ...tmp ]);
    };

    
    return local.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">
          {el.toolIdLabel}
        </TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell>{el.commentary}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteTool} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, props.dataTools, qty]);
  
  return (
    <FormDialog 
      title="Asignar Herramientas"
      singular="ToolsDialog"
      table="employees"
    >
      <Fragment>       
        <ListItemAdd handleClick={handleAddTool}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AutocompleteField 
                label="Herramienta" 
                _id="toolId" 
                dialog={false}
                options={tools}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                dialog={false}
                fields={[{
                  id: "quantity", label: "Cant.",
                  variant: "outlined", margin: "dense"
                }]}
              />
            </Grid>
            <TextField
              dialog={false}
              fields={[{
                id: "commentaryTool", label: "Comentario",
                variant: "outlined", margin: "dense", required: false
              }]}
            />
          </Grid>
        </ListItemAdd>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Herramienta</TableCell>
              <TableCell align="center">Cant.</TableCell>
              <TableCell>Comentario</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </Fragment>
    </FormDialog>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  selectedTool: {
    toolId: state.field.value.toolId, 
    toolIdLabel: state.field.value.toolIdLabel,
    quantity: state.field.value.quantity,
    commentaryTool: state.field.value.commentaryTool
  },
  tools: state.records.tools || defaultTableValue,
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Tools);