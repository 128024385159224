// import format from 'date-fns/format';
// import localeES from 'date-fns/locale/es';

const logistic = (method, values) => {
	const tmp = {};
  switch (method) {
    case 'PUT':
    	if (values.manufactureId) return { manufactureId: values.manufactureId };
    	if (values.status) return { status: values.status, commentary: values.commentary };
      if (values.collectionTime) {
       return { receptionName: values.receptionName, collectionDate: values.collectionDate ? values.collectionDate : ' ', collectionTime: values.collectionTime, collectionCommentary: values.collectionCommentary };
     }
      if (values.employeeId) return { employeeId: values.employeeId }

    	tmp['addressLine1'] = values.addressLine1;
    	tmp['addressLine2'] = values.addressLine2;
    	tmp['city'] = values.city;
    	tmp['zipCode'] = values.zipCode;
    	tmp['reference'] = values.reference;
      tmp['deliveryDate'] = values.deliveryDate;
    	tmp['deliveryTime'] = values.deliveryTime;
    	tmp['deliveryCommentary'] = values.deliveryCommentary;
      

    	if (values.countryId) tmp['countryId'] = values.countryId;
    	if (values.stateId) tmp['stateId'] = values.stateId;

      return tmp;
    default:
      return tmp;
  }
}

export default logistic;
