import React from "react";
import { connect } from 'react-redux';

import { closeFormDialog } from '../../_actions';

import { FormDialog } from '../../_presentationals/FormDialog';
import { AutocompleteField } from './../../_presentationals/Form';

const SellerDialog = props => {
  const handleClick = () => props.closeFormDialog('SellerDialog');
  return(
    <FormDialog 
      buttonLabel="Actualizar" 
      handleCustomizedClick={handleClick} 
      singular="SellerDialog" 
      title="Configuración del Vendedor"
      clearDataAfterClose={false}
    >
      <AutocompleteField
        icon="user"
        label="Trabajador" 
        _id="employeeId" 
        options={props.employees}
      />
    </FormDialog>
  );
};

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(null, mapDispatchToProps)(SellerDialog);