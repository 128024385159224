import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';

import { closeFormDialog } from '../../_actions';

import { DateField } from '../../_presentationals/Form';
import { InventoryControl } from './InventoryControl';

const Dialog = props => {

  const handdleFilter = e => {
    InventoryControl(props.start,props.finish);
    props.closeFormDialog('InventoryFilter');
  };

	return (
    <Fragment>
  	  <FormDialog  
        singular="InventoryFilter" 
        title="Filtro"
        primaryButtonLabel="Continuar"
        handleCustomizedClick={handdleFilter}
      >
        <DateField
          id="start"
          label="Fecha Inicial"
          icon="today"
        />
        <DateField
          id="finish"
          label="Fecha Final"
        />
  	  </FormDialog>
    </Fragment>
	)
};

const mapStateToProps = state => ({
  start: state.field.value.start,
  finish: state.field.value.finish
});

const mapDispatchToProps = dispatch => ({
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export const FilterDialog = connect(mapStateToProps, mapDispatchToProps)(Dialog);