import React, { Fragment, useEffect, useMemo, useState } from "react";

import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import PersonIcon from '@material-ui/icons/Person';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';

import { openFormDialog, recordsActions, editValueAction, closeFormDialog, progressActions, alertActions } from './../../_actions';

import { TextField, AutocompleteField } from './../../_presentationals/Form';
import { styles } from './style';
import { Header } from './_Header';
import { ListItemEdit } from './_ListItemEdit';
import { ListItemAdd } from './_ListItemAdd';
import { CustomerDialog } from './_CustomerDialog';
import  SellerDialog  from './_SellerDialog';
import ProductDialog  from './_ProductDialog';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog/index';
import { Snackbar } from '../../_presentationals/Snackbar';

import { CourtesyTicket } from './ticket';

const Courtesy = ({ classes, openFormDialog, getRecords, editValueAction, ...props }) => {
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  const [ confirmId, setConfirmId ] = useState(0);

  const OpenCustomerDialog = () => openFormDialog('CustomerDialog');
  const OpenSellerDialog = () => openFormDialog('SellerDialog');

  useEffect(() => {
    if(props.alert === "success" && parseInt(props.courtesyId) > 0){
      CourtesyTicket(props.courtesyId);
      setLocal([]);
      setQty(0);
      editValueAction({ courtesyId: 0 });
    }
  }, [props.alert, openFormDialog, props.courtesyId, editValueAction]);

  useEffect(() => {
    const handleError = props.errorMessage;
    let key = "";
    Object.keys(props.validation).forEach(el => { 
      if(props.validation[el].isError === true){
        key = el; 
      }
    });
    const message = errorMessage(key);
    if(key !== "" && message !== ""){
      handleError(message);
    }
  }, [props.validation, props.errorMessage]);
  
  useEffect(() => {
    getRecords({ table: 'customers' }); //1
    getRecords({ table: 'employees', sellers: true }); //2
    getRecords({ table: 'companies'}); //4
    getRecords({ table: 'products'}); //5
  }, [getRecords, editValueAction]);

  const customers = useMemo(() => (
    props.customers.count > 0 ? props.customers.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName} ${el.CustomerPhones[0].phone}`, customerDiscount: el.discountRange, address: el.CustomerAddress, billingInformationId: (el.BillingInformation && el.BillingInformation.id) ? el.BillingInformation.id : 0 })) : []
  ), [props.customers]);

  const employees = useMemo(() => (
    props.employees.count > 0 ? props.employees.rows.map(el => ({ value: el.id, label: `${el.firstName} ${el.lastName}`})) : []
  ), [props.employees]);

  const products = useMemo(() => {
    return (props.products.count > 0 ? props.products.rows.map(el => {
      let label = '';
      switch(parseInt(el.type.id)) {
        case 1:
          label = `${el.name} (Renta: ${el.qtyForRent} | Venta: ${el.qtyForSale} )`;
          break;
        case 2:
          label = `${el.name} (Existencia: ${el.Inventories[0] ? el.Inventories[0].quantity : 0})`;
          break;
        default:
          label = el.name;
          break;
      }

      return {
        value: el.id, 
        label,
        price: el.prices && parseFloat(el.prices.salePrice),
        rentPrice: el.prices && el.prices.rentPrice,
        availableDiscount: el.Clasification && el.Clasification.discount
      }
    }) : [])
  }, [props.products]);

  const handleCloseConfigProduct = () => {
    local[props.index]['isRented'] = props.methodProduct === 1 ? 0 : 1;

    local[props.index]['mobility'] = props.fields.mobility;
    local[props.index]['planQuantity'] = props.planQuantity;
    local[props.index]['method'] = props.rentOptions;
    local[props.index]['methodLabel'] = methodToText(props.rentOptions);
    local[props.index]['addressLine1'] = props.fields.addressLine1 ? props.fields.addressLine1 : " ";
    local[props.index]['addressLine2'] = props.fields.addressLine2 ? props.fields.addressLine2 : " ";
    local[props.index]['city'] = props.fields.city ? props.fields.city : " ";
    local[props.index]['zipCode'] = props.fields.zipCode ? props.fields.zipCode : " ";
    local[props.index]['countryId'] = props.fields.countryId
    local[props.index]['stateId'] = props.fields.stateId;
    local[props.index]['reference'] = props.fields.reference ? props.fields.reference : " ";
    local[props.index]['deliveryDate'] = props.fields.deliveryDate ? props.fields.deliveryDate : " ";
    local[props.index]['deliveryTime'] = props.fields.deliveryTime ? props.fields.deliveryTime : " ";
    local[props.index]['deliveryComment'] = props.fields.deliveryComment ? props.fields.deliveryComment : " ";
    local[props.index]['PhysicalSeries'] = props.PhysicalSeries || [];
    setLocal(local);
    setQty(JSON.stringify(local));
    props.closeFormDialog("ProductDialog");
  }

  const handleAddProduct = () => {
    const { productId, productIdLabel, quantity = 1, rentPrice } = props.selectedProduct;
    if(!productId){ return; }

    local && local.push({ productId, productIdLabel, quantity: parseFloat(quantity), isRented: 0, rentPrice });
    setLocal(local);
    setQty(JSON.stringify(local));
    editValueAction({ productId: null, productIdLabel: '', quantity: 1, rentPrice: [] });
  };

  const handleConfirmSale = e => {
    const { id } = e.currentTarget.dataset;
    setConfirmId(id);
  }

  const handleCancelCourtesy = e => {
    setConfirmId(0);
  };

  const handleCourtesy = () => {
    setConfirmId(0);
    editValueAction({ Products: local });
    props.createRecords('courtesies');
  };

  const tableRows = useMemo(() => {

    if(qty){}

    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(local));
      setLocal([ ...tmp ]);
    };

    const handleConfigProduct = e => {
      const rentPrice = JSON.parse(e.currentTarget.dataset.rent_price);
      const isRented = e.currentTarget.dataset.is_rented;
      const index = e.currentTarget.dataset.index;
      console.log(parseInt(isRented) ? 2 : 1)
      editValueAction({ methodProduct: parseInt(isRented) ? 2 : 1, index, rentPrices: [ rentPrice.length, rentPrice ],
        PhysicalSeries: local[index]['PhysicalSeries'], qtyProductSelected: local[index]['quantity'], productSelectedId: local[index]['productId'],
        planQuantity: local[index]['planQuantity'] ||  1
      });
      openFormDialog('ProductDialog');
    }


    return local.map((el, index) => {
      const planQuantity = parseInt(local[index]['planQuantity']) > 1 ? `(${local[index]['planQuantity']})` : '';
      return (
        <TableRow key={index}>
          <TableCell margin="none" className={ classes.tableCell }>
            <Tooltip title="Configurar" aria-label="Configurar" classes={{ tooltip: classes.tooltip }}>
              <IconButton style={{ margin: 0 }} onClick={handleConfigProduct} data-index={index} data-is_rented={el.isRented ? 1 : 0} data-rent_price={JSON.stringify(el.rentPrice)} aria-label="settings">
                <Icon className="material-icons-outlined" fontSize="small">settings</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar" aria-label="Eliminar" classes={{ tooltip: classes.tooltip }}>
              <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
                <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell component="th" scope="row">
            {el.productIdLabel} {el.isRented ? `${el.methodLabel} ${planQuantity}` : "(Venta)"}
          </TableCell>
          <TableCell colSpan={4} align="center">{el.quantity}</TableCell>
        </TableRow>
      );
    } 
    );
  }, [local, qty, classes, editValueAction, openFormDialog]);

  return (
    <Fragment>
      <Header handleClick={handleConfirmSale} />
      <List dense={true} className={ classes.noPadding }>
        <ListItemEdit
          icon={<PersonIcon />}
          title="Cliente"
          text={ props.customerIdLabel }
          handleClick={OpenCustomerDialog}
        />
        <Divider />
        <ListItemEdit
          icon={<StoreMallDirectoryIcon />}
          title="Vendedor"
          text={ props.employeeIdLabel }
          handleClick={OpenSellerDialog}
        />
        <Divider />
        <ListItemAdd handleClick={handleAddProduct} >
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <AutocompleteField 
                label="Producto" 
                _id="productId" 
                dialog={false}
                options={ products }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                dialog={false}
                fields={[{
                  id: "quantity", label: "Cant.",
                  variant: "outlined", margin: "dense", required: false
                }]}
              />
            </Grid>
          </Grid>
        </ListItemAdd>
      </List>
      <Divider />
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Acciones</TableCell>
            <TableCell>Producto</TableCell>
            <TableCell colSpan={4} align="center">Cant.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { tableRows }
        </TableBody>
      </Table>
      <Grid container spacing={2} style={{paddingLeft: 15, paddingRight: 15}}>
        <Grid item xs={12}>
          <TextField
            dialog = {false}
            fields={[{
              id: "commentary", 
              label: "Comentario",
              variant: "outlined", 
              margin: "dense", 
              required: false
            }]}
          />   
        </Grid>
      </Grid>
      
      <CustomerDialog customers={customers} />
      <SellerDialog employees={employees} />
      <ConfirmDialog _id={confirmId} handleAction={handleCourtesy} handleCancel={handleCancelCourtesy} />
      <ProductDialog dataSeries={props.PhysicalSeries} qtyProduct={props.qtyProductSelected} productId={props.productSelectedId} handleClick={handleCloseConfigProduct} Address={props.fields.address} />
      <Snackbar />
    </Fragment>
  );
}

const methodToText = value => {
  let label = "";
  switch (value){
    case "rentPerEvent":
      label = "Renta por evento";
      break;
    case "rentPer1Month":
      label = "Renta por 1 mes (Comercial)";
      break;
    case "rentPer4Months":
      label = "Renta por 4 meses";
      break;
    case "rentPer6Months":
      label = "renta por 6 meses";
      break;
    case "rentPer12Months":
      label = "Renta por 12 meses";
      break;
    case "rentPer8Months":
      label = "Renta por 8 meses";
      break;
      case "rentPerEventIndustry":
      label = "Renta por evento";
      break;
    case "rentPer1MonthIndustry":
      label = "Renta por 1 mes (Comercial)";
      break;
    case "rentPer4MonthsIndustry":
      label = "Renta por 4 meses";
      break;
    case "rentPer6MonthsIndustry":
      label = "renta por 6 meses";
      break;
    case "rentPer12MonthsIndustry":
      label = "Renta por 12 meses";
      break;
    case "rentPer8MonthsIndustry":
      label = "Renta por 8 meses";
      break;
    default:
      label = "Indefinido";
      break;
  }
  return label;
};

const errorMessage = key => {
  switch(key){
    case "Products":
      return "Selecciona al menos un producto para su venta.";
    case "productId":
      return "Selecciona al menos un producto para su venta.";
    case "userId":
      return "Selecciona un vendedor.";
    case "customerId":
      return "Selecciona un cliente.";
    case "catchment":
      return "Selecciona un metodo de captación.";
    case "Error":
      return "Error desconocido, favor de reportar."
    case "PhysicalSeries":
      return "Seleccione las series correspondientes.";
    default:
      return "";
  }
}

const defaultTableValue = {count: 0, rows: []};
 
const mapStateToProps = state => ({ 

  validation: state.field.validation,

  index: state.field.value.index,
  rentPrices: state.field.value.rentPrices || [0, []],
  methodProduct: state.field.value.methodProduct,
  catchment: state.field.value.catchment,
  rentOptions: state.field.value.rentOptions,
  planQuantity: state.field.value.planQuantity || 1,

  selectedProduct: {
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity,
    rentPrice: state.field.value.rentPrice
  },

  courtesyId: state.field.value.courtesyId, 

  products: state.records.products || defaultTableValue,
  customers: state.records.customers || defaultTableValue,
  employees: state.records.employees || defaultTableValue,
  
  alert: state.alert.type,

  customerIdLabel: state.field.value.customerIdLabel ? state.field.value.customerIdLabel : 'Público en General',
  employeeIdLabel: state.field.value.employeeIdLabel ? state.field.value.employeeIdLabel : 'No Asignado',

  fields: state.field.value,

  progress: state.progress.isLoadingDialog,

  PhysicalSeries: state.field.value.PhysicalSeries || [],
  qtyProductSelected: state.field.value.qtyProductSelected || 0,
  productSelectedId: state.field.value.productSelectedId || 0,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: data => dispatch(openFormDialog(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  createRecords: value => dispatch(recordsActions.create(value)),
  openProgressBar: () => dispatch(progressActions.open()),
  closeProgressBar: () => dispatch(progressActions.close()),
  errorMessage: value => dispatch(alertActions.error(value)),
  pendingMessage: value => dispatch(alertActions.pending(value)),
  clear: () => dispatch(alertActions.clear()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Courtesy));