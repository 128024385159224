import React, { Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import locale from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import Module from '../../_presentationals/Module';

import { moneyFormat } from '../../_helpers/moneyFormat';
import { openFormDialog } from '../../_actions';

import Filter from './filter.dialog';

const statusLabel = ['No Definido','Abierta','No Definido','Cotizado','Autorizado','No Autorizado','Cerrado','Facturado','Pendiente de Entrega','Entregado'];

const ServiceOrder = ({ openFormDialog, ...props }) => {
  
  const serviceOrdersReport = useMemo(() => 
    props.serviceOrdersReport.count > 0 ? props.serviceOrdersReport.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el,
        showIf: parseInt(el.status) === 7 ? true : false,
        _alternative: parseInt(el.status) === 6 ? true : false,
      }, 
      "Creado el": {
        value: format(parseISO(el.createdAt), 'dd/MM/yyyy HH:ss', {locale}),
        _sort: 'date',
        noBreak: true
      },
      Folio: {
        value: el.folio + '-' + el.serial,
        noBreak: true
      },
      Cliente: {
        value: el.Customer ? el.Customer.firstName + ' ' + el.Customer.lastName : '-',
        filter: true,
        _sort: 'text'
      },
      Equipo: {
        value: el.Product ? el.Product.name : '-',
        filter: true,
        _sort: 'text'
      },
      Serie: {
        value: el.physicalSerie,
        _sort: 'text'
      },
      Descripción: {
        value: el.description
      },
      "Registrado por": {
        value: el.User ? el.User.firstName + ' ' + el.User.lastName : '-',
        noBreak: true
      },
      Estatus: {
        value: statusLabel[parseInt(el.status)],
        noBreak: true
      },
      Total: {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right",
        _sort: 'alt'
      }
    })) : []
  , [props.serviceOrdersReport]);

  const handleFilter = () => {
    openFormDialog('Filter');
  }

  return (
    <Fragment>
      <Module
        table="serviceOrdersReport"
        name="Reporte de Ordenes de Servicio"
        records={serviceOrdersReport}
        singularName="orden de servicio"
        create={true}
        filter={handleFilter}
        title="Orden de servicio"
        download={true}
      >
      </Module>
      <Filter/>
    </Fragment>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  serviceOrdersReport: state.records.serviceOrdersReport || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});


export default connect(mapStateToProps,mapDispatchToProps)(ServiceOrder);
