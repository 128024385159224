import React, { useEffect, useState, Fragment } from "react";

import { connect } from 'react-redux';

import CompareArrowsIcon from '@material-ui/icons/CompareArrowsOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from './../Sales.v2/helpers/IconButton';
import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog, openFormDialog } from './../../_actions';

import Replace from './replace.dialog.js';

const defaultValues = {
  logisticId: null,
  logisticProductId: null,
}

const Component = ({ closeFormDialog, openFormDialog, ...props }) => {
  const [ values, setValues ] = useState(defaultValues);
  const [ manufactures, setManufacures ] = useState([]);
  
  useEffect(() => {
    props.logisticId && recordsServices.getAllV2(`v2/logistics/${props.logisticId}`).then(data => {
      const tmp = data.LogisticProducts.map(el => ({ 
        id: el.id, logisticId: el.logisticId, name: el.Product.name, physicalSerie: el.Manufacture ? el.Manufacture.physicalSerie : '-'
      }))
      setManufacures(tmp);
    })
  }, [props.logisticId]);

  const handleReplace = (e,params) => {
    setValues(params);
    closeFormDialog('Manufacture');
    openFormDialog('Replace');
  }

  return (
    <Fragment>
      <FormDialog 
        title="Equipos asignados"
        name="manufacture"
        singular="Manufacture"
        showActionButton={false}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell>Serie</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            manufactures.map((el,i) => {
              return (
                <TableRow key={el.id}>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.physicalSerie}</TableCell>
                  <TableCell>
                    <IconButton 
                      size='small'
                      label='Reemplazar'
                      icon={<CompareArrowsIcon />}
                      action={handleReplace}
                      params={{ 
                        position: i, 
                        logisticId: el.logisticId,
                        logisticProductId: el.id
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
          </TableBody>
        </Table>
      </FormDialog>
      <Replace logisticId={values.logisticId} reload={props.reload} logisticProductId={values.logisticProductId} />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);