const logistic = ({method, ...props}) => {

	switch (method) {
		case 'PUT':
			return `v2/logistics/${props._id}`
    default:
      return 'v2/logistics?status=2&isRent=true';
  }
}

export default logistic;