import React from 'react';

import { FormDialog } from './../../_presentationals/FormDialog';
// import AvatarField from 'react-avatar-edit';

// import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



export const ProfilePicture = ({ handleSubmit, handleChangedAvatar }) => (
	<FormDialog
	  handleSubmit={ handleSubmit }
	  singular="Avatar"
	  closeAfterSave={true}
	>
		<Typography variant="h5" align="center" style={{ marginTop: "20px" }} gutterBottom>
      Herramienta en Desarrollo
    </Typography>
		{/*<Grid container spacing={0}>
			<Grid item xs={12}>
		  	<AvatarField
		      width={"96%"}
		      height={295}
		      onBeforeFileLoad={ handleChangedAvatar }
		      label={ "Arrastra o selecciona una foto" }
		      labelStyle={{fontSize: 16, color: "#a9a9a9"}}
		    />
		  </Grid>
	  </Grid>*/}
	</FormDialog>
);
