import React, { Fragment, useState, useMemo, useEffect } from "react";
import { connect } from 'react-redux';
import { recordsActions, editValueAction, alertActions } from '../../_actions';

import Module from '../../_presentationals/Module';
import { TextField, SelectField } from './../../_presentationals/Form';
import { GeneralDialog } from '../../_presentationals/GeneralDialog/index';
import { moneyFormat } from '../../_helpers/moneyFormat';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Receipt from '@material-ui/icons/Receipt';
import Link from '@material-ui/core/Link';

import { config, history } from './../../_helpers';
import { PaymentTicket } from './ticket';

const Payment = ({createRecords, editValueAction, paymentId, isSettled, CpUuid, folioCp, pendingMessage, ...props}) => {

  const [ isComplementDialogOpen, setIsComplementDialogOpen ] = useState(false);
  const [ complement, setComplement ] = useState({});
  
  useEffect(() => {
    if(CpUuid && folioCp) setComplement({ CpUuid, folioCp });
  },[CpUuid, folioCp]);

  useEffect(() => {
    props.redirect.id === "" && history.push('/AccountsReceivable');
  }, [props.redirect.id]);

  useEffect(() => {
    if(props.alert.type === "success" && isSettled) {
      editValueAction({ isComplement: true, accountsReceivableId: props.redirect.id });
      setTimeout(function(){
        pendingMessage("Espere un momento, estamos emitiendo el complemento de pago.");
      }, 1000);
      createRecords('invoices');
    }
    if(props.alert.type === "success" && CpUuid && folioCp) {
      setIsComplementDialogOpen(true);
    }
  }, [props.alert, CpUuid, folioCp, createRecords, editValueAction, pendingMessage, isSettled, props.redirect.id]);
  // }, [props.alert]);

  const handleCancelComplement = () => {
    setIsComplementDialogOpen(false);
  };

  useEffect(() => {
    if (paymentId > 0 && paymentId !== "") { PaymentTicket(paymentId)}
  }, [paymentId]);

  const payments = useMemo(() => 
    props.payments.count > 0 ? props.payments.rows.map(el => ({
      ID: { 
        show: null,
        value: el.id,
        data: el
      }, 
      Abono: {
       value: moneyFormat(el.payment),
       alt: parseFloat(parseFloat(el.payment).toFixed(2)),
       align: 'right'
      },
      "Forma de pago":{
        value: el.method.label
      },
      Cliente:{
        value: el.AccountReceivable.Customer.firstName+' '+el.AccountReceivable.Customer.lastName
      },
      Usuario:{
        value: el.User.firstName+' '+el.User.lastName
      }
    })) : []
  , [props.payments]);

	const availableMethods = [
    {key: "01", value: "Efectivo"},
    {key: "02", value: "Cheque nominativo"},
    {key: "03", value: "Transferencia electrónica de fondos"},
    {key: "04", value: "Tarjeta de crédito"},
    {key: "05", value: "Monedero electrónico"},
    {key: "06", value: "Dinero electrónico"},
    {key: "08", value: "Vales de despensa"},
    {key: "12", value: "Dación en pago"},
    {key: "13", value: "Pago por subrogación"},
    {key: "14", value: "Pago por consignación"},
    {key: "15", value: "Condonación"},
    {key: "17", value: "Compensación"},
    {key: "23", value: "Novación"},
    {key: "24", value: "Confusión"},
    {key: "25", value: "Remisión de deuda"},
    {key: "26", value: "Prescripción o caducidad"},
    {key: "27", value: "A satisfacción del acreedor"},
    {key: "28", value: "Tarjeta de débito"},
    {key: "29", value: "Tarjeta de servicios"},
    {key: "30", value: "Aplicación de anticipos"},
    {key: "31", value: "Intermediario pagos"},
    {key: "99", value: "Por definir"},
    {key: "101", value: "Devolución de efectivo a clientes"}
  ];

  const handdleTicket = (e) => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    if(data.method.id !== '100') PaymentTicket(data.id);
  }

  return (
    <Fragment>
      <Module
        table="payments"
        name="Abonos"
        records={payments}
        singularName="Abono"
        filter={false}
        title="Crear Abono"
        edit={false}
        actions={[
          {
            icon: <Receipt fontSize="small" />,
            label: "Generar Ticket",
            handle: handdleTicket
          }
        ]}
      >
        <Fragment>
          <TextField
              fields={[{
                id: "payment", 
                label: "Abono",
                required: false
              }]}
            />
            <SelectField
              fields={[{
                id: 'paymentMethod',
                label: 'Forma de pago',
                options: availableMethods,
              }]}
            />
            <br />
            {props.redirect.data && (<Table>
            <TableHead>
              <TableRow>
                <TableCell>NV</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Importe</TableCell>
                <TableCell>Abonado</TableCell>
                <TableCell>Saldo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{props.redirect.data.Sale.folio}</TableCell>
                <TableCell>{props.redirect.data.Customer.firstName}</TableCell>
                <TableCell>${props.redirect.data.total}</TableCell>
                <TableCell>${props.redirect.data.totalPayment}</TableCell>
                <TableCell>${props.redirect.data.balance}</TableCell>
              </TableRow>
            </TableBody>
          </Table>)}
        </Fragment>
      </Module>
      <GeneralDialog isOpen={isComplementDialogOpen} title={`Complemento de Pago:`} handleCancel={handleCancelComplement}>
        <Fragment>
          <Link href={`${config.apiUrl}/files/invoices/complements/${complement.CpUuid}/pdf`} target="_blank">{complement.folioCp}</Link>
        </Fragment>
      </GeneralDialog>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStatesToProps = state => ({
  paymentId: state.field.value.paymentId || 0,
  isSettled: state.field.value.isSettled || 0,
  CpUuid: state.field.value.CpUuid || 0,
  folioCp: state.field.value.folioCp || 0,
  redirect: state.redirect,
  payments: state.records.payments || defaultTableValue,
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  editValueAction: data => dispatch(editValueAction(data)),
  createRecords: value => dispatch(recordsActions.create(value)),
  pendingMessage: value => dispatch(alertActions.pending(value)),
});

export default connect(mapStatesToProps,mapDispatchToProps)(Payment);