const products = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
    case 'PUT':
      return `products/${props._id}`;
    case 'GET':
    	if([0,1,2,4].includes(props.type)) return `products/type/${props.type}`;
    	return `products`;
    default:
      return 'products';
  }
}

export default products
