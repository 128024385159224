import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField, SelectField } from './../../_presentationals/Form';

import { statesSelectField } from '../../_helpers';

const BillingInformations = props => {

  const [taxRegimeOptions, setTaxRegimeOptions] = useState([]);

  // eslint-disable-next-line
  const states = useCallback(statesSelectField(props.billingCountryId), [props.billingCountryId]);

  useEffect(() => {
    const f = async () => {
      try {
        const response = await fetch(
          'https://raw.githubusercontent.com/bambucode/catalogos_sat_JSON/master/c_RegimenFiscal.json',
        );
        const jsonResponse = await response.json();
        setTaxRegimeOptions(
          jsonResponse.map((el) => ({
            value: `${el.id} ${el.descripcion}`,
            key: el.id,
          })),
        );
      } catch (e) {
        console.log(e);
      }
    };
    f();
  }, []);

  return (
    <FormDialog
      title="Agregar datos de Facturación"
      name="BillingInformation"
      singular="Billing"
      table="billings"
    >
      <TextField
        icon="domain"
        fields={[{
          id: "businessName",
          label: "Razón Social"
        }]}
      />
      <TextField
        fields={[{
          id: "identifier",
          label: "RFC"
        }]}
      />
      <TextField
        icon="email"
        fields={[{
          id: "email",
          label: "Correo Electrónico",
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "altEmail",
          label: "Correo Electrónico (Alternativo)",
          required: false
        }]}
      />
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1",
            label: "Calle y Número",
            multiline: true
          },
          {
            id: "addressLine2",
            label: "Colonia",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city",
            label: "Ciudad"
          },
          {
            id: "zipCode",
            label: "Código Postal"
          }
        ]}
      />
      <SelectField
        fields={[{
          id: 'billingCountryId',
          label: 'País',
          options: props.countries
        }]}
      />
      <SelectField
        fields={[{
          id: 'billingStateId',
          label: 'Estado',
          options: states,
        }]}
      />
      <SelectField
        fields={[{
          required: true,
          id: 'taxRegime',
          label: 'Regimen fiscal',
          options: taxRegimeOptions
          
        }]}
      />
    </FormDialog>
  );
}

const mapStateToProps = state => ({
  billingCountryId: state.field.value.billingCountryId || 0
});

// const mapDispatchToProps = dispatch => ({
//   getRecords: value => dispatch(recordsActions.getAll(value)),
//   clearRecords: value => dispatch(recordsActions.clearRecords(value))
// });

// export default BillingInformations;
export default connect(mapStateToProps)(BillingInformations);
