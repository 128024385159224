import React from "react";
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import { recordsActions, closeFormDialog } from '../../_actions';

import { DateField } from '../../_presentationals/Form';

const Dialog = props => {

  const handdleFilter = e => {
    const { start, finish } = props;
    props.getRecords({ table: 'saleAuths', filter: true, start, finish });
    props.closeFormDialog('SalesAuthReportFilter');
  };

	return (
	  <FormDialog  
      singular="SalesAuthReportFilter" 
      title="Filtro"
      primaryButtonLabel="Continuar"
      handleCustomizedClick={handdleFilter}
    >
      <DateField
        id="start"
        label="Fecha Inicial"
        icon="today"
      />
      <DateField
        id="finish"
        label="Fecha Final"
      />
	  </FormDialog>
	)
};

const mapStateToProps = state => ({ 
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date()
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);