const suppliers = ({method, ...props}) => {
  switch (method) {
  	case 'DELETE':
  	case 'PUT':
  		return `suppliers/${props._id}`;
    default:
      return 'suppliers';
  }
}

export default suppliers;