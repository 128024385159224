import { useState, useEffect } from "react";
import * as recordsServices from '../_services';

export const ClassificationSelectField = () => {
	const [ classification, setClassification ] = useState([]);

	useEffect(() => {
		recordsServices.getAll({ table: 'clasifications' }).then(async el => {
			const data = await Promise.all(el.rows.map(({ id, name }) => ({ key: id, value: name })));
			data.length > 0 && setClassification(data);
		});
	}, []);
	
	return classification;
}

export const SubClassificationSelectField = classificationId => {
	const [ classification, setClassification ] = useState([]);

	useEffect(() => {
		recordsServices.getAll({ table: 'subClasifications', clasificationId: classificationId }).then(async el => {
			const data = el.count > 0 && await Promise.all(el.rows.map(({ id, name }) => ({ key: id, value: name })));
			data.length > 0 && setClassification(data);
		});
	}, [classificationId]);
	
	return classification;
}