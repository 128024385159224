import React, { useState, useEffect } from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import WebIcon from '@material-ui/icons/WebOutlined';

import { getAllV2 } from './../../../_services/records.services';
import FormDialog from './../helpers/FormDialog';
import Autocomplete from './../helpers/Autocomplete';
import SelectField from './../helpers/SelectField';

const _session = JSON.parse(localStorage.getItem('session'));
const ivaTax = _session ? parseFloat(_session.data.ivaTax) : 16;

const defaultValues = {
  customerId: null,
  catchmentMethod: 1
}

const catchmentOptions = [
  { value: 1, label: "Cliente" },
  { value: 2, label: "Internet" },
  { value: 3, label: "Distribuidor" },
  { value: 4, label: "Página web" },
  { value: 5, label: "Comisionista" },
  { value: 6, label: "Recomendación" },
  { value: 7, label: "Publicidad en carros" },
  { value: 8, label: "Seguimiento de cliente" },
  { value: 9, label: "Redes sociales empresa" },
  { value: 10, label: "Redes sociales personales" },
  { value: 11, label: "Publicidad fachada de la empresa" },
  { value: 12, label: "Publicidad fuera de la sucursal" },
  { value: 13, label: "Mercado Libre" },
  { value: 14, label: "No identificado" }
];

const Customer = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  const [ customers, setCustomers ] = useState([]);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  useEffect(() => {
    const f = async () => {
			try {
    await getAllV2('groups/customers').then(data => {

      const tmp = data.rows.map(el => {
        const address = `${el.CustomerAddress && el.CustomerAddress.addressLine1 ? el.CustomerAddress.addressLine1 : ''} ${el.CustomerAddress && el.CustomerAddress.addressLine2 ? el.CustomerAddress.addressLine2 : ''}, ${el.CustomerAddress && el.CustomerAddress.city ? el.CustomerAddress.city: ''}, ${el.CustomerAddress && el.CustomerAddress.State ? el.CustomerAddress.State.name : ''}, CP ${el.CustomerAddress && el.CustomerAddress.zipCode ? el.CustomerAddress.zipCode : ''}`;
        return { 
          label: `${el.CustomerPhones && el.CustomerPhones.length > 0 && el.CustomerPhones[0].phone} ${el.firstName} ${el.lastName}`.trim(), 
          value: el.id,
          iva: el.iva ? el.iva : ivaTax,
          address,
        }
      })
      setCustomers(tmp);
    });
  } catch (error) {
    console.log(error)
  }
  
}
f();
  }, []);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = () => {
    try{
      if(!values.customerId) throw new Error('Selecciona un cliente para la venta.');
      props.handleAction(values);
      setErrors({});
    }catch(e) {
      setErrors({ customerId: e.message });
    }
  }

	return (
    <FormDialog
      title='Cliente y Captación'
      actionLabel='Seleccionar'
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      handleAction={handleAction}
    >
      <Autocomplete 
        icon={<AccountCircleIcon />}
        name='customerId'
        label='Nombre del Cliente' 
        onChange={handleAutocompleteChange}
        options={customers}
        value={values.customerId}
        error={errors.customerId}
      />
      <SelectField 
        icon={<WebIcon />}
        name='catchmentMethod'
        label='Método de Captación' 
        onChange={handleChange}
        options={catchmentOptions}
        value={values.catchmentMethod}
      />
    </FormDialog>
	)
}

export default Customer;