const warranties = ({method, ...props}) => {
	switch (method) {
    case 'POST':
      if(props.isSale) return `warranties/${props.warrantyId}/sales`;
      if(props.isCourtesy) return `warranties/${props.warrantyId}/courtesies`;
      return 'warranties';
    case 'PUT':
      if(props.isStatus) return `warranties/${props._id}/status`;
      if(props.isSerie) return `warranties/${props._id}/series`;
      if(props.isOut) return `warranties/${props._id}/out`;
      return `warranties/${props._id}/pieces`
    case 'GET':
      const query = { productId: props.productId };
      Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null || query[key] === '' || query[key] === 0) && delete query[key]);      
      let querys = '';
      Object.keys(query).forEach(key => {
        querys += `${key}=${query[key]}&`;
      });
      return `warranties?${querys}`;
    case 'DELETE':
      return `warranties/${props._id}`;
    default:
      return 'warranties';
  }
}

export default warranties;