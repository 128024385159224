import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { ListItem, ListItemText, Grid}from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { SelectField, TextField } from '../../_presentationals/Form';
import { FormDialog } from './../../_presentationals/FormDialog';
import { openFormDialog } from './../../_actions';

const EditCreditCommission = ({openFormDialog, ...props}) => {

  const handleClick = () => {
    openFormDialog('EditCreditCommission');
  };

  return(
    <Fragment>
      <ListItem
        divider 
        button 
        onClick={handleClick}
      >
        <Grid item xs={2}>
          Comisión
        </Grid>
        <ListItemText 
          inset
          primary="12 Meses (10)"
        />
        <EditIcon />
      </ListItem>
      <FormDialog
        title="Editar comisión a crédito"
        singular='EditCreditCommission'
      >
        <SelectField
        fields={[{
          id: 'months',
          label: 'Meses',
          options: [
            { key: 1, value: '3 meses' },
            { key: 2, value: '6 meses' },
            { key: 3, value: '12 meses' }
          ]
        }]}
        />
        <TextField
          fields={[{
            id:'commission',
            label: 'comisión',
            required: true
          }]}
        />
      </FormDialog>
    </Fragment>
  );  
}

const mapDispatchToProps = dispatch => ({  
  openFormDialog: data => dispatch(openFormDialog(data))
});

export default connect(null, mapDispatchToProps)(EditCreditCommission);
