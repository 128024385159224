import React, { useState, useEffect, Fragment, useMemo } from "react";
import { connect } from 'react-redux';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import Module from '../../_presentationals/Module';
import { TextField, SelectField, DateField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';

import * as recordsServices from './../../_services/records.services';
import { recordsActions, openFormDialog, editValueAction } from '../../_actions';

const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));

const FailureV2 = ({ getRecords, openFormDialog, editValueAction, ...props }) => {
  
  const [ customerOptions, setCustomerOptions ] = useState([]);
  const [ productOptions, setProductOptions ] = useState([]);
  
  useEffect(() => {
    recordsServices.getAllV2('groups/customers').then(data => {
      const tmp = data.rows.map(el => ({
        label: `${el.CustomerPhones && el.CustomerPhones[0].phone} ${el.firstName} ${el.lastName}`.trim(), 
        value: el.id
      }))
      setCustomerOptions(tmp);
    });
    recordsServices.getAllV2('products/type/1').then(data => {
      const tmp = data.rows.map(el => ({
        label: el.name, 
        value: el.id
      }))
      setProductOptions(tmp);
    });
  }, []);

  const failuresV2 = useMemo(() => 
    props.failuresv2.count > 0 ? props.failuresv2.rows.map(el => ({
      ID: { 
        show: true,
        value: el.id,
        data: el
      }, 
      "Creado el": {
        value: format(parseISO(el.createdAt), 'dd/MM/yyyy HH:ss', {locale: localeES}),
        _sort: 'date',
        noBreak: true
      },
      Cliente: {
        value: el.Customer ? el.Customer.firstName + ' ' + el.Customer.lastName : '-',
        filter: true,
        _sort: 'text'
      },
      Equipo: {
        value: el.Product ? el.Product.name : '-',
        filter: true,
        _sort: 'text'
      },
      "Reporte de Cliente": {
        value: el.description
      },
      Recolección: {
        value: el.LogisticV2 ? format(parseISO(el.LogisticV2.collectionDate), 'dd/MM/yyyy HH:ss', {locale: localeES}) : '-',
        noBreak: true
      },
      Domicilio: {
        value: el.LogisticV2 ? el.LogisticV2.address : '-'
      }
    })) : []
  , [props.failuresv2]);

  return (
    <Fragment>
      <Module
        table="failuresv2"
        name="Reporte de Servicio"
        records={failuresV2}
        singularName="reporte de servicio"
        filter={false}
        create={true}
        title="Reporte de Servicio"
        download={true}
      >
        <Autocomplete
          icon="person"
          label="Cliente" 
          _id="customerId" 
          options={customerOptions}
        />
        <Autocomplete
          icon="toys"
          label="Equipo" 
          _id="productId" 
          options={productOptions}
        />
        <TextField
          fields={[{
            id: "description",
            label: "Reporte de Cliente"
          }]}
        />
        <TextField
          icon="room"
          fields={[{
            id: "address",
            label: "Domicilio de Recolección",
            required: false
          }]}
        />
        <DateField
          id="collectionDate"
          label="Fecha de recolección"
          icon="today"
          disablePast={true}
        />
        <SelectField
          fields={[{
            id: 'collectionTime',
            label: 'Hora de recolección',
            options: serviceTime,
            required: false
          }]}
        />
        <TextField
          fields={[{
            id: "collectionCommentary",
            label: "Comentario para chofer"
          }]}
        />
      </Module>
    </Fragment>
  );

}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  failuresv2: state.records.failuresv2 || defaultTableValue,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(FailureV2);
