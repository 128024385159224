import React, { useMemo, Fragment, useEffect } from "react";

import { connect } from 'react-redux';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction, recordsActions  } from '../../_actions';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';
import ProductDialog from './products';
import PhysicalInventoryReport from './physicalReport';

const Inventory = ({getRecords, ...props}) => {

  useEffect(() => { 
    getRecords({ table: 'products' });
  }, [getRecords]);

  const products = useMemo(() => {
    return (props.products.count > 0 ? props.products.rows.map(el => ({
      value: el.id, 
      label: el.name
    })) : [])
  }, [props.products]);

  const physicalInventories = useMemo(() =>
    props.physicalInventories.count > 0 ? props.physicalInventories.rows.map(el => (
      {
        ID: { 
          show: false,
          value: el.id,
          data: el
        }, 
        Fecha:{
          value: format(new Date(el.createdAt), "dd/MM/yyyy HH:mm", { locale }),
          _sort: 'date'
        },
        Folio: {
          value: el.folio
        },
        Usuario: {
          value: `${el.User.firstName} ${el.User.lastName}`,
          filter: true,
          _sort: 'alt'
        },
        Descripción:{
          value: el.description
        }
      }
    )) : []
  ,[props.physicalInventories]);

  const handleReport = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    PhysicalInventoryReport(id);
  }
  
  return (
    <Fragment>
      <Module
        table="physicalInventories"
        name="Inventario Físico"
        records={physicalInventories}
        singularName="Inventario"
        title="Inventariar"
        filter={false}
        edit={false}
        download={true}
        _delete={false}
        actions={[{
          icon: <PictureAsPdfIcon fontSize="small" />, label: "Consultar", handle: handleReport
        }]}
      >
        <ProductDialog products={products} />
      </Module>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  physicalInventories: state.records.physicalInventories || defaultTableValue,
  products: state.records.products || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  getRecords: value => dispatch(recordsActions.getAll(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);