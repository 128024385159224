import React, { Fragment, useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import * as recordsServices from './../../../../_services/records.services';

import LinearForm from './../../helpers/LinearForm';
import Autocomplete from './../../helpers/Autocomplete';
import TextField from './../../helpers/TextField';
import SelectField from './../../helpers/SelectField';

import useStyles from './style';

const defaultValues = {
	productId: null,
	qty: 1,
	discount: 0,
	price: 0,
	isWarranty: false
}

const warrantyOptions = [
	{ value: true, label: 'Si' },
	{ value: false, label: 'No' },
];

const Form = props => {

	const classes = useStyles();

	const [ values, setValues ] = useState(defaultValues);
	const [ products, setProducts ] = useState([]);
	const [ errors, setErrors ] = useState({});

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	useEffect(() => {
		const f = async () => {
			try {
				await recordsServices.getAllV2('products').then(data => {
					const tmp = data.rows.map(el => { 
						const inventory = el.type.id === 1 
							? `Renta: ${el.qtyForRent}, Venta: ${el.qtyForSale}`
							: `Existencia: ${el.Inventories[0].quantity}`;
						return { 
							label: `${el.name} (${inventory})`, 
							value: el.id,
							name: el.name,
							prices: el.prices,
							type: el.type.id
						};
				})
				setProducts(tmp);
			});
			} catch (error) {
				console.log(error)
			}
			
		}
		f();
	}, []);

	const handleAutocompleteChange = (e, name, value) => {
		const price = (value && props.costSettings) 
			? value.prices.productionCost
			: (value && value.prices.salePrice) ? value.prices.salePrice : 0;
    setValues(values => ({ ...values, [name]: value, price }));
    if(value && parseInt(value.type) === 1) {
    	setValues(values => ({ ...values, qty: 1 }));
    }
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = e => {
  	try{

  		if(!values.productId){ 
  			const e = new Error('Selecciona un producto.')
  			e.name = 'productId';
  			throw e; 
  		}

  		if(parseFloat(values.qty) <= 0 || isNaN(parseFloat(values.qty))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'qty';
  			throw e; 
  		}

  		if(parseFloat(values.discount) < 0){ 
  			const e = new Error('Proporciona un descuento mayor o igual a cero.')
  			e.name = 'discount';
  			throw e; 
  		}

  		if(parseFloat(values.price) < 0 ||  isNaN(parseFloat(values.price))){ 
  			const e = new Error('Proporciona un precio unitario mayor o igual a cero.')
  			e.name = 'price';
  			throw e; 
  		}

  		props.handleAdd({
  			product: { 
  				id: values.productId.value,
  				name: values.productId.name,
  				type: values.productId.type,
  				prices: { ...values.productId.prices, tmpSalePrice: parseFloat(values.price) },
  			},
  			qty: parseFloat(values.qty),
  			discount: parseFloat(values.discount),
  			price: parseFloat(values.price),
  			isWarranty: values.isWarranty
  		});

  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  	
  }

	return (
		<Fragment>
			<div className={classes.linearFormContent}>
				<LinearForm handleAdd={handleAddProduct} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={(props.isCourtesy || props.isLend) ? 10 : !props.showWarranty ? 6 : 4}>
							<Autocomplete 
				        name='productId'
				        label='Producto' 
				        variant='outlined'
				        onChange={handleAutocompleteChange}
				        options={products}
				        value={values.productId}
				        error={errors.productId}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={2}>
							<TextField 
				        name='qty'
				        label='Cant.'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.qty}
				        error={errors.qty}
				        disabled={values.productId && parseInt(values.productId.type) === 1 ? true : false}
				        noFormat
				      />
						</Grid>
						{
							props.showWarranty &&
								<Grid item xs={12} md={2}>
									<SelectField 
						        name='isWarranty'
						        label='Garantía'
						        size='small'
						        variant='outlined'
						        onChange={handleChange}
						        value={values.isWarranty}
						        error={errors.isWarranty}
						        options={warrantyOptions}
						        noFormat
						      />
								</Grid>
						}
						{
							(!props.isCourtesy && !props.isLend) &&
								<Grid item xs={12} md={2}>
									<TextField 
						        name='discount'
						        label='Dscto. %'
						        type='number' 
						        variant='outlined'
						        onChange={handleChange}
						        value={values.discount}
						        error={errors.discount}
						        disabled={props.costSettings ? true : false}
						        noFormat
						      />
								</Grid>
						}
						{
							(!props.isCourtesy && !props.isLend) &&
								<Grid item xs={12} md={2}>
									<TextField 
						        name='price'
						        label={props.costSettings ? 'Costo' : 'P. Unitario'}
						        type='number' 
						        variant='outlined'
						        onChange={handleChange}
						        value={values.price}
						        error={errors.price}
						        disabled={props.costSettings ? true : false}
						        noFormat
						      />
								</Grid>
						}
					</Grid>
				</LinearForm>
			</div>
			
		</Fragment>
	);
}

export default Form