const groups = ({method, ...props}) => {
  switch (method) {
    case 'DELETE':
    case 'PUT':
      return `groups/${props._id}`;
    default:
      return 'groups';
  }
}

export default groups;
