import { profileConstants } from '../_constants';

const initialState = {
	local: '',
	image: 'https://live.staticflickr.com/8819/17936349330_c5fbfa8f6a_b.jpg'
};

export const profile = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.GET_PROFILE:
      return { ...state, image: action.payload };
    default:
      return state
  }
};

// export const profile = (state = initialState, action) => {
//   switch (action.type) {
//     case profileConstants.CHANGE_LOCAL_PROFILE:
//       return { ...state, local: action.payload };
//     case profileConstants.GET_PROFILE:
//       return { ...state, image: action.payload };
//     case profileConstants.RESET_PROFILE:
//       return { ...state, local: "" };
//     default:
//       return state
//   }
// };