import React, { useMemo, Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction  } from '../../_actions';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog/index';
// import localeES from 'date-fns/locale/es';
// import { parseISO } from 'date-fns';
// import format from 'date-fns/format';

import ListAltIcon from '@material-ui/icons/ListAlt';
import UpdateIcon from '@material-ui/icons/Update';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Receipt from '@material-ui/icons/Receipt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import BookIcon from '@material-ui/icons/BookOutlined';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import Log from './log';
import ChangeStatus from './changeStatus';
import { CollectionTicket } from './collectionTicket';
import { FilterDialog }  from './filter';

import { redirectActions, recordsActions } from '../../_actions';
import { history } from './../../_helpers';
import { config } from './../../_helpers/config';
import { moneyFormat } from '../../_helpers/moneyFormat';

import WarrantyDialog from '../FailureReport/_WarrantyDialog';
import FailureTicket from '../FailureReport/failureTicket';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const LogisticSale = ({openFormDialog, getRecords, editValueAction, ...props}) => {
  const [employees,setEmployees] = useState([]);
  const [Products,setProducts ] = useState([]);
  const [actions,setActions] = useState([]);
  const [confirmId,setConfirmId] = useState(0);

  useEffect(() => {

    const tmpAction = [
      { icon: <Receipt fontSize="small" />, label: "Ticket de Entrega", handle: collectionTicket },
      { icon: <ZoomInIcon fontSize="small" />, label: "Consultar Venta", handle: details }
    ];

    if(privileges.logisticSale && privileges.logisticSale.collection){ 
      tmpAction.push({ icon: <UpdateIcon fontSize="small" />, label: "Cambiar Estatus", handle: handdleChangeStatus });
    }

    if(privileges.logisticSale && privileges.logisticSale.products){ 
      tmpAction.push({ icon: <LocalShippingIcon fontSize="small" />, label: "Entregas", handle: redirect });
    }

    if(privileges.logisticSale && privileges.logisticSale.log){ 
      tmpAction.push({ icon: <ListAltIcon fontSize="small" />, label: "Revisar Historial", handle: handdleLog });
    }

    if(privileges.failures && privileges.failures.warranty) {
      tmpAction.push(
        { icon: <BookIcon fontSize="small" />, label: "Ticket De Falla",handle: handleTicket },
        { icon: <BuildIcon fontSize="small" />, label: "Recolectar Falla", handle: handleWarranty },
        { icon: <DeleteIcon fontSize="small" />, label: "Eliminar Falla", handle: handleCancelFailure }
      );
    }

    setActions(tmpAction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 


  const logisticsSale = useMemo(() => 
    props.logisticCollection.count > 0 ? props.logisticCollection.rows.map(el => { return{
      ID: { 
        show: false,
        value: el.id,
        data: el
      }, 
      Fecha:{
        value: el.collectionDate || 'No Especificado'
      },
      "Folio":{
        value: el.folio,
        filter: true
      },
      "Cliente":{
        value: el.Customer.firstName,
        filter: true,
       _sort: 'text'
      },
      Total:{
        value: el.isSale ? moneyFormat(el.total) : moneyFormat(0),
        alt: el.isSale ? parseFloat(parseFloat(el.total).toFixed(2)) : 0,
        align: 'right'
      },      
      Estatus:{
        value: `${el.generalStatus}`,
      }
    }}) : []
  , [props.logisticCollection]);

  const redirect = e => { 
    const { setRedirect } = props;
    const data = JSON.parse(e.currentTarget.dataset.row);
    if(data.isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    if(data.isRent) {
      setRedirect({id: data.id, data, isCollection: true, table: `${ data.isSale ? 'logisticSale' : 'logisticCourtesy' }`, returnTo: '/Logistic/Collection', returnToLabel: 'Recolección' });
      history.push(`/Logistic/${ data.isSale ? 'Collection' : 'Courtesy' }/Deliveries`);
    }
  };

  const handdleLog = e => {
    const { logs, isRent } = JSON.parse(e.currentTarget.dataset.row);
    if(isRent) {
      editValueAction({ logs });
      openFormDialog('Log');
    }
  };
  
  const handdleChangeStatus = e => {
    const { id, isSale, isSample, Products, isRent } = JSON.parse(e.currentTarget.dataset.row);
    if(isRent) {
      setProducts(Products);
      editValueAction({ id, isSale, isSample });
      openFormDialog('ChangeStatus'); 
    }
  };

  const details = e => {
    const { id, isSale, isSample, isRent } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    if(isRent) isSale ? window.open(`${config.apiUrl}/notes/sales/${id}`, '_blank') : window.open(`${config.apiUrl}/notes/courtesies/${id}`, '_blank');
  };

  const collectionTicket = e => {
    const { id, isSale, isSample, isRent } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    if(isRent) CollectionTicket(id,isSale);
  };

  const filter = () => {
    openFormDialog('LogisiticReportDialog');
  };

  const handleWarranty = e => {
    const { id, isFailure, isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    if(isFailure) {
      editValueAction({ id });
      openFormDialog('WarrantyDialog');
    }
  }

  const handleTicket = e => {
    const { id, isFailure, isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    if(isFailure) FailureTicket(id);
  }

  const handleCancelFailure = e => {
    const { id, isFailure, isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    if(isFailure) setConfirmId(id);
  }

  const handleCancelConfirmation = e => {
    const { isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    setConfirmId(0);
  };

  const handleConfirmation = e => {
    const { isSample } = JSON.parse(e.currentTarget.dataset.row);
    if(isSample) {
      alert('Acción invalida para muestras.');
      return;
    }
    editValueAction({ _id: confirmId, id: confirmId, isCancel: true });
    props.updateRecords('failures');
    setConfirmId(0);
  };

  useEffect(() => { getRecords({ table: 'employees', employeeRole: 6 }); }, [ getRecords ]);
  useEffect(() => {
    const tmp = props.employees.rows ? props.employees.rows.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) : [];
    setEmployees(tmp);
  }, [props.employees]);


  return (
    <Fragment>
      <Module
        table="logisticCollection"
        name="recolecciones pendientes"
        records={logisticsSale}
        title="Recolecciones Pendientes"
        singularName="PDF"
        filter={filter}
        edit={false}
        download={true}
        create={true}
        _delete={false}
        actions={actions}
      >
      </Module>
      <Fragment>
        <Log />
        <ChangeStatus Products={Products} />
        <FilterDialog employees={employees} />
        <WarrantyDialog />
        <ConfirmDialog _id={confirmId} handleAction={handleConfirmation} handleCancel={handleCancelConfirmation} />
      </Fragment>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => {
  return {
    logisticCollection: state.records.logisticCollection || defaultTableValue,
    employees: state.records.employees || defaultTableValue,
    employeeId: state.field.value.employeeId || ''
  }
}

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  setRedirect: data => dispatch(redirectActions.setRedirect(data)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
  updateRecords: value => dispatch(recordsActions.update(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticSale);