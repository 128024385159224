import jsPDF from 'jspdf';
import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';

const currentDate = new Date();

export const JsonToPdf = (title, keys, data) => {
	const doc = new jsPDF('landscape');	
	header(doc, title, keys);

	const width = 275 / parseInt(keys.length);
	let breakLine = 33;
	let heightBreakLine = 4;
	let rowsLine = 1;

	data.forEach( row => {
		breakLine = breakLine + heightBreakLine;
		let x = 10;
		
		keys.forEach(({ key, align = 'left' }) => {
			const label = doc.splitTextToSize(`${row[key] || '-'}`, (width - 2));
			rowsLine = label.length > rowsLine ? label.length : rowsLine;

			const tmpX = alignText(x,width,align);
			doc.text(tmpX, breakLine, label, align);
			
			x = x + width;
		});

		breakLine = breakLine + (heightBreakLine * rowsLine) - 2;
		doc.line(10, breakLine, 285, breakLine);

		rowsLine = 1;

		if(breakLine >= 185){
			breakLine = 33;
			doc.addPage('L');
			header(doc, title, keys);
		}
	});


	doc.save(`${title}_${format(currentDate, "dd-MMM-yyyy_HH-mm", {locale: localeES})}.pdf`);
}

const header = (doc, title, keys) => {

	doc.setFontSize(14);
	doc.text(title.toUpperCase(), 10, 20);
	doc.text(`${format(currentDate, "dd-MMM-yyyy HH:mm", {locale: localeES})}`, 285, 20, 'right');

	const width = 275 / keys.length;
	let x = 10;

	doc.setFontSize(10);
	keys.forEach(({ label, align }) => {
		const tmpX = alignText(x,width,align);
		doc.text(tmpX, 30, label, align);
		x = x + width;
	});

	doc.line(10, 33, 285, 33);

}

const alignText = (x,width,align) => {
	let tmpX = x;
	switch(align) {
		case 'right':
			tmpX = x + width - 2;
			break;
		case 'center':
			tmpX = width / 2 + x;
			break;
		default:
			tmpX = x;
			break;
	}

	return tmpX;
}