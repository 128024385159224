import React, { useMemo, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { recordsActions  } from './../../_actions';

import { history } from './../../_helpers';
import Module from '../../_presentationals/Module';
import AutocompleteField from './../../_presentationals/Form/AutocompleteFieldV2';
import { SelectField, TextField, DateField } from './../../_presentationals/Form';

const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));

const Component = props => {
  const { alert, getRecords } = props;
  const [ loadData, setLoadData ] = useState(0);
  
  useEffect(() => {
    props.redirect.id === "" && history.push('/Sample/');
  }, [props.redirect.id]);

  useEffect(() => { 
    (alert === "success" || !loadData) && getRecords({ table: 'manufactures', filter: true, status: 'allow' });  
    setLoadData(1);
  }, [ alert, loadData, getRecords ]);

  const manufactures = useMemo(() => {
    return props.manufactures.rows ? props.manufactures.rows.map(({ id, physicalSerie, statusLabel, Product }) => ({ value: id, label: `${Product.name} ${physicalSerie} ${statusLabel}` })) : [];
  }, [props.manufactures]);
  
  const sampleManufactures = useMemo(() => 
    props.sampleManufactures.count > 0 ? props.sampleManufactures.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      Producto: {
        value: el.Manufacture.Product.name,
        filter: true,
        _sort: 'text'
      },
      "Serie Física": {
        value: el.Manufacture.physicalSerie,
        filter: false,
        _sort: 'text'
      }
    })) : []
  , [props.sampleManufactures]);

  return (
    <Module
      table="sampleManufactures"
      name="equipos en muestra"
      singularName="equipo en muestra"
      title="Equipo en Muestra"
      records={sampleManufactures}
      download={true}
      filter={false}
      edit={false}
    >
      <AutocompleteField
        label="Serie Física" 
        _id="manufactureId" 
        options={ manufactures }
      />
      <DateField
        id="deliveryDate"
        label="Fecha de Entrega"
        icon="today"
        disablePast={true}
      />
      <SelectField
        fields={[{
          id: 'deliveryTime',
          label: 'Hora de entrega',
          options: serviceTime,
          required: false
        }]}
      />
      <DateField
        id="collectionDate"
        label="Fecha de Recolección"
        icon="today"
        disablePast={true}
      />
      <TextField
        fields={[{
          id: "deliveryComment",
          label: "Comentario",
          multiline: true,
          required: false
        }]}
      />
    </Module>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  redirect: state.redirect,
  manufactureId: state.field.value.manufactureId || 0,
  sampleManufactures: state.records.sampleManufactures || defaultTableValue,
  manufactures: state.records.manufactures || defaultTableValue,
  alert: state.alert.type
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);