import React, { Fragment, useMemo, useState  } from "react";
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import localeES from 'date-fns/locale/es';
import randomize from 'randomatic';

import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturnOutlined';

import Module from '../../_presentationals/Module';
import { openFormDialog, editValueAction, recordsActions } from '../../_actions';
import Snackbar from './../Sales.v2/helpers/Snackbar';

import { config } from './../../_helpers/config';
import { moneyFormat } from './../../_helpers/moneyFormat';

import CreditNoteDialog from './creditNote.dialog';
import Filter from './filter.dialog';
import CancelSaleDialog from './cancelSale.dialog';

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
  const [ saleId, setSaleId ] = useState(null);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  
  const invoices = useMemo(() => {
    return props.salesv2.count > 0 ? props.salesv2.rows.map(el => { 
      console.log(el.uri)
      return {
        ID: { 
          show:false,
          value: el.id,
          data: el,
          showIf: el.sale ? true : false,
        },
        Tipo: {
          value: el.type,
          filter: true,
          _sort: 'text',
          noBreak: true
        },
        Estatus: {
          value: el.status,
          filter: true,
          _sort: 'text',
          noBreak: true
        },
        Fecha: { 
          value: format(parseISO(el.date), "dd/MM/yyyy HH:mm", {locale: localeES}),
          _sort: 'date',
          noBreak: true
        },
        Folio: {
        	value: <a href={el.uri} target="_blank" rel="noopener noreferrer">{el.folio}</a>,
          alt: el.folio,
          _sort: 'text',
          noBreak: true
        },
        Cliente: {
        	value: el.customer,
          _sort: 'text'
        },
        RFC: {
          value: el.identifier,
          _sort: 'text'
        },
        Dirección: {
          value: el.address,
          _sort: 'text'
        },
        Vendedor: {
          value: el.employee,
          _sort: 'text'
        },
        Usuario: {
          value: el.user,
          _sort: 'text'
        },
        Subtotal: {
        	value: moneyFormat(el.subtotal),
          alt: parseFloat(parseFloat(el.subtotal).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Descuento: {
          value: moneyFormat(el.discount),
          alt: parseFloat(parseFloat(el.discount).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Impuestos: {
          value: moneyFormat(el.taxes),
          alt: parseFloat(parseFloat(el.taxes).toFixed(2)),
          align: "right",
          _sort: 'alt'
        },
        Total: {
          value: moneyFormat(el.total),
          alt: parseFloat(parseFloat(el.total).toFixed(2)),
          align: "right",
          _sort: 'alt',
          accumulate: true
        }
      }
    }) : [];

  }, [props.salesv2]);

  const handleDelete = e => {
    const { saleId } = JSON.parse(e.currentTarget.dataset.row);
    setSaleId(saleId);
    props.openFormDialog('CancelSaleDialog');
  }

  const handleCreditNote = e => {
    const { saleId } = JSON.parse(e.currentTarget.dataset.row);
    setSaleId(saleId);
    props.openFormDialog('CreditNotesDialog');
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handelXml = e => {
    const { uuid } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/files/invoices/${uuid}/xml`, '_blank');
  };

  const handleFilter = () => {
    props.openFormDialog('Filter');
  }

  const handleAdd = () => {
    const tmpCode = randomize('*', 10);
    setRefreshCode(tmpCode);
  }

  const handleCloseCancel = () => {
    props.closeFormDialog('CancelSaleDialog');
    setSaleId(null);
  }

  return (
    <Fragment>
      <Module
        table="salesv2"
        name="reporte de facturas"
        records={invoices}
        refreshCode={refreshCode}
        singularName="reporte"
        title="Filtrar Facturas"
        filter={handleFilter}
        create={false}
        edit={false}
        _delete={false}
        download={true}
        actions={[ 
          { icon: <DescriptionIcon fontSize="small" />, label: "Descargar XML", handle: handelXml },
          { showIf: true, icon: <AssignmentReturnIcon fontSize="small" />, label: "Nota de Crédito", handle: handleCreditNote },
          { icon: <DeleteOutlineIcon fontSize="small" />, label: "Cancelar", handle: handleDelete },
        ]}
      />
      <Filter />
      <CancelSaleDialog id={saleId} handleClose={handleCloseCancel} />
      <CreditNoteDialog id={saleId} handleAdd={handleAdd} />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: [] };

const mapStateToProps = state => ({
  salesv2: state.records.salesv2 || defaultTableValue,
  start: state.field.value.start || new Date(),
  finish: state.field.value.finish || new Date(),
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction : value => dispatch(editValueAction(value)),
  _delete: value => dispatch(recordsActions._delete(value)),
  getRecords: value => dispatch(recordsActions.getAll(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);

