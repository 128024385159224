import { isMobile } from "react-device-detect";
export const styles = theme => ({
  actionButton: {
    padding: theme.spacing(1)
  },
  headerTitle: {
    fontSize: "15px",
    color: "#141414"
  },
  success: {
    background: '#ebffeb',
  },
  table: {
  	width: 1800
  },
  tooltip: {
    marginTop: theme.spacing(0.5)
  },
  header: {
    paddingTop: isMobile ? theme.spacing(2) : theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(1.5)
  },
  headerButtons: {
    textAlign: "right"
  }
});