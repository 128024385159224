import React, { useMemo, useState, useEffect } from "react";
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { FormDialog } from '../../_presentationals/FormDialog';
import { styles } from './style';
import * as recordsServices from '../../_services';

import { SelectField, TextField, DateField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { format } from 'date-fns';
import localeES from 'date-fns/locale/es';

import { editValueAction, recordsActions } from './../../_actions';
import { ListItemAdd } from './_ListItemAdd';

import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const mobilityOptions = [
  { key: 1, value: 'Entrega' },
  { key: 2, value: 'Renovación' },
  { key: 3, value: 'Cobranza' }
];

const ProductDialog = ({classes, dataSeries, productId, qtyProduct, editValueAction, getRecords, clearRecords, fields, ...props}) => {
  let [series, setSeries] = useState([]);
  const [qty, setQty] = useState(0);
  const [physicalSerie, setPhysicalSerie] = useState([]);
  const [isAdd, setAdd] = useState(false);

  const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));

  const { countryId } = fields.address || {};

  useEffect(() => {
    const { addressLine1, addressLine2, city, zipCode, countryId, stateId, reference } = fields.address || {};
    editValueAction({ addressLine1, addressLine2, city, zipCode, countryId, stateId, reference });
  }, [fields.address,editValueAction]);

  /*useEffect(() => { getRecords({ table: 'countries' }); return () => clearRecords('countries'); }, [getRecords, clearRecords]);

  const countries = useMemo(() => {
    return props.countries.count > 0 ? props.countries.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.countries]);*/

  useEffect(() => { 
    getRecords({ table: 'states', countryId }); return () => clearRecords('states'); 
  }, [getRecords, countryId, clearRecords]);

  const states = useMemo(() => {
    return props.states.count > 0 ? props.states.rows.map(({id, name}) => ({ key: id, value: name })) : [];
  }, [props.states]);

  const rentPrices = useMemo(() => {
    const tmp = [];
    props.rentPrices[1].map(el => tmp.push({ value: methodToText(el.method), key: el.method }));
    return tmp;
  }, [props.rentPrices]);

  const methodOptions = useMemo(() => {
    const tmp = [{ key: 1, value: "Venta" }];
    if(props.rentPrices[0] > 0){
      tmp.push({ key: 2, value: "Renta" });
    }
    return tmp;
  }, [props.rentPrices]);

  const handleAddProduct = e => {
    setAdd(true);
    const { manufactureId, manufactureIdLabel } = props.selectedProduct;
    if(!manufactureId || parseInt(series.length) + 1 > parseFloat(qtyProduct)) return;

    const index = physicalSerie.findIndex( el => ( parseInt(el.value) === parseInt(manufactureId) ));
    delete physicalSerie[index];
    const tmp = physicalSerie.filter( el => el);
    setPhysicalSerie(tmp);

    series && (series = [ ...series, { manufactureId, manufactureIdLabel } ]);
    editValueAction({ PhysicalSeries: series, manufactureId: null });
    setQty(JSON.stringify(series));
    setSeries(series);
  };
  
  useEffect(() => {
    parseInt(productId) > 0 && recordsServices.getAll({
      table: 'manufactures', productId, status: parseInt(props.methodProduct) === 1 ? 2 : 3,
      methodPlan: props.rentOptions, deliveryDate: props.deliveryDate, quantity: props.planQuantity
    }).then(async data => {
      const tmp = await parseInt(data.count) > 0 ? data.rows.map(el => ({ value: el.id, label: `${el.Product.name} Serie: ${el.physicalSerie} ${el.statusLabel}` })) : [];
      setPhysicalSerie(tmp);
    });
    editValueAction({ PhysicalSeries: [], manufactureId: null });
    setQty(JSON.stringify([]));
    setSeries([]);

  }, [productId, props.methodProduct, props.rentOptions, props.deliveryDate, props.planQuantity, editValueAction]);

  useEffect(() => setAdd(false), [props.isOpen]);

  useEffect(() => {
    if(!isAdd && dataSeries) {
      const tmp = dataSeries.map( el => ( el.manufactureId && ({ manufactureId: el.manufactureId, manufactureIdLabel: el.manufactureIdLabel }) ));
      setQty(JSON.stringify(tmp));
      setSeries(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.isOpen, isAdd]);

  const tableRows = useMemo(() => {    
    const handleDeleteProduct = e => {
      const index = e.currentTarget.dataset.index;
      delete series[index];
      const tmp = series.filter(el => el);
      setQty(JSON.stringify(tmp));
      setSeries([ ...tmp ]);
    };

    return series.map((el, index) => {
      return (<TableRow key={index}>
        <TableCell component="th" scope="row">
          {el.manufactureIdLabel}
        </TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteProduct} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series,qty]);

  return (
    <FormDialog 
      singular="ProductDialog" 
      title="Configuración del Producto" 
      clearDataAfterClose={false}
      handleCustomizedClick={props.handleClick}
    >
      <SelectField 
      	icon="category"
        fields={[{
          id: "methodProduct",
          label: "Modalidad",
          options: methodOptions
        }]}
      />
      {
        (props.methodProduct && props.methodProduct === 2) && 
          <SelectField 
            fields={[{
              id: "rentOptions",
              label: "Plan de Renta",
              options: rentPrices
            }]}
          />
      }
      {
        props.methodProduct && props.methodProduct === 2 && <TextField
          fields={[{
            id: "planQuantity",
            label: "Cantidad de Plan"
          }]}
        />
      }
      <DateField
        id="deliveryDate"
        label="Fecha de Entrega"
        icon="today"
        disablePast={true}
      />
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title}>{`Asignar Series | Cantidad: ${qtyProduct} - Agregados: ${series.length}`}</Typography>
          <ListItemAdd handleClick={handleAddProduct}>
            <Autocomplete
              label="Serie Física" 
              _id="manufactureId" 
              dialog={false}
              options={physicalSerie}
            />
          </ListItemAdd>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <SelectField 
        icon={<LocalShippingIcon />}
        fields={[{
          id: "mobility",
          label: "Movilidad",
          options: mobilityOptions
        }]}
      />
      <TextField
        icon="room"
        fields={[
          {
            id: "addressLine1",
            label: "Calle y Número",
            multiline: true
          },
          {
            id: "addressLine2",
            label: "Colonia",
            multiline: true,
            required: false
          }
        ]}
      />
      <TextField
        fields={[
          {
            id: "city",
            label: "Ciudad",
            required: false
          },
          /*{
            id: "zipCode",
            label: "Código Postal",
            required: false
          }*/
        ]}
      />
      {/*<SelectField
        fields={[{
          id: 'countryId', 
          label: 'País',
          options: countries,
          required: false
        }]}
      />*/}
      <SelectField
        fields={[{
          id: 'stateId',
          label: 'Estado',
          options: states,
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "reference",
          label: "Referencia",
          multiline: true,
          required: false
        }]}
      />
      <SelectField
        fields={[{
          id: 'deliveryTime',
          label: 'Hora de entrega',
          options: serviceTime,
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "deliveryComment",
          label: "Comentario",
          multiline: true,
          required: false
        }]}
      />
    </FormDialog>
  );

}

const methodToText = value => {
  let label = "";
  switch (value){
    case "rentPerEvent":
      label = "Renta por evento (Comercial)";
      break;
    case "rentPer1Month":
      label = "Renta por 1 mes (Comercial)";
      break;
    case "rentPer2Months":
      label = "Renta por 2 meses (Comercial)";
      break;
    case "rentPer3Months":
      label = "Renta por 3 meses (Comercial)";
      break;
    case "rentPer4Months":
      label = "Renta por 4 meses (Comercial)";
      break;
    case "rentPer5Months":
      label = "Renta por 5 meses (Comercial)";
      break;
    case "rentPer6Months":
      label = "renta por 6 meses (Comercial)";
      break;
    case "rentPer7Months":
      label = "Renta por 7 meses (Comercial)";
      break;
    case "rentPer8Months":
      label = "Renta por 8 meses (Comercial)";
      break;
    case "rentPer9Months":
      label = "Renta por 9 meses (Comercial)";
      break;
    case "rentPer10Months":
      label = "Renta por 10 meses (Comercial)";
      break;
    case "rentPer11Months":
      label = "Renta por 11 meses (Comercial)";
      break;
    case "rentPer12Months":
      label = "Renta por 12 meses (Comercial)";
      break;
    case "rentPerEventIndustry":
      label = "Renta por evento (Industria)";
      break;
    case "rentPer1MonthIndustry":
      label = "Renta por 1 mes (Industria)";
      break;
    case "rentPer2MonthsIndustry":
      label = "Renta por 2 meses (Industria)";
      break;
    case "rentPer3MonthsIndustry":
      label = "Renta por 3 meses (Industria)";
      break;
    case "rentPer4MonthsIndustry":
      label = "Renta por 4 meses (Industria)";
      break;
    case "rentPer6MonthsIndustry":
      label = "renta por 6 meses (Industria)";
      break;
    case "rentPer12MonthsIndustry":
      label = "Renta por 12 meses (Industria)";
      break;
    case "rentPer8MonthsIndustry":
      label = "Renta por 8 meses (Industria)";
      break;
    default:
      label = "Indefinido";
      break;
  }
  return label;
};

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  countries: state.records.countries || defaultTableValue,
  states: state.records.states || defaultTableValue,
  rentPrices: state.field.value.rentPrices || [0, []],
  fields: state.field.value,
  methodProduct: state.field.value.methodProduct || 2,
  rentOptions: state.field.value.rentOptions || 'rentPerEvent',
  deliveryDate: state.field.value.deliveryDate || format(new Date(),'yyyy/MM/dd',{locale: localeES}),
  planQuantity: state.field.value.planQuantity || 1,
  selectedProduct: {
    manufactureId: state.field.value.manufactureId, 
    manufactureIdLabel: state.field.value.manufactureIdLabel
  },
  alert: state.alert,
  isOpen: state.formDialog
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  editValueAction: data => dispatch(editValueAction(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductDialog));