import React from "react";

import { FormDialog } from '../../_presentationals/FormDialog';
import { SelectField } from './../../_presentationals/Form';

const Dialog = props =>
  <FormDialog  
    singular="statusFailure" 
    table="vehicleFailures"
    title="Actulizar Estatus"
  >
    <SelectField
      fields={[{
        id: 'status', 
        label: 'Estatus',
        options: [{
          key: 2, value: 'Reparado'
        }]
      }]}
    />
  </FormDialog>

export default Dialog;