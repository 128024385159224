import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import SnackbarUI from '@material-ui/core/Snackbar';

import { Content } from './content';
import { alertActions } from './../../_actions';
import { styles } from './style';

const SnackbarComponent = ({ classes, alert, clearAlert }) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
    const allowedAlert = ['success', 'error', 'default'];
    allowedAlert.includes(alert.type) ? setIsOpen(true) : setIsOpen(false);
  }, [alert]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setIsOpen(false);
    clearAlert();
  };

	return (
		<SnackbarUI
			anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={ classes.snackbar}
      open={ isOpen }
      onClose={handleClose}
      autoHideDuration={ 10000 }
		>
			<Content
        onClose={handleClose}
        variant={ alert.type || "default" }
        message={ alert.message || "Petición realizada con éxito." }
      />
		</SnackbarUI>
	);

}

const mapStateToProps = state => ({ alert: state.alert });
const mapDispatchToProps = dispatch => ({ clearAlert: () => dispatch(alertActions.clear()) });

export const Snackbar = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent));