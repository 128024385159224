const consignedManufactures = ({method, ...props}) => {
  switch (method) {
    case 'GET':
      const query = { 
        productId: props.productId,
        status: 'consigned'
      };

      Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null || query[key] === '' || query[key] === 0) && delete query[key]);      
      let querys = '';
      Object.keys(query).forEach(key => {
        querys += `${key}=${query[key]}&`;
      });
      
      return `products/manufactures?${querys}`;
    default:
      return `products/manufactures`;
  }
}

export default consignedManufactures