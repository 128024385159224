import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import Module from '../../_presentationals/Module';
import { TextField, DateField } from './../../_presentationals/Form';
import { history } from './../../_helpers';
import { moneyFormat } from '../../_helpers/moneyFormat';

const VehicleExpense = props => {
  useEffect(() => {
    props.redirect.id === "" && history.push('/Vehicle');
  }, [props.redirect.id]);

  const vehicleExpenses = useMemo(() => 
    props.vehicleExpenses.count > 0 ? props.vehicleExpenses.rows.map(el => ({
      ID: { 
        show: false,
        value: el.id,
        data: el
      },
      Fecha: {
        value: format(parseISO(el.date), 'dd/MM/yyyy', {locale: localeES})
      },
      Descripción: {
        value: el.commentary
      },
      kilometros: {
        value: el.kilometres
      },
      Total:{
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: "right"
      },
    })) : []
  , [props.vehicleExpenses]);

  return (
    <Module
      edit={false}
      table="vehicleExpenses"
      name="gastos del vehículo"
      singularName="gasto del vehículo"
      filter={false}
      title="Registrar gasto de vehículo"
      records={vehicleExpenses}
    >
      <TextField
        fields={[{
          id: "total",
          label: "Monto del Gasto"
        }]}
      />
      <DateField
        id="date"
        label="Fecha"
        icon="today"
        disablePast={true}
      />
      <TextField
        fields={[{
          id: "kilometres",
          label: "Kilometros del Vehículo",
          required: false
        }]}
      />
      <TextField
        fields={[{
          id: "commentary",
          label: "Descripción",
          required: false
        }]}
      />
    </Module>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  redirect: state.redirect,
  vehicleExpenses: state.records.vehicleExpenses || defaultTableValue,
});

export default connect(mapStateToProps)(VehicleExpense);