import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import Module from '../../_presentationals/Module';
import { SelectField, TextField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import { recordsActions, editValueAction, openFormDialog } from '../../_actions';

import Log from './log';
import ConfirmDialog from './_ConfirmDialog';
import Warranty from './warranty';
import DeleteManucfacture from './_deleteManufacture';
import { FilterDialog } from './filter';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Manufacture = ({
  getRecords,
  updateRecords,
  editValueAction,
  clearRecords,
  openFormDialog,
  ...props
}) => {
  const [spareProducts, setSpareProducts] = useState([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const tmpAction = [];

    if (privileges.manufactures && privileges.manufactures.delete) {
      tmpAction.push({
        icon: <DeleteIcon fontSize='small' />,
        label: 'Eliminar Equipo',
        handle: handleDelete,
      });
    }

    setActions(tmpAction);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    editValueAction({ type: 1 });
    !props.isOpen.Warranty && getRecords({ table: 'products', type: 1 });
  }, [getRecords, props.alert.type, props.isOpen, editValueAction]);

  useEffect(() => {
    getRecords({ table: 'products', type: 1 });
    getRecords({ table: 'employees', employeeRole: 3 });
  }, [getRecords, clearRecords]);

  const products = useMemo(() => {
    return props.products.count > 0
      ? props.products.rows.map(({ id, name, rentPrice }) => ({
          value: id,
          label: name,
          rentPrice,
        }))
      : [];
  }, [props.products]);

  const manufactures = useMemo(
    () =>
      props.manufactures.count > 0
        ? props.manufactures.rows.map(el => ({
            ID: {
              show: true,
              data: el,
              value: el.id,
            },
            'Serie Física': {
              value: el.physicalSerie,
              filter: true,
              _sort: 'text',
            },
            Producto: {
              value: el.Product.name,
              filter: true,
              _sort: 'text',
            },
            Estatus: {
              value: el.status,
              filter: true,
              _sort: 'text',
            },
          }))
        : [],
    [props.manufactures],
  );

  const handleDelete = e => {
    const { id, Pieces } = JSON.parse(e.currentTarget.dataset.row);
    setSpareProducts(Pieces);
    editValueAction({ _id: id });
    openFormDialog('_delete');
  };

  const filter = () => {
    openFormDialog('ManufacturesFilter');
  };

  return (
    <Fragment>
      <Module
        table='manufactures'
        name='Equipos En Bodega'
        records={manufactures}
        singularName='Equipo'
        filter={filter}
        edit={false}
        _delete={false}
        title='Ensamblar Producto'
        download={true}
        actions={actions}
      >
        <Autocomplete icon='poll' label='Producto' _id='productId' options={products} />
        <TextField
          fields={[
            {
              id: 'physicalSerie',
              label: 'Serie Física',
            },
          ]}
        />
        <SelectField
          fields={[
            {
              id: 'status',
              label: 'Renta o Venta',
              options: [
                { key: 2, value: 'Disponible para Venta' },
                { key: 3, value: 'Disponible para Renta' },
              ],
            },
          ]}
        />
      </Module>
      <Log />
      <ConfirmDialog />
      <Warranty />
      <FilterDialog products={products} />
      <DeleteManucfacture spareProducts={spareProducts} />
    </Fragment>
  );
};

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  status: state.field.value.status || 0,
  type: state.field.value.type || 0,
  products: state.records.products || defaultTableValue,
  productId: state.field.value.productId || 0,
  employees: state.records.employees || defaultTableValue,
  manufactures: state.records.manufactures || defaultTableValue,
  alert: state.alert,
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  updateRecords: value => dispatch(recordsActions.update(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  openFormDialog: data => dispatch(openFormDialog(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Manufacture);
