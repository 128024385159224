import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { auth } from './auth.reducer';
import { field } from './field.reducer';
import { formDialog } from './formDialog.reducer';
import { userNoAuth, user } from './user.reducer';
import { profile } from './profile.reducer';
import { progress } from './progress.reducer';
import { records } from './records.reducer';
import { redirect } from './redirect.reducer';

const rootReducer = combineReducers ({
  alert,
  auth,
  field,
  formDialog,
  user,
  userNoAuth,
  profile,
  progress,
  records,
  redirect
});
export default rootReducer;
