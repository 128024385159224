const api = ['businessName', 'identifier', 'email', 'altEmail', 'addressLine1', 'addressLine2', 'city', 'zipCode', 'taxRegime'];

const billingInformations = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      tmp['countryId'] = values['billingCountryId'];
      tmp['stateId'] = values['billingStateId'];
      return tmp;
    default:
      return tmp;
  }
}

export default billingInformations;
