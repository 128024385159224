// const session = JSON.parse(localStorage.getItem('session'));
// const BRANCH_ID = session && session.data.branchId;

const users = ({method, ...props}) => {
  switch (method) {
    case 'GET':
    	return `users/branch`
      // return  `branches/${props.branchId || BRANCH_ID}/users`;
    case 'PUT':
    case 'DELETE':
      return `users/${props._id}`;
    default:
      return 'users';
  }
}

export default users;
