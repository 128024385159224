const api = ['quantityInvoice', 'paymentForm', 'useCdfi', 'commentary'];

const invoices = (method, values) => {
  const paymentMethod = parseInt(values.paymentMethodInvoice) === 2 ? "PPD" : "PUE";
  const tmp = {};
  switch (method) {
    case 'PUT':
      return { 
        paymentForm: values.paymentForm, useCdfi: values.useCdfi, 
        reference: values.reference, commentary:"", paymentMethod
      };
    case 'POST':
      tmp['paymentMethod'] = paymentMethod;
      if (values.creditNotes) {
        const cnTmp = { 
          type: values.type, paymentForm: values.paymentForm, useCdfi: values.useCdfi,
          reference: values.reference, commentary:"", paymentMethod: values.paymentMethodInvoice,
          removeFromInvoice: values.removeFromInvoice || false
        };
        if (values.type === 2) cnTmp['Concepts'] = values.Products;
        if (values.type === 3) cnTmp['bonus'] = values.bonus;
        return cnTmp;
      }

      if (values.checkIns) return { CheckIns: values.checkIns };

      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      return tmp;
    default:
      return tmp;
    }
}

export default invoices;
