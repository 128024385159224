import { userConstants } from '../_constants';

const userNoAuth = (state = {}, action) => {
  switch (action.type) {
    case userConstants.REGISTER_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case userConstants.GETBYID_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state
  }
};

export { userNoAuth, user }