import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import format from 'date-fns/format';

import RoomIcon from '@material-ui/icons/RoomOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import DateTimeField from './../Sales.v2/helpers/DateTimeField';
import TextField from './../Sales.v2/helpers/TextField';
import Autocomplete from './../Sales.v2/helpers/Autocomplete';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  "deliveryCommentary": "",
  "deliveryDate": null,
  "deliveryEmployeeId": null,
  "address": "",
  "collectionDate": null,
  "collectionCommentary": ""
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Edit = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  
  useEffect(() => {
    props.logisticId && recordsServices.getAllV2(`v2/logistics/${props.logisticId}`).then(data => {
      const employee = props.Employees.find(el => el.value === data.deliveryEmployeeId);
      setValues({
        address: data.address,
        deliveryCommentary: data.deliveryCommentary,
        deliveryDate: data.deliveryDate,
        deliveryEmployeeId: employee ? employee : null,
        collectionDate: data.collectionDate,
        collectionCommentary: data.collectionCommentary ? data.collectionCommentary : '',
      })
    })
  }, [props.logisticId, props.Employees]);

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const updateLogistic = async () => {
    try{
      await recordsServices.updateV2(`v2/logistics/${props.logisticId}`, {
        ...values,
        deliveryEmployeeId: values.deliveryEmployeeId ? values.deliveryEmployeeId.value : null
      });
      props.closeFormDialog('Edit');
      props.reload();
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <FormDialog 
      title="Configuración de Logística"
      name="edit"
      singular="Edit"
      handleCustomizedClick={updateLogistic}
    >
      <Autocomplete 
        icon={<AccountCircleIcon />}
        name='deliveryEmployeeId'
        label='Chofer' 
        onChange={handleAutocompleteChange}
        options={props.Employees}
        value={values.deliveryEmployeeId}
      />
      <TextField
        icon={<RoomIcon />}
        name='address'
        onChange={handleChange}
        value={values.address}
        label='Domicilio de Entrega'
      />
      <DateTimeField
        icon={<EventIcon />}
        name='deliveryDate'
        label='Fecha y Hora de Entrega'
        onChange={handleDateChange}
        value={values.deliveryDate}
      />
      <TextField
        name='deliveryCommentary'
        value={values.deliveryCommentary}
        onChange={handleChange}
        label='Comentario de la Logística'
      />
      <DateTimeField
        icon={<EventIcon />}
        name='collectionDate'
        label='Fecha y Hora de Recolección'
        onChange={handleDateChange}
        value={values.collectionDate}
      />
      <TextField
        name='collectionCommentary'
        value={values.collectionCommentary}
        onChange={handleChange}
        label='Comentario para la recolección'
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </FormDialog>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || []
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);