import React, { useEffect, useState, Fragment } from "react";

import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import Autocomplete from './../Sales.v2/helpers/Autocomplete';
import TextField from './../Sales.v2/helpers/TextField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  manufactureId: null,
  authCommentary: '',
}

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = props => {
  
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  const [ manufactures, setManufacures ] = useState([]);
  
  useEffect(() => {
    props.isOpen.Replace && recordsServices.getAllV2(`v2/manufactures?status=3`).then(data => {
      const tmp = data.rows.map(el => ({ label: `${el.Product.name} Serie: ${el.physicalSerie}`, value: el.id }))
      setManufacures(tmp);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const handleAction = async () => {
    try{
      const respose = await recordsServices.updateV2(`v2/logistics/${props.logisticId}/products/${props.logisticProductId}`, {
        ...values,
        manufactureId: values.manufactureId ? values.manufactureId.value : null
      });
      if(respose.errorCode) throw new Error(respose.errorMessage);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: 'El equipo necesita autorización para efectuar el cambio.', 
        severity: 'success' 
      }));
      setValues(defaultValues);
      props.closeFormDialog('Replace');
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog 
        title="Seleccionar Equipo"
        name="replace"
        singular="Replace"
        handleCustomizedClick={handleAction}
      >
        <Autocomplete 
          name='manufactureId'
          label='Equipo' 
          onChange={handleAutocompleteChange}
          options={manufactures}
          value={values.manufactureId}
          noFormat
          variant='outlined'
        />
        <br/>
        <TextField
          name='authCommentary'
          onChange={handleChange}
          value={values.authCommentary}
          label='Justificación'
          noFormat
          variant='outlined'
        />
      </FormDialog>
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);