const api = ['salePrice', 'productionCost'];

const pricesProducts = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'PUT':
      tmp['rentPrice'] = [];
      Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
        let rentArray = {};

        switch (key) {
          case 'rentPerEvent': 
          case 'rentPerEventIndustry': rentArray = { method: key, price: values[key], days: 1 }; break;
          case 'rentPer1Month': 
          case 'rentPer1MonthIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 1).toFixed(2), days: 30 }; break;
          case 'rentPer2Months':
          case 'rentPer2MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 2).toFixed(2), days: 60 }; break;
          case 'rentPer3Months':
          case 'rentPer3MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 3).toFixed(2), days: 90 }; break;
          case 'rentPer4Months':
          case 'rentPer4MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 4).toFixed(2), days: 120 }; break;
          case 'rentPer5Months':
          case 'rentPer5MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 5).toFixed(2), days: 150 }; break;
          case 'rentPer6Months':
          case 'rentPer6MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 6).toFixed(2), days: 180 }; break
          case 'rentPer7Months':
          case 'rentPer7MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 7).toFixed(2), days: 210 }; break;
          case 'rentPer8Months':
          case 'rentPer8MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 8).toFixed(2), days: 240 }; break;
          case 'rentPer9Months':
          case 'rentPer9MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 9).toFixed(2), days: 270 }; break
          case 'rentPer10Months':
          case 'rentPer10MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 10).toFixed(2), days: 300 }; break
          case 'rentPer11Months':
          case 'rentPer11MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 11).toFixed(2), days: 330 }; break
          case 'rentPer12Months': 
          case 'rentPer12MonthsIndustry': 
            rentArray = { method: key, price: parseFloat(values[key] * 12).toFixed(2), days: 360 }; break;
          default: break;
        }
        Object.keys(rentArray).length > 0 && tmp['rentPrice'].push(rentArray);
      });
      tmp['salePrice'] = parseFloat(values['salePrice']).toFixed(2);
      tmp['productionCost'] = parseFloat(values['productionCost']).toFixed(2);
      return tmp;
    default:
      return tmp;
  }
}

export default pricesProducts;
