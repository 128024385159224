import React, { Fragment, useMemo, useState } from "react";
import { connect } from 'react-redux';
import randomize from 'randomatic';

import AppsIcon from '@material-ui/icons/AppsOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';

import Module from '../../_presentationals/Module';
import { ConfirmDialog } from '../../_presentationals/ConfirmDialog';
import * as recordsServices from './../../_services/records.services';
import { moneyFormat } from '../../_helpers/moneyFormat';
import { openFormDialog } from '../../_actions';

import Snackbar from './../Sales.v2/helpers/Snackbar';
import InvoiceData from './../Sales.v2/InvoiceData';

import ProductDialog from './product.dialog';
import PDF from './preNote.pdf';

// const session = JSON.parse(localStorage.getItem('session'));
// const privileges = session && JSON.parse(session.data.privileges);

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}

const Component = ({ editValueAction, ...props }) => {
  const [ saleAuthId, setSaleAuthId ] = useState(null);
  const [ isProductOpen, setIsProductOpen ] = useState([false, null]);
  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ refreshCode, setRefreshCode ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isInvoiceDataOpen, setIsInvoiceDataOpen ] = useState([false, null]);
  // const [ actions, setActions ] = useState([]);

  // useEffect(() => {
  //   const tmpAction = [
  //     { icon: <ZoomInIcon fontSize="small" />, label: "Consultar", handle: preNote },
  //   ];
  //   if(privileges.saleAuths && privileges.saleAuths.authorize){ 
  //     tmpAction.push({ icon: <DoneAllIcon fontSize="small" />, label: "Autorizar", handle: handleAuth });
  //   }
  //   setActions(tmpAction);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); 
  
  const saleAuths = useMemo(() =>
    props.saleAuths.count > 0 ? props.saleAuths.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      Fecha: {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        _sort: 'date'
      },
      Cliente: {
       value: el.Customer ? `${el.Customer.firstName} ${el.Customer.lastName}` : '-',
       filter: true,
       _sort: 'text'
      },
      'Precio Original': {
        value: moneyFormat(el.originalPrice),
        alt: parseFloat(parseFloat(el.originalPrice).toFixed(2)),
        align: 'right',
        _sort: 'alt',
        accumulate: true,
        accumulateLabel: 'Total Original'
      },
      Importe: {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt',
        accumulate: true,
        accumulateLabel: 'Total'
      },
      Utilidad: {
        value: `${moneyFormat(parseFloat(parseFloat(el.utiliy)))}%`,
        align: 'right',
        _sort: 'text'
      },
      'Justificación': {
        value: el.authCommentary,
       filter: true,
       _sort: 'text'
      },
      Usuario: {
        value: el.User ? `${el.User.firstName} ${el.User.lastName}` : '-',
        filter: true,
        _sort: 'text'
      },
      Vendedor: {
        value: el.Employee ? `${el.Employee.firstName} ${el.Employee.lastName}` : '-',
        filter: true,
        _sort: 'text'
      },
      Sucursal: {
        value: el.Branch.name,
        filter: true,
        _sort: 'text'
      }
    })) : []
  , [props.saleAuths]);

  const handleActionAuthorize = async saleAuthId => {
    try{
      setIsLoading(true);
      await recordsServices.updateV2(`v2/saleAuths/${saleAuthId}/authorize`).then(data => {
        if(data.errorCode) throw new Error(data.errorMessage)
        const tmpCode = randomize('*', 10);
        setRefreshCode(tmpCode);
        setIsLoading(false);
        setSaleAuthId(0);
        setSnackbar(snackbar => ({ 
          ...snackbar, 
          isOpen: true, 
          label: 'El CFDi fue emitido con éxito', 
          severity: 'success' 
        }));
        setIsInvoiceDataOpen([true, data.uuid]);
      });
      
    }catch(e){
      setIsLoading(false);
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  }

  const handleAuthorize = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setSaleAuthId(id);
  }

  const handleCancelAuthorize = () => setSaleAuthId(null);

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }

  const handleProducts = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    setIsProductOpen([true, id]);
    props.openFormDialog('Product');
  }

  const handleInvoiceDataClose = () => {
    setIsInvoiceDataOpen([false, null]);
  }

  // const handleAuth = e => {
  //   const { id } = JSON.parse(e.currentTarget.dataset.row);
  //   editValueAction({ _id: id, id });
  //   props.openFormDialog('AuthDialog');
  // }
  
  const preNote = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    PDF(id);
  };

  return (
    <Fragment>
      <Module
        table="saleAuthsV2"
        name="Autorización de Facturas"
        records={saleAuths}
        singularName="Autorización"
        create={false}
        edit={false}
        _delete={true}
        download={true}
        filter={false}
        actions={[
          { icon: <AppsIcon fontSize="small" />, label: "Productos", handle: handleProducts },
          { icon: <DoneAllIcon fontSize="small" />, label: "Autorizar", handle: handleAuthorize },
          { icon: <ZoomInIcon fontSize="small" />, label: "Consultar", handle: preNote }
        ]}
        refreshCode={refreshCode}
      />
      <ConfirmDialog 
        _id={saleAuthId} 
        isLoading={isLoading}
        handleCancel={handleCancelAuthorize} 
        handleAction={handleActionAuthorize} 
      />
      <ProductDialog 
        saleAuthId={isProductOpen[1]} 
      />
      <Snackbar 
        isOpen={snackbar.isOpen} 
        label={snackbar.label} 
        severity={snackbar.severity} 
        time={3000} 
        handleClose={handleCloseSnackbar}
      />
      <InvoiceData isOpen={isInvoiceDataOpen[0]} uuid={isInvoiceDataOpen[1]} handleClose={handleInvoiceDataClose} />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  saleAuths: state.records.saleAuthsV2 || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Component);