const inventories = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'PUT':
      tmp['quantity'] = values['quantity'];
      return tmp;
    default:
      return tmp;
  }
}

export default inventories;
