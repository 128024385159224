import React from "react";

import { FormDialog } from './../../_presentationals/FormDialog';
import { TextField } from './../../_presentationals/Form';

const InventoryDialog = () => 
  <FormDialog
    title="Actualizar inventario"
    name="InventoryDialog"
    singular="InventoryDialog"
    table="inventories"
  >
    <TextField
      fields={[{
        id: "quantity",
        label: "Cantidad",
        required:false
      }]}
    />
  </FormDialog>

export default InventoryDialog;
