import React from "react";


import { FormDialog } from '../../_presentationals/FormDialog';

import { SelectField, TextField, DateField } from './../../_presentationals/Form';

// import { editValueAction, recordsActions } from './../../_actions';

const CollectionLogistic = props => {
  const serviceTime = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"].map(el => ({ key: `${el}:00:00`, value: `${el}:00` }));

  return (
    <FormDialog 
      title="Recolección"
      name="collectionLogistic"
      singular="CollectionLogistic"
      table="logistic"
    >
      <TextField
        fields={[{
          id: "receptionName",
          label: "Nombre de quien recibe",
          required: false
        }]}
      />
       <DateField
        id="collectionDate"
        label="Fecha de recolección"
        icon="today"
        disablePast={true}
      />
      <SelectField
        fields={[{
          id: 'collectionTime', 
          label: 'Hora de recolección',
          options: serviceTime
        }]}
      />
      <TextField
        fields={[{
          id: "collectionCommentary",
          label: "Comentario",
          required: false
        }]}
      />
    </FormDialog>
  );
}


export default CollectionLogistic;