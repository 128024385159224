import React, { PureComponent, Fragment } from "react";
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { userActions, authActions, validateFieldAction } from "./../../_actions";
import Background from './../../_presentationals/Background';
import { TextField } from './../../_presentationals/Form';

import { history } from './../../_helpers';

class forgotPassword extends PureComponent {

  handleClickStage = () => {
    alert("En desarrollo");
  }

  returnWindow = () => {
    history.push('/public/signIn');
  }

  render() {
    const { classes } = this.props;
    return (
      <Background
        footer={ <Fragment>¿Quieres iniciar sesión? <Link href="#" onClick={ this.returnWindow } className={ classes.bodyLink } underline="always">Clic aquí.</Link></Fragment> }
      >
        <Typography variant="h4" align="center" className={ classes.typographyTitle1 } gutterBottom>
          Recuperar contraseña
        </Typography>
        <Typography variant="subtitle1" align="center" className={ classes.typographySubtitle1 }>
          Un correo de recuperación será enviado a la dirección proporcionada.
        </Typography>
        <br/>
        <Fragment>
          <TextField
            dialog={false}
            fields={[{
              id: "username",
              label: "Correo Electrónico",
              variant: "outlined",
              autoFocus: true
            }]}
          />
          <Button color="primary" onClick={this.handleClickStage} className={classes.registerButton} size="large" fullWidth>
            Siguiente
          </Button>
        </Fragment>
      </Background>
    );
  }
}


const mapStateToProps = state => ({
  phoneNumber: state.field.value.username,
  verificationCode: state.field.value.verificationCode,
  readyToSMS: state.field.validation.readyToSMS,
  readyToRecover: state.field.validation.readyToRecover,
  phoneNumberValidation: state.field.validation.username ? state.field.validation.username.isError : true
});

const mapDispatchToProps = dispatch => ({
  verifyUsername: data => dispatch(userActions.verifyUsername(data)),
  passwordRecovery: data => dispatch(authActions.passwordRecovery(data)),
  validateFieldAction: data => dispatch(validateFieldAction(data)),
  forgotPasswordByPhone: data => dispatch(userActions.forgotPasswordByPhone(data))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(forgotPassword));
