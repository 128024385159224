const manufactures = (method, values) => {
	const tmp = {};
  switch (method) {
    case 'PUT':
      if(values.isMigration) return { Manufactures: values.manufacuresTr }
      if(values.isDelete) {
        return { spareProducts: values.spareProducts };
      }
    	tmp['physicalSerie'] = values.physicalSerie;
    	tmp['status'] = values.status;
      tmp['employeeId'] = values.employeeId;
    	return tmp;
    case 'POST':
      if(values.productId) {
        tmp['type'] = values.type;
        tmp['physicalSerie'] = values.physicalSerie;
        tmp['status'] = values.status;
        tmp['rentPlan'] = values.rentPlan;
        tmp['customerId'] = values.customerId;
        tmp['createdAt'] = values.createdAt;
      }
      if(values.isWarranty) {
        tmp['commentary'] = values.commentary;
        tmp['Pieces'] = values.Pieces;
      }
      return tmp;
    default:
      return tmp;
    }
}

export default manufactures;
