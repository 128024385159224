import { alertConstants } from '../_constants';

const pending = message => ({ type: alertConstants.PENDING, message });
const success = message => ({ type: alertConstants.SUCCESS, message });
const error = (message = "Ha ocurrido un error") => ({ type: alertConstants.ERROR, message: message });
const clear = () => ({ type: alertConstants.CLEAR });

export const alertActions = {
    pending,
    success,
    error,
    clear
};