import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';

import ProductIcon from '@material-ui/icons/ToysOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

import { redirectActions } from '../../_actions';
import { history } from './../../_helpers';
import Module from '../../_presentationals/Module/index.v2';
import { TextField } from './../../_presentationals/Form/TextField';
import { SelectField } from './../../_presentationals/Form/SelectField';
import { countriesSelectField, statesSelectField } from '../../_helpers';

import * as recordsServices from '../../_services';
import SamplePdf from './sample.pdf';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Component = props => {

  const [ samples, setSamples ] = useState([]);
  const [ loadData, setLoadData ] = useState(0);
  // eslint-disable-next-line
  const states = useCallback(statesSelectField(props.countryId), [props.countryId]);

  const redirect = e => {
    const data = JSON.parse(e.currentTarget.dataset.row);
    props.setRedirect({id: data.id, table: 'sampleManufactures', returnTo: '/Sample', returnToLabel: 'Muestras'});
    history.push('/Sample/Manufacture'); 
  };

  const handelPdf = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    SamplePdf(id);
  }

  useEffect(() => {
    (props.alert === "success" || !loadData) && 
    recordsServices.getAll({ table: 'samples' }).then(data => {
      data.count > 0 && setSamples(data.rows.map(el => ({
        ID: { 
          value: el.id,
          data: el,
          show: false,
        }, 
        Nombre: {
         value: el.name,
         filter: true,
         _sort: 'text'
        },
        Teléfono: { 
          value: el.phone, 
          _sort: 'text'
        },
        Domicilio:{
          value: el.addressLine1 + (el.addressLine2 !== "" ? ', ' + el.addressLine2 : "") + (el.city !== "" ? ', ' + el.city : "") + (el.zipCode !== "" ? ', ' + el.zipCode : "")
        }
      })));
      setLoadData(1);
    });
  }, [loadData, props.alert]);

  return (
    <Module
      table="samples"
      name="Muestras"
      singularName="Muestra"
      title="Crear Muestra"
      records={samples}
      download={true}
      filter={false}
      actions={ (privileges.consignmentManufactures && privileges.consignmentManufactures.read) && [
        { icon: <PictureAsPdfIcon fontSize="small" />, label: "PDF", handle: handelPdf },
        { icon: <ProductIcon fontSize="small" />, label: "Asignar Equipos", handle: redirect },
      ]}
    >
      <TextField
        icon="account_circle"
        fields={[{
          id: "name", 
          label: "Nombre",
          autoFocus: true
        }]}
      />
      <TextField
        icon="phone"
        fields={[{
          id: "phone",
          label: "Teléfono"
        }]}
      />
      <TextField
          icon="room"
          fields={[
            {
              id: "addressLine1",
              label: "Calle y Número",
              multiline: true,
              required: false
            },
            {
              id: "addressLine2",
              label: "Colonia",
              multiline: true,
              required: false
            }
          ]}
        />
        <TextField
          fields={[
            {
              id: "city",
              label: "Ciudad",
              required: false
            },
            {
              id: "zipCode",
              label: "Código Postal",
              required: false
            }
          ]}
        />
        <SelectField
          fields={[{
            id: 'countryId', 
            label: 'País',
            options: countriesSelectField,
            required: false
          }]}
        />
        <SelectField
          fields={[{
            id: 'stateId',
            label: 'Estado',
            options: states,
            required: false
          }]}
        />
        <TextField
          fields={[{
            id: "reference",
            label: "Referencia",
            multiline: true,
            required: false
          }]}
        />
    </Module>
  );
}

const mapStateToProps = state => ({
  countryId: state.field.value.countryId || 0,
  alert: state.alert.type
});

const mapDispatchToProps = dispatch => ({
  setRedirect: data => dispatch(redirectActions.setRedirect(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Component);