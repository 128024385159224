import React, { Fragment, useState } from "react";
import randomize from 'randomatic';
import { connect } from 'react-redux';

import { FormDialog } from '../../_presentationals/FormDialog';
import * as recordsServices from './../../_services/records.services';
import { closeFormDialog } from './../../_actions';

import SelectField from './../Sales.v2/helpers/SelectField';
import Snackbar from './../Sales.v2/helpers/Snackbar';

const defaultValues = {
  status: "",
}

const statusOptions = [
  { value: 5, label: "No Autorizado" },
];

const defaultSnackbar = {
  isOpen: false,
  label: '',
  severity: 'success'
}


const Component = props => {

  const [ snackbar, setSnackbar ] = useState(defaultSnackbar);
  const [ values, setValues ] = useState(defaultValues);
  
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleCloseSnackbar = () => {
    setSnackbar(defaultSnackbar);
  }
  
  const handleAction = async () => {
    try{
      const respose = await recordsServices.updateV2(`v2/serviceOrders/${props.serviceOrderId}/status?status=${values.status}`);
      if(respose.errorCode) throw new Error(respose.errorMessage);
      setSnackbar(snackbar => ({ 
        ...snackbar, 
        isOpen: true, 
        label: respose.message, 
        severity: 'success' 
      }));
      setValues(defaultValues);
      props.closeFormDialog('Status');
      const tmpCode = randomize('*', 10);
      props.reload(tmpCode);
    }catch(e){
      setSnackbar(snackbar => ({ ...snackbar, isOpen: true, label: e.message, severity: 'warning' }));
    }
  };

  return (
    <Fragment>
      <FormDialog
        title="Cambiar Estatus"
        name="status"
        singular="Status"
        handleCustomizedClick={handleAction}
      >
        <SelectField 
          name='status'
          label='Estatus' 
          onChange={handleChange}
          options={statusOptions}
          value={values.status}
        />
      </FormDialog>
      <Snackbar 
          isOpen={snackbar.isOpen} 
          label={snackbar.label} 
          severity={snackbar.severity} 
          time={3000} 
          handleClose={handleCloseSnackbar}
        />
    </Fragment>
  );

}

const mapStateToProps = state => ({
  fields: state.field.value || [],
  isOpen: state.formDialog,
});

const mapDispatchToProps = dispatch => ({ 
  closeFormDialog: data => dispatch(closeFormDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);