const api = [ 'externalFolio', 'amount', 'paymentMethod', 'customerId' ];

const independentPayments = (method, values) => {
  const tmp = {};
  switch (method) {
    case 'POST':
    case 'PUT':
     Object.keys(values).forEach(key => {
        if(api.includes(key)) { tmp[key] = values[key];}
      });
      api.forEach(key =>{
        if(!Object.keys(tmp).includes(key)) tmp[key] = "";
      });
      if(values.currencyKey) tmp['currencyKey'] = values.currencyKey;
      if(values.exchangeRate) tmp['exchangeRate'] = values.exchangeRate;

      return tmp;
    default:
      return tmp;
  }
}

export default independentPayments;
