const session = JSON.parse(localStorage.getItem('session'));
const BRANCH_ID = session && session.data.branchId;

const pricesProducts = ({method, ...props}) => {
  switch (method) {
    case 'PUT':
      return `products/${props.id}/branches/${BRANCH_ID}/prices`;
    case 'GET':
      return `products`
    //case 'PUT':
    //case 'DELETE':
      //return `groups/customers/${props._id}`;
    default:
      return null;
  }
}

export default pricesProducts;
