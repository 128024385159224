import React, { useState } from 'react';

import randomize from 'randomatic';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import useStyles from './style';

import Customer from './Customer';
import Employee from './Employee';
import Currency from './Currency';
import PaymentMethod from './PaymentMethod';
import Setting from './Setting';
import Cart from './Cart';
import Copyright from './Copyright';
import ConfirmSale from './ConfirmSale';

import TextField from './helpers/TextField';

const defaultValues = {
  customerId: null,
  address: '',
  iva: 16,
  catchmentMethod: 1,
  currency: 'MXN',
  exchange: 1,
  commentary: '',
  employeeId: null,
  daysToPay: 0,
  paymentMethod: 1,
  rentPlanPaymentMethod: 1,
  deliveryCommentary: '',
  deliveryDate: null,
  mobility: 1,
  Cart: [],
};

const SalesV2 = props => {
  const classes = useStyles();

  const [values, setValues] = useState(defaultValues);

  const [action, setAction] = useState(1);
  const [isCustomerOpen, setIsCustomerOpen] = useState(false);
  const [isEmployeeOpen, setIsEmployeeOpen] = useState(false);
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [isPaymentMethodOpen, setIsPaymentMethodOpen] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isConfirmSaleOpen, setIsConfirmSaleOpen] = useState(false);
  const [codeToClear, setCodeToClear] = useState(null);

  const handleChooseCustomer = data => {
    setValues(values => ({
      ...values,
      customerId: data.customerId.value,
      address: data.customerId.address,
      catchmentMethod: data.catchmentMethod,
      iva: data.customerId.iva,
    }));
    handleCloseCustomer();
  };

  const handleChooseEmployee = employeeId => {
    setValues(values => ({ ...values, employeeId: employeeId.value }));
    handleCloseEmployee();
  };

  const handleChooseCurrency = data => {
    setValues(values => ({ ...values, currency: data.currency, exchange: data.exchange }));
    handleCloseCurrency();
  };

  const handleChoosePaymentMethod = data => {
    setValues(values => ({ ...values, ...data }));
    handleClosePaymentMethod();
  };

  const handleChooseSetting = data => {
    setValues(values => ({ ...values, ...data }));
    handleCloseSetting();
  };

  const handleChooseConfirmSale = () => {
    handleCloseConfirmSale();
  };

  const handleCloseCustomer = () => setIsCustomerOpen(false);
  const handleCloseEmployee = () => setIsEmployeeOpen(false);
  const handleCloseCurrency = () => setIsCurrencyOpen(false);
  const handleClosePaymentMethod = () => setIsPaymentMethodOpen(false);
  const handleCloseSetting = () => setIsSettingOpen(false);
  const handleCloseConfirmSale = () => setIsConfirmSaleOpen(false);

  const handleActionChange = (e, newAction) => {
    switch (parseInt(newAction)) {
      case 1:
        setIsCustomerOpen(true);
        break;
      case 2:
        setIsEmployeeOpen(true);
        break;
      case 3:
        setIsCurrencyOpen(true);
        break;
      case 4:
        setIsPaymentMethodOpen(true);
        break;
      case 5:
        setIsSettingOpen(true);
        break;
      default:
        break;
    }
    setAction(newAction);
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  };

  const getCart = data => {
    setValues(values => ({ ...values, Cart: data }));
  };

  const handleConfirmSale = () => {
    setIsConfirmSaleOpen(true);
  };

  const clearForm = () => {
    const tmpCode = randomize('*', 10);
    setCodeToClear(tmpCode);
    setValues(defaultValues);
  };

  return (
    <div className={classes.container}>
      <div className={classes.spaceBetween}>
        <div>
          <Typography variant='h4'>Facturación</Typography>
        </div>
        <div>
          <Button
            variant='outlined'
            color='secondary'
            size='large'
            className={classes.outlinedButton}
            onClick={handleConfirmSale}
          >
            Emitir Factura
          </Button>
        </div>
      </div>
      <Card className={classes.cardHeader}>
        <div>
          <Tabs value={action} onChange={handleActionChange} aria-label='Opciones de facturación'>
            <Tab label='Cliente' value={1} className={classes.tabs} />
            <Tab label='Vendedor' value={2} className={classes.tabs} />
            <Tab label='Moneda' value={3} className={classes.tabs} />
            <Tab label='Método de Pago' value={4} className={classes.tabs} />
            <Tab label='Logística' value={5} className={classes.tab} />
          </Tabs>
        </div>
      </Card>
      <Card className={classes.card}>
        <Cart
          getCart={getCart}
          currency={values.currency}
          iva={values.iva}
          deliveryDate={values.deliveryDate}
          mobility={values.mobility}
          codeToClear={codeToClear}
          rentPlanPaymentMethod={values.rentPlanPaymentMethod}
        />
      </Card>
      <Card className={classes.card}>
        <div className={classes.commentaryContent}>
          <TextField
            noFormat
            name='commentary'
            label='Comentarios (Orden de servicio, etc.)'
            variant='outlined'
            onChange={handleChange}
            value={values.commentary}
            multiline={true}
            maxRows={2}
            minRows={2}
          />
        </div>
      </Card>
      <div className={classes.copyrightContent}>
        <Copyright />
      </div>
      <Customer
        isOpen={isCustomerOpen}
        handleAction={handleChooseCustomer}
        handleClose={handleCloseCustomer}
        codeToClear={codeToClear}
      />
      <Employee
        isOpen={isEmployeeOpen}
        handleAction={handleChooseEmployee}
        handleClose={handleCloseEmployee}
        codeToClear={codeToClear}
      />
      <Currency
        isOpen={isCurrencyOpen}
        handleAction={handleChooseCurrency}
        handleClose={handleCloseCurrency}
        codeToClear={codeToClear}
      />
      <PaymentMethod
        isOpen={isPaymentMethodOpen}
        handleAction={handleChoosePaymentMethod}
        handleClose={handleClosePaymentMethod}
        codeToClear={codeToClear}
      />
      <Setting
        isOpen={isSettingOpen}
        handleAction={handleChooseSetting}
        handleClose={handleCloseSetting}
        address={values.address}
        codeToClear={codeToClear}
      />
      <ConfirmSale
        isOpen={isConfirmSaleOpen}
        handleAction={handleChooseConfirmSale}
        handleClose={handleCloseConfirmSale}
        values={values}
        clearForm={clearForm}
        codeToClear={codeToClear}
        iva={values.iva}
      />
    </div>
  );
};

export default SalesV2;
