import React, { Fragment, useEffect, useMemo } from "react";
import { connect } from 'react-redux';

import Module from '../../_presentationals/Module';
import { recordsActions, editValueAction, openFormDialog } from '../../_actions';
import statusLabel from '../Manufacture.v2/_helpers';

const Manufacture = ({ getRecords, editValueAction, clearRecords, ...props }) => {
  useEffect(() => { 
    editValueAction({ type: 1 });
    !props.isOpen.Warranty && getRecords({ table: 'products', type: 1 });
  },[ getRecords, props.alert.type, props.isOpen, editValueAction ]);

  useEffect(() => { 
    getRecords({ table: 'products', type: 1 });
    getRecords({ table: 'employees', employeeRole: 3 });
  }, [getRecords, clearRecords]);

  const manufactureConsigmentV2 = useMemo(() =>
    props.manufactureConsigmentV2.count > 0 ? props.manufactureConsigmentV2.rows.map(el => ({
      ID:{
        show: false,
        data: el,
        value: el.id
      },
      Producto:{
        value: el.Product.name,
        filter: true,
       _sort: 'text'
      },
      "Serie Física":{
        value: el.physicalSerie,
        filter: true,
       _sort: 'text'
      },
      Sucursal:{
        value: el.Branch.name,
       _sort: 'text'
      },
      Estatus:{
        value: statusLabel(el.status),
       _sort: 'text'
      },
      // 'Plan de Renta':{
      //   value: !el.logisticV2.rentPlan ? '-' : el.logisticV2.isRent ? rentPlanToLabel(el.logisticV2.rentPlan)[0] : el.logisticV2.rentPlan,
      //  _sort: 'text'
      // },
      Prestador:{
        value: el.logisticV2.customer ? el.logisticV2.customer : '-',
       _sort: 'text'
      },
    })) : []
  ,[props.manufactureConsigmentV2]);

  return (
    <Fragment>
      <Module
        table="manufactureConsigmentV2"
        name="Equipos Consignados"
        records={manufactureConsigmentV2}
        singularName="Equipo"
        filter={false}
        edit={false}
        _delete={false}
        title="Ensamblar Producto"
        create={false}
        download={true}
      >
      </Module>
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  status: state.field.value.status || 0,
  type: state.field.value.type || 0,
  products: state.records.products || defaultTableValue,
  productId: state.field.value.productId || 0,
  employees: state.records.employees || defaultTableValue,
  manufactureConsigmentV2: state.records.manufactureConsigmentV2 || defaultTableValue,
  alert: state.alert,
  isOpen: state.formDialog, 
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  updateRecords: value => dispatch(recordsActions.update(value)),
  editValueAction: data => dispatch(editValueAction(data)),
  clearRecords: value => dispatch(recordsActions.clearRecords(value)),
  openFormDialog: (data) => dispatch(openFormDialog(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Manufacture);