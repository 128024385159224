import React, { useEffect, Fragment, useState, useMemo } from "react";
import { connect } from 'react-redux';
import { recordsActions } from '../../_actions';

import Icon from '@material-ui/core/Icon';
import { TextField } from './../../_presentationals/Form';
import Autocomplete from './../../_presentationals/Form/AutocompleteFieldV2';
import Grid from '@material-ui/core/Grid';
import { ListItemAdd } from './listItemAdd';
import { editValueAction } from './../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { moneyFormat } from '../../_helpers/moneyFormat';

const Product = ({ getRecords, editValueAction, ...props }) => {
  const [local, setLocal] = useState([]);
  const [qty, setQty] = useState(0);
  
  const handleAddProduct = e => {
    const { productId, productIdLabel, quantity = 0, price = 0, discount = 0, name } = props.selectedProduct;
    
    if(
      ((props.type === 1 || !props.type) && !productId) || (props.type === 2 && (!name || name === ''))
    ) { return; }

    const tmp = parseFloat(quantity) * parseFloat(price);
    const totalTmp = (parseFloat(tmp) - (parseFloat(tmp) * (parseFloat(discount)/100)));
    const iva = parseFloat(tmp) * .16;
    const total = parseFloat(totalTmp) * 1.16;

    const product = { productId, name: name || productIdLabel, quantity: parseFloat(quantity), price: parseFloat(price), discount: parseFloat(discount), iva: parseFloat(iva), total: parseFloat(total) };

    local && local.push(product);
    editValueAction({ Products: local, name: '', quantity: 0, price: 0, discount: 0 });
    setQty(JSON.stringify(local));
    setLocal(local);
  };
  
  const handleAddService = e => {
    const { vehicleId, vehicleIdLabel, serviceDescription, quantity = 0, price = 0, discount = 0 } = props.selecteVehicle;
    
    if(((props.type === 3 || !props.type) && !vehicleId)) { return; }

    const tmp = parseFloat(quantity) * parseFloat(price);
    const total = (parseFloat(tmp) - (parseFloat(tmp) * (parseFloat(discount)/100)));

    const service = { vehicleId, vehicle: vehicleIdLabel, service: serviceDescription, quantity: parseFloat(quantity), price: parseFloat(price), discount: parseFloat(discount), total: parseFloat(total) };

    local && local.push(service);
    editValueAction({ Products: local, serviceDescription: '', quantity: 0, price: 0, discount: 0 });
    setQty(JSON.stringify(local));
    setLocal(local);
  };

  useEffect(() => { 
    editValueAction({Products: [], quantity: 0, price: 0, discount: 0 });
    setLocal([]);
  }, [props.type, editValueAction]);

  useEffect(() => { 
    getRecords({ table: 'products' }); 
    getRecords({ table: 'vehicles' }); 
  }, [getRecords]);

  const products = useMemo(() => {
    return (props.products.count > 0 ? props.products.rows.map(el => ({
      value: el.id, 
      label: el.name
    })) : [])
  }, [props.products]);

  const vehicles = useMemo(() => {
    return (props.vehicles.count > 0 ? props.vehicles.rows.map(el => ({
      value: el.id, 
      label: `${el.name} ${el.brand} ${el.model}`
    })) : [])
  }, [props.vehicles]);

  const tableRows = useMemo(() => {    
    const handleDeleteItem = e => {
      const index = e.currentTarget.dataset.index;
      delete local[index];
      const tmp = local.filter(el => el);
      setQty(JSON.stringify(tmp));
      setLocal(tmp);
    }; 

    return local.map((el, index) => {
      return (<TableRow key={index}>
        {props.type === 3  && (
          <TableCell component="th" scope="row">
            {el.vehicle}
          </TableCell>
        )} 
        <TableCell component="th" scope="row">
          {props.type !== 3 ? el.name : el.service}
        </TableCell>
        <TableCell align="right">{moneyFormat(el.price)}</TableCell>
        <TableCell align="center">{el.quantity}</TableCell>
        <TableCell align="right">{el.discount}</TableCell>
        <TableCell align="right">{moneyFormat(el.iva)}</TableCell>
        <TableCell align="right">{moneyFormat(el.total)}</TableCell>
        <TableCell margin="none" align="right">
          <Tooltip title="Eliminar" aria-label="Eliminar">
            <IconButton style={{ margin: 0 }} onClick={handleDeleteItem} data-index={index} aria-label="delete">
              <Icon className="material-icons-outlined" fontSize="small">delete</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, qty, props.type]);

  return (
    <Fragment>
      <ListItemAdd handleClick={props.type !== 3 ? handleAddProduct : handleAddService}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {(props.type === 1 || !props.type) && (
              <Autocomplete
                label="Producto" 
                _id="productId" 
                dialog={false}
                options={products}
                variant="outlined"
              />
            )}
            {([2,4].includes(props.type)) && (
              <TextField
                dialog={false}
                fields={[{
                  id: "name", label: "Producto",
                  variant: "outlined", margin: "dense"
                }]}
              />
            )}
            {(props.type === 3) && (
              <Autocomplete
                label="Vahículo" 
                _id="vehicleId" 
                dialog={false}
                options={vehicles}
                variant="outlined"
              />
            )}
          </Grid>
          {(props.type === 3) && (
            <Grid item xs={12}>
              <TextField
                dialog={false}
                fields={[{
                  id: "serviceDescription", label: "Servicio",
                  variant: "outlined", margin: "dense", grid: 12
                }]}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              dialog={false}
              fields={[{
                id: "quantity", label: "Cant.",
                variant: "outlined", margin: "dense", grid: 4
              },{
                id: "price", label: "Precio",
                variant: "outlined", margin: "dense", grid: 4
              },{
                id: "discount", label: "Desc.",
                variant: "outlined", margin: "dense", grid: 4
              }]}
            />
          </Grid>
        </Grid>
      </ListItemAdd>
      <Table size="small">
        <TableHead>
          <TableRow>
            {props.type === 3 && <TableCell>Vahículo</TableCell>}
            <TableCell>{props.type === 3 ? 'Servicio' : 'Producto'}</TableCell>
            <TableCell align="right">Precio</TableCell>
            <TableCell align="center">Cant.</TableCell>
            <TableCell align="right">Desc.</TableCell>
            <TableCell align="right">IVA</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </Fragment>
  );
}

const defaultTableValue = { count: 0, rows: [] };

const mapStateToProps = state => ({
  type: state.field.value.type || 0,
  products: state.records.products || defaultTableValue,
  vehicles: state.records.vehicles || defaultTableValue,
  selectedProduct: {
    name: state.field.value.name, 
    productId: state.field.value.productId, 
    productIdLabel: state.field.value.productIdLabel, 
    quantity: state.field.value.quantity, 
    price: state.field.value.price, 
    discount: state.field.value.discount
  },
  selecteVehicle: {
    serviceDescription: state.field.value.serviceDescription, 
    vehicleId: state.field.value.vehicleId, 
    vehicleIdLabel: state.field.value.vehicleIdLabel, 
    quantity: state.field.value.quantity, 
    price: state.field.value.price, 
    discount: state.field.value.discount
  }
});

const mapDispatchToProps = dispatch => ({
  getRecords: value => dispatch(recordsActions.getAll(value)),
  editValueAction: data => dispatch(editValueAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Product);