import React, { useEffect, Fragment, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { recordsActions, editValueAction, openFormDialog } from '../../_actions';

import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';

import Module from '../../_presentationals/Module';
import { moneyFormat } from '../../_helpers/moneyFormat';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

import AuthDialog from './auth.dialog';
import PDF from './preNote.pdf';
import { config } from './../../_helpers/config';

const session = JSON.parse(localStorage.getItem('session'));
const privileges = session && JSON.parse(session.data.privileges);

const Component = ({ updateRecords, editValueAction, ...props }) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const tmpAction = [
      { icon: <ZoomInIcon fontSize="small" />, label: "Nota Principal", handle: details },
      { icon: <PictureAsPdfIcon fontSize="small" />, label: "Consultar", handle: preNote },
    ];
    if(privileges.pendingSales && privileges.pendingSales.createSale){ 
      tmpAction.push({ icon: <DoneAllIcon fontSize="small" />, label: "Genrar Venta", handle: handleCreateSale });
    }
    setActions(tmpAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const pendingSales = useMemo(() =>
    props.pendingSales.count > 0 ? props.pendingSales.rows.map(el => ({
      ID: {
        show: false,
        value: el.id,
        data: {
          ...el
        }
      },
      Fecha: {
        value: format( parseISO(el.createdAt), 'dd/MM/yyyy HH:mm', {locale: localeES} ),
        _sort: 'date'
      },
      NV: {
        value: el.saleFolio,
        _sort: 'date'
      },
      Cliente: {
       value: el.customer,
       filter: true,
       _sort: 'text'
      },
      Pago: {
        value: `${el.payment} / ${el.totalPayments}`,
        align: 'center'
      },
      "Importe": {
        value: moneyFormat(el.total),
        alt: parseFloat(parseFloat(el.total).toFixed(2)),
        align: 'right',
        _sort: 'alt',
        accumulate: true,
        accumulateLabel: 'Total'
      },
      Vendedor: {
        value: el.employee,
        filter: true,
        _sort: 'text'
      },
    })) : []
  , [props.pendingSales]);

  const handleCreateSale = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    editValueAction({ _id: id, id });
    props.openFormDialog('CreateDialog');
  }
  const preNote = e => {
    const { id } = JSON.parse(e.currentTarget.dataset.row);
    PDF(id);
  };

  const details = e => {
    const { saleId } = JSON.parse(e.currentTarget.dataset.row);
    window.open(`${config.apiUrl}/notes/sales/${saleId}`, '_blank');
  };

  return (
    <Fragment>
      <Module
        table="pendingSales"
        name="Rentas Mensuales Pendientes"
        records={pendingSales}
        singularName="Pendientes"
        create={false}
        edit={false}
        _delete={true}
        download={true}
        filter={false}
        actions={actions}
      />
      <AuthDialog />
    </Fragment>
  );
}

const defaultTableValue = {count: 0, rows: []};

const mapStateToProps = state => ({
  pendingSales: state.records.pendingSales || defaultTableValue
});

const mapDispatchToProps = dispatch => ({
  openFormDialog: (data) => dispatch(openFormDialog(data)),
  editValueAction: data => dispatch(editValueAction(data)),
  updateRecords: table => dispatch(recordsActions.update(table)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);